import {createSvgIcon} from '@mui/material/utils';
import React from "react";


export default function CheckBoxIcon(props) {
    const Icon = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
        >
            <rect x="4" y="4" width="16" height="16"
                  rx="3.5" fill="currentColor" stroke="currentColor"/>
        </svg>,
        'CheckBox',
    )
    return <Icon {...props} data-cy="check-box-icon"/>
}