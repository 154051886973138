import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import MuiTextField from '@mui/material/TextField'
import {useState} from 'react'
import IconButton from '@mui/material/IconButton'

import ArrowDownSmallIcon from 'shared/assets/icons/ArrowDownSmall'
import ArrowUpSmallIcon from 'shared/assets/icons/ArrowUpSmall'

const TextField = styled(MuiTextField)(({theme}) => ({
    '& .MuiInputBase-root, & .MuiOutlinedInput-root, &.MuiOutlinedInput-root': {
        boxShadow: 'none',
        height: '40px',
        paddingRight: 0,
        '& fieldset': {
            display: 'flex',
            boxShadow: 'none !important',
            border: '1px solid',
            borderColor: theme.palette.grey[100],
        },
        '& input': {
            boxShadow: 'none',
            height: '40px',
        },
    },
}))

const IncrementButton = styled(IconButton)(({theme}) => ({
    borderRadius: 0,
    borderTopRightRadius: '8px',
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '20px',
    width: '32px !important',
    padding: '4px 10px',
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.grey[100],
    borderBottom: 'none',
    background: 'white',
}))

const DecrementButton = styled(IncrementButton)(({theme}) => ({
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[100],
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[100],
    borderRadius: 0,
    borderBottomRightRadius: '8px',
}))

/**
 * @param {number | ""} defaultValue
 * @param {number | ""} value
 * @param {number} min
 * @param {number} max
 * @param {number} step
 * @param {int} precision - precision describes digits count can be in decimal part of the value
 * @param {function} format
 * @param {function} onChange
 * @return {JSX.Element}
 */
export function NumberInput(
    {
        defaultValue = 0,
        value,
        min = Number.MIN_SAFE_INTEGER,
        max = Number.MAX_SAFE_INTEGER,
        step = 1,
        precision = 2,
        format = (value) => value,
        onChange = () => {
        },
    }) {
    const [_value, _setValue] = useState(value ?? defaultValue)
    const [lastValidValue, setLastValidValue] = useState(value ?? defaultValue)
    const [isEditMode, setIsEditMode] = useState(false)

    const handleChange = (event) => {
        let newValue = event.target.value

        const numberOrPartOfNumber = /^-?(\d+)?((\.\d+)|(\.))?/gm
        newValue = newValue.toString().match(numberOrPartOfNumber)?.[0]

        if (canValidateInput(newValue)) {
            newValue = validateValue(newValue)
        }

        _setValue(newValue)
        onChange(newValue)
    }

    function canValidateInput(value) {
        const intOrFloat = /^-?(\d+)?(\.\d+)?$/
        return intOrFloat.test(value)
            && value !== ''
            && value !== '-'
    }

    const handleIncrement = (event) => {
        event.stopPropagation()
        let newValue = isNaN(_value) ? lastValidValue : _value

        newValue = validateValue(Number(newValue) + step)
        _setValue(newValue)
        onChange(newValue)
    }

    const handleDecrement = (event) => {
        event.stopPropagation()
        let newValue = isNaN(_value) ? lastValidValue : _value

        newValue = validateValue(Number(newValue) - step)
        _setValue(newValue)
        onChange(newValue)
    }

    const validateValue = (value) => {
        value = constrainValue(value)
        value = Number(value.toFixed(precision))
        setLastValidValue(value)

        return value
    }

    const constrainValue = (newValue) => {
        return Math.max(min, Math.min(newValue, max))
    }

    const getValueView = () => {
        const valueView = value ?? _value
        return isEditMode ? valueView : format(valueView)
    }

    return (
        <TextField
            value={getValueView()}
            onFocus={() => setIsEditMode(true)}
            onBlur={() => setIsEditMode(false)}
            onChange={handleChange}
            inputProps={{
                maxLength: 50,
            }}
            InputProps={{
                endAdornment:
                    <Box>
                        <IncrementButton
                            data-cy={'increment-button'}
                            variant={'outlined'}
                            disableTouchRipple
                            onClick={handleIncrement}
                        >
                            <ArrowUpSmallIcon size={'small'}/>
                        </IncrementButton>
                        <DecrementButton
                            data-cy={'decrement-button'}
                            variant={'outlined'}
                            disableTouchRipple
                            onClick={handleDecrement}
                        >
                            <ArrowDownSmallIcon size={'small'}/>
                        </DecrementButton>
                    </Box>,
            }}
        />
    )
}
