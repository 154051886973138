/**
 * @param {Object} object
 */
export function isEmpty(object) {
    return Object.keys(object).length === 0
}

/**
 * @param {string} email
 */
export function isEmailValid(email) {
    if (!email) return false
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

/**
 * @param {string} password
 */
export function isPasswordValid(password) {
    const calculatePercent = () => {
        let percent = 0
        const keys = Object.keys(result)
        keys.slice(0, -1).forEach((key) => {
            const value = result[key]
            percent += +value
        })
        percent /= Object.keys(result).length - 1
        return percent * 100
    }

    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/
    const isValid = regex.test(password)

    const result = {
        minLength: password.length >= 8,
        hasNumber: /\d/.test(password),
        hasSpecialChar: /[!@#$%^&*]/.test(password),
        hasUppercaseChar: /[A-Z]/.test(password),
        isValid: isValid,
    }

    return {
        ...result,
        percent: calculatePercent(),
    }
}

/**
 * @param {string|number} value
 */
export function formatPhoneNumber(value) {
    // Remove any non-digit characters from the input value
    return value.toString().replace(/(\d)(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')
}

