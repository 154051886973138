import {Box, Typography, Chip, MenuItem, Menu, IconButton} from '@mui/material';
import MoreIcon from "shared/assets/icons/More";
import React from 'react';
import {CheckBox} from "shared/ui/CheckBox";
import { ChipSeverity } from 'shared/ui/SpanSeverity';
import dayjs from "dayjs";
import TrashIcon from "../../shared/assets/icons/Trash";

export default function ReportItem({report, type, selected, triggered, onAction, isActive=true, onClick, ...other}) {
    // let loc = report.alertconfiguration.location;
    // let locationGroup = loc.location_group;
    // let locationName = loc.label;
    // if (locationGroup) {
    //     locationName = locationGroup+"/"+locationName;
    // }
    let border = '1px solid #ccc';
    if (type === 'configuration') {
        triggered = true;
        border = 'none';
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) =>  (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        if(action==="delete") {
            onAction(report, 'delete_report');
        }
        else if(action==="edit"){
            onAction(report, 'edit');
        }
        else {
            setAnchorEl(null);
            if (action) onAction(report, action)
        }
    };

    let datefmt = (dt) => {
        return dayjs(dayjs(dt).valueOf()).format('MMM D, h:mm A');
    }

    return(
        <>
        <Box sx={{ height:'48px', display:'flex', cursor: 'pointer', flexGrow: '1', padding:' 8px 16px 8px 18px', flexDirection:'row', alignItems:'center', gap:'8px', justifyContent:'space-between',
                   borderRadius:'6px', border:border, margin:'8px 16px' }}
             onClick={(event) => {
                 onAction && onAction(report, 'open_report')
                 onClick?.(event)
             }}
             {...other}
        >
            {!triggered && <CheckBox 
                checked={selected || false} 
                onClick={(ev) => ev.stopPropagation()}
                onChange={() => onAction && onAction(report, 'select', !selected)}
            />}
            <Typography>
                {report.weatherdefinition.name}
                {/*{report.alertconfiguration.weather_definition.name}*/}
            </Typography>
            <Box className='row' sx={{ marginLeft: 'auto', lineHeight: '16px'}}>
                {/*{report.alert && <>*/}
                {/*    <Typography variant="label">Start:</Typography><Typography>{datefmt(report.alert.issue_time)}</Typography>*/}
                {/*    <Spacer/>*/}
                {/*    <Typography variant="label">End:</Typography><Typography>{report.alert.triggered ? "Ongoing" : datefmt(report.alert.expiration_time)}</Typography>*/}
                {/*    <Spacer/>*/}
                {/*</>}*/}
                {/*<Chip*/}
                {/*    icon={<MapPinIcon size={'small'}/>}*/}
                {/*    label={locationName}*/}
                {/*    size={'small'}*/}
                {/*/>*/}
                <ChipSeverity severity={report.weatherdefinition.severity}/>
                {isActive?
                <Box sx={{ display: 'flex'}}>
                    <IconButton id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>
                        <MoreIcon/>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose('')}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {/*<MenuItem*/}
                        {/*    onClick={handleClose("edit")}*/}
                        {/*    key="edit"*/}
                        {/*>*/}
                        {/*    <PenIcon size={'small'}/>*/}
                        {/*    Edit*/}
                        {/*</MenuItem>*/}
                        <MenuItem
                            onClick={handleClose("delete")}
                            key="delete"
                            color={'error'}
                        >
                            <TrashIcon size={'small'}/>
                            Delete
                        </MenuItem>
                    </Menu>
                </Box> : null}
            </Box>
        </Box>
        </>
    )
}
