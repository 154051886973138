import {createSvgIcon} from "@mui/material/utils";

export default function PhoneIcon(params) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.9326 16.5575C21.9076 16.4135 21.8776 16.2864 21.8466 16.1764C21.6456 15.4584 21.0236 14.9123 20.2616 14.7843L15.9306 14.0634C15.2046 13.9464 14.4726 14.2244 14.0256 14.7924C13.9676 14.8654 13.9116 14.9413 13.7996 15.0653C11.6116 14.1143 9.88256 12.3844 8.96856 10.1454C9.07156 10.0764 9.17059 10.0024 9.26459 9.92545C9.82159 9.47645 10.0976 8.75649 9.98559 8.04849L9.31457 3.79337C9.19757 3.04837 8.68557 2.43947 7.97857 2.20547C7.82557 2.15447 7.64558 2.10651 7.43658 2.06851C6.09658 1.83051 4.72554 2.20748 3.67254 3.11148C2.57154 4.05748 1.96356 5.4303 2.00256 6.8793C2.22456 15.1323 8.86459 21.7735 17.1186 21.9965C17.1616 21.9975 17.2056 21.9975 17.2486 21.9975C18.6376 21.9975 19.9506 21.3963 20.8666 20.3363C21.7786 19.2823 22.1666 17.9045 21.9326 16.5575ZM19.3536 19.0294C18.8026 19.6674 18.0246 20.0154 17.1716 19.9984C9.98057 19.8044 4.19455 14.0183 4.00055 6.82632C3.97855 5.98232 4.33358 5.18252 4.97558 4.63052C5.45758 4.21652 6.03457 3.99844 6.63257 3.99844C6.78057 3.99844 6.92959 4.01248 7.07959 4.03848C7.18259 4.05848 7.27156 4.08038 7.33856 4.10538L8.00458 8.37442C7.95458 8.41442 7.90256 8.45239 7.84656 8.49039C7.01156 9.05839 6.70256 10.0885 7.09656 10.9955C8.24956 13.6495 10.3466 15.7463 12.9996 16.9003C13.9056 17.2943 14.9376 16.9865 15.5066 16.1515L15.5995 16.0365L19.9196 16.7174C19.9346 16.7714 19.9486 16.8315 19.9606 16.9015C20.0926 17.6545 19.8716 18.4314 19.3536 19.0294Z"
                fill="currentColor"/>
        </svg>, 'Phone')
    return <Icon {...params} data-cy="phone-icon"/>
}