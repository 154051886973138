import React, {useState, useEffect} from 'react';
import {Menu, MenuItem, IconButton, Box, Typography} from '@mui/material';
import {CheckBox} from "shared/ui/CheckBox";
import {api} from 'shared/libs/Auth';
import ModalComponent from 'shared/ui/ModalComponent';
import avatar from 'shared/assets/svg/avatar.svg';
import UserDetails from 'entities/user/UserDetails';
import EditContactPopup from 'features/contact/EditContactPopup';
import DragVerticalIcon from "shared/assets/icons/DragVertical";
import MoreIcon from "shared/assets/icons/More";
import TrashIcon from "shared/assets/icons/Trash";
import {formatPhoneNumber} from "shared/libs/Utils";
import PenIcon from "shared/assets/icons/Pen";
import StarIcon from "shared/assets/icons/Star";
import CloseIcon from "shared/assets/icons/Close";
import BellSlashIcon from "shared/assets/icons/BellSlashIcon";
import LinkIcon from "shared/assets/icons/Link";
import CircularArrowIcon from "shared/assets/icons/CircularArrowIcon";
import AgreeModal from "shared/ui/AgreeModal";
import ContactStatus from "../../../widgets/contact/ContactStatus";

export default function DraggableContactLine({
                                                 contact,
                                                 onCheckedUser,
                                                 isChecked,
                                                 loadUserContacts,
                                                 onUpdateContact,
                                                 allGroups,
                                                 addGroup,
                                                 onDrag
                                             }) {
    const [openModalUserPopUp, setOpenModalUserPopUp] = useState(false);
    const [currentTypeOfModalBox, setCurrentTypeOfModalBox] = useState('');

    useEffect(() => {
        setContactLine({
            id: contact.id,
            // checked: false,
            avatar: avatar,
            userName: contact.name,
            email: contact.email,
            phone: contact.phone,
            status: contact.status,
            group: contact.group
        });
    }, [contact]);

    const handleCheckboxChange = (action) => (event) => {
        event.stopPropagation();
        if (action === "select") {
            onCheckedUser(contact, action, !isChecked);
        }
    };

    // TODO: use actual data for edit contact form
    const arrStatus = {
        'No subscription': {menu: [MenuItemRemoveContact, MenuItemEditContact]},
        // 'No subscription': { menu: [MenuItemEditContact, MenuItemSendInvite, MenuItemRemoveContact] },
        'Admin': {menu: [MenuItemRevokeAdminRights, MenuItemCancelSubscription, MenuItemRemoveUser]},
        'Invite sent': {menu: [MenuItemResendInvite, MenuItemGetInviteLink, MenuItemCancelInvite, MenuItemRemoveContact]},
        'Active subscription': {menu: [MenuItemGiveAdminRights, MenuItemCancelSubscription, MenuItemRemoveUser]},
        'Subscription expired': {menu: [MenuItemRenewSubscription, MenuItemRemoveUser]}
    };

    const [contactLine, setContactLine] = useState({
        id: contact.id,
        checked: false,
        avatar: avatar,
        userName: contact.name,
        email: contact.email,
        phone: contact.phone,
        status: contact.status,
        group: contact.group
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    const openDeleteModal = () => setIsDeleteModalOpen(true);

    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handleDeleteConfirm = async (contactId) => {
        try {
            await api.delete(`/contacts/${contactId}`);
            await loadUserContacts();
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
        closeDeleteModal();
    };

    const handleDeleteContact = (agree) => {
        if (agree) {
            handleDeleteConfirm(contact.id)
        } else {
            closeDeleteModal()
        }
    }


    function MenuItemEditContact() {
        return (
            <MenuItem onClick={() => setCurrentTypeOfModalBox('editContact')}>
                <PenIcon size={'small'}/>
                Edit contact
            </MenuItem>
        );
    }

    // function MenuItemSendInvite() {
    //     return (
    //         <MenuItem onClick={handleClose}>
    //             <EnvelopeIcon size={'small'}/>
    //             Send invite
    //         </MenuItem>
    //     );
    // }

    function MenuItemRemoveContact() {
        return (
            <Box>
                <MenuItem
                    color={'error'}
                    onClick={openDeleteModal}
                >
                    <TrashIcon size={'small'}/>
                    Remove contact
                </MenuItem>
                {isDeleteModalOpen &&
                    <AgreeModal
                        data={{
                            message: <>Are you sure you want delete contact <span style={{fontWeight:"500", color:"var(--palette-grey-900)"}}>"{contact.name}"</span>?</>,
                            title: "Delete contact",
                            agreeMsg: "Delete",
                            mode: "deleting",
                            agreeFunc: handleDeleteContact
                        }}
                    />}
            </Box>
        );
    }

    function MenuItemRevokeAdminRights() {
        return (
            <MenuItem onClick={handleClose}>
                <StarIcon size={'small'}/>
                Revoke admin rights
            </MenuItem>
        );
    }

    function MenuItemCancelSubscription() {
        return (
            <MenuItem onClick={handleClose}>
                <BellSlashIcon size={'small'}/>
                Cancel subscription
            </MenuItem>
        );
    }

    function MenuItemRemoveUser() {
        return (
            <MenuItem onClick={handleClose} color={'error'}>
                <TrashIcon size={'small'}/>
                Remove user
            </MenuItem>
        );
    }

    function MenuItemResendInvite() {
        return (
            <MenuItem onClick={handleClose}>
                <CircularArrowIcon size={'small'}/>
                Resend invite
            </MenuItem>
        );
    }

    function MenuItemGetInviteLink() {
        return (
            <MenuItem onClick={handleClose}>
                <LinkIcon size={'small'}/>
                Get invite link
            </MenuItem>
        );
    }

    function MenuItemCancelInvite() {
        return (
            <MenuItem onClick={handleClose}>
                <CloseIcon size={'small'}/>
                Cancel invite
            </MenuItem>
        );
    }

    function MenuItemGiveAdminRights() {
        return (
            <MenuItem onClick={handleClose}>
                <StarIcon size={'small'}/>
                Give admin rights
            </MenuItem>
        );
    }

    function MenuItemRenewSubscription() {
        return (
            <MenuItem onClick={handleClose}>
                <CircularArrowIcon size={'small'}/>
                Renew subscription
            </MenuItem>
        );
    }


    return (
        <Box className={'contact-line'}>
            <Box
                className={'row gap0'}
                onClick={() => setOpenModalUserPopUp(true)}
                sx={{cursor: 'pointer', width: '200px', flexGrow: 2}}
            >
                <IconButton
                    variant={'outlined'}
                    size={'tiny'}
                    sx={{marginRight: '8px'}}
                    onMouseDown={onDrag}
                    onTouchStart={onDrag}
                >
                    <DragVerticalIcon size={'small'}/>
                </IconButton>
                <CheckBox
                    id={`checkbox-${contact.id}`}
                    checked={isChecked || false}
                    onChange={handleCheckboxChange('select')}
                    onClick={event => event.stopPropagation()}
                    sx={{marginRight: '4px'}}
                />
                <Typography className={'textEllipsis medium'}>
                    {contactLine.userName}
                </Typography>
            </Box>
            <Typography className={'textEllipsis'} sx={{width: '100px', flexGrow: 2}}>
                {contactLine.email}
            </Typography>
            <Typography sx={{width: '160px',}}>
                {formatPhoneNumber(contactLine.phone)}
            </Typography>
            <ContactStatus status={contactLine.status}/>
            <IconButton
                variant={'outlined'}
                size={'tiny'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreIcon size={'small'}/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {arrStatus[contactLine.status] && arrStatus[contactLine.status].menu.map((menuItemFunction, index) => {
                    const MenuItemElement = menuItemFunction();
                    return React.cloneElement(MenuItemElement, {key: index});
                })}
            </Menu>

            <ModalComponent visible={currentTypeOfModalBox === 'editContact'} style={{padding: "0px"}}>
                <EditContactPopup
                    contactData={contactLine}
                    allGroups={allGroups}
                    onClose={() => setCurrentTypeOfModalBox('')}
                    onUpdate={onUpdateContact}
                    addGroup={addGroup}
                />
            </ModalComponent>

            <ModalComponent visible={openModalUserPopUp}>
                <UserDetails user={contactLine} onClose={() => setOpenModalUserPopUp(false)}/>
            </ModalComponent>
        </Box>
    );
}
