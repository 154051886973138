import {createSvgIcon} from "@mui/material/utils";


export default function CheckCircleIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.707 9.29297C17.098 9.68397 17.098 10.316 16.707 10.707L11.707 15.707C11.512 15.902 11.256 16 11 16C10.744 16 10.488 15.902 10.293 15.707L7.293 12.707C6.902 12.316 6.902 11.684 7.293 11.293C7.684 10.902 8.316 10.902 8.707 11.293L11 13.5861L15.293 9.29297C15.684 8.90197 16.316 8.90197 16.707 9.29297ZM22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12ZM20 12C20 7.589 16.411 4 12 4C7.589 4 4 7.589 4 12C4 16.411 7.589 20 12 20C16.411 20 20 16.411 20 12Z"
                fill="currentColor"/>
        </svg>
        , 'CheckCircle')
    return <Icon {...props} data-cy="check-circle-icon"/>
}
