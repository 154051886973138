import {styled} from '@mui/material/styles'
import {PickersDay as MuiPickersDay} from '@mui/x-date-pickers'
import React from 'react'

import theme from 'app/Theme'

const StyledPickersDay = styled(MuiPickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'today'
        && prop !== 'dayIsBetween',
})(({outsideCurrentMonth, today, dayIsBetween}) =>
    (
        {
            ...(outsideCurrentMonth && {
                color: theme.palette.action.disabled,
            }),
            ...(!outsideCurrentMonth && {
                backgroundColor: 'none',
            }),
            ...(today && {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
                borderWidth: '1px',
                borderStyle: 'solid',
            }),
            ...(dayIsBetween && {
                backgroundColor: 'none',
                color: theme.palette.primary.dark,
            }),
            margin: '0 4px 0 4px',
        }))

export function WeekPickersDay({day, dayRange = [], today, selected, ...other}) {
    const start = dayRange[0]
    const end = dayRange[1]

    const dayRangeIsNotEmpty = dayRange.length !== 0
    const dayIsBetween = day.isBetween(start, end, null, '[]')
    const isFirstDay = day.isSame(start, 'day')
    const isLastDay = day.isSame(end, 'day')

    return (
        <div
            className={'row'}
            style={{
                ...(dayRangeIsNotEmpty && (isFirstDay || isLastDay || dayIsBetween) && {
                    backgroundColor: theme.palette.grey.background,
                }),
                ...(isFirstDay && {
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }),
                ...(isLastDay && {
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                }),
            }}
        >
            <StyledPickersDay
                {...other}
                day={day}
                dayIsBetween={dayIsBetween}
                today={today}
                selected={false}
            />
        </div>
    )
}
