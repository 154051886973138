export const weatherColors= {
    'Moderate': 'rgb(104, 157, 255)',
    'Severe': 'rgb(250, 150, 58)',
    'Critical': 'rgb(245, 135, 135)',
};

export const weatherBackgroundColors = {
    'Moderate': 'rgba(248, 250, 255, 1.0)',
    'Severe': 'rgba(255, 243, 232, 1.0)',
    'Critical': 'rgba(254, 238, 238, 1.0)',
};

export const weatherLabelColors = {
    'Moderate': 'rgba(27, 81, 179, 1.0)',
    'Severe': 'rgba(232, 111, 0, 1.0)',
    'Critical': 'rgba(168, 59, 59, 1.0)',
};
