import {createSvgIcon} from "@mui/material/utils";

export default function CleanIcon(props) {
    const Icon = createSvgIcon(
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.6542 4.34075L14.7703 10.4021L14.6544 10.2869L14.52 10.1533L20.4102 4.05698C20.4592 4.03963 20.4938 4.03941 20.5136 4.04136C20.5481 4.04474 20.5789 4.0588 20.6043 4.08219C20.6291 4.10513 20.6516 4.14002 20.6611 4.19075C20.6668 4.22145 20.67 4.27118 20.6542 4.34075ZM13.7963 13.773L11.0812 21.0638C8.89166 20.469 7.07349 19.3631 5.76914 17.8387C4.54055 16.4028 3.73195 14.5543 3.52083 12.3146C6.19504 12.9435 8.95048 13.0014 11.4564 11.3665L11.4953 11.3412L11.5316 11.3123C11.5526 11.2957 11.573 11.2818 11.592 11.2705L11.6034 11.2643L11.6034 11.2643L11.6112 11.26C11.6206 11.2547 11.6282 11.251 11.634 11.2485C11.6381 11.2503 11.6432 11.2526 11.6492 11.2558L12.6884 12.2915L12.7009 12.3043L12.7008 12.3044L12.7106 12.3142L13.7325 13.3318C13.8359 13.4416 13.853 13.5066 13.8569 13.5269C13.8603 13.5449 13.8684 13.6022 13.8148 13.7269L13.805 13.7497L13.7963 13.773Z"
                stroke="currentColor" strokeWidth="2"/>
        </svg>, 'Clean')
    return <Icon {...props} data-cy="clean-icon"/>
}