import TextField from "@mui/material/TextField";
import React, { useState, useRef } from 'react';
import './locationSearch.css';
import {Autocomplete,} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../../shared/assets/icons/Search";

const states = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
    'AS': 'American Samoa',
    'DC': 'District of Columbia',
    'FM': 'Federated States of Micronesia',
    'GU': 'Guam',
    'MH': 'Marshall Islands',
    'MP': 'Northern Mariana Islands',
    'PW': 'Palau',
    'PR': 'Puerto Rico',
    'VI': 'Virgin Islands',
    'AA': 'Armed Forces Americas',
    'AE': 'Armed Forces Africa, Canada, Europe and Middle East',
    'AP': 'Armed Forces Pacific',
}


export function LocationSearch({ requester, onSelect, countryCode = 'US', mapPositionRef }) {
    const [searchList, setSearchList] = useState([]);
    const [searchText, setSearchText] = useState('');

    const TIME_TYPING_AWAITING_MS = 250;
    const MIN_SYMBOLS_SEARCH = 3;

    const timeoutIndex = useRef(0);

    function runSearch() {
        clearTimeout(timeoutIndex.current);
        if (searchText.length >= MIN_SYMBOLS_SEARCH) {
            timeoutIndex.current = setTimeout(() => {
                let currentIndex = timeoutIndex.current;
                getCitiesBySubstring(searchText, data => {
                    if (currentIndex !== timeoutIndex.current) {
                        return;
                    }
                    console.log(data)
//                    data = data.sort((a, b) => {
//                        const rank1 = a.rank + ((a && a.code === countryCode) ? 100 : 0);
//                        const rank2 = b.rank + ((b && b.code === countryCode) ? 100 : 0);
//                        return rank2 - rank1;
//                    });
                    setSearchList(data);
                });
            }, TIME_TYPING_AWAITING_MS);
        } else {
            setSearchList([]);
        }
    }

    function getCitiesBySubstring(str, clbk) {
        const reZip = new RegExp(/^\d+$/);
        str = str.trim().split(/\s*,\s*/).join(" ");
        let url = 'reports/geocode/address.json?location=' + str + '&country=' + countryCode + '&bias_lon=' + mapPositionRef.current.position.lng + '&bias_lat=' + mapPositionRef.current.position.lat;
        requester.isready(() => {
            requester.textProductsGet({
                path: url,
                callback: data => {
                    if (!data) {
                        clbk([]);
                        return;
                    }
                    let list = data.map(e => {
                        let codeCountry = e.country ? e.country : '',
                            region = e.state ? e.state : '',
                            country = e.country ? e.country : '';
                        let address = e.address;
                        const us_begin = address.indexOf(', United States');
                        const city_begin = address.indexOf(e.city + ',')
                        if (us_begin > 0) {  // remove United States from address line
                            address = address.slice(0, us_begin) + address.slice(us_begin + 15);
                            let state_begin = address.indexOf(', ' + region) // remove state code from address line
                            if (state_begin > 0) address = address.slice(0, state_begin) + address.slice(state_begin + 4);
                            else {
                                if (states[region]) { // remove state name from address line
                                    state_begin = address.indexOf(', ' + states[region])
                                    if (state_begin > 0) address = address.slice(0, state_begin) + address.slice(state_begin + states[region].length + 2);
                                }
                            }
                        }
                        return {
                            name: address + ', ' + (city_begin >= 0 ? '' : e.city + ', ') + (codeCountry === 'US' ? region : country),
                            crds: e.coordinates,
                            code: codeCountry,
                            rank: (e.population && e.population.rank) ? e.population.rank : 0,
                            zip: '' // reZip.exec(str) ? searchText : ''
                        }
                    });
                    clbk(list);
                }
            });
        });
    }

    const isEnglish = (text) => { //checking if the entered string is English
        const regex = /^[a-zA-Z ,]+$/;
        return regex.test(text);
    }

    const isNumeric = (text) => { //checking if the entered string include numbers
        const regex = /\d/;
        return regex.test(text);
    }

    const isLatinOrNumericText = (text) =>{ //checking if the entered string is english or include numbers
        return isEnglish(text) || isNumeric(text) || text.length === 0;
    }

    function onSelectLocation(location) {
        setSearchText('');
        setSearchList([]);
        onSelect(location);
    }

    const handleFocus = () => {
        if (searchText.length >= MIN_SYMBOLS_SEARCH) {
            runSearch();
        }
    }

    return (
        <Autocomplete
            className={'spacer'}
            value={searchText}
            onFocus={handleFocus}
            options={searchList.map((location) => ({
                label: location.name,
                location: location,
            }))}
            filterOptions={(options, state) => options}
            onChange={(event, option) => onSelectLocation(option.location)}
            renderInput={props =>
                <TextField
                    {...props}
                    onKeyUp={runSearch}
                    value={searchText}
                    onChange={({target}) => setSearchText(target.value)}
                    InputProps={{
                        ...props?.InputProps,
                        startAdornment: (
                            <InputAdornment position={"start"}>
                                <SearchIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: null
                    }}
                    placeholder="Search by city or zip code"
                />
            }
        />
    );
}
