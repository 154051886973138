import {Paper} from '@mui/material'
import Draggable from 'react-draggable'

export function DraggablePaper(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper
                {...props}
                sx={{padding: 0, pointerEvents: 'auto'}}
            />
        </Draggable>
    )
}
