import React from "react";
import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import 'shared/ui/emptyStates/EmptyStateDefinitionPreview.css';
import {ReactComponent as Background} from "shared/assets/svg/DefinitionBackgroundCell.svg";
import {ReactComponent as PreviewRectangle1} from "shared/assets/emptyStates/WeatherPreviewRectangle1.svg";
import {ReactComponent as PreviewRectangle2} from "shared/assets/emptyStates/WeatherPreviewRectangle2.svg";
import {ReactComponent as PreviewRectangleMain} from "shared/assets/emptyStates/WeatherPreviewRectangleMain.svg";

export default function EmptyStatesDefinitionPreview() {

    return (
        <Box>
            <Typography
                className={'EmptyStatesDefinitionPreviewTitle'}
                style={{color: 'white', fontSize: '16px', fontWeight: "400", lineHeight: '24px'}}
            >
                Select parameters to <br/> see the preview
            </Typography>
            <Box
                className={'EmptyStatesDefinitionPreviewBackground'}
                style={{position: "absolute"}}>
                <Background/>
            </Box>
            <Box
                className={'EmptyStatesDefinitionPreviewRectangle1'}
                style={{position: "absolute"}}>
                <PreviewRectangle1/>
            </Box>
            <Box
                className={'EmptyStatesDefinitionPreviewRectangleMain'}
                style={{position: "absolute"}}>
                <PreviewRectangleMain/>
            </Box>
            <Box
                className={'EmptyStatesDefinitionPreviewRectangle2'}
                style={{position: "absolute"}}>
                <PreviewRectangle2/>
            </Box>
        </Box>
    )
}