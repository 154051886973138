import {createSvgIcon} from "@mui/material/utils";

export default function RulerIcon(props) {
    const Icon = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512">
            <path d="M23.436,20.728L3.272,.564C2.712,.006,1.911-.151,1.183,.148,.454,.451,0,1.13,0,1.92V24H22.08c.79,0,1.469-.453,1.771-1.184,.302-.729,.143-1.53-.416-2.089Zm-.508,1.706c-.147,.354-.463,.566-.847,.566H1V1.92c0-.384,.211-.7,.566-.848,.355-.144,.729-.072,1,.199l2.684,2.684-2.397,2.397,.707,.707,2.397-2.397,2.828,2.828-2.396,2.397,.707,.707,2.396-2.397,2.829,2.829-2.397,2.397,.707,.707,2.397-2.397,2.828,2.828-2.397,2.397,.707,.707,2.397-2.397,2.828,2.828-2.397,2.397,.707,.707,2.397-2.397,2.63,2.63c.271,.271,.346,.645,.199,.999Z"/>
            <path d="M5,19h7.207l-7.207-7.207v7.207Zm1-4.793l3.793,3.793h-3.793v-3.793Z"/>
        </svg>, 'Ruler')
    return <Icon {...props} data-cy="ruler-icon"/>
}

