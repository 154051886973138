import axios from "axios";
import {api, checkIsLogged} from '../../../shared/libs/Auth';

function fetchData(path){
    return api.get(path)
        .then(res=>res.data)
        .catch((error) => console.log(JSON.stringify(error)));
}

function postData(path, obj){
    return api.post(path, obj)
    .then(res=>res.data)
    .catch((error) => console.log(JSON.stringify(error)));
}

function putData(path, obj){
    return api.put(path, obj)
    .then(res=>res.data)
    .catch((error) => console.log(JSON.stringify(error)));
}

function deleteData(path, obj){
    return api.delete(path, obj)
    .then(res=>res.data)
    .catch((error) => console.log(JSON.stringify(error)));
}

export async function postLocation(location){
    return postData('/locations', location)
}

export async function putLocation(id, location){
    return putData('/locations/' + id, location)
}

export async function deleteLocation(id){
    return deleteData('/locations/' + id)
}

export async function postWeatherDefinition(definition){
    return postData('/weather/definitions', definition)
}

export async function putWeatherDefinition(definition){
    return putData('/weather/definitions', definition)
}

export async function deleteWeatherDefinition(id){
    return deleteData('/weather/definitions/' + id)
}

// export async function fetchAlerts(){
//     return fetchData('/alerts/');
// }

export async function fetchRealAlerts(ts_after=0, ts_min=0, ts_max=0, limit=0){
    let url = '/alerts?ts_after=' + ts_after;
    if (ts_min) url += '&ts_min=' + ts_min;
    if (ts_max) url += '&ts_max=' + ts_max;
    if (limit) url += '&limit=' + limit;
    return fetchData(url);
}

export async function postAlerts(ts_after=0, ts_min=0, ts_max=0, limit=0, filters={}){
    let url = '/alerts?ts_after=' + ts_after;
    if (ts_min) url += '&ts_min=' + ts_min;
    if (ts_max) url += '&ts_max=' + ts_max;
    if (limit) url += '&limit=' + limit;
    return postData(url, filters);
}

export async function fetchRealAlertByAid(aid=0){
    return fetchData('/alerts/' + aid);
}

// export async function fetchLocations(){
//     return fetchData('/locations/');
// }

export async function fetchRealLocations(){
    return fetchData('/locations');
}

// export async function fetchTypes(){
//     return fetchData('/types/');
// }

export function checkLogon(){
    checkIsLogged();
}

export async function fetchWeatherIcons(){
    return fetchData('/weather/icons');
}

export async function fetchWeatherDefinitions(){
    return fetchData('/weather/definitions');
}

export async function fetchWeatherDefinition(definition_id){
    return fetchData('/weather/definitions/' + definition_id);
}

export async function fetchProducts(){
    return fetchData('/products');
}

export async function fetchMapProducts() {
    return fetchData('/map-products')
}

export async function fetchAlertConfigurations(){
    return fetchData('/alert-configurations');
}

export async function fetchAlertConfigurationsExtended(){
    return fetchData('/configuration-groups/extended');
}

export async function fetchConfigurationGroups(){
    return fetchData('/configuration-groups');
}

export async function fetchConfigurationGroup(id){
    return fetchData('/configuration-groups/' + id);
}

export async function postConfigurationGroup(data){
    return putData('/configuration-groups', data);
}

export async function putConfigurationGroup(id, data){
    return putData('/configuration-groups/' + id, data);
}

export async function deleteConfigurationGroup(id){
    return deleteData('/configuration-groups/' + id);
}

export async function togglePauseConfigurationGroup(id, data){
    return putData('/configuration-groups/' + id, data);
}

export async function fetchAllReports(triggered) {
    if (triggered) {
        return fetchData('/reports/?triggered');
    }
    return fetchData('/reports/');
}

export async function fetchReport(id) {
    return fetchData('/reports/'+id);
}

export async function createReport(obj){
    return postData('/reports/', obj);
}

export async function editReport(report){
    return putData('/reports/', report)
}

export async function deleteReport(id){
    return deleteData('/reports/' + id);
}

export async function fetchReportConfigurations(){
    return fetchData('/reports/configurations');
}

export async function fetchContacts() {
    return fetchData('/contacts');
}

export async function fetchTimelineViews() {
    return fetchData('/storage/timelineview');
}

export async function createTimelineView(obj) {
    return postData('/storage/timelineview', obj);
}

export async function editTimelineView(obj){
    return putData('/storage/timelineview', obj);
}

export async function deleteTimelineView(id){
    return deleteData('/storage/timelineview/' + id);
}

export async function fetchMapViews() {
    return fetchData('/storage/mapview');
}

export async function createMapView(obj) {
    return postData('/storage/mapview', obj);
}

export async function editMapView(obj){
    return putData('/storage/mapview', obj);
}

export async function deleteMapView(id){
    return deleteData('/storage/mapview/' + id);
}

export async function fetchDefaultMapView() {
    return fetchData('/storage/defaultmapview');
}

export async function createDefaultMapView(obj) {
    return postData('/storage/defaultmapview', obj);
}

export async function editDefaultMapView(obj) {
    return putData('/storage/defaultmapview?name=defaultmapview', obj);
}

export async function fetchActionItems() {
    return fetchData('/action-items');
}

export async function fetchActionItemsCategories() {
    return fetchData('/action-items/categories');
}

export async function createActionItem(obj) {
    return postData('/action-items', obj);
}

export async function editActionItem(obj) {
    return putData('/action-items', obj);
}

export async function deleteActionItem(id) {
    return deleteData('/action-items/' + id);
}

export async function fetchMetarNearest(coordinates) {
    return fetchMapData('/reports/metar/nearest', {
        lat: coordinates.lat,
        lon: coordinates.lng,
    })
}

export async function fetchCityNearest(coordinates) {
    return fetchMapData('/reports/city/nearest', {
        lat: coordinates.lat,
        lon: coordinates.lng,
    })
}

export async function fetchForecast(coordinates) {
    return fetchMapData('/reports/pointforecast/basic', {
        lat: coordinates.lat,
        lon: coordinates.lng,
        days: 7,
    })
}

async function fetchMapData(path, params) {
    const key = requester.getKey()
    path = `https://api.velocityweather.com/v1/${key}` + path
    return axios.get(path, {
        params: {
            ...params,
            ts: requester.getTs(),
            sig: requester.getSig(),
        }
    })
}
