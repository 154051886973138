import {
    Alert,
    Button,
    IconButton,
    InputAdornment,
    Snackbar,
    Typography
} from '@mui/material';
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import React, {useEffect, useState} from 'react';
import 'widgets/contact/Contacts.css'
import {api} from 'shared/libs/Auth';
import TextField from '@mui/material/TextField';
import {PhoneField} from "shared/ui/PhoneField/PhoneField";
import CloseIcon from "shared/assets/icons/Close";
import EnvelopeIcon from "shared/assets/icons/Envelope";
import PhoneIcon from "shared/assets/icons/Phone";
import UserIcon from "shared/assets/icons/User";
import CopyTooltip from 'shared/ui/CopyTooltip';
import {Portal} from '@mui/base';
import ContactStatus from "../../../widgets/contact/ContactStatus";

const changesData = {
    isNameChanged: false,
    isEmailChanged: false,
    isPhoneChanged: false,
    allow_sms_notificationsChanged: false,
    allow_email_notificationsChanged: false,
}

const EditUserForm = ({onClose, currentUser, setCurrentUser}) => {

    const [data, setData] = useState({...currentUser});
    const [changes, setChanges] = useState(changesData);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [emailMassage, setEmailMassage] = useState('');
    const [emailError, setEmailError] = useState(false);

    const timeout = 2000; // 2sec

    const CheckIsValidEmail = (emailAddress) =>{
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(emailAddress)
    }

    useEffect(() => {
        let dataUpdated = false;
        for (let key in changes) {
            dataUpdated += changes[key]
        }
        if (data.userName.trim() === '' || !CheckIsValidEmail(data.email) || data.phone === 'Invalid' || data.phone==='' || !dataUpdated) {
            setDisableSaveButton(true);
        } else setDisableSaveButton(false)

    }, [data]);

    const handleSnackbarClick = () => {
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const onSaveHandle = () => {

        setEmailMassage('');
        setEmailError(false)

        const newData = {}

        if (changes.isNameChanged === true) {
            const [newFirstName, newLastName] = data.userName.trim().split(/\s+/);
            newData.first_name = newFirstName;
            newData.last_name = newLastName || '';
        }

        if (changes.isEmailChanged === true) {
            newData.email = data.email;
        }

        if (changes.isPhoneChanged === true) {
            if (data.phone !== 'Invalid') {
                newData.phone = data.phone;
            } else {
                setSnackbarSeverity('error')
                setSnackbarMessage('The data is not valid');
                handleSnackbarClick();
                return
            }
        }

        if (changes.allow_sms_notificationsChanged) newData.allow_sms_notifications = data.allow_sms_notifications;
        if (changes.allow_email_notificationsChanged) newData.allow_email_notifications = data.allow_email_notifications;

        api.patch('/user', newData)
            .then(() => {
                setCurrentUser({...data, ...newData});
                setSnackbarSeverity('success');
                setSnackbarMessage('Data has been changed');
                handleSnackbarClick();
                setTimeout(() => {
                    onClose();
                }, 3000);
            })
            .catch(reason => {
                if (reason.response) {
                    if (reason.response.data.email) {
                        setEmailError(true);
                        setEmailMassage(reason.response.data.email)
                    }
                }
            });
    }

    return (
        <div className={'column gap24'} style={{width: '520px', alignItems: 'center'}}>
            <div className={'row fullWidth'}>
                <h3>Edit profile</h3>
                <ContactStatus status={data.status}/>
                <Spacer/>
                <IconButton variant={'outlined'} onClick={() => onClose()}>
                    <CloseIcon size={'small'}/>
                </IconButton>
            </div>

            <Typography className={'fullWidth'} variant={'label'} sx={{color: theme.palette.grey[500]}}>
                Name
                <TextField
                    autoComplete={'on'}
                    defaultValue={data.userName}
                    onChange={e => {
                        setData({...data, userName: e.target.value});
                        setChanges({...changes, isNameChanged: true});
                        console.log('name changed: %s', e.target.value);
                    }}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.userName}/>,
                    }}>
                </TextField>
            </Typography>
            <Typography className={'fullWidth'} variant={'label'} sx={{color: theme.palette.grey[500]}}>
                Email address
                <TextField
                    autoComplete={'on'}
                    inputMode={'email'}
                    defaultValue={data.email}
                    onChange={e => {
                        setData({...data, email: e.target.value});
                        setChanges({...changes, isEmailChanged: true});
                        console.log('Email changed: %s', e.target.value);
                    }}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <EnvelopeIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.email}/>,
                    }}
                    helperText={emailMassage}
                    error={emailError || data.email === '' || !CheckIsValidEmail(data.email)}
                />
            </Typography>
            <Typography className={'fullWidth'} variant={'label'} sx={{color: theme.palette.grey[500]}}>
                Phone number
                <PhoneField
                    inputMode={'tel'}
                    phone={data.phone}
                    errorBorder={data.phone === ''}
                    onChange={(phoneInput) => {
                        setData({...data, phone: phoneInput});
                        setChanges({...changes, isPhoneChanged: true});
                    }}
                    InputProps={{
                        required: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon size={'small'}/>
                            </InputAdornment>
                        ),
                        endAdornment: <CopyTooltip textToCopy={data.phone}/>,
                    }}/>

            </Typography>

            <div className={'row fullWidth'} style={{padding:"2px"}}>
                <Button
                    className={'spacer'}
                    disabled={disableSaveButton}
                    onClick={() => onSaveHandle()}
                >
                    Save contact
                </Button>
                <Button
                    className={'spacer'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>

                <Portal>
                    <Snackbar open={openSnackbar} autoHideDuration={timeout} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{width: '100%'}}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Portal>
            </div>
        </div>
    );
}
export default EditUserForm