import React from "react";
import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import {ReactComponent  as Background} from "shared/assets/emptyStates/Background.svg";
import {ReactComponent  as DefinitionsPin} from "shared/assets/emptyStates/DefinitionsPin.svg";
import {ReactComponent  as DefinitionsRectangle} from "shared/assets/emptyStates/DefinitionsRectangle.svg";

export default function EmptyStateDefinitions({title, text}) {

		return (
				<Box sx={{height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
						<Box sx={{display:"flex", flexDirection:"column", justifyContent:"end", width:"600px", height:"360px", borderRadius:"12px", padding:"40px", position:"relative", overflow:"hidden", backgroundColor:"white"}}>
								<Box sx={{position:"absolute", right:"10px", bottom:"-7px"}}>
										<Background/>
								</Box>
								<Box sx={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
										<Box sx={{position:"absolute", left:"190px", top:"58px"}}>
												<DefinitionsPin/>
										</Box>
										<Box sx={{position:"absolute", right:"60px", bottom:"25px"}}>
												<DefinitionsRectangle/>
										</Box>
								</Box>
								<Box sx={{display:"flex", alignItems:"start", flexDirection:"column", textAlign: "start", gap:"12px"}}>
										<Typography sx={{fontSize:"24px", fontWeight:"500", width:"250px", lineHeight:"32px", height:"64px", letterSpacing:"0.25px"}}>{title}</Typography>
										<Typography sx={{fontSize:"14px", fontWeight:"400", width:"245px", lineHeight:"20px", height:"40px", color:"var(--palette-grey-500)", letterSpacing:"0.25px"}}>{text}</Typography>
								</Box>
						</Box>
				</Box>
		)
}