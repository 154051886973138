import TrashIcon from "shared/assets/icons/Trash";
import React, { useState } from 'react';
import {Button, Divider, Menu, MenuItem} from "@mui/material";
import MapMarkerIcon from "../../../../shared/assets/icons/MapMarker";
import LayerIcon from "../../../../shared/assets/icons/Layer";
import PointIcon from "../../../../shared/assets/icons/Point";
import CloseIcon from "../../../../shared/assets/icons/Close";
import './ClearButton.css'
import CleanIcon from "../../../../shared/assets/icons/Clean";
const ClearButton = ({ clearLocations, clearLayers, clearPq, allClear }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(false);
    }

    return (
        <div>
            <Button
                variant={'outlined'}
                color={'secondary'}
                startIcon={<CleanIcon />}
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls={isOpen ? 'clear-button-menu' : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
            >
                Clear
            </Button>
            <Menu
                id="clear-button-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        clearLocations();
                        handleClose();
                    }}
                >
                    <MapMarkerIcon size={'small'}/>
                    Locations
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        clearLayers();
                        handleClose();
                    }}
                >
                    <LayerIcon size={'small'}/>
                    Layers
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        clearPq();
                        handleClose();
                    }}
                >
                    <PointIcon size={'small'}/>
                    Point queries
                </MenuItem>
                <MenuItem
                    color={'error'}
                    onClick={() => {
                        allClear();
                        handleClose();
                    }}
                >
                    <TrashIcon size={'small'}/>
                    Clear all
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ClearButton;