import {createSvgIcon} from "@mui/material/utils";

export default function PenIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.6577 3.34253C19.7927 2.47753 18.6427 2.00049 17.4197 2.00049C16.1967 2.00049 15.0467 2.47753 14.1817 3.34253L3.64866 13.8755C3.20366 14.3205 2.91365 14.8835 2.81065 15.5035L2.02067 20.2415C1.94067 20.7215 2.09869 21.2145 2.44169 21.5575C2.72669 21.8425 3.11366 21.9995 3.51066 21.9995C3.59366 21.9995 3.67565 21.9925 3.75865 21.9785L8.49668 21.1885C9.11668 21.0855 9.67967 20.7955 10.1247 20.3505L20.6577 9.8175C21.5227 8.95251 21.9997 7.80147 21.9997 6.57947C21.9997 5.35647 21.5227 4.20753 20.6577 3.34253ZM8.71067 18.9385C8.56267 19.0865 8.37468 19.1835 8.16868 19.2175L4.10667 19.8945L4.78367 15.8325C4.81767 15.6265 4.91466 15.4385 5.06266 15.2905L12.9687 7.38452L16.6167 11.0325L8.71067 18.9385ZM19.2437 8.40454L18.0307 9.61743L14.3827 5.96948L15.5957 4.75647C16.0827 4.26947 16.7317 4.00049 17.4197 4.00049C18.1087 4.00049 18.7567 4.26947 19.2437 4.75647C19.7307 5.24347 19.9997 5.89144 19.9997 6.58044C19.9997 7.26944 19.7307 7.91754 19.2437 8.40454Z"
                fill="currentColor"/>
        </svg>, 'Pen')
    return <Icon {...props} data-cy="pen-icon"/>
}