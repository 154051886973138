import {createSvgIcon} from "@mui/material/utils";


export default function SlidersIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 3.5C7.42 3.5 6.09702 4.559 5.66302 6H3.5C2.948 6 2.5 6.447 2.5 7C2.5 7.553 2.948 8 3.5 8H5.66302C6.09702 9.441 7.42 10.5 9 10.5C10.93 10.5 12.5 8.93 12.5 7C12.5 5.07 10.93 3.5 9 3.5ZM9 8.5C8.173 8.5 7.5 7.827 7.5 7C7.5 6.173 8.173 5.5 9 5.5C9.827 5.5 10.5 6.173 10.5 7C10.5 7.827 9.827 8.5 9 8.5ZM14.5 7C14.5 6.447 14.948 6 15.5 6H21.5C22.052 6 22.5 6.447 22.5 7C22.5 7.553 22.052 8 21.5 8H15.5C14.948 8 14.5 7.553 14.5 7ZM21.5 16H19.337C18.903 14.559 17.58 13.5 16 13.5C14.07 13.5 12.5 15.07 12.5 17C12.5 18.93 14.07 20.5 16 20.5C17.58 20.5 18.903 19.441 19.337 18H21.5C22.052 18 22.5 17.553 22.5 17C22.5 16.447 22.052 16 21.5 16ZM16 18.5C15.173 18.5 14.5 17.827 14.5 17C14.5 16.173 15.173 15.5 16 15.5C16.827 15.5 17.5 16.173 17.5 17C17.5 17.827 16.827 18.5 16 18.5ZM10.5 17C10.5 17.553 10.052 18 9.5 18H3.5C2.948 18 2.5 17.553 2.5 17C2.5 16.447 2.948 16 3.5 16H9.5C10.052 16 10.5 16.447 10.5 17Z"
                fill="currentColor"/>
        </svg>
        , 'Sliders')
    return <Icon {...props} data-cy="sliders-icon"/>
}