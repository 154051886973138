import React, {useEffect, useState} from 'react';
import { api } from '../../shared/libs/Auth';
import { Box } from '@mui/system';
import { Snackbar,  Alert } from '@mui/material';
import AddContactsForm from 'widgets/contact/AddContactsForm';
import { Button, IconButton } from '@mui/material';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import theme from '../../app/Theme'
import ImportCSVModal from "../../shared/ui/ImportCSVModal";

export default function Contacts({contacts, groups, onHideContacts, onUpdateContacts, sx}) {
    const [localContacts, setLocalContacts] = useState(contacts);
    const [localGroups, setLocalGroups] = useState(groups);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // 'error' or 'success'
    const [validContactsCount, setValidContactsCount] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(null);

    const CheckIsValidEmail = (emailAddress) =>{
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(emailAddress) || emailAddress === ''
    }
    const CheckIsValidPhoneNumber = (phoneNumber) =>{
        return phoneNumber === 'Invalid'
    }

    useEffect(() => {
        let count = 0;
        localContacts.forEach(contact => {
            if (CheckIsValidEmail(contact.email) && !CheckIsValidPhoneNumber(contact.phone) && contact.name.trim() !== '' && (contact.email || contact.phone)) {
                count++;
            }
        });
        setValidContactsCount(count);

        if (validContactsCount === localContacts.length) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [localContacts]);

    const openSnackbar = (message, severity = 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleServerError = (errorResponse) => {
        let errorMessage = 'An error occurred. Please try again.';
        if (errorResponse.errors) {
            const errorMessages = errorResponse.errors.map(error => Object.values(error).join(', ')).join(' ');
            errorMessage = errorMessages;
        }
        openSnackbar(errorMessage, 'error');
    };


    const saveContacts = async () => {
        const responseGet = await api.get('/contacts');
        const contactsInSystem = responseGet.data;
    
        const validContacts = localContacts.filter(contact => 
            contact.name.trim() !== '' || contact.email.trim() !== '' || contact.phone.trim() !== ''
        );
    
        const contactsToDelete = contactsInSystem.filter(contactInSystem => 
            !validContacts.some(validContact => validContact.id === contactInSystem.id)
        );
    
        const contactsToDeleteIds = contactsToDelete.map(contact => contact.id);
        if (contactsToDeleteIds.length > 0) {
            try {
                await api.delete('/contacts', { data: { ids: contactsToDeleteIds }});
                console.log('Contacts deleted:', contactsToDeleteIds);
            } catch (error) {
                console.error('Error deleting contacts:', error);
            }
        }

        try {
            const responsePost = await api.post('/contacts', validContacts);
            console.log('Contacts saved:', responsePost.data);
            openSnackbar("Contacts saved successfully", "success");
            onUpdateContacts(responsePost.data, contactsInSystem);
            setTimeout(() => {
                onHideContacts(); // return to UsersAndContacts
            }, 1000);
        } catch (error) {
            console.error('Error saving contacts:', error);
            handleServerError(error.response.data);
        }
    };


    const importContactsActionHandler = (file, groupName, onSuccess, onError) => {
        setIsImporting(true)
        if (!file) {
            console.log("Import cancelled or failed.");
            openSnackbar("Import cancelled or failed", "error");
            setIsImporting(false);
            return;
        }
        console.log("Processing file:", file.name, "for group:", groupName);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('group', groupName);

        api.post('/contacts', formData, {
            headers: {
                'Content-Type': undefined
            }
        })
        .then(response => {
            openSnackbar("Contacts imported successfully", "success");
            onUpdateContacts(response.data)
            onSuccess(response.data);
            setIsImporting(false)
        })
        .catch(error => {
            onError(error.response ? error.response.data : { error: error.message });
            setIsImporting(false)
        });

    };


    return (
        <Box className='settings' sx={{backgroundColor: theme.palette.grey[100], display:"flex", flexDirection: "column", gap:"24px"}}>
            <Box className='settings-toolbar' sx={{backgroundColor: "white", padding:"16px 24px"}}>
                <IconButton variant={'outlined'} onClick={onHideContacts}>
                    <ArrowLeftSmallIcon size={'small'}/>
                </IconButton>
                <h3>
                    Add contacts
                </h3>
                <div style={{marginLeft: 'auto'}}>
                    <ImportCSVModal
                        actionHandler={importContactsActionHandler}
                        initialGroups={localGroups}
                        typeOfImportingFiles={"contacts"}
                        buttonTitle={"Import"}
                        modalTitle={"Import contacts"}
                        isImporting={isImporting}
                    />
                </div>
                <Button variant={'contained'} color={'primary'} onClick={saveContacts} disabled={!isValid}>
                    Save contacts
                </Button>
            </Box>

            <Box sx={{width:"1216px",
                display:"flex",
                alignSelf:"center",
                boxShadow:'-4px 0px 14px 0px rgba(103, 122, 142, 0.12)',
                borderRadius:'16px',
                backgroundColor: "white",
                marginBottom: "60px",
            }}>
                <Box className='FormContactsWrapper' sx={{overflow: 'auto', flexGrow: '1'}}>
                    <AddContactsForm 
                        contacts={localContacts} 
                        groups={localGroups} 
                        onContactsChange={setLocalContacts} 
                    />
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Box>
    );
}
