import React, {useRef, useState} from 'react'
import {Box, IconButton, Button, Dialog, FormControlLabel} from '@mui/material'
import TextField from '@mui/material/TextField'
import CloseIcon from '../assets/icons/Close'
import {CheckBox} from './CheckBox'

export default function SaveViewModal({headerText, saveText, saveFunc, view, checkBoxes = []}) {
    const [changed, setChanged] = useState(false)
    const view_ref = useRef(JSON.parse(JSON.stringify(view)))

    return (
        <Dialog
            className='timeline-modal'
            open={true}
            onClose={() => {
                saveFunc(false)
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '500px', gap: '16px'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center'}}>
                    <Box sx={{display: 'flex', fontWeight: '500', fontSize: '24px', color: 'var(--palette-grey-900)'}}>
                        {headerText}
                    </Box>
                    <IconButton
                        variant={'outlined'}
                        onClick={() => {
                            saveFunc(false)
                        }}
                        sx={{marginLeft: 'auto', padding: '4px'}}
                    >
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </Box>
                <Box style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Box
                        className='modal-section-name'
                        sx={{width: '100%'}}
                    >
Title
                    </Box>
                    <TextField
                        style={{width: '100%'}}
                        value={view_ref.current.title}
                        onChange={(e) => {
                            view_ref.current.title = e.target.value; setChanged(!changed)
                        }}
                        placeholder='Please enter title'
                    />
                </Box>
                <Box>
                    <Box
                        className='modal-section-name'
                        sx={{width: '100%'}}
                    >
                        Include
                    </Box>
                    {checkBoxes.map((item) => (
                        <FormControlLabel
                            style={{display: 'flex', width: '100%', alignContent: 'center', gap: '4px'}}
                            className={'modal-checkbox-root'}
                            key={item.key}
                            control={
                                <CheckBox
                                    checked={view_ref.current.includes[item.key]}
                                    onChange={(event) => {
                                        view_ref.current.includes[event.target.name] = event.target.checked
                                        setChanged(!changed)
                                    }}
                                    name={item.key}
                                    size={'medium'}
                                />
                            }
                            label={item.text}
                        />
                    ))}
                </Box>
                <Box className='row'>
                    <Button
                        fullWidth
                        className={'spacer'}
                        style={{fontWeight: '400', fontSize: '16px', width: '50%'}}
                        onClick={() => {
                            saveFunc(view_ref.current)
                        }}
                        disabled={view_ref.current.title.trim().length < 1}
                    >
                        {saveText}
                    </Button>
                    <Button
                        fullWidth
                        className={'spacer'}
                        style={{fontWeight: '400', fontSize: '16px', width: '50%'}}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={() => {
                            saveFunc(false)
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
