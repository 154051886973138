import {createSvgIcon} from "@mui/material/utils";

export default function PauseIcon(props) {
    const Icon = createSvgIcon(
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-18wq8ra" fill="none"
             focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PauseCircleOutlineIcon" tabIndex="-1">
            <path
                d="M9 16h2V8H9zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m1-4h2V8h-2z" fill="currentColor"></path>
        </svg>, 'Pause')
    return <Icon {...props} data-cy="pause-icon"/>
}