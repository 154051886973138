import {FocusTrap} from "@mui/base";
import {Button, Divider} from "@mui/material";
import UserDetails from "entities/user/UserDetails";
import ChangePasswordForm from "features/user/ui/ChangePasswordForm";
import EditUserForm from "features/user/ui/EditUserForm";
import {useState} from "react";
import * as React from "react";
import LockIcon from "shared/assets/icons/Lock";
import PenIcon from "shared/assets/icons/Pen";
import ModalComponent from "shared/ui/ModalComponent";

export default function UserInfoModal({isOpen, onClose, currentUser, setCurrentUser}) {

		const [isOpenEditUserForm, setIsOpenEditUserForm] = useState(false);
		const [isOpenChangePasswordForm, setIsOpenChangePasswordForm] = useState(false);

		const handleOpenEditUserForm = () => {
				setIsOpenEditUserForm(true)
		}

		const handleCloseEditUserForm = () => {
				setIsOpenEditUserForm(false)
		}

		const handleOpenChangePasswordForm = () => {
				setIsOpenChangePasswordForm(true)
		}

		const handleCloseChangePasswordForm = () => {
				setIsOpenChangePasswordForm(false)
		}

		return (
				<>
						<ModalComponent
								visible={isOpen}
								onCloseModal={onClose}
						>
								<UserDetails
										user={currentUser}
										onClose={onClose}
								/>
								<Divider/>
								<div className={'row fullWidth'}>
										<Button
												className={'spacer'}
												startIcon={<PenIcon/>}
												onClick={handleOpenEditUserForm}
										>
												Edit profile
										</Button>
										<Button
												data-cy={'change-password-button'}
												className={'spacer'}
												variant={'outlined'}
												color={'secondary'}
												startIcon={<LockIcon/>}
												onClick={handleOpenChangePasswordForm}
										>
												Change password
										</Button>
								</div>
						</ModalComponent>

						<ModalComponent visible={isOpenEditUserForm}>
								<EditUserForm onClose={handleCloseEditUserForm} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
						</ModalComponent>

					<FocusTrap open={isOpenChangePasswordForm}>
						<ModalComponent visible={isOpenChangePasswordForm}>
							<ChangePasswordForm onClose={handleCloseChangePasswordForm}/>
						</ModalComponent>
					</FocusTrap>
				</>

		)
}