const DEFAULT_REFRESH_TIME_IN_MS = 300000; // 5 min;
const DEFAULT_URL_PREFIX = 'cdn';

async function getSig() {
    const accessToken = localStorage.getItem('accessToken')
    return fetch('/products/signature', {headers: {Authorization: 'Bearer ' + accessToken}})
}

export class Signature{
    constructor(refreshTimeInMs = DEFAULT_REFRESH_TIME_IN_MS, urlPrefix = DEFAULT_URL_PREFIX){
        this.isInitialized = false;
        this.defaultUrls = ['', '1', '2', '3', '4', '5'].map((n) => '//' + urlPrefix + n + '.velocityweather.com/v1/');
        this._baseUrlIndex = 0;

        const _upSigData = ({key, sig, ts})=>{
            this.key = key;
            this.sig = sig;
            this.ts = ts;
            this.isInitialized = true;
        };

        getSig().then(response => response.json()).
            then(_upSigData).catch(()=>{this.isInitialized = false;});

        window.setInterval(()=>{
            getSig().then(response => response.json()).
                then(_upSigData).catch(()=>{this.isInitialized = false;});
        }, refreshTimeInMs);
    }

    _getBaseUrl() {
        this._baseUrlIndex = (this._baseUrlIndex + 1) % this.defaultUrls.length;
        return this.defaultUrls[this._baseUrlIndex];
    }

    makeUrl({path,params}) {
        if (!this.isInitialized) return;
        let url = `${this._getBaseUrl()}${this.key}/${path}?ts=${this.ts}&sig=${this.sig}`;
        for (let key in params) {
            (params[key] !== undefined) && (url+=`&${key}=${params[key]}`);
        }
        return url;
    }

}
