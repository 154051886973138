import {createSvgIcon} from "@mui/material/utils";


export default function DragVerticalIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 4C7.89543 4 7 4.89543 7 6C7 7.10457 7.89543 8 9 8C10.1046 8 11 7.10457 11 6C11 4.89543 10.1046 4 9 4Z"
                fill="currentColor"/>
            <path
                d="M15 4C13.8954 4 13 4.89543 13 6C13 7.10457 13.8954 8 15 8C16.1046 8 17 7.10457 17 6C17 4.89543 16.1046 4 15 4Z"
                fill="currentColor"/>
            <path
                d="M7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12Z"
                fill="currentColor"/>
            <path
                d="M15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10Z"
                fill="currentColor"/>
            <path
                d="M7 18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18C11 19.1046 10.1046 20 9 20C7.89543 20 7 19.1046 7 18Z"
                fill="currentColor"/>
            <path
                d="M15 16C13.8954 16 13 16.8954 13 18C13 19.1046 13.8954 20 15 20C16.1046 20 17 19.1046 17 18C17 16.8954 16.1046 16 15 16Z"
                fill="currentColor"/>
        </svg>
        , 'DragVertical')
    return <Icon {...props} data-cy="drag-vertical-icon"/>
}