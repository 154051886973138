import React from "react";

export function ListGroup({children}) {
		return (
				<div
						className={'column'}
						style={{
								width: '100%',
								gap: '16px',
								justifyContent: 'flex-start',
								flexWrap: 'wrap',
								padding: "4px"
						}}
				>
						{children}
				</div>
		)
}