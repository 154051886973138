import CopyIcon from 'shared/assets/icons/Copy'
import React, {useCallback, useEffect, useState} from 'react'
import {IconButton, InputAdornment, Tooltip} from '@mui/material'
import {CopyToClipboard} from 'react-copy-to-clipboard/src'

const CopyTooltip = ({textToCopy}) => {
    const [openTooltip, setOpenTooltip] = useState(false)
    const [title, setTitle] = useState('Copied!')

    const handleTooltipClose = () => {
        setOpenTooltip(false)
    }

    const handleTooltipOpen = useCallback(() => {
        setOpenTooltip(true)
    }, [],
    )

    const onCopy = (text, result) => {
        if (result) {
            handleTooltipOpen()
        }
        result ? setTitle('Copied!') : setTitle('Not copied!')
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            handleTooltipClose()
        }, 1000)
        return () => {
            clearTimeout(timeout)
        }
    }, [openTooltip])

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            enterDelay={500}
            leaveDelay={200}
            title={title}
        >
            <InputAdornment position="end">
                <CopyToClipboard
                    text={textToCopy}
                    onCopy={onCopy}
                >
                    <IconButton>
                        <CopyIcon size={'small'}/>
                    </IconButton>
                </CopyToClipboard>
            </InputAdornment>
        </Tooltip>
    )
}

export default CopyTooltip
