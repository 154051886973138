import {createSvgIcon} from "@mui/material/utils";

export default function ArrowRightIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.7068 12.7073L14.7068 19.7073C14.5118 19.9023 14.2559 20.0003 13.9999 20.0003C13.7439 20.0003 13.4879 19.9023 13.2929 19.7073C12.9019 19.3163 12.9019 18.6842 13.2929 18.2933L18.5859 13.0003H2.99988C2.44688 13.0003 1.99988 12.5533 1.99988 12.0003C1.99988 11.4473 2.44688 11.0003 2.99988 11.0003H18.5859L13.2929 5.70731C12.9019 5.31631 12.9019 4.68425 13.2929 4.29325C13.6839 3.90225 14.3158 3.90225 14.7068 4.29325L21.7068 11.2933C22.0978 11.6842 22.0978 12.3163 21.7068 12.7073Z"
                fill="currentColor"/>
        </svg>, 'ArrowRight')
    return <Icon {...props} data-cy="arrow-right-icon"/>
}