import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

export default class DateAdapter extends AdapterDayjs {
    constructor(params = {}) {
        super(params)

        this.getWeekdays = () => {
            return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        }
    }
}
