import React from 'react';
import { Box, IconButton } from '@mui/material';
import {ManageLocationScreen} from 'widgets/location/ui/manageLocationScreen/ManageLocationScreen';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";


export default function EditLocationScreen({ defaultValue={}, editLocation=false, selectLocations=false, closeOnSave=false, onClose, groups=[] }) {

    console.log("EditLocationScreen.editLocation", editLocation);
    console.log("EditLocationScreen.defaultValue", defaultValue);
    return (
        <Box className="EditLocationPage fullHeight" sx={{ overflow: 'hidden', flexGrow: '1', background: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'stretch' }}>

            <Box className={'toolbar padding'} sx={{borderBottom: '1px solid #dbe0ea' }}>
                <IconButton size={'small'} variant={'outlined'} onClick={() => {console.log("EditLocationScreen.selectLocations", selectLocations, defaultValue); onClose(!selectLocations);}}>
                    <ArrowLeftSmallIcon size={'small'}/>
                </IconButton>
                <h3>
                    {(defaultValue && defaultValue.options)?'Edit':'Add'} location
                </h3>
            </Box>

            <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'row', flexGrow: '1', padding: '20px', backgroundColor:"var(--palette-grey-100)"}}>
                   <ManageLocationScreen style={{padding: 0}} showLocations={!editLocation} editLocation={editLocation} selectLocations={selectLocations} defaultLocation={defaultValue} closeOnSave={closeOnSave} onChange={onClose} />
            </Box>
        </Box>
    );
};
