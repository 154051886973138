import React from 'react';
import BellSlashIcon from "../../../shared/assets/icons/BellSlashIcon";

const PausedChip = ({isEnable = false}) => {
    return (
        <div>
            {isEnable ? <></> : <div style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                padding: "4px 12px 4px 10px",
                backgroundColor: "var(--palette-grey-background)",
                borderRadius: "8px", border: "1px solid var(--palette-grey-100)",
                fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-500)"
            }}><BellSlashIcon size={"small"}/>Paused</div>}
        </div>
    );
};

export default PausedChip;