import {Box} from '@mui/system'
import {Chip} from '@mui/material'
import React from 'react'

export default function SpanSverity({children, label=children}) {
    const COLORS = {
        Critical: '#a83b3b',
        Severe: '#e86f00',
        Moderate: '#1b51b3',
        Default: '##171E27',
    }
    const BACKGROUND ={
        Critical: 'rgba(168, 59, 59, 0.25)',
        Severe: 'rgba(232, 111, 0, 0.25)',
        Moderate: 'rgba(27, 81, 179, 0.25)',
        Default: 'rgba(240, 242, 245, 1)',
    }

    const SPAN_SEVERITY_STYLE ={
        width: '100%',
        height: '24px',
        padding: '4px 8px',
        gap: '4px',
        // background:'#f1f5fd',
        borderRadius: '8px',
        color: COLORS[children],
        background: BACKGROUND[children],
    }
    return (
        <Box sx={SPAN_SEVERITY_STYLE}>
            {label}
        </Box>
    )
}

export function ChipSeverity({severity, label = severity, size}) {
    const COLORS = {
        Critical: 'red',
        Severe: 'yellow',
        Moderate: 'blue',
        Default: 'dark grey',
    }

    return (
        <Chip
            sx={size === 'small' && {height: '24px', '& .MuiChip-label': {fontSize: '14px'}}}
            label={label}
            variant={COLORS[label]}
        />
    )
}
