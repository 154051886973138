import {createSvgIcon} from "@mui/material/utils";


export default function FileIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.414 7L15 2.58606C14.622 2.20806 14.12 2 13.586 2H9C6.243 2 4 4.243 4 7V17C4 19.757 6.243 22 9 22H15C17.757 22 20 19.757 20 17V8.41394C20 7.88794 19.787 7.372 19.414 7ZM15 5.41394L16.586 7H16.5C15.673 7 15 6.327 15 5.5V5.41394ZM15 20H9C7.346 20 6 18.654 6 17V7C6 5.346 7.346 4 9 4H13V5.5C13 7.43 14.57 9 16.5 9H18V17C18 18.654 16.654 20 15 20Z"
                fill="currentColor"/>
        </svg>
        , 'File')
    return <Icon {...props} data-cy="file-icon"/>
}