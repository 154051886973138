import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel.css';
import AlertConfigurationStepper from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/AlertConfigurationStepper';
import StepperScreens from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/StepperScreens';
import { useSearchParams } from 'react-router-dom';
import { fetchConfigurationGroup } from "pages/alerts/api/FetchAlerts";


function ManageAlertConfigurationPanel({ onClose, defaultTab = 'Locations', args={} }) {

    const [currentTab, setCurrentTab] = useState(defaultTab)
    const [configGroup, setConfigGroup] = useState(null);
    const [alertConfigurationValid, setAlertConfigurationValid] = useState({
        isAnyDefinitionSelected: false,
        isAnyLocationSelected: false,
        isDisableCreationLocationMode: false, //mode of creating location. Should be true to make isValid.
        isValid: false,
    })

    const [isAlertStepperVisible, setIsAlertStepperVisible] = useState(true);
    const [isPressedSaveButton, setIsPressedSaveButton] = useState(false); //Listener of save button press

    const [query] = useSearchParams();
    const editArg = query.get('edit');
    const copyArg = query.get('copy');
    const wdArg = query.get('wd');

    const handleBeforeUnload = (event) => {
        if (!isPressedSaveButton) {
            event.preventDefault();
            event.returnValue = '';
            return '';
        }
    };

    useEffect(() => {
        if (Object.keys(args).length) setConfigGroup(args);
        else if (editArg)
            fetchConfigurationGroup(editArg).then((data) => {data.id = editArg; setConfigGroup(data)});
        else if (copyArg)
            fetchConfigurationGroup(copyArg).then((data) => {setConfigGroup(data)});
        else if (wdArg)
            setConfigGroup({definition: parseInt(wdArg), locations: {}});
        else setConfigGroup({});
    }, []);
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isPressedSaveButton]);
    if (!configGroup) return (<></>)

    const handleSaveButton = () =>{
        setIsPressedSaveButton(true)
    }

    return (
        <Box className="newWeatherAlertsWrapper" sx={{ overflow: 'hidden' }}>
            <div className='bodyNWA fullWidth'>
                {isAlertStepperVisible && <AlertConfigurationStepper
                    currentTab={currentTab}
                    onChange={setCurrentTab}
                    alertConfigurationValid={alertConfigurationValid}
                    isPressedSaveButtonFunc={()=> handleSaveButton()}
                />}
                <StepperScreens
                    currentStep={currentTab}
                    alertConfigurationValid={alertConfigurationValid}
                    onChange={setAlertConfigurationValid}
                    configGroup={configGroup}
                    onClose={onClose}
                    showSidebar={isAlertStepperVisible}
                    setShowSidebar={() => setIsAlertStepperVisible(!isAlertStepperVisible)}
                    isPressedSaveButton={isPressedSaveButton}
                />
            </div>
        </Box>
    );
}

export default ManageAlertConfigurationPanel;
