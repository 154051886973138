import React from 'react';
import theme from "../../../../app/Theme";
import {MenuItem} from "@mui/material";
import {Select} from "../../../../shared/ui/Select";

const UrgencySelector = ({urgency, onChange}) => {
    return (
        <Select
            placeholder={''}
            defaultValue={urgency}
            onChange={onChange}
            InputProps={{
                startAdornment: <div className={'paragraph'} style={{color: theme.palette.grey[400]}}>
                    Urgency:
                </div>
            }}
        >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"High"}>High</MenuItem>
        </Select>
    );
};

export default UrgencySelector;