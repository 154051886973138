import {IconButton} from "@mui/material";
import maplibregl from "maplibre-gl";
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {formatPqResponse} from "../../../../pages/map/model/utils/pqdataformatter";
import PointIcon from "../../../../shared/assets/icons/Point";

const PointQueryTool = ({productsController, activeProducts, clear, activeTool, setActiveTool}) => {
    const [isActive, setIsActive] = useState(false);
    const isActiveRef = useRef(false);
    const pointQueriesPopups = useRef({});
    const objectContentPointQuery = useRef({});
    const [lastClickedLocations, setLastClickedLocations] = useState([]);

    useEffect(() => {
        clearInfo()
    }, [clear]);

    useEffect(() => {
        if (activeTool !== "PointQuery") {
            setIsActive(false)
        }
    }, [activeTool]);

    useEffect(() => {
        if (isActive) {
            setActiveTool()
            window?.map?.on?.('click', handleMapClick)
        } else {
            window?.map?.off?.('click', handleMapClick)
        }
    }, [isActive]);

    const updatePopups = () => {
        lastClickedLocations.forEach(({coords, popupId}) => {
            PrepareForRenderPopup(coords, popupId);
        });
    }

    useEffect(() => {
        window?.map?.on?.('selectedTime', updatePopups)
        return () => {
            window?.map?.off?.('selectedTime', updatePopups)
        }
    })

    useEffect(() => {
        updatePopups()
    }, [isActive, lastClickedLocations]);

    const handleClickOnButton = () => {
        setIsActive(prevState => {
            const newIsActive = !prevState
            isActiveRef.current = newIsActive

            return newIsActive
        });
    };

    const handleMapClick = useCallback((event) => {
        if (!isActiveRef.current) return

        const popupId = Math.random().toString(36).substring(2, 15);
        pointQueriesPopups.current[popupId] = new maplibregl.Popup({
            closeOnClick: false,
            closeButton: false
        })

        setLastClickedLocations(prevLocations => [
            ...prevLocations,
            {coords: event.lngLat, popupId}
        ])
    }, []);

    const getCoordinates = (clickEvent) => {
        return clickEvent ? clickEvent.lngLat : window.map.getCenter();
    }

    const PrepareForRenderPopup = (coords, popupId) => {
        const popup = pointQueriesPopups.current[popupId];
        if (!popup) return; // CGT-5093
        objectContentPointQuery.current = '<div>Loading...</div>';

        productsController.current.getPQ(coords).then((values) => {
            objectContentPointQuery.current = values;
            _functionRenderPopup(objectContentPointQuery.current, popupId);
        });

        popup.setLngLat(coords);
        popup.addTo(window.map);
    };

    const _functionRenderPopup = (payloadObj, popupId) => {
        const popup = pointQueriesPopups.current[popupId];

        if (typeof (payloadObj) === 'string') {
            popup.setHTML(`<div class="custom-popup">${payloadObj}</div`);
            return;
        }

        if (Object.values(payloadObj).length === 0) {
            payloadObj = {Warning: {text: "No data"}};
        }

        if (activeProducts.length === 0) {
            payloadObj = {Warning: {text: "No layers selected"}};
        }

        const popupContent = document.createElement('div');

        let stringValueHTML = '';
        for (let key in payloadObj) {
            stringValueHTML += `<div class="custom-popup-text-wrapper"><span class="custom-popup-text">${formatPqResponse(payloadObj[key])}</span></div>`;
        }
        popupContent.innerHTML = stringValueHTML;

        const button = document.createElement('div');
        button.classList.add('custom-popup-button');
        button.setAttribute('data-cy', 'close-button')
        button.textContent = "×";

        button.addEventListener('click', () => {
            deletePopup(popup, popupId)
        });

        const popupContainer = document.createElement('div');
        popupContainer.appendChild(popupContent);
        popupContainer.appendChild(button);
        if (popup) {
            popup.setDOMContent(popupContainer)
                .getElement()
                .setAttribute('data-cy', 'point-query-tool-popup')
        }
    }

    const deletePopup = (popup, id) => {
        delete pointQueriesPopups.current[id];
        setLastClickedLocations((prevLocations) =>
            prevLocations.filter((location) => location.popupId !== id)
        );
        popup.remove()
    }

    const clearInfo = () => {
        Object.values(pointQueriesPopups.current).forEach(popup => {
            popup.remove();
        });
        pointQueriesPopups.current = {};
        setLastClickedLocations([]);
    }

    return (
        <IconButton
            data-cy={'point-query-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={"small"}
            className={`productpqswitcher ${isActive ? 'active' : ''}`}
        >
            <PointIcon/>
        </IconButton>
    );
};

export default PointQueryTool;