import {createSvgIcon} from "@mui/material/utils";


export default function DesktopIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.873 2H7.87305C5.11605 2 2.87305 4.243 2.87305 7V13C2.87305 15.757 5.11605 18 7.87305 18H11.873V20H8.87305C8.32105 20 7.87305 20.447 7.87305 21C7.87305 21.553 8.32105 22 8.87305 22H16.873C17.425 22 17.873 21.553 17.873 21C17.873 20.447 17.425 20 16.873 20H13.873V18H17.873C20.63 18 22.873 15.757 22.873 13V7C22.873 4.243 20.63 2 17.873 2ZM20.873 13C20.873 14.654 19.527 16 17.873 16H7.87305C6.21905 16 4.87305 14.654 4.87305 13V7C4.87305 5.346 6.21905 4 7.87305 4H17.873C19.527 4 20.873 5.346 20.873 7V13Z"
                fill="currentColor"/>
        </svg>
        , 'Desktop')
    return <Icon {...props} data-cy="desktop-icon"/>
}