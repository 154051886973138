import React from 'react';
import InfoCircleIcon from "../assets/icons/InfoCircle";

const InfoPanel = ({title, text}) => {
    return (
        <div style={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            backgroundColor: "var(--palette-grey-100)",
            borderRadius: "16px",
            padding: "24px",
            width:"100%",
        }}>
            <div style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "var(--palette-grey-900)",
                display: "flex",
                gap: "8px"
            }}>
                <InfoCircleIcon/>
                {title}
            </div>
            <div style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-500)", lineHeight: "20px"}}>
                {text}
            </div>
        </div>
    );
};

export default InfoPanel;