import {createSvgIcon} from "@mui/material/utils";


export default function UploadIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.29303 7.70731C7.90203 7.31631 7.90203 6.68425 8.29303 6.29325L11.293 3.29325C11.684 2.90225 12.316 2.90225 12.707 3.29325L15.707 6.29325C16.098 6.68425 16.098 7.31631 15.707 7.70731C15.512 7.90231 15.256 8.00028 15 8.00028C14.744 8.00028 14.488 7.90231 14.293 7.70731L13 6.41422V11.0003C13 11.5533 12.552 12.0003 12 12.0003C11.448 12.0003 11 11.5533 11 11.0003V6.41422L9.70697 7.70731C9.31597 8.09831 8.68403 8.09831 8.29303 7.70731ZM17 10.0003C16.448 10.0003 16 10.4473 16 11.0003C16 11.5533 16.448 12.0003 17 12.0003C18.654 12.0003 20 13.3463 20 15.0003V16.0003C20 17.6543 18.654 19.0003 17 19.0003H7C5.346 19.0003 4 17.6543 4 16.0003V15.0003C4 13.3463 5.346 12.0003 7 12.0003C7.552 12.0003 8 11.5533 8 11.0003C8 10.4473 7.552 10.0003 7 10.0003C4.243 10.0003 2 12.2433 2 15.0003V16.0003C2 18.7573 4.243 21.0003 7 21.0003H17C19.757 21.0003 22 18.7573 22 16.0003V15.0003C22 12.2433 19.757 10.0003 17 10.0003ZM18 15.0003C18 14.4483 17.552 14.0003 17 14.0003C16.448 14.0003 16 14.4483 16 15.0003C16 15.5523 16.448 16.0003 17 16.0003C17.552 16.0003 18 15.5523 18 15.0003Z"
                fill="currentColor"/>
        </svg>
        , 'Upload')
    return <Icon {...props} data-cy="upload-icon"/>
}