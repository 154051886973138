import {Box, Button, Divider, Drawer, IconButton, Chip} from "@mui/material";
import React, {useState, useEffect} from "react";
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {CheckBox} from "shared/ui/CheckBox";
import {getConfigurations} from "pages/settings/ui/alerts/AlertsSettings";
import AlertsSettingsList from "pages/settings/ui/alerts/AlertsSettingsList";
import {
    fetchAlertConfigurations,
    fetchConfigurationGroups,
    fetchRealLocations,
    fetchWeatherDefinitions,
    deleteConfigurationGroup, fetchAlertConfigurationsExtended, togglePauseConfigurationGroup
} from "pages/alerts/api/FetchAlerts";
import MapMarkerIcon from 'shared/assets/icons/MapMarker';
import AlertConfigurationDetails from "entities/alertConfig/ui/AlertConfigurationDetails";
import AgreeModal from "shared/ui/AgreeModal";
import {SearchInput} from "shared/ui/SearchInput";

export function AlertConfigsFilter(
    {
        onChange,
        selectedConfigs,
        setSelectedConfigs,
        openButtonText = 'Alert Configurations'
    }) {
    const [searchString, setSearchString] = useState("");
    const [selectedConfigsTemp, setSelectedConfigsTemp] = useState({configs: {}});
    const [alertConfigs, setAlertConfigs] = useState([]);
    const [weatherDefinitions, setWeatherDefinitions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [configGroups, setConfigGroups] = useState([]);
    const [alertGroups, setAlertGroups] = useState({});
    const [extendedAlertConfigs, setExtendedAlertConfigs] = useState([]); //contains paused alert data or not
    const [pauseAlertConfig, setPauseAlertConfig] = useState(null);
    const [restartAlertConfig, setRestartAlertConfig] = useState(null);
    //const [severity, setSeverity] = useState('All');
    const [isOpenConfigsPanel, setIsOpenConfigsPanel] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [deleteConfig, setDeleteConfig] = useState(false);
    const severity = 'All';

    let configurationsCount = 0;
    const configs = selectedConfigs.configs;
    if (configs)
        for (let key in configs)
            if (configs[key].selected) configurationsCount++;

    useEffect(() => {
        fetchAlertConfigurations().then((data) => {
            setAlertConfigs(data)
        });
        fetchWeatherDefinitions().then((data) => {
            setWeatherDefinitions(data)
        });
        fetchConfigurationGroups().then((data) => {
            setConfigGroups(data)
        });
        fetchAlertConfigurationsExtended().then((data) => {
            setExtendedAlertConfigs(data)
        });
        fetchRealLocations().then((data) => {
            setLocations(data.location)
        });
    }, []);

    useEffect(() => {
        const alrt = getConfigurations(weatherDefinitions, locations, alertConfigs, configGroups);
        setAlertGroups(alrt);
        if (!Object.keys(alrt).length) return;
        if (!selectedConfigs.configs || Object.keys(selectedConfigs.configs).length !== Object.keys(alertGroups).length) {
            const configs = {};
            for (let key in alrt)
                configs[key] = {selected: true, group: alrt[key]}
            setSelectedConfigs({configs: copySelectedConfigs(configs)})
            setSelectedConfigsTemp({configs: configs});
        }
        else {
            const configs = copySelectedConfigs(selectedConfigs.configs);
            setSelectedConfigsTemp({configs: configs});
        }
    }, [weatherDefinitions, locations, alertConfigs, configGroups, selectedConfigs.configs]);

    const copySelectedConfigs = (configs) => {
        const configsCopy = {}
        if(!configs) return {};
        for (let key in configs) {
            configsCopy[key] = {...configs[key]};
        }
        return configsCopy;
    }

    const onClose = () => setIsOpenConfigsPanel(false)

    const onCancel = () => {
        const configs = copySelectedConfigs(selectedConfigs.configs);
        setSelectedConfigsTemp({configs: configs});
        onClose();
    }

    const onAction = (action, alert) => {
        if (action === 'select') {
            const configs = selectedConfigsTemp.configs;
            configs[alert.groupId].selected = !configs[alert.groupId].selected;
            setSelectedConfigsTemp({configs: configs});
        }
        else if (action === 'open') setOpenAlert(alert);
        else if (action === 'single_delete') setDeleteConfig(alert);
        if (action === 'edit') {
            const locations = {};
            alert.locations.forEach(loc=>{locations[loc.id] = true});
            onChange('edit_alert', {back: true, locations: locations, definition: alert.definition_id, id: alert.groupId})
        }
        else if (action === 'duplicate') {
            const locations = {};
            alert.locations.forEach(loc=>{locations[loc.id] = true});
            onChange('edit_alert', {back: true, locations: locations, definition: alert.definition_id})
        }
        else if (action === "pause") {
            setPauseAlertConfig(alert)
        }
        else if (action === "restart") {
            setRestartAlertConfig(alert)
        }
    }

    const doDeleteConfiguration = (agreed) => {
        if (agreed) {
            deleteConfigurationGroup(deleteConfig.groupId);
            const validConfigs = [];
            for (let idx in alertGroups) {
                const alert = alertGroups[idx];
                if (alert.groupId === deleteConfig.groupId) continue;
                validConfigs.push(alert)
            }
            setAlertGroups(validConfigs);
            delete selectedConfigsTemp.configs[deleteConfig.groupId];
            setSelectedConfigsTemp({...selectedConfigsTemp});
            delete selectedConfigs.configs[deleteConfig.groupId];
            setSelectedConfigs({...selectedConfigs});
            setOpenAlert(false);
        }
        setDeleteConfig(false);
    }

    const updateExtendedAlertConfigs = (id, isRestart) =>{
        const newExtendedAlertConfigs = extendedAlertConfigs.map((alertConf)=>{
            if (alertConf.group_id === id) {
                return { ...alertConf, enabled: isRestart };
            }
            return alertConf;
        });
        setExtendedAlertConfigs(newExtendedAlertConfigs)
    }

    const handlePauseOrRestartAlertConfig = (agreed, id, isRestart) =>{
        if(agreed){
            const data = JSON.stringify({enabled: isRestart})
            togglePauseConfigurationGroup(id, data).then((response) => {
                if(response?.success){
                    updateExtendedAlertConfigs(id, isRestart)
                }
                else{
                    console.log("Error with pause/restart alert configuration")
                }
            })
        }
        setPauseAlertConfig(null)
        setRestartAlertConfig(null)
    }

    const applySelected = () => {
        const configs = copySelectedConfigs(selectedConfigsTemp.configs);
        setSelectedConfigs({configs: configs});
        onClose();
    }

    const allSelected = () => {
        if (!Object.keys(selectedConfigsTemp.configs).length) return false;
        let selected = true;
        const configs = selectedConfigsTemp.configs;
        for (let key in configs) {
            if (!configs[key].selected) { selected = false; break; }
        }
        return selected;
    }

    const allIndeterminate = () => {
        if (!Object.keys(selectedConfigsTemp.configs).length) return false;
        // let selected = true;
        const configs = selectedConfigsTemp.configs;
        const state0 = configs[Object.keys(configs)[0]].selected;
        let indet = false;
        for (let key in configs) {
            if (configs[key].selected !== state0) { indet = true; break; }
        }
        return indet;
    }

    const selectAll = () => {
        let state = !allSelected();
        const configs = selectedConfigsTemp.configs;
        for (let key in configs) {
            configs[key].selected = state
        }
        setSelectedConfigsTemp({configs: configs});
    }

    let searchStringChanged = ({target}) => {
        setSearchString(target.value.trim());
    };

    return (
        <>
        <Button
            data-cy={'alert-configurations-filter-button'}
            variant={'outlined'}
            color={'secondary'}
            startIcon={<MapMarkerIcon/>}
            endIcon={<Chip label={configurationsCount} size={'small'}/>}
            onClick={() => setIsOpenConfigsPanel(true)}
        >
            {openButtonText}
        </Button>

        <Drawer
            anchor={'right'}
            open={isOpenConfigsPanel}
            onClose={onCancel}
            ModalProps={{ onBackdropClick: onCancel }}
        >
            {!openAlert &&
            <div className={'column gap16 fullHeight'} style={{minWidth: '400px'}}>
                <div className={'row'}>
                    <IconButton
                        onClick={onCancel}
                        size={'small'}
                        variant={'outlined'}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <div className={'subtitle medium'}>
                        Alert configuration filter
                    </div>
                    <Spacer/>
                </div>
                <SearchInput
                    placeholder='Search for alert configuration'
                    value={searchString}
                    onChange={searchStringChanged}
                />
                <Box className={'column fullWidth spacer'}>
                <div
                    className={'column gap12 fullWidth spacer'}
                    style={{
                        alighItems: 'center',
                    }}
                >
                    <div
                        className={'row gap4'}
                        style={{
                            paddingLeft: '13px',
                            marginTop: '16px',
                            borderBottomColor: theme.palette.grey[100],
                        }}
                    >
                        <CheckBox
                            checked={allSelected()}
                            indeterminate={allIndeterminate()} 
                            onClick={selectAll}
                        />
                        <div className={'paragraph'} style={{color: theme.palette.grey[900]}}>
                            All configurations ({Object.keys(selectedConfigsTemp.configs).length})
                        </div>
                    </div>
                    <Divider/>
                    <AlertsSettingsList
                        alertGroups={alertGroups}
                        onChange={onAction}
                        selectedConfigsTemp={selectedConfigsTemp}
                        searchString={searchString.toLocaleLowerCase()}
                        severity={severity}
                        selectedLocations={{}}
                        extendedAlertConfigs={extendedAlertConfigs}
                    />
                    <Box style={{display:"flex", gap:"12px", justifyContent:"end", paddingBottom:"24px"}}>
                        <Button
                            className={'regular'}
                            variant={"outlined"}
                            color={"primary"}
                            style={{width:"96px"}}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={'regular'}
                            variant={"contained"}
                            color={"primary"}
                            style={{width:"96px"}}
                            onClick={applySelected}
                        >
                            Apply
                        </Button>
                    </Box>
                </div>
                </Box>
            </div>
            }
            {
                openAlert &&
                <AlertConfigurationDetails alert={openAlert}
                                           extendedAlertConfig={Object.values(extendedAlertConfigs).filter((alert)=>alert.group_id === openAlert.groupId)}
                                           onChange={(action) => {
                    if (action === 'delete') {
                        onAction('delete', openAlert)
                        return;
                    }
                    onAction(action, openAlert)
                    setOpenAlert(null);
                }}/>
            }
        </Drawer>

            {pauseAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Pausing this alert will prevent it from being triggered, but will not remove previously triggered instances. Are you sure you want to pause "${pauseAlertConfig.type}" alert?`,
                        title: "Pause alert configuration",
                        agreeMsg: "Yes, pause",
                        mode: "pausing",
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, pauseAlertConfig.groupId, false)
                    }}
                />
            }

            {restartAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to restart "${restartAlertConfig.type}" alert?`,
                        title: "Restart alert configuration",
                        agreeMsg: "Yes, restart",
                        mode: "restarting",
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, restartAlertConfig.groupId, true)
                    }}
                />
            }

            {deleteConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want delete "${deleteConfig.type}" alert configuration?`,
                        title: "Delete alert configuration",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: doDeleteConfiguration,
                    }}
                />
            }
        </>
    )
}
