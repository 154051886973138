import TextField from '@mui/material/TextField'

export function RangeTextField(props) {
    const {dateRange = [], dayFormat, ...other} = props
    const value = dateRange.map((date) => {
        return date.format(dayFormat)
    }).join(' — ')

    return (
        <TextField
            {...other}
            value={value}
        >
        </TextField>)
}
