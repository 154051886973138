import React from 'react'
import {Box, Button, Chip, Divider, Step, Stepper, Typography} from '@mui/material'
import MapPinIcon from 'shared/assets/icons/MapPin'
import 'widgets/alertConfig/ui/manageAlertConfigurationPanel/AlertConfigurationStepper.css'
import {Spacer} from 'shared/ui/Spacer'
import CheckCircleIcon from "../../../../shared/assets/icons/CheckCircle";
import WeatherIcon from "../../../../shared/assets/icons/Weather";
import UserInfoIcon from "../../../../shared/assets/icons/UserInfo";
import BellIcon from "../../../../shared/assets/icons/Bell";

const StepCompleted = () => {
    return (
        <Chip
            className={'stepCompleted'}
            variant={'green'}
            size={'small'}
            icon={<CheckCircleIcon size={'small'}/>}
            label={'Completed'}
        />
    )
}

function AlertConfigurationStepper({onChange, currentTab, alertConfigurationValid, isPressedSaveButtonFunc}) {

    const getSelectedClass = (tabName) => {
        return currentTab === tabName ? 'selected' : ''
    }

    const handleLocationsClick = () => {
        onChange('Locations')
    }

    const handleWeatherDefinitionClick = () => {
        // Is Step 1 completed
        if (alertConfigurationValid.isAnyLocationSelected && alertConfigurationValid.isDisableCreationLocationMode) {
            onChange('Weather definition')
        }
    }

    const handleReportingClick = () => {
        if (alertConfigurationValid.isValid) {
            onChange('Reporting')
        }
    }

    const handleNotificationsClick = () => {
        if (alertConfigurationValid.isValid) {
            onChange('Notifications')
        }
    }

    const handleSaveButtonClick = () => {
        isPressedSaveButtonFunc()
    }
    return (
		    <Box style={{display: 'flex', flexDirection: 'column'}}>
            <Box className={'NewAlertStepper'}>
                <Stepper
                    orientation={'vertical'}
                    nonLinear
                >
                    <Step
                        data-cy={'step-locations'}
                        className={getSelectedClass('Locations')}
                        onClick={handleLocationsClick}
                        completed={alertConfigurationValid.isAnyLocationSelected}
                    >
                        <Box className={'stepLabel'}>
                            <Typography className={'medium title'}>
                            Step 1
                            </Typography>
                            <Spacer/>
                            <StepCompleted/>
                        </Box>
                        <Divider/>
                        <Box>
                            <Box className={'row gap4'}>
                                <MapPinIcon size={'small'}/>
                                <Typography className={'medium title'}>
                                Locations
                                </Typography>
                            </Box>
                            <Typography className={'description'}>
                            Select locations to receive alerts about weather events
                            </Typography>
                        </Box>
                    </Step>
                    <Step
                        data-cy={'step-weather-definition'}
                        className={getSelectedClass('Weather definition')}
                        style={{cursor: alertConfigurationValid.isAnyLocationSelected && alertConfigurationValid.isDisableCreationLocationMode ? 'pointer' : 'default'}}
                        onClick={handleWeatherDefinitionClick}
                        completed={alertConfigurationValid.isAnyLocationSelected || alertConfigurationValid.isAnyDefinitionSelected}
                    >
                        <Box className={'stepLabel'}>
                            <Typography className={'medium title'}>
                            Step 2
                            </Typography>
                            {alertConfigurationValid.isAnyDefinitionSelected && <StepCompleted/>}
                        </Box>
                        <Divider/>
                        <Box>
                            <Box className={'row gap4'}>
                                <WeatherIcon size={'small'}/>
                                <Typography className={'medium title'}>
                                Weather definition
                                </Typography>
                            </Box>
                            <Typography className={'description'}>
                            Select weather definitions you want to track
                            </Typography>
                        </Box>
                    </Step>
                    <Step
                        data-cy={'step-reporting'}
                        className={getSelectedClass('Reporting')}
                        style={{cursor: alertConfigurationValid.isValid ? 'pointer' : 'default'}}
                        onClick={handleReportingClick}
                        completed={alertConfigurationValid.isValid}
                    >
                        <Box className={'stepLabel'}>
                            <Typography className={'medium title'}>
                            Step 3
                            </Typography>
                            <Typography fontStyle={'italic'}>
                            Optional
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box>
                            <Box className={'row gap4'}>
                                <UserInfoIcon size={'small'}/>
                                <Typography className={'medium title'}>
                                Reporting
                                </Typography>
                            </Box>
                            <Typography className={'description'}>
                            Create an automated reporting with action items
                            </Typography>
                        </Box>
                    </Step>
                    <Step
                        data-cy={'step-notifications'}
                        className={getSelectedClass('Notifications')}
                        style={{cursor: alertConfigurationValid.isValid ? 'pointer' : 'default'}}
                        onClick={handleNotificationsClick}
                        completed={alertConfigurationValid.isValid}
                    >
                        <Box className={'stepLabel'}>
                            <Typography className={'medium title'}>
                            Step 4
                            </Typography>
                            <Typography fontStyle={'italic'}>
                            Optional
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box>
                            <Box className={'row gap4'}>
                                <BellIcon size={'small'}/>
                                <Typography className={'medium title'}>
                                Notifications
                                </Typography>
                            </Box>
                            <Typography className={'description'}>
                            Configure external notifications to receive alerts outside the system
                            </Typography>
                        </Box>
                    </Step>
                </Stepper>
            </Box>
				    <Box className={'buttonWrapper'}>
						    <Button
                                data-cy={'save-alert-configuration-button'}
								    disabled={!alertConfigurationValid.isValid}
								    onClick={handleSaveButtonClick}
						    >
								    Save alert configuration
						    </Button>
				    </Box>
		    </Box>
    )
}

export default AlertConfigurationStepper
