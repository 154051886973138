import {DatePicker as MuiDatePicker} from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'

import {ActionBar} from 'shared/ui/datePickers/ActionBar'
import {CalendarHeader} from 'shared/ui/datePickers/CalendarHeader'
import 'shared/ui/datePickers/DatePicker.css'
import {PickersDay} from 'shared/ui/datePickers/PickersDay'

/**
 * @param {DateView} defaultView Initial view to display
 * @param {Object} availableViews Available views for navigation from each view
 * @param {DateView[]} availableViews.month Available views for navigation from month view
 * @param {DateView[]} availableViews.year Available views for navigation from year view
 * @param slots Slots for custom content rendering
 * @param slotProps Props for slot components
 * @param {string} format Date formatting string
 * @param {string} placeholder Placeholder text when no date is selected
 * @param {dayjs.Dayjs | null} defaultValue Default date value
 * @param {(value: dayjs.Dayjs | null) => void} onChange Callback when the selected date changes
 * @param {(value: dayjs.Dayjs | null) => void} onAccept Callback when a date is accepted
 * @param {() => void} onClear Callback when the date is cleared
 * @param {dayjs.Dayjs | null} value Controlled date value
 * @param other
 */
export function DatePicker(
    {
        defaultView = 'day',
        availableViews = {
            month: ['month', 'day'],
            year: ['year', 'day'],
        },
        slots,
        slotProps,
        format = "MMM DD 'YY",
        placeholder = format,
        defaultValue,
        value,
        onChange,
        onAccept,
        onClear,
        ...other
    }) {
    const [view, setView] = useState(defaultView)
    const [views, setViews] = useState(availableViews.month)
    const [_value, _setValue] = useState(defaultValue)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        onChange?.(_value)
    }, [_value])

    function handleMonth(date) {
        let newValue = getValue() || date
        newValue = newValue.month(date.month())
        _setValue(newValue)

        const newView = view === 'month' ? 'day' : 'month'
        setView(newView)
    }

    const formatMonth = (date) => {
        date = date || dayjs()
        return date.format('MMM').toUpperCase()
    }

    function handleYear(date) {
        let newValue = getValue() || date
        newValue = newValue.year(date.year())
        _setValue(newValue)

        const newView = view === 'year' ? 'day' : 'year'
        setView(newView)
    }

    const formatYear = (date) => {
        date = date || dayjs()
        return date.format('YYYY')
    }

    const getValue = () => {
        return value ?? _value
    }

    const handleClear = () => {
        _setValue(null)
        onClear?.()
    }

    function handleCancel() {
        setOpen(false)
    }

    function handleAccept() {
        onAccept?.(_value)
        setOpen(false)
    }

    return (
        <MuiDatePicker
            onMonthChange={handleMonth}
            onYearChange={handleYear}

            value={getValue()}
            format={format}
            view={view}
            views={views}
            openTo={view}
            onChange={(value) => _setValue(value)}
            onViewChange={(value) => setView(value)}

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnSelect={false}

            slots={{
                actionBar: ActionBar,
                day: PickersDay,
                toolbar: CalendarHeader,
                ...slots,
            }}
            slotProps={{
                actionBar: {
                    actions: ['clear', 'cancel', 'accept'],
                    onAccept: handleAccept,
                    onCancel: handleCancel,
                    onClear: handleClear,
                },
                textField: {
                    placeholder: placeholder,
                    sx: {
                        '& .MuiInputBase-root': {
                            pointerEvents: 'none',
                        },
                        '& .MuiInputBase-root button': {
                            pointerEvents: 'all',
                        },
                    },
                },
                toolbar: {
                    view: view,
                    slotProps: {
                        month: {
                            value: formatMonth(getValue()),
                            button: {
                                onClick: () => {
                                    const newView = view === 'month' ? 'day' : 'month'
                                    setViews(availableViews.month)
                                    setView(newView)
                                },
                            },
                        },
                        year: {
                            value: formatYear(getValue()),
                            button: {
                                onClick: () => {
                                    const newView = view === 'year' ? 'day' : 'year'
                                    setViews(availableViews.year)
                                    setView(newView)
                                },
                            },
                        },
                    },
                },
                ...slotProps,
            }}
            {...other}
            data-cy={'jojo'}

        />
    )
}
