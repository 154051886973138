import {createSvgIcon} from "@mui/material/utils";


export default function LineIcon(props) {
const Icon = createSvgIcon(
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M22 5C22 6.30754 21.1635 7.4197 19.9965 7.83054C19.6847 7.9403 19.3493 8 19 8C18.8295 8 18.6624 7.98578 18.4997 7.95846C18.1314 7.89663 17.7858 7.76769 17.4761 7.58465L7.58465 17.4761C7.76769 17.7858 7.89663 18.1314 7.95846 18.4997C7.98578 18.6624 8 18.8295 8 19C8 19.3493 7.9403 19.6847 7.83054 19.9965C7.4197 21.1635 6.30754 22 5 22C3.34315 22 2 20.6569 2 19C2 17.6925 2.83649 16.5803 4.00352 16.1695C4.31531 16.0597 4.65068 16 5 16C5.17047 16 5.33763 16.0142 5.50034 16.0415C5.86866 16.1034 6.21422 16.2323 6.52401 16.4154L16.4154 6.52401C16.2323 6.21422 16.1034 5.86866 16.0415 5.50034C16.0142 5.33763 16 5.17047 16 5C16 4.65068 16.0597 4.31531 16.1695 4.00352C16.5803 2.83649 17.6925 2 19 2C20.6569 2 22 3.34315 22 5ZM6.8 19C6.8 19.9941 5.99411 20.8 5 20.8C4.00589 20.8 3.2 19.9941 3.2 19C3.2 18.0059 4.00589 17.2 5 17.2C5.99411 17.2 6.8 18.0059 6.8 19ZM20.8 5C20.8 5.99411 19.9941 6.8 19 6.8C18.0059 6.8 17.2 5.99411 17.2 5C17.2 4.00589 18.0059 3.2 19 3.2C19.9941 3.2 20.8 4.00589 20.8 5Z" fill="currentColor"/>
</svg>
, 'Line')
return <Icon {...props} data-cy="line-icon"/>
}