import {useParams, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {api} from 'shared/libs/Auth';
import "./infoPages.css"
import {Button, Link} from "@mui/material";
import logoTabMenu from "../../shared/assets/png/logo.png";

export default function ApproveUser() {
    const {pk} = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        api.get('/users/approve/' + pk)
            .then(response => {
                setUser(response.data);
                setError('');
            })
            .catch(error => {
                console.error('Error receiving user data:', error);
                setError('User does not exist or you do not have permission to edit this user.');
                setUser(null);
            });
    }, [pk]);

    const handleApprove = () => {
        api.post('/users/approve/' + pk)
            .then(response => {
                setStatus(response.data.status);
                // setTimeout(() => {
                //     navigate('/');
                // }, 6000); 
            })
            .catch(error => {
                console.error('Error approving user:', error);
                setStatus('Error approving user');
            });
    };

    return (
        <div className={"infoPages_wrapper"}>
            <div className={"infoPages_header"}>
                <Link href={'/login'}>
                    <img src={logoTabMenu} alt={'logo'}/>
                </Link>
                <Button
                    variant={'text'} color={'primary'}
                    className={"infoPages_button"}
                    href={'/signup'}
                >
                    Sign up
                </Button>
            </div>
            <div className={"infoPages_content"}>
                <h2>Approve user</h2>
                {error ? (
                    <p className={'infoPages_error'}>{error}</p>
                ) : (
                    user ? (
                        <>
                            <div className={'infoPages_full_info'}>
                                <h3>{user.first_name} {user.last_name}</h3>
                                <div>Email: {user.email}</div>
                                <div>Phone: {user.phone}</div>
                                {status && <div>Status: {status.includes("success") ?
                                    <span className={"infoPages_success"}>{status}</span> :
                                    <span className={"infoPages_error"}>{status}</span>}</div>}
                                <Button onClick={handleApprove} className={"infoPages_button"}>Approve User</Button>
                            </div>
                        </>
                    ) : (
                        <p>Loading user information...</p>
                    )
                )}
            </div>
        </div>
    );
}
