import Button from '@mui/material/Button'
import React from 'react'

export function ActionBar(
    {
        onAccept,
        onClear,
        onCancel,
        onSetToday,
        actions,
        slots,
        slotProps,
    }) {
    const buttons = actions?.map((actionType) => {
        switch (actionType) {
            case 'clear':
                return (
                    <Button
                        data-cy={'action-clear-button'}
                        variant={'outlined'}
                        color={'secondary'}
                        size={'small'}
                        onClick={onClear}
                        key={actionType}
                    >
                        Clear
                    </Button>
                )
            case 'cancel':
                return (
                    <Button
                        data-cy={'action-cancel-button'}
                        variant={'outlined'}
                        size={'small'}
                        onClick={onCancel}
                        key={actionType}
                    >
                        Cancel
                    </Button>
                )
            case 'accept':
                return (
                    <Button
                        data-cy={'action-apply-button'}
                        size={'small'}
                        onClick={onAccept}
                        key={actionType}
                    >
                        Apply
                    </Button>
                )
            case 'today':
                return (
                    <Button
                        data-cy={'action-today-button'}
                        variant={'outlined'}
                        size={'small'}
                        onClick={onSetToday}
                        key={actionType}
                    >
                        Today
                    </Button>
                )
            default:
                return null
        }
    })

    return (
        <div
            className={'column'}
            style={{gridRow: 3, gridColumn: 2, display: 'inline-table'}}
        >
            {slots?.toolbar?.(slotProps?.toolbar)}
            <div className={'row'}>
                {buttons}
            </div>
        </div>
    )
}
