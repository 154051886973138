import axios from 'axios'

const api = axios.create({
/*
  headers: {
    'Content-Type': 'application/json',
  },
*/
})
api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken')
        config.headers['Authorization'] = 'Bearer ' + accessToken
        return config
    },
    (error) => {
        return {}
    },
)

async function getJSON(url) {
    try {
        const response = await api.get(url)
        return response.data
    } catch (err) {
        return {}
    }
}

class BaronSignaturedRequester {
    constructor(options) {
        if (isNaN(options.updateCacheTimeInSec)) {
            options.updateCacheTimeInSec = 300
        }
        this.options = options
        this._baseUrlIndex = 0
        this._makeDefaultBaseUrlStack(options.url_prefix)
        this.options.defaultUrl = 'http://api.velocityweather.com/v1/'
        this.options.defaultFormatUrl = '//' + (options.url_prefix || 'cdn') + '{s}.velocityweather.com/v1/'
        // this.options.keySg = this._sigdecode(this.options.bootstrap, this.options.defaultUrl)[0];
        this.signature = getJSON('/products/signature').then((data) => {
            this.sign = data
            return data
        })
        this.products = getJSON('/products/')

        // this.sign = this._makeSignature(options.url_prefix);
        setInterval(() => {
            // this.sign = this._makeSignature();
            // this.signature =
            getJSON('/products/signature').then((data) => {
                this.sign = data
            })
        }, this.options.updateCacheTimeInSec * 1000)
        this.getKey = this.getKey.bind(this)
        this.getTs = this.getTs.bind(this)
        this.getSig = this.getSig.bind(this)
        this.getSignature = this.getSignature.bind(this)
        this.cache = {}
    }
    isready(cbk) {
        // Promise.all([this.signature, this.products]).then(cbk, cbk);
        this.signature.then(cbk, cbk)
    }
    _makeDefaultBaseUrlStack(urlPrefix = 'cdn') {
        const nums = ['', '1', '2', '3', '4', '5']
        this.options.defaultBaseUrlStack = nums.map((n) => '//' + urlPrefix + n + '.velocityweather.com/v1/')
    }

    _getHashedUrl(url) {
        let sum = 0
        for (var i = 0, v; i < url.length; i++) {
            v = url.charCodeAt(i); if (!isNaN(v)) sum ^= v
        }
        const index = sum % this.options.defaultBaseUrlStack.length
        return this.options.defaultBaseUrlStack[index]
    }

    _getBaseUrl(obj) {
        if (obj.isHashedUrl) {
            return this._getHashedUrl(obj.url)
        } else {
            this._baseUrlIndex = (this._baseUrlIndex + 1) % this.options.defaultBaseUrlStack.length
            return this.options.defaultBaseUrlStack[this._baseUrlIndex]
        }
    }

    _json(url, obj) {
        url = (obj.apiUrl || this._getBaseUrl(obj)) + this.getKey() + '/' + url
        url += url.indexOf('?') >= 0 ? '&' : '?'
        url += 'ts=' + this.sign.ts + '&sig=' + this.sign.sig
        return fetch(url).then((data) => data.json()).catch((error) => console.log(error))
    }

    _saveInCache(obj, fullUrl, data) {
        if (obj.isCache) {
            this.cache[fullUrl] = JSON.stringify(data)
            if (obj.timeCache) {
                setTimeout(() => {
                    delete this.cache[fullUrl]
                }, obj.timeCache)
            }
        }
    }

    textProductsGet(obj) {
        if ((typeof (obj.callback) !== 'function') && (typeof (obj.specificCallback) !== 'function')) return
        let url = obj.path
        if (obj.params) {
            const keysParams = Object.keys(obj.params)
            if (keysParams.length) {
                url += '?' + keysParams[0] + '=' + obj.params[keysParams[0]]
                for (let i = 1; i < keysParams.length; i++) {
                    const n = keysParams[i]
                    url += '&' + n + '=' + obj.params[n]
                }
            }
        }
        if (this.cache[url]) {
            if (typeof (obj.callback) === 'function') {
                obj.callback(JSON.parse(this.cache[url]))
            }
            if (typeof (obj.onData) === 'function') {
                obj.onData(JSON.parse(this.cache[url]))
            }
            return
        }
        let dataCollected = []

        this._json(url, obj).then((d) => {
            if (typeof (obj.specificCallback) === 'function') {
                obj.specificCallback(d)
                return
            }
            if (!d) {
                obj.callback()
                return
            }
            d = d[Object.keys(d)[0]]
            const key = obj.key ? obj.key : 'data'
            dataCollected = obj.isGetFullData ? [d] : (d[key] && (dataCollected = d[key]));
            (typeof (obj.onData) === 'function') && (obj.onData(obj.isGetFullData ? d : d[key]))

            if (!(d.meta && d.meta.pages && (d.meta.pages > 1))) {
                obj.callback(dataCollected)
                this._saveInCache(obj, url, dataCollected)
                return
            }
            (d.meta.from) && (url += '&from=' + d.meta.from)

            const promises = []
            for (let i = 2; i <= d.meta.pages; i++) {
                promises.push(this._json(url + '&page=' + i, obj).then((data) => {
                    if (!data) return
                    data = data[Object.keys(data)[0]]
                    data = obj.isGetFullData ? [data] : data[key];
                    (typeof (obj.onData) === 'function') && obj.onData(data)
                    return data
                }))
            }
            Promise.allSettled(promises).then((results) => {
                results.forEach((d2) => {
                    d2 && d2.value && (dataCollected = dataCollected.concat(d2.value))
                })
                obj.callback(dataCollected)
                this._saveInCache(obj, url, dataCollected)
            })
        })
    }

    getSignature() {
        return 'ts=' + this.sign.ts + '&sig=' + this.sign.sig
    }

    getKey() {
        return this.sign.key
    }
    getTs() {
        return this.sign.ts
    }
    getSig() {
        return this.sign.sig
    }
};

const requester = window.requester = new BaronSignaturedRequester({
    url_prefix: 'cdn',
})
export default requester
