import {createSvgIcon} from "@mui/material/utils";

export default function HistoryIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 3C10.6131 3 8.32387 3.94821 6.63604 5.63604C4.94821 7.32387 4 9.61305 4 12H2.19231C1.74896 12 1.52494 12.5342 1.83567 12.8504L4.60691 15.6707C4.80149 15.8687 5.12012 15.8703 5.31666 15.6742L8.14389 12.854C8.45947 12.5392 8.23653 12 7.79077 12H6C6 10.1435 6.7375 8.36301 8.05025 7.05025C9.36301 5.7375 11.1435 5 13 5C14.8565 5 16.637 5.7375 17.9497 7.05025C19.2625 8.36301 20 10.1435 20 12C20 13.8565 19.2625 15.637 17.9497 16.9497C16.637 18.2625 14.8565 19 13 19C11.4307 19 9.98035 18.4777 8.8157 17.5997C8.37346 17.2663 7.73827 17.2617 7.34666 17.6533C6.95764 18.0424 6.94932 18.681 7.37743 19.0266C8.03629 19.5583 8.76906 19.9944 9.5542 20.3203C10.6462 20.7736 11.8176 21.0047 13 21C15.3869 21 17.6761 20.0518 19.364 18.364C21.0518 16.6761 22 14.3869 22 12C22 9.61305 21.0518 7.32387 19.364 5.63604C17.6761 3.94821 15.3869 3 13 3Z"
                fill="currentColor"/>
            <path
                d="M12 13V8.75C12 8.33579 12.3358 8 12.75 8C13.1642 8 13.5 8.33579 13.5 8.75V12.25L16.3943 13.97C16.7288 14.1688 16.8387 14.6012 16.6397 14.9355C16.441 15.2695 16.0093 15.3793 15.6751 15.181L12 13Z"
                fill="currentColor"/>
        </svg>, 'History')
    return <Icon {...props} data-cy="history-icon"/>
}