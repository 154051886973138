import {Checkbox as MuiCheckbox} from '@mui/material'
import CheckBoxIcon from 'shared/assets/icons/CheckBox'
import FilledCheckBoxIcon from 'shared/assets/icons/filled/CheckBox.jsx'
import MinusSquareIcon from 'shared/assets/icons/filled/MinusSquare'

export function CheckBox(props) {
    return (
        <MuiCheckbox
            icon={<CheckBoxIcon size={props.size}/>}
            checkedIcon={<FilledCheckBoxIcon size={props.size}/>}
            indeterminateIcon={<MinusSquareIcon size={props.size}/>}
            {...props}
        />
    )
}
