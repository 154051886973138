import {createSvgIcon} from "@mui/material/utils";

export default function InfoCircleIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM13 8C13 8.552 12.552 9 12 9C11.448 9 11 8.552 11 8C11 7.448 11.448 7 12 7C12.552 7 13 7.448 13 8ZM13 12V16C13 16.553 12.552 17 12 17C11.448 17 11 16.553 11 16V12C11 11.447 11.448 11 12 11C12.552 11 13 11.447 13 12Z"
                fill="currentColor"/>
        </svg>
        , 'InfoCircle')
    return <Icon {...props} data-cy="info-circle-icon"/>
}