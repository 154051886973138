import {createSvgIcon} from "@mui/material/utils";

export default function BellIcon(props) {
    const Icon = createSvgIcon(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.6696 15.5177L19.5636 13.6727C19.1906 13.0507 18.9946 12.3407 18.9946 11.6167V8.98975C18.9946 5.13575 15.8566 1.99976 11.9996 1.99976C8.1426 1.99976 5.00459 5.13575 5.00459 8.98975V11.6167C5.00459 12.3407 4.80759 13.0508 4.43559 13.6718L3.32959 15.5177C2.89759 16.2377 2.88759 17.1028 3.30059 17.8328C3.71359 18.5628 4.46259 18.9998 5.30259 18.9998H8.99859C8.99859 20.6538 10.3446 21.9998 11.9986 21.9998C13.6526 21.9998 14.9986 20.6538 14.9986 18.9998H18.6956C19.5356 18.9998 20.2846 18.5628 20.6986 17.8328C21.1116 17.1028 21.1006 16.2367 20.6696 15.5177ZM11.9986 19.9998C11.4476 19.9998 10.9986 19.5518 10.9986 18.9998H12.9986C12.9986 19.5518 12.5496 19.9998 11.9986 19.9998ZM18.9576 16.8478C18.9256 16.9048 18.8486 16.9998 18.6956 16.9998H5.30159C5.14859 16.9998 5.07159 16.9038 5.03959 16.8478C5.00759 16.7918 4.96559 16.6768 5.04359 16.5468L6.14959 14.6997C6.70759 13.7677 7.0036 12.7017 7.0036 11.6167V8.98975C7.0036 6.23775 9.24459 3.99976 11.9986 3.99976C14.7526 3.99976 16.9936 6.23775 16.9936 8.98975V11.6167C16.9936 12.7017 17.2886 13.7678 17.8476 14.7008L18.9546 16.5468C19.0316 16.6768 18.9896 16.7908 18.9576 16.8478Z"
                fill="currentColor"/>
        </svg>, 'Bell')
    return <Icon {...props} data-cy="bell-icon"/>
}

