import {createSvgIcon} from "@mui/material/utils";

export default function PlusSquareIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 2H9C5.14 2 2 5.141 2 9V15C2 18.859 5.14 22 9 22H15C18.86 22 22 18.859 22 15V9C22 5.141 18.86 2 15 2ZM20 15C20 17.757 17.757 20 15 20H9C6.243 20 4 17.757 4 15V9C4 6.243 6.243 4 9 4H15C17.757 4 20 6.243 20 9V15ZM17 12C17 12.553 16.552 13 16 13H13V16C13 16.553 12.552 17 12 17C11.448 17 11 16.553 11 16V13H8C7.448 13 7 12.553 7 12C7 11.447 7.448 11 8 11H11V8C11 7.447 11.448 7 12 7C12.552 7 13 7.447 13 8V11H16C16.552 11 17 11.447 17 12Z"
                fill="currentColor"/>
        </svg>, 'PlusSquare')
    return <Icon {...props} data-cy="plus-square-icon"/>
}