let cachedTimeZones = null

export function getTimeZones() {
    if (cachedTimeZones) {
        return cachedTimeZones
    }

    const today = new Date()
    cachedTimeZones = Intl.supportedValuesOf('timeZone').map((timeZone) => (
        {
            value: timeZone,
            label: getTimeZoneWithOffset(timeZone, today),
        }
    ))
    cachedTimeZones.sort((a, b) => a.label.localeCompare(b.label))
    return cachedTimeZones
}

/**
 * @param {string} timeZone
 * @param {Date} date
 */
export function getTimeZoneWithOffset(timeZone, date) {
    const offset = new Intl.DateTimeFormat('en', {
        timeZone: timeZone,
        timeZoneName: 'longOffset',
    })
        .formatToParts(date)
        .find((part) => part.type === 'timeZoneName')
        .value
        .replace(/GMT(.+)/g, 'GMT($1)')

    return `${offset} ${timeZone.replace('_', ' ')}`
}
