export const ForecastMinMax = {  // todo move to backend model
    "Hail Probability - Forecast": ["max"],
    "Future Radar": ["max"],
    "Rainfall Accumulation - Forecast": ["max"],
    "Extreme Weather Index - Rain": ["max"],
    "Snow Accumulation - Forecast": ["max"],
    "Extreme Weather Index - Snow": ["max"],
    "Surface Temperature - Forecast": ["max", "min"],
    "Extreme Weather Index - Cold": ["min"],
    "Extreme Weather Index - Heat": ["max"],
    "Feels Like - Forecast": ["min", "max"],
    "Wind Chill - Forecast": ["min"],
    "Wind Speed - Forecast": ["max"],
    "Extreme Weather Index - Wind": ["max"],
    "Wind Gusts - Forecast": ["max"],
    "Wave Heights": ["max"],
    "Relative Humidity - Forecast": ["max", "min"],
    "Air Quality - Forecast": ["max"],
    "Lightning - Forecast": ["max"],
    "Flash Flood Risk": ["max"],
    "Extreme Weather Index - Hail": ["max"],
    "Fog - Forecast": ["min"]
}
