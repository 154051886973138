import {Checkbox} from '@mui/material'
import CheckCircleIcon from 'shared/assets/icons/CheckCircle'
import FilledCheckCircleIcon from 'shared/assets/icons/filled/CheckCircle'

export function CheckCircle(props) {
    return (
        <Checkbox
            variant={'CheckCircle'}
            icon={<CheckCircleIcon size={props.size}/>}
            checkedIcon={<FilledCheckCircleIcon size={props.size}/>}
            {...props}
        />
    )
}
