import React, {useEffect, useRef, useState} from 'react';
import {Box, Button} from '@mui/material';
import WeatherIcon from 'shared/assets/icons/Weather';
import MapPinIcon from 'shared/assets/icons/MapPin';
import BellIcon from 'shared/assets/icons/Bell';
import WeatherDefinitionSetting from './weatherDefinitions/WeatherDefinitionSetting';
import ReportList from '../../reports/ReportList';
import SavedLocationsSetting from './savedLocations/SavedLocationsSetting';
import UserAndContactsSetting from './usersAndContacts/UserAndContactsSetting';
import PreferencesSetting from './preferences/PreferencesSetting';
import NewWeatherDefinitionPage from 'widgets/weatherDefinition/NewWeatherDefinitionPage';
import {EditLocationScreen} from "widgets/location";
import ManageAlertConfigurationPanel
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel';
import AlertsSettings from "./alerts/AlertsSettings";
import {styled} from "@mui/material/styles";
import theme from "app/Theme";
import UsersThreeIcon from "shared/assets/icons/UsersThree";
import SlidersIcon from "shared/assets/icons/Sliders";
import {useLocation} from 'react-router-dom';
import ActionItemsSettings from "./actionItems/ActionItemsSettings";


const SettingsMenuButton = styled(Button)(({selected}) => ({
    width: '100%',
    background: 'none',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'start',
    alignContent: 'center',
    height: '48px',
    color: theme.palette.grey[900],
    padding: '16px',
    ':hover': {
        borderColor: 'transparent',
    },
    ...(selected && {
        background: theme.palette.grey.background,
        color: theme.palette.primary.dark,
        '& .MuiSvgIcon-root': {color: theme.palette.primary.dark,},
        ':hover': {
            background: theme.palette.grey.background,
        },
    }),
}))

SettingsMenuButton.defaultProps = {
    variant: '',
    color: '',
}

export const SETTINGS_SECTION = {
    weatherDefinition: '#weather-definitions',
    savedLocations: '#saved-locations',
    alerts: '#alerts',
    // reports: '#reports',
    // actionItems: '#action-items',
    usersAndContacts: '#users-and-contacts',
    preferences: '#preferences',
}

const getInitialSettingsSection = location => {
    const section = location.hash

    if (Object.values(SETTINGS_SECTION).includes(section))
        return section;
    else
        return SETTINGS_SECTION.weatherDefinition;
};

export default function SettingsPage() {

    const [editDefinition, setEditDefinition] = useState(null);
    const [editAlertConfig, setEditAlertConfig] = useState(null);
    const [editLocation, setEditLocation] = useState(null);
    const [newLocation, setNewLocation] = useState(null);
    const [hideSettingsMenu, setHideSettingsMenu] = useState();
    const update = useRef();

    const location = useLocation();
    const settingsSection = getInitialSettingsSection(location);

    useEffect(() => {
        setEditDefinition(null)
        setEditAlertConfig(null)
        setEditLocation(null)
        setNewLocation(null)
        setHideSettingsMenu(false)
    }, [location]);

    const onDefinitionAction = (action, definition) => {
        if (action === 'edit_definition') {
            definition.groups = definition.rules;
            setEditDefinition(definition)
        } else if (action === 'edit_configuration') {
            setEditAlertConfig({wd: definition})
        }
    }

    const onLocationAction = (action, location) => {
        console.log("SettingsPage.onLocationAction", action, location);
        if (action === 'edit_location') {
            setEditLocation(location);
            setNewLocation(false);
        } else if (action === 'new_location') {
            setEditLocation(null);
            setNewLocation(true)
        }
    }

    const onAlertsAction = (action, alert) => {
        console.log("SettingsPage.onAlertsAction", action, alert);
        // eslint-disable-next-line default-case
        switch (action) {
            case 'new_alert':
                console.log("SettingsPage.onAlertsAction.new_alert");
                break;
            case 'edit_alert':
                console.log("SettingsPage.onAlertsAction.edit_alert");
                setEditAlertConfig(alert)
                break;
            case 'duplicate_alert':
                console.log("SettingsPage.onAlertsAction.duplicate_alert");
                break;
            case 'delete_alert':
                console.log("SettingsPage.onAlertsAction.delete_alert");
                break;
            case 'pause/restart_alert':
                console.log("SettingsPage.onAlertsAction.pause/restart_alert");
                update.current = true;
                break;
        }
    }

    let showSettingPage = () => !editDefinition && !editLocation && !newLocation && !editAlertConfig
    //    {!editDefinition && !editLocation && !newLocation && !editAlertConfig &&
    //    }
    let doupdate = () => {
        let doupdate = update.current;
        update.current = false;
        return doupdate;
    }

    const handleMenuButtonClick = settingName => {
        window.location.hash = settingName
    };

    console.log("SettingsPage.update", update.current);
    return (
        <>
            <Box sx={{
                display: showSettingPage() ? 'flex' : 'none',
                flex: '1 1 auto',
                overflow: 'hidden',
                flexDirection: 'row'
            }}>

                <Box className={'gap8'} sx={{
                    width: '288px',
                    minWidth: '288px',
                    maxWidth: '288px',
                    height: 'auto',
                    display: hideSettingsMenu ? 'None' : 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '16px 8px 0px',
                    justifyContent: 'flex-start'
                }}>
                    <SettingsMenuButton
                        selected={settingsSection === SETTINGS_SECTION.weatherDefinition}
                        onClick={() => handleMenuButtonClick(SETTINGS_SECTION.weatherDefinition)}
                        startIcon={<WeatherIcon size={'small'}/>}
                    >
                        Weather definitions
                    </SettingsMenuButton>
                    <SettingsMenuButton
                        selected={settingsSection === SETTINGS_SECTION.savedLocations}
                        onClick={() => handleMenuButtonClick(SETTINGS_SECTION.savedLocations)}
                        startIcon={<MapPinIcon size={'small'}/>}
                    >
                        Saved locations
                    </SettingsMenuButton>
                    <SettingsMenuButton
                        selected={settingsSection === SETTINGS_SECTION.alerts}
                        onClick={() => handleMenuButtonClick(SETTINGS_SECTION.alerts)}
                        startIcon={<BellIcon size={'small'}/>}
                    >
                        Alert configurations
                    </SettingsMenuButton>
                    {/*<SettingsMenuButton*/}
                    {/*    selected={settingsSection === SETTINGS_SECTION.actionItems}*/}
                    {/*    onClick={() => handleMenuButtonClick(SETTINGS_SECTION.actionItems)}*/}
                    {/*    startIcon={<DashboardIcon size={'small'}/>}*/}
                    {/*>*/}
                    {/*    Action items*/}
                    {/*</SettingsMenuButton>*/}
                    <SettingsMenuButton
                        selected={settingsSection === SETTINGS_SECTION.usersAndContacts}
                        onClick={() => handleMenuButtonClick(SETTINGS_SECTION.usersAndContacts)}
                        startIcon={<UsersThreeIcon size={'small'}/>}
                    >
                        Users and contacts
                    </SettingsMenuButton>
                    <SettingsMenuButton
                        selected={settingsSection === SETTINGS_SECTION.preferences}
                        onClick={() => handleMenuButtonClick(SETTINGS_SECTION.preferences)}
                        startIcon={<SlidersIcon size={'small'}/>}
                    >
                        Preferences
                    </SettingsMenuButton>
                </Box>

                <Box sx={{
                    height: '100%',
                    flexGrow: "1",
                    background: '#ffffff',
                    boxShadow: '4px 0px 14px rgba(103, 122, 142, 0.12)',
                    borderRadius: '16px 16px 0 16px'
                }}>
                    {settingsSection === SETTINGS_SECTION.weatherDefinition && (
                        <WeatherDefinitionSetting onChange={onDefinitionAction} doupdate={doupdate} setHideSettingsMenu={setHideSettingsMenu}/>)}
                    {settingsSection === SETTINGS_SECTION.savedLocations && (
                        <SavedLocationsSetting onChange={onLocationAction} doupdate={doupdate}/>)}
                    {settingsSection === SETTINGS_SECTION.alerts && (
                        <AlertsSettings onChange={onAlertsAction} doupdate={doupdate}/>)}
                    {settingsSection === SETTINGS_SECTION.actionItems && (<ActionItemsSettings/>)}
                    {settingsSection === SETTINGS_SECTION.reports && (<ReportList onChange={setHideSettingsMenu}/>)}
                    {settingsSection === SETTINGS_SECTION.usersAndContacts && (
                        <UserAndContactsSetting setShowAddContacts={setHideSettingsMenu}/>)}
                    {settingsSection === SETTINGS_SECTION.preferences && (<PreferencesSetting/>)}
                </Box>

            </Box>
            {
                editAlertConfig &&
                <ManageAlertConfigurationPanel
                    args={editAlertConfig}
                    onClose={(doupdate) => {
                        setEditAlertConfig(false);
                        update.current = doupdate === true;
                    }}
                />
            }

            {
                editDefinition &&
                <NewWeatherDefinitionPage
                    sx={{width: '100%'}}
                    onClose={(doupdate) => {
                        setEditDefinition(null);
                        update.current = doupdate === true;
                    }}
                    defaultValue={editDefinition}
                />
            }

            {
                editLocation &&
                <EditLocationScreen
                    sx={{width: '100%'}}
                    editLocation={true}
                    onClose={(doupdate) => {
                        console.log("*** editLocation.doupdate", doupdate);
                        setEditLocation(null);
                        update.current = doupdate === true;
                    }}
                    defaultValue={editLocation.location}
                    groups={editLocation.groups}
                />
            }

            {
                newLocation &&
                <EditLocationScreen
                    sx={{width: '100%'}}
                    editLocation={false}
                    onClose={(doupdate) => {
                        console.log("*** newLocation.doupdate", doupdate);
                        setNewLocation(null);
                        update.current = doupdate === true;
                    }}
                />
            }

        </>
    )

}
