import {createSvgIcon} from "@mui/material/utils";

export default function BellSlashIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5669 13.6755C19.1959 13.0595 18.9999 12.3495 18.9999 11.6205V8.99048C18.9999 8.22848 18.8779 7.48651 18.6379 6.77551L20.7069 4.70654C21.0979 4.31554 21.0979 3.68348 20.7069 3.29248C20.3159 2.90148 19.6839 2.90148 19.2929 3.29248L5.29389 17.2915L5.29188 17.2935L3.29288 19.2925C2.90188 19.6835 2.90188 20.3155 3.29288 20.7065C3.48788 20.9015 3.74388 20.9995 3.99988 20.9995C4.25588 20.9995 4.51189 20.9015 4.70689 20.7065L6.41389 18.9995H8.99988C8.99988 20.6535 10.3459 21.9995 11.9999 21.9995C13.6539 21.9995 14.9999 20.6535 14.9999 18.9995H18.6999C19.5369 18.9995 20.2839 18.5645 20.6969 17.8375C21.1109 17.1075 21.1009 16.2395 20.6689 15.5165L19.5669 13.6755ZM11.9999 20.0005C11.4489 20.0005 10.9999 19.5525 10.9999 19.0005H12.9999C12.9999 19.5525 12.5509 20.0005 11.9999 20.0005ZM18.9569 16.8525C18.9179 16.9195 18.8429 17.0005 18.6999 17.0005H8.41389L16.9449 8.46948C16.9629 8.64348 16.9999 8.81348 16.9999 8.99048V11.6205C16.9999 12.7125 17.2949 13.7795 17.8519 14.7045L18.9519 16.5435C19.0319 16.6785 18.9899 16.7945 18.9569 16.8525ZM3.66088 14.9685L4.43088 13.6785C4.80388 13.0605 4.99988 12.3495 4.99988 11.6205V8.99048C4.99988 5.13648 8.13988 2.00049 11.9999 2.00049C13.5909 2.00049 15.0909 2.51753 16.3369 3.49353C16.7719 3.83453 16.8479 4.46346 16.5069 4.89746C16.1649 5.33246 15.5359 5.4075 15.1029 5.0675C14.2129 4.3695 13.1399 4.00049 11.9999 4.00049C9.24288 4.00049 6.99988 6.23848 6.99988 8.99048V11.6205C6.99988 12.7135 6.70488 13.7805 6.14588 14.7075L5.37788 15.9935C5.18988 16.3065 4.85789 16.4805 4.51789 16.4805C4.34389 16.4805 4.16688 16.4345 4.00588 16.3385C3.53288 16.0565 3.37788 15.4425 3.66088 14.9685Z"
                fill="currentColor"/>
        </svg>
        , 'BellSlash')
    return <Icon {...props} data-cy="bell-slash-icon-icon"/>
}