import {createSvgIcon} from "@mui/material/utils";

export default function CheckIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.29289 10.2929C4.90237 10.6834 4.90237 11.3166 5.29289 11.7071L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289L11 14.5858L6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929Z"
                  fill="currentColor"/>
        </svg>, 'Check')
    return <Icon {...props} data-cy="check-icon"/>
}