import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, FormControlLabel, FormGroup, IconButton, Typography} from '@mui/material';
import { CheckBox } from "shared/ui/CheckBox";
import PenIcon from "shared/assets/icons/Pen";
import CustomMessagePopup, {tokens, renderMessageWithTokens} from 'features/notification/CustomMessagePopup';


function NotificationCheckboxWithMessage({ label, checked, onNotificationDataChange, message='', type, onChange, isDefaultMessage, onSaveAsDefault}) {
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [content, setContent] = useState([]);

    const handleSaveAsDefault = () => {
        if(onSaveAsDefault) {
            onSaveAsDefault(type, message);
        }
    };


    useEffect(() => {
        setContent(renderMessageWithTokens(message, tokens));
    }, [message, tokens]);


    const handleChange = (e) => {
        onNotificationDataChange(type, e.target.checked);
    };

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CheckBox 
                            checked={checked} 
                            onChange={handleChange}
                        />
                    } 
                    label={label}
                />
                {checked && (
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '32px', gap: '12px', alignSelf: 'stretch' }}>
                        <Typography variant="body2">{content}</Typography>
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '8px',
                            flex: '1 0 0'
                        }}>
                            <IconButton
                                variant={'outlined'}
                                size={'tiny'}
                                onClick={() => setShowMessagePopup(true)}
                            >
                                <PenIcon size={'small'}/>
                            </IconButton>
                            {isDefaultMessage ? (
                                <Typography style={{color: 'var(--palette-grey-500)'}}>
                                    Default message
                                </Typography>
                            ) : (
                            <Button variant={'outlined'} color={'primary'} style={{height: '32px', whiteSpace: 'nowrap'}} onClick={handleSaveAsDefault}>
                                Save as default
                            </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </FormGroup>
            <CustomMessagePopup
                visible={showMessagePopup}
                onCloseModal={() => setShowMessagePopup(false)}
                // onSaveMessage={handleSave}
                messageText={message}
                tokens={tokens}
                onChange={onChange}
            />
        </>
    );
}

export default NotificationCheckboxWithMessage;
