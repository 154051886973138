import {Box, IconButton, Button} from '@mui/material';
import React from 'react';
import Iframe from 'react-iframe';
import TrashIcon from '../../shared/assets/icons/Trash';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {Spacer} from "shared/ui/Spacer";

export default function ReportNotification({report, onAction}) {
    return <Box className="ReportDetails" sx={{ height: '100%', flexGrow: '1', background: '#ffffff', overflow: "hidden", margin: "0", display: 'flex', flexDirection: 'column', alignItem: 'stretch' }}>
                <Box className={'toolbar padding'}>
                    <IconButton variant={'outlined'} size={'small'} onClick={(ev) => { if (ev) ev.stopPropagation(); onAction(report, 'close_report'); }}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3 className={'regular'}>
                        {report.name}
                    </h3>
                    <Spacer/>
                    <Button
                        variant={'outlined'}
                        color={'error'}
                        onClick={(ev) => {
                            if (ev) ev.stopPropagation();
                            onAction(report, 'delete_report');
                        }}
                        startIcon={<TrashIcon/>}
                    >
                        Delete
                    </Button>
                </Box>
                <Box sx={{flexGrow: "1", overflow: 'auto'}}>
                <Iframe url={'/api/v1/reports/'+report.id+'/notification'} height="100%" width="100%" sandbox={"allow-same-origin"}></Iframe>
                </Box>
           </Box>;
}

