import React from 'react';
import theme from "../../../../app/Theme";
import {MenuItem} from "@mui/material";
import {Select} from "../../../../shared/ui/Select";

const CategorySelector = ({allCategories, category, onChange}) => {

    return (<Select
        placeholder={''}
        defaultValue={category}
        onChange={onChange}
        InputProps={{
            startAdornment: <div className={'paragraph'} style={{color: theme.palette.grey[400]}}>
                Category:
            </div>
        }}
    >
        <MenuItem value={"All"}>All</MenuItem>
        {allCategories.map((cat) => {
            return (<MenuItem key={cat.id} value={cat.name}>
                {cat.name}
            </MenuItem>)
        })}
    </Select>);
};

export default CategorySelector;