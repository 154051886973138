import React, {useEffect, useState} from "react";
import {fetchRealLocations} from "pages/alerts/api/FetchAlerts";
import {calcGroups} from "pages/alerts/ui/AlertsPage";
import MapMarkerIcon from "shared/assets/icons/MapMarker";
import {Box, Button, Chip, Divider, Drawer, IconButton} from "@mui/material";
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {Spacer} from "shared/ui/Spacer";
import theme from "app/Theme";
import {CheckBox} from "shared/ui/CheckBox";
import {calcSelected, LocationsTree} from "widgets/location/ui/LocationsTree";
import {SearchInput} from "shared/ui/SearchInput";


export const LocationFilter = ({selectedLocations, setSelectedLocations, openButtonText = 'Locations'}) =>{

		const [searchedLocations, setSearchedLocations] = useState("");
		const [locations, setLocations] = useState([]);
		const [selectedLocs, setSelectedLocs] = useState(selectedLocations);
		const [isOpenLocationsPanel, setIsOpenLocationsPanel] = useState(false);
		const [isButtonPressed, setIsButtonPressed] = useState(false);

		const locationsCount = Object.values(selectedLocations).filter(value => value === true).length;
		const locationGroups = calcGroups(locations);

		useEffect(() => {
				fetchRealLocations().then((data) => {
						setLocations(data.location)
				});
		}, []);

		const handleSearchedChange = event => {
				setSearchedLocations(event.target.value)
				if (!locationGroups) {
						return;
				}
		}

		const selectAll = (searchedLocations) => {
				let s = searchedLocations.trim().toLocaleLowerCase();
				let changed = false;
				for (let group in locationGroups) {
						let locs = locationGroups[group];
						for (let i in locs) {
								let loc = locs[i];
								let prev = selectedLocs[loc.id];
								if (loc.label.toLocaleLowerCase().indexOf(s) >= 0) {
									selectedLocs[loc.id] = true;
								}
								if (!changed && prev !== selectedLocs[loc.id]) {
									changed = true;
								}
						}
				}
				if (changed) {
						setSelectedLocs({...selectedLocs});
				}
		}

		const deselectAll = (searchedLocations) => {
				let s = searchedLocations.trim().toLocaleLowerCase();
				let changed = false;
				for (let group in locationGroups) {
						let locs = locationGroups[group];
						for (let i in locs) {
								let loc = locs[i];
								let prev = selectedLocs[loc.id];
								if (loc.label.toLocaleLowerCase().indexOf(s) >= 0) {
								    if (selectedLocs[loc.id] !== false) {
										selectedLocs[loc.id] = false;
										changed = true;
								    }
                                                                }
						}
				}
				if (changed) {
						setSelectedLocs({...selectedLocs});
				}
		}

		const allSelected = () => {
				let [selected, unselected] = calcSelected(locationGroups, searchedLocations, selectedLocs);
				return unselected === 0;
		}

		const allIndeterminate = () => {
				let [selected, unselected] = calcSelected(locationGroups, searchedLocations, selectedLocs);
				return unselected > 0 && selected > 0;
		}

		const allCount = () => {
				return calcSelected(locationGroups, searchedLocations, selectedLocs).reduce((a, b) => a + b, 0);
		}

		const handleSelectAll = (event) => {
				event.stopPropagation();
				let [selected, unselected] = calcSelected(locationGroups, searchedLocations, selectedLocs);
				if (unselected === 0) {
						// deselect
						deselectAll(searchedLocations);
				}
				else {
						// select
						selectAll(searchedLocations);
				}
		}

		const handleApplySelected = () => {
                                const locs = {};
                                locations.forEach(loc => {  // CGT-5006 fix invalid filter state on first apply
                                    locs[loc.id] = (selectedLocs[loc.id] !== undefined)? selectedLocs[loc.id] : true;
                                });
				setSelectedLocations(locs);
				setIsButtonPressed(true);
				onClose();
		}

		const onClose = () => setIsOpenLocationsPanel(false)

		return (
				<>
						<Button
								variant={'outlined'}
								color={'secondary'}
								startIcon={<MapMarkerIcon/>}
								endIcon={<Chip label={locationsCount} size={'small'}/>}
								onClick={() => setIsOpenLocationsPanel(true)}
						>
								{openButtonText}
						</Button>

						<Drawer
								anchor={'right'}
								open={isOpenLocationsPanel}
								onClose={onClose}
								ModalProps={{ onBackdropClick: onClose }}
						>
								<div className={'column gap16 fullHeight'} style={{minWidth: '400px'}}>
										<div className={'row'}>
												<IconButton
														onClick={onClose}
														size={'small'}
														variant={'outlined'}>
														<ArrowLeftSmallIcon size={'small'}/>
												</IconButton>
												<div className={'subtitle medium'}>
														Alert location filter
												</div>
												<Spacer/>
										</div>
										<SearchInput
												placeholder='Search for location'
												value={searchedLocations}
												onChange={handleSearchedChange}
										/>
										<div
												className={'column gap12 fullWidth spacer'}
												style={{
														alighItems: 'center',
												}}
										>
												<div
														className={'row gap4'}
														style={{
																paddingLeft: '13px',
																marginTop: '16px',
																borderBottomColor: theme.palette.grey[100],
														}}
												>
														<CheckBox
																checked={allSelected()}
																indeterminate={allIndeterminate()}
																onClick={handleSelectAll}
														/>
														<div className={'paragraph'} style={{color: theme.palette.grey[900]}}>
																All locations ({allCount()})
														</div>
												</div>
												<Divider/>
												<LocationsTree
														locationGroups={locationGroups}
														selectedLocations={selectedLocs}
														handleSelectedLocations={setSelectedLocs}
														searchString={searchedLocations}
												/>
										</div>
										<Spacer/>
										<Box style={{display:"flex", gap:"12px", justifyContent:"end", paddingBottom:"24px"}}>
												<Button
														className={'regular'}
														variant={"outlined"}
														color={"primary"}
														style={{width:"96px"}}
														onClick={onClose}
												>
														Cancel
												</Button>
												<Button
														className={'regular'}
														variant={"contained"}
														color={"primary"}
														style={{width:"96px"}}
														onClick={handleApplySelected}
												>
														Apply
												</Button>
										</Box>
								</div>
						</Drawer>
				</>
		)
}