import React, {useEffect, useState} from 'react';
import {Spacer} from "../../../../shared/ui/Spacer";
import {Button, Divider, IconButton, Radio, Typography} from "@mui/material";
import ArrowLeftSmallIcon from "../../../../shared/assets/icons/ArrowLeftSmall";
import TextField from "@mui/material/TextField";
import PlusIcon from "../../../../shared/assets/icons/Plus";
import PenIcon from "../../../../shared/assets/icons/Pen";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import {SearchInput} from "../../../../shared/ui/SearchInput";
import {fetchWeatherDefinitions} from "../../../alerts/api/FetchAlerts";
import DefinitionPreviewImage from "../../../../shared/assets/png/DefinitionPreview.png";
import {RenderGroup} from "../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderPreview";
import ActionItemConfirmationModal from "../../../../shared/ui/ActionItemConfirmationModal";

const EditActionItemPage = ({allCategories, defaultValue = {}, onChange = () => {}, isDuplicating = false}) => {

    const [nameOfActionItem, setNameOfActionItem] = useState(defaultValue.name || null);
    const [categories, setCategories] = useState(allCategories);
    const [selectedUrgency, setSelectedUrgency] = useState(defaultValue.urgency || null);
    const [selectedCategory, setSelectedCategory] = useState(defaultValue.category || categories[0]);
    const [selectedWeatherDefinition, setSelectedWeatherDefinition] = useState(defaultValue.weather_definition || null);
    const [description, setDescription] = useState(defaultValue.description || null);

    const [weatherDefinitions, setWeatherDefinitions] = useState(null);
    const [weatherDefinitionFilter, setWeatherDefinitionFilter] = useState(null);
    const [isOpenSearching, setIsOpenSearching] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    useEffect(() => {
        fetchWeatherDefinitions().then((data) => {
            const definitions = data.sort((a, b) => a.name.localeCompare(b.name));
            setWeatherDefinitions(definitions)
        });
    }, []);

    const handleChangeName = (event) => {
        setNameOfActionItem(event.target.value)
    }

    const handleChangeCategory = (category) => {
        setSelectedCategory(category)
    }

    const handleChangeUrgency = (urgency) => {
        setSelectedUrgency(urgency)
    }

    const handleChangeSelectedWeatherDefinition = (definition) => {
        setSelectedWeatherDefinition(definition);
        setIsOpenSearching(false);
        setWeatherDefinitionFilter(definition.name);
    }

    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleWeatherDefinitionFilter = (event) => {
        setWeatherDefinitionFilter(event.target.value)
    }

    const handleEditCategory = (event) => {
        event.stopPropagation()
    }

    const handleDeleteCategory = (event) => {
        event.stopPropagation()
    }

    const handleSaveActionItem = () => {
        const actionItem = {
            user: defaultValue.user,
            id: isDuplicating ? null : defaultValue.id,
            name: nameOfActionItem,
            category: selectedCategory.id,
            urgency: selectedUrgency,
            weather_definition: selectedWeatherDefinition.id,
            description: description
        };
        isDuplicating ? onChange("save_actionItem", actionItem) : onChange("save_actionItem", actionItem)
        setShowSuccessModal(false)
    }

    const handleClose = () => {
        onChange()
    }

    const renderRules = (rules) => {
        return rules.map((rule, index) =>
            <RenderGroup
                group={{
                    ...rule, isFirst: !index,
                }}
            />
        )
    }

    return (<>
        <div className={'fullHeight fullWidth'}>
            <div className={'settings'} style={{height: '100%'}}>
                <div className={'settings-toolbar'} style={{borderBottom: "1px solid var(--palette-grey-100)"}}>
                    <IconButton onClick={handleClose} variant={'outlined'} size={'small'}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3>
                        {defaultValue.id ? "Edit" : "New"} action items
                    </h3>
                    <Spacer/>
                    <Button
                        disabled={!nameOfActionItem || !selectedUrgency || !selectedCategory || !selectedWeatherDefinition || !description}
                        onClick={() => setShowSuccessModal(true)}
                    >
                        Save action item
                    </Button>
                </div>
                <div style={{
                    padding: "16px 24px", display: "flex", flexDirection: "column", gap: "8px", overflowY: "auto"
                }}>
                    <div className={"actionItemsContentWrapper"}>
                        <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Typography
                                style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-600)"}}>
                                Name of action item
                            </Typography>
                            <TextField style={{width: "640px"}} placeholder={"Enter action item title"}
                                       value={nameOfActionItem} onChange={handleChangeName}/>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: "8px", paddingTop: "16px"}}>
                            <Typography
                                style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-600)"}}>
                                Category
                            </Typography>
                            <div style={{display: "flex", flexDirection: "column", width: "350px", gap: "16px"}}>
                                {categories?.map((category, index) => (
                                    <div style={{display: "flex", alignItems: "center", gap: "8px", cursor: "pointer"}}
                                         key={index}
                                         onClick={() => handleChangeCategory(category)}>
                                        <Radio value={index} checked={selectedCategory?.id === category.id}/>
                                        <span style={{
                                            fontSize: "16px",
                                            color: "var(--palette-grey-900)",
                                            alignItems: "center",
                                            display: "flex",
                                            gap: "4px",
                                            lineHeight: "24px",
                                        }}>
                                            <svg className={'customSvg'}>
                                                <use
                                                    height="18"
                                                    width="18"
                                                    href={category.icon.icon + '#svg2'}
                                                />
                                            </svg>
                                            {category.name}
                                        </span>
                                        <Spacer/>
                                        <IconButton
                                            id="basic-button"
                                            variant={'outlined'}
                                            style={{
                                                width: "32px", height: "32px", boxShadow: "0px 1px 3px 0px #677A8E3D"
                                            }}
                                            onClick={handleEditCategory}
                                        >
                                            <PenIcon size={'small'}/>
                                        </IconButton>
                                        <IconButton
                                            id="basic-button"
                                            variant={'outlined'}
                                            style={{
                                                width: "32px", height: "32px", boxShadow: "0px 1px 3px 0px #677A8E3D"
                                            }}
                                            onClick={handleDeleteCategory}
                                        >
                                            <TrashIcon size={'small'}
                                                       style={{color: "var(--palette-primaryRed-main)"}}/>
                                        </IconButton>
                                    </div>))}
                            </div>
                        </div>
                        <Divider/>
                        <Button
                            style={{fontSize: "14px", fontWeight: "500", width: "fit-content"}}
                            startIcon={<PlusIcon size={'small'}/>}
                            variant={'text'}
                            color={'primary'}
                            size={'small'}
                        >
                            New category
                        </Button>
                    </div>
                    <div className={"actionItemsContentWrapper"}>
                        <div className={"newActionItemTitlesWrapper"}>
                            <Typography className={"newActionItemTitles"}>
                                Urgency level
                            </Typography>
                            <div style={{display: "flex", gap: "40px"}}>
                                <div className={"actionItemsRadio"} onClick={() => handleChangeUrgency("Low")}>
                                    <Radio value="Low" checked={selectedUrgency === "Low"}/>
                                    <Typography className={"actionItemsRadioTitle"}>Low</Typography>
                                </div>
                                <div className={"actionItemsRadio"} onClick={() => handleChangeUrgency("Medium")}>
                                    <Radio value="Medium" checked={selectedUrgency === "Medium"}/>
                                    <Typography className={"actionItemsRadioTitle"}>Medium</Typography>
                                </div>
                                <div className={"actionItemsRadio"} onClick={() => handleChangeUrgency("High")}>
                                    <Radio value="High" checked={selectedUrgency === "High"}/>
                                    <Typography className={"actionItemsRadioTitle"}>High</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"actionItemsContentWrapper"}>
                        <div className={"newActionItemTitlesWrapper"}>
                            <div style={{display: "flex", gap: "16px"}}>
                                <div style={{display: "flex", flexDirection: "column", gap: "4px", width: "600px"}}>
                                    <Typography className={"newActionItemTitles"}>
                                        Weather definition
                                    </Typography>
                                    <SearchInput
                                        defaultValue={selectedWeatherDefinition?.name}
                                        value={weatherDefinitionFilter}
                                        style={{width: "100%"}}
                                        placeholder='Search for weather definitions'
                                        onChange={handleWeatherDefinitionFilter}
                                        onClick={() => setIsOpenSearching(!isOpenSearching)}
                                    />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        overflowY: "auto",
                                        maxHeight: "400px",
                                        height: isOpenSearching ? "fit-content" : "0",
                                        backgroundColor: 'white',
                                        borderRadius: '12px',
                                        width: "100%",
                                        padding: isOpenSearching ? "12px" : "0",
                                    }}
                                         className={isOpenSearching ? "productsVisible" : "productsInvisible"}>

                                        <Typography style={{
                                            fontSize: "12px", fontWeight: "400", color: "var(--palette-grey-400)"
                                        }}> Select an option </Typography>

                                        {weatherDefinitions && weatherDefinitions.map(wd => {
                                            {
                                                if (wd.name.toLocaleLowerCase().indexOf(weatherDefinitionFilter) >= 0 || !weatherDefinitionFilter) {
                                                    return (<div
                                                        key={wd.id}
                                                        style={{
                                                            fontSize: "14px",
                                                            fontweight: "400",
                                                            lineHeight: "30px",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => handleChangeSelectedWeatherDefinition(wd)}>
                                                        {wd.name}
                                                    </div>)
                                                }
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                                {selectedWeatherDefinition && selectedWeatherDefinition.rules && !selectedWeatherDefinition.rules.category && //check for fixed weather definition
                                    <div style={{display: "flex", flexDirection: "column", gap: "4px", width: "600px"}}>
                                        <Typography className={"newActionItemTitles"}>
                                            Parameters
                                        </Typography>

                                        <div
                                            className={'definitionPreview'}
                                            style={{backgroundImage: `url(${DefinitionPreviewImage})`}}
                                        >
                                            <div className={'definitionGrid'}
                                                 style={{minHeight: "153px", position: "relative", overflow: "hidden"}}>
                                                {selectedWeatherDefinition.ui_rules && renderRules(selectedWeatherDefinition.ui_rules)}
                                                {selectedWeatherDefinition.rules && !selectedWeatherDefinition.ui_rules && renderRules(selectedWeatherDefinition.rules)}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <Divider/>
                            <Button
                                style={{fontSize: "14px", fontWeight: "500", width: "fit-content"}}
                                startIcon={<PlusIcon size={'small'}/>}
                                variant={'text'}
                                color={'primary'}
                                size={'small'}
                            >
                                New weather definition
                            </Button>
                        </div>
                    </div>
                    <div className={"actionItemsContentWrapper"}>
                        <div className={"newActionItemTitlesWrapper"}>
                            <Typography className={"newActionItemTitles"}>
                                Description of action item
                            </Typography>
                            <TextField style={{width: "640px"}} placeholder={"Enter action item description"} multiline inputProps={{ maxLength: 1000 }}
                                       minRows={2} maxRows={5} value={description} onChange={handleChangeDescription}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showSuccessModal && (
            <ActionItemConfirmationModal
                name={nameOfActionItem}
                urgency={selectedUrgency}
                agreeFunc={handleSaveActionItem}
                goToSettingsFunc={handleSaveActionItem}
            />
        )}
    </>);
};

export default EditActionItemPage;