import {TextProductsConverter} from "./TextProductsConverter";
import tree from "../../pages/map/model/textproducts/airportSensors.json";

export function getFlightCategory(data) {
    let rawMetar = data.raw_metar;
    let visibility = (data.visibility && data.visibility.value !== undefined) ? data.visibility.value * 0.0006213711922373 : null;
    if (!visibility) {
        visibility = (data.parsedData && data.parsedData.visibility) ? data.parsedData.visibility : null;
    }
    const clouds = [' FEW', ' SCT', ' BKN', ' OVC', ' VV'];
    let minCeiling = 1000; // min ceiling in hundreds feets
    let cloudsCover; // clouds cover
    rawMetar = rawMetar.split(' BECMG ')[0];
    rawMetar = rawMetar.split(' RMK ')[0];
    rawMetar = rawMetar.split(' TEMPO ')[0];
    if (rawMetar.indexOf(' CLR ') <= 0 && rawMetar.indexOf(' SKC ') <= 0 && rawMetar.indexOf(' NSC ') <= 0 && rawMetar.indexOf(' NCD ') <= 0 && rawMetar.indexOf(' CAVOK ') <= 0) {
        for (let i = 0; i < clouds.length; i++) {
            let c = 1000;
            const ar = rawMetar.split(clouds[i]);
            if (ar.length > 1) {
                c = parseInt(ar[1].split()[0].substring(0, 3), 10);
                if (isNaN(c) && i === 4) c = 0; // vv/// - bad case
                cloudsCover = (i + 1) * 25;
            }
            if (c < minCeiling) minCeiling = c;
        }
    } else {
        minCeiling = 120;
        cloudsCover = 0;
    }
    if (minCeiling === 1000) minCeiling = undefined;
    let flightCategory = ['VFR', 'grey', '#808080', cloudsCover];
    if ((minCeiling > 30 && visibility > 5) || (minCeiling === 120 && visibility === undefined)) flightCategory = ['VFR', 'white', '#fefefe', cloudsCover];
    else if (minCeiling < 5 || visibility < 1) flightCategory = ['LIFR', 'purple', '#9b11b2', cloudsCover];
    else if (minCeiling < 10 || visibility < 3) flightCategory = ['IFR', 'red', '#f8001e', cloudsCover];
    else if (minCeiling <= 30 || visibility <= 5) flightCategory = ['MVFR', 'blue', '#1d00fd', cloudsCover];

    return flightCategory;
}

export function getVisibleSensors(map) {
    const bounds = map.getBounds();
    let zoom = Math.ceil(map.getZoom());
    if (zoom > 18) {
        zoom = 18;
    }
    const currentSet = tree[zoom];
    let visibleSensors = currentSet.filter(s => (s[1][0] < bounds._ne.lat) && (s[1][0] > bounds._sw.lat));
    if (bounds._ne.lng < bounds._sw.lng) {
        //filter by to 360 and from -360
        visibleSensors = visibleSensors.filter(s => (s[1][1] > bounds._ne.lng) || (s[1][1] < bounds._sw.lng));
    } else {
        visibleSensors = visibleSensors.filter(s => (s[1][1] < bounds._ne.lng) && (s[1][1] > bounds._sw.lng));
    }
    return visibleSensors;
}

export function detectColorTemperature(v) {
    for (let i = 0; i < tempScaleShort.length; i++) {
        if (v < tempScaleShort[i]) {
            return temperatureScale[i];
        }
    }
    return '';
}

export function getVisibilityText(v) {
    if (isNaN(v)) return '';
    return TextProductsConverter.range(v);
}

export function createPointObjectCityState(str) {
    if (!str) return {name: ''};
    const tmp = str.split(',');
    const result = {name: tmp[0]};
    result.country = tmp[tmp.length - 1];
    result.state = (tmp.length > 2) ? tmp[tmp.length - 2] : '';
    if ((result.country === ' United States') || (result.country === ' US')) {
        result.state = usStatesTable[result.state.substring(1)];
        result.country = 'US'
    }
    return result;
}

const temperatureScale = {
    0: '#6cfffb',
    1: '#29abe2',
    2: '#f9dd54',
    3: '#f7c31e',
    4: '#f7931e'
};
const tempScaleShort = [0, 9.44, 21.12, 29.5, 10000];
const usStatesTable = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "Washington DC",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

export function getSkyConditionText(p) {
    const isValidSkyCondition = function (t) {
        return (t === 'Clear' || t === 'Cloudy' || t === 'Partly Cloudy' || t === 'Mostly Clear');
    };

    if (p.weather_code && p.weather_code.text && isValidSkyCondition(p.weather_code.text)) {
        return p.weather_code.text;
    } else {
        let skyCondition = '';
        const cloudCoverValue = (p.cloud_cover && p.cloud_cover.value) ? p.cloud_cover.value : getFlightCategory(p)[3];
        if (!cloudCoverValue) {
            return;
        } else {
            if (cloudCoverValue < 25) skyCondition = "Clear";
            else if (cloudCoverValue < 50) skyCondition = "Few Clouds";
            else if (cloudCoverValue < 75) skyCondition = "Scattered Clouds";
            else if (cloudCoverValue < 100) skyCondition = "Broken Clouds";
            else skyCondition = "Overcast";

        }
        return skyCondition;

    }
}

export function getWeatherConditionIconClass(weatherConditionCode, isDay) {
    if (!weatherConditionCode) {
        return ''
    }

    const baseClass = `weather-condition-${weatherConditionCode}`
    const shouldHaveDayNightSuffix = weatherConditionCode >= 9000 && weatherConditionCode <= 9003

    if (shouldHaveDayNightSuffix) {
        return `${baseClass}-${isDay ? 'day' : 'night'}`
    } else {
        return baseClass
    }
}
