import {createSvgIcon} from "@mui/material/utils";


export default function MapIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5434 3.46008C19.6554 2.91908 18.5734 2.878 17.6494 3.354L15.4384 4.48901C15.1604 4.63201 14.8404 4.63201 14.5624 4.48901L10.3514 2.32703C9.93441 2.11303 9.47741 2.00703 9.01941 2.00403C9.01241 2.00403 9.0074 2 9.0004 2C8.9934 2 8.98841 2.00403 8.98141 2.00403C8.52341 2.00703 8.06641 2.11303 7.64841 2.32703L4.20142 4.09802C2.84342 4.79502 1.99939 6.19204 1.99939 7.74304V17.9199C1.99939 19.0029 2.5444 19.982 3.4574 20.54C4.3454 21.081 5.42641 21.122 6.35141 20.646L8.56241 19.511C8.84041 19.368 9.16042 19.368 9.43842 19.511L13.6494 21.6731C14.0684 21.8881 14.5284 21.996 14.9884 21.998C14.9924 21.998 14.9964 22 15.0004 22C15.0044 22 15.0084 21.998 15.0124 21.998C15.4724 21.996 15.9324 21.8881 16.3514 21.6731L19.7984 19.9021C21.1564 19.2051 22.0004 17.8081 22.0004 16.2571V6.08008C22.0014 4.99708 21.4564 4.01808 20.5434 3.46008ZM5.43939 18.865C5.00639 19.087 4.6394 18.9151 4.5014 18.8311C4.1894 18.6401 4.0014 18.299 4.0014 17.918V7.74097C4.0014 6.94297 4.42839 6.22798 5.11639 5.87598L8.0014 4.39404V17.6001C7.8844 17.6421 7.76239 17.672 7.65039 17.73L5.43939 18.865ZM10.3524 17.73C10.2404 17.672 10.1184 17.6431 10.0014 17.6001V4.39307L13.6504 6.26697C13.7624 6.32497 13.8844 6.35397 14.0014 6.39697V19.604L10.3524 17.73ZM20.0014 16.2571C20.0014 17.0551 19.5744 17.7701 18.8864 18.1221L16.0014 19.604V6.39697C16.1184 6.35497 16.2404 6.32497 16.3524 6.26697L18.5634 5.13208C18.9954 4.90908 19.3634 5.08202 19.5014 5.16602C19.8134 5.35702 20.0014 5.69798 20.0014 6.07898V16.2571Z"
                fill="currentColor"/>
        </svg>
        , 'Map')
    return <Icon {...props} data-cy="map-icon"/>
}