import {createSvgIcon} from "@mui/material/utils";


export default function DesktopLoadIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 2H7C4.243 2 2 4.243 2 7V13C2 15.757 4.243 18 7 18H11V20H8C7.448 20 7 20.447 7 21C7 21.553 7.448 22 8 22H16C16.552 22 17 21.553 17 21C17 20.447 16.552 20 16 20H13V18H17C19.757 18 22 15.757 22 13V7C22 4.243 19.757 2 17 2ZM20 13C20 14.654 18.654 16 17 16H7C5.346 16 4 14.654 4 13V7C4 5.346 5.346 4 7 4H17C18.654 4 20 5.346 20 7V13Z"
                fill="currentColor"/>
            <path
                d="M15.707 10.0571C16.098 9.66613 16.098 9.03407 15.707 8.64307L12.707 5.64307C12.512 5.44807 12.256 5.3501 12 5.3501C11.744 5.3501 11.4881 5.44807 11.2931 5.64307L8.29305 8.64307C7.90205 9.03407 7.90205 9.66613 8.29305 10.0571C8.68405 10.4481 9.31599 10.4481 9.70699 10.0571L11 8.76404L11 13.3501C11 13.9031 11.448 14.3501 12 14.3501C12.552 14.3501 13 13.9031 13 13.3501L13 8.76404L14.2931 10.0571C14.6841 10.4481 15.316 10.4481 15.707 10.0571Z"
                fill="currentColor"/>
        </svg>
        , 'DesktopLoad')
    return <Icon {...props} data-cy="desktop-load-icon"/>
}