import {createSvgIcon} from "@mui/material/utils";

export default function CalendarIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 3C17 2.447 16.552 2 16 2C15.448 2 15 2.447 15 3H9C9 2.447 8.552 2 8 2C7.448 2 7 2.447 7 3C4.243 3 2 5.243 2 8V17C2 19.757 4.243 22 7 22H17C19.757 22 22 19.757 22 17V8C22 5.243 19.757 3 17 3ZM7 5V6C7 6.553 7.448 7 8 7C8.552 7 9 6.553 9 6V5H15V6C15 6.553 15.448 7 16 7C16.552 7 17 6.553 17 6V5C18.654 5 20 6.346 20 8H4C4 6.346 5.346 5 7 5ZM17 20H7C5.346 20 4 18.654 4 17V10H20V17C20 18.654 18.654 20 17 20ZM17 13C17 13.552 16.552 14 16 14C15.448 14 15 13.552 15 13C15 12.448 15.448 12 16 12C16.552 12 17 12.448 17 13ZM13 13C13 13.552 12.552 14 12 14C11.448 14 11 13.552 11 13C11 12.448 11.448 12 12 12C12.552 12 13 12.448 13 13ZM9 13C9 13.552 8.552 14 8 14C7.448 14 7 13.552 7 13C7 12.448 7.448 12 8 12C8.552 12 9 12.448 9 13ZM17 17C17 17.552 16.552 18 16 18C15.448 18 15 17.552 15 17C15 16.448 15.448 16 16 16C16.552 16 17 16.448 17 17ZM13 17C13 17.552 12.552 18 12 18C11.448 18 11 17.552 11 17C11 16.448 11.448 16 12 16C12.552 16 13 16.448 13 17ZM9 17C9 17.552 8.552 18 8 18C7.448 18 7 17.552 7 17C7 16.448 7.448 16 8 16C8.552 16 9 16.448 9 17Z"
                fill="currentColor"/>
        </svg>, 'Calendar')
    return <Icon {...props} data-cy="calendar-icon"/>
}