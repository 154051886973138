import AccordionDetails from '@mui/material/AccordionDetails'
import {styled} from '@mui/material/styles'

import theme from 'app/Theme'

const AccordionDetailsUserGroup = styled(AccordionDetails)(() => ({
    background: theme.palette.grey.background,
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    borderTop: '1px solid transparent',
    borderRadius: '0 0 12px 12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0 16px 12px 16px',
}))

export default AccordionDetailsUserGroup
