import {Autocomplete, Box, Button, IconButton, Typography} from '@mui/material';
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from 'react';
import {
    fetchContacts,
    fetchReportConfigurations, fetchWeatherDefinitions
} from "../alerts/api/FetchAlerts";
import ReportItem from './ReportItem';
import CloseIcon from "../../shared/assets/icons/Close";
import {Spacer} from "shared/ui/Spacer";
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {SearchInput} from "shared/ui/SearchInput";

export default function NewReport({onAction, defaultValue= null}) {
    console.log("defaultValue", defaultValue)

    let selectedConfigFromDefault = null;
    if(defaultValue) {selectedConfigFromDefault = {name: defaultValue?.alertconfiguration.weather_definition.name, alertconfiguration: defaultValue.alertconfiguration}}

    const [configurations, setConfigurations] = useState();
    const [uniqueConfigurations, setUniqueConfigurations] = useState();
    const [name, setName] = useState(defaultValue?.name || '');
    const [selectedConfig, setSelectedConfig] = useState(selectedConfigFromDefault);

    let acKey = ac => (ac.weather_definition.name+'/'+ac.location.label).toLocaleLowerCase();
    useEffect(() => {
        let proms = [fetchWeatherDefinitions()];
        Promise.allSettled(proms).then((rr) => {
            if (rr[0].status === 'fulfilled') {
                // setConfigurations(rr[0].value.data.sort((a, b) => acKey(a).localeCompare(acKey(b))));
                setConfigurations(rr[0].value)
            }
        });
    }, []);

    useEffect(() => {
        const uniqueNames = [];
        const uniqueConfigurationsArray = [];
        if(!configurations)return
        Object.values(configurations).forEach(config => {
            if (!uniqueNames.includes(config.name)) {
                uniqueNames.push(config.name);
                uniqueConfigurationsArray.push(config);
            }
        });
        console.log("uniqueConfigurationsArray", uniqueConfigurationsArray)
        setUniqueConfigurations(uniqueConfigurationsArray);
    }, [configurations]);

    let hasData = () => selectedConfig;
    let createdReport = () => {
        console.log("createdReport", selectedConfig);

        if (selectedConfig) {
            return (defaultValue?
                {id: defaultValue.id , name: name, alertconfiguration: selectedConfig.alertconfiguration.id}:
                {weatherdefinition: selectedConfig.weatherdefinition.id})
        }
    }

    let handleCancelAlert = () => {
        setSelectedConfig(null);
    };

    function handleSelectedConfigChange(event, value) {
        if (value && Object.keys(value).length)
            setSelectedConfig({...value})
        else
            setSelectedConfig(null)
    }

    console.log("selectedConfig", selectedConfig)

    return <Box className="NewReport container">
                <Box className={'toolbar'}>
                    <IconButton size={'small'} variant={'outlined'} onClick={(ev) => { if (ev) ev.stopPropagation(); defaultValue ? onAction(defaultValue, 'cancel_edit_report') : onAction(hasData(), 'cancel_create_report'); }}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3>
                        {defaultValue? "Edit" : "New"} report
                    </h3>
                    <Spacer/>
                    <Button variant={'contained'} color={'primary'}
                            disabled={!createdReport()}
                            onClick={(ev) => { ev.stopPropagation();
                            defaultValue? onAction(undefined, 'edit_report', createdReport()) : onAction(undefined, 'create_report', createdReport());
                            }}
                    >
                        Save report
                    </Button>
                </Box>
        <Box sx={{width: '100%', height:"700px", overflowY:"auto"}}>
        <Box className="NewReportForm column gap32 fullWidth" sx={{maxWidth: '1000px'}}>
            <Box className={'column gap16 fullWidth'}>
                <Typography className={'subtitle medium'}>
                    General settings
                </Typography>
                {/*<Typography className={'label'}>*/}
                {/*    Report name*/}
                {/*    <TextField*/}
                {/*        autoComplete={"off"}*/}
                {/*        placeholder='Enter name'*/}
                {/*        value={name}*/}
                {/*        title='Report name'*/}
                {/*        defaultValue=''*/}
                {/*        onChange={event => setName(event.target.value)}*/}
                {/*    />*/}
                {/*</Typography>*/}
                <Typography className={'label'}>
                    Weather definition
                    {defaultValue? null :
                    <Autocomplete
                        disableClearable
                        value={selectedConfig}
                        onChange={handleSelectedConfigChange}
                        getOptionLabel={report => report?.name || ''}
                        options={uniqueConfigurations?.map(weatherDefinition => ({
                            weatherdefinition: weatherDefinition
                        })) ?? [{weatherdefinition: {}}]
                        }
                        renderOption={(props, report) => (
                            <ReportItem
                                {...props}
                                key={report.id}
                                type='configuration'
                                report={report}
                            />
                        )}
                        renderInput={props =>
                            <SearchInput
                                {...props}
                                type={'text'}
                                placeholder={'Search for weather definition'}
                            />
                        }
                    />}
                    {!!selectedConfig &&
                        <Box className={'row'}>
                            <ReportItem type='configuration' report={selectedConfig} isActive={!defaultValue}/>
                            {defaultValue? null :
                                <IconButton variant={'outlined'} size={'small'} onClick={handleCancelAlert} sx={{marginLeft: '-30px'}}>
                                    <CloseIcon size={'small'}/>
                                </IconButton>
                            }
                        </Box>
                    }
                </Typography>
            </Box>
        </Box>
        </Box>
    </Box>;
}

