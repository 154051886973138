import {Box, Chip, Typography} from "@mui/material";
import DefinitionPreviewImage from "../../../shared/assets/png/DefinitionPreview.png";
import EmptyStatesDefinitionPreview from "../../../shared/ui/emptyStates/EmptyStateDefinitionPreview";
import {Spacer} from "../../../shared/ui/Spacer";
import React from "react";
import {availableToRenderDefinition} from "./availableToRenderDefinition";
import {productsDict} from "../../../entities/weatherDefinition/api/Products";

export function DefinitionRenderPreview({definition}) {
    return (
        <div
            className={'definitionPreview'}
            style={{backgroundImage: `url(${DefinitionPreviewImage})`, width:"100%"}}
        >
            <Typography
                className={'medium'}
                style={{color: 'white'}}
            >
                Definition preview
            </Typography>
            <div className={'definitionGrid'} style={{minHeight: "153px", position: "relative", overflow: "hidden"}}>
                {availableToRenderDefinition(definition) ?
                    definition.groups.map((group, index) =>
                        <RenderGroup
                            group={{
                                ...group,
                                isFirst: !index,
                            }}
                        />
                    ) :
                    <EmptyStatesDefinitionPreview/>
                }
            </div>
            <Spacer/>
        </div>
    )
}

export function RenderGroup({group}) {
    let lines = []
    group.lines.forEach((lineObject, index) => {
        let line = DefinitionRenderLine({
            line: {
                ...lineObject,
                isFirst: !index,
                isLast: index === group.lines.length - 1,
            },
            group: group,
        })
        lines.push(...line)
    })
    return lines;
}

function DefinitionRenderLine({group, line}) {
    function getDefinitionValues() {
        if (line.values.length === 2)
            return `${line.values[0] ?? '----'} to ${line.values[1] ?? '----'} ${line.units}`
        else if ('' + line.values[0])
            return `${line.relation} ${line.values[0]} ${line.units}`
        else
            return `--- ${line.units}`
    }

    return [
        <Typography className={'union'}>
            {!group.isFirst && line.isFirst ? group.union : line.union}
        </Typography>,
        <Box className={'row gap4'} style={{justifyContent: 'flex-start',}}>
            <Typography
                className={'braces'}
                style={{color: line.isFirst ? 'white' : 'transparent'}}
            >
                (
            </Typography>
            {productsDict && productsDict[line.product_name] && productsDict[line.product_name].icon &&
                <svg className={'productIcon'}>
                    <use
                        height="16"
                        width="16"
                        href={`${productsDict[line.product_name].icon}#svg2`}
                    />
                </svg>
            }
            <Typography>
                {line.product_name}
            </Typography>
        </Box>,
        <Box className={'row gap4'} style={{justifyContent: 'flex-end'}}>
            <Chip label={getDefinitionValues()}/>
            <Typography
                className={'braces'}
                style={{color: line.isLast ? 'white' : 'transparent'}}
            >
                )
            </Typography>
        </Box>,
        <div className={'line'}/>
    ]
}