import {toFahrenheit} from './UnitsConverter'

// TODO: move
const baron = {
    isMetricalUnits: false,
}

export const TextProductsConverter = {
    range: function (x, withoutUnits) {
        if (isNaN(x)) return null;
        let r = baron.isMetricalUnits ? (x * 0.001) : Math.round(x * 0.0006213711922373);
        r = r.toFixed(2);
        const units = baron.isMetricalUnits ? ' km' : ' mi';
        return withoutUnits ? r : r.replace('.00', '') + units;
    },
    metersToInches(value) {
        return value * 39.3701
    },
    metersToFeet(meters) {
        return meters * 3.28084
    },
    temperature(t, withoutUnits = false) {
        if (isNaN(t)) return null;
        const temp = baron.isMetricalUnits ? Math.round(t) : toFahrenheit(t);
        return withoutUnits ? temp : temp + "&deg;";
    },
    height: function (h, withoutUnits, floatcount) {
        if (isNaN(h)) return null;
        const units = baron.isMetricalUnits ? ' m' : ' ft';
        let temp = baron.isMetricalUnits ? h : h / 0.3048;
        if (isNaN(floatcount)) floatcount = 0;
        temp = !isNaN(floatcount) ? temp.toFixed(floatcount) : (baron.isMetricalUnits ? temp.toFixed(1) : temp);
        return withoutUnits ? temp : temp + units;
    },
    wind(wind) {
        if (!wind) return null
        const direction = this.windDirection(wind.dir)
        const speed = this.speed(wind.speed)
        return `${direction} ${speed}`
    },
    windDirection: function (d) {
        if (isNaN(d)) return '';
        d = (d % 360) + 360;
        const val = (d / 22.5) + 0.5;
        const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
        return arr[~~(val % 16)];
    },
    marineSpd: function (s, withoutUnits) {
        if (isNaN(s)) return null;
        const temp = Math.round(s / 0.514);
        const units = " kn";
        return withoutUnits ? temp : temp + units;
    },
    pressure: function (p, withoutUnits) {
        if (isNaN(p)) return null;
        const temp = baron.isMetricalUnits ? Math.round(p) : (p * 0.75006 / 25.4).toFixed(2);
        const units = baron.isMetricalUnits ? ' mbar' : ' inHg';
        return withoutUnits ? temp : temp + units;
    },
    speed: function (s, withoutUnits, floatcount) {
        if (isNaN(s)) return null;
        let temp = baron.isMetricalUnits ? s : s * 2.237;
        if (isNaN(floatcount)) floatcount = 0;
        temp = temp.toFixed(floatcount);
        const units = baron.isMetricalUnits ? ' m/s' : ' mph';
        return withoutUnits ? temp : temp + units;
    },
    speedKmPeH: function (s, withoutUnits, floatcount) {
        if (isNaN(s)) return null;
        let temp = baron.isMetricalUnits ? s * 3.6 : s * 2.237;
        if (isNaN(floatcount)) floatcount = 0;
        temp = temp.toFixed(floatcount);
        const units = baron.isMetricalUnits ? ' km/h' : ' mph';
        return withoutUnits ? temp : temp + units;
    },
    speedMphInMs: function (s, withoutUnits) {
        if (isNaN(s)) return null;
        const temp = baron.isMetricalUnits ? Math.round(s / 2.237) : Math.round(s);
        const units = baron.isMetricalUnits ? ' m/s' : ' mph';
        return withoutUnits ? temp : temp + units;
    },
    speedMetersPerSecondToKnots: function (s) {
        return s * 1.9438462;
    },
    visibilityTextToFloatMiles: function (s) {
        /*
         * 1/2SM - Visibility one-half statute mile
         * 2 1/4SM - Visibility two and one-quarter statute miles
         * 5SM - Visibility five statute miles
         * P6SM - Visibility more than six statute miles
         */
        s = s.toUpperCase();
        if (s === 'P6SM' || s === '9999') {
            return 6
        }
        if (s.slice(-2) === 'SM') {
            const val = s.slice(0, -2);
            const eq = val.split('/');
            if (eq.length === 1) {
                return parseInt(eq[0]);
            } else {
                const v = (parseInt(eq[0]) / parseInt(eq[1]));
                return isNaN(v) ? null : v.toFixed(2);
            }
        } else {
            const tmpVal = parseInt(s);
            return isNaN(tmpVal) ? null : (tmpVal * 0.0006213711922373).toFixed(2);
        }
    },
    getFunctionOfMappingObject(cfg) {
        /*EXAMPLE OF CONFIG FOR METAR (param for Mapping):
          {
              'temp' : 'temperature.value',
              'dew' : 'temperature.dew_point',
              'cloudcover' : 'cloud_cover.value',
              'speed' : 'wind.speed',
              'winddir': 'wind.dir',
              'code': 'weather_code.value',
              'isDay': 'daylight',
              'name' : 'station.name',
              'visibility' : 'visibility.value',
              'humidity' : 'relative_humidity.value',
              'windChill' : 'temperature.wind_chill',
              'heatIndex' : 'temperature.heat_index',
              'id' : 'station.id'
          }*/
        let linesFunction = [];
        for (let i in cfg) {
            let line = cfg[i].split('.'), condition = 'o', value = 'o';
            for (let k = 0; k < line.length; k++) {
                const l = line[k];
                value += '["' + l + '"]';
                condition += (k !== line.length - 1) ? '&&' + value : '&&(' + value + '!==undefined)';
            }
            linesFunction.push('(' + condition + ') && (res["' + i + '"]=' + value + ')');
        }
        let bodyFunction = 'var res={};' + linesFunction.join(';');
        bodyFunction += ';return res;';
        return new Function('o', bodyFunction);
    },
}