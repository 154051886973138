const alertTypes = {
    sia: 'Critical',
    ehw: 'Severe',
    ra: 'Moderate',
}

export const productNamesByApiType = {
    //
    fgy: ['Fog - Current'],

    eha: ['Baron Extreme Weather Index - Heat', 'Heat Index - Current', 'Heat Index - Forecast'], // Excessive heat
    hty: ['Baron Extreme Weather Index - Heat', 'Heat Index - Current', 'Heat Index - Forecast'], // Heat Advisory
    dsa: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Dangerous Storm Approaching
    ra: ['Baron Extreme Weather Index - Rain', 'Rainfall Accumulation - Current', 'Rainfall Accumulation - Forecast'],
    RA: ['Baron Extreme Weather Index - Rain', 'Rainfall Accumulation - Current', 'Rainfall Accumulation - Forecast'],
    hua: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts'], // Hurricane Watch
    huw: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts'], // Hurricane Warning
    tra: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Tropical Storm
    trw: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Tropical Storm Warning
    hia: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts'], // Inland Hurricane Watch
    hiw: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts'], // Inland Hurricane Warning
    tia: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Inland Tropical Storm Watch
    tiw: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Inland Tropical Storm Warning
    cfa: ['Flash Flood Risk', 'Road Conditions - Current', 'Road Conditions - Forecast'], // Coastal Flood Watch
    cfw: ['Flash Flood Risk', 'Road Conditions - Current', 'Road Conditions - Forecast'], // Coastal Flood Warning
    wiy: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts', 'High-Res Wind Speed - Current', 'Wind Speed - Forecast'], // Wind Advisory
    wcy: ['High-Res Wind Chill - Current', 'Wind Chill - Forecast'], // Wind Chill Advisory
    wca: ['High-Res Wind Chill - Current', 'Wind Chill - Forecast'], // Wind Chill Watch
    wcw: ['High-Res Wind Chill - Current', 'Wind Chill - Forecast'], // Wind Chill Warning
    hwa: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts', 'High-Res Wind Speed - Current', 'Wind Speed - Forecast'], // High Wind Watch
    hww: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts', 'High-Res Wind Speed - Current', 'Wind Speed - Forecast'], // High Wind Warning
    lwy: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts', 'High-Res Wind Speed - Current', 'Wind Speed - Forecast'], // Lake Wind Advisory
    duy: [], // Blowing Dust Advisory
    eww: ['Damaging Wind Path', 'Baron Extreme Weather Index - Wind', 'Damaging Wind Probability - Current', 'Damaging Wind Probability Day 2', 'Wind Gusts', 'High-Res Wind Speed - Current', 'Wind Speed - Forecast'], // Extreme Wind Warning
    dsw: [], // Dust Storm Warning
    ehw: ['Baron Extreme Weather Index - Heat', 'Heat Index - Current', 'Heat Index - Forecast'], // Excessive Heat Warning
    dmy: ['Smoke - Current', 'Smoke - Forecast', 'Smoke - Current', 'Smoke - Forecast'], // Dense Smoke Advisory
    fly: ['Flash Flood Risk', 'Road Conditions - Current', 'Road Conditions - Forecast', 'Tornado Probability - Current', 'Tornado Probability Day 2', 'Flash Flood Risk'], // Flood Advisory
    hzw: ['Road Conditions - Current', 'Road Conditions - Forecast'], // Hard Freeze Warning
    tw: ['Tornado Probability - Current', 'Tornado Probability Day 2'], // Tornado Warning
    toa: ['Tornado Probability - Current', 'Tornado Probability Day 2'], // Tornado Watch
    sva: ['Lightning - Forecast'], // Severe Thunderstorm
    tsw: ['Lightning - Forecast'], // Severe Thunderstorm Warning
    ffw: ['Flash Flood Risk'], // Flash Flood Warning
    fw: ['Flash Flood Risk'], // Flood Warning
    ssa: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Storm Approaching
    sia: ['Severe Weather Probability - Current', 'Severe Weather Probability Day 2', 'Severe Weather Probability Day 3'], // Storms in Area
    lia2: ['Lightning - Forecast'], // Lightning in Area
    gr: ['Baron Extreme Weather Index - Hail', 'Hail Probability - Current', 'Hail Probability - Forecast', 'Hail Size'], // "Hail is Approaching
    sgmtraffic: [], // Traffic Message
    usereventsalert: [],

}

export function mapAlertType(alertType) {
    return alertTypes[alertType] ?? 'Moderate'
}
