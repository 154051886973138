import {createSvgIcon} from "@mui/material/utils";


export default function LogoutIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 7V17C21 19.757 18.757 22 16 22H12C11.448 22 11 21.553 11 21C11 20.447 11.448 20 12 20H16C17.654 20 19 18.654 19 17V7C19 5.346 17.654 4 16 4H12C11.448 4 11 3.553 11 3C11 2.447 11.448 2 12 2H16C18.757 2 21 4.243 21 7ZM3.07575 12.382C2.97475 12.138 2.97475 11.862 3.07575 11.618C3.12675 11.495 3.19976 11.385 3.29276 11.292L7.29175 7.29297C7.68275 6.90197 8.31475 6.90197 8.70575 7.29297C9.09675 7.68397 9.09675 8.31603 8.70575 8.70703L6.41276 11H13.9988C14.5508 11 14.9988 11.447 14.9988 12C14.9988 12.553 14.5508 13 13.9988 13H6.41276L8.70575 15.293C9.09675 15.684 9.09675 16.316 8.70575 16.707C8.51075 16.902 8.25475 17 7.99875 17C7.74275 17 7.48675 16.902 7.29175 16.707L3.29276 12.708C3.19976 12.615 3.12675 12.505 3.07575 12.382Z"
                fill="currentColor"/>
        </svg>, 'Logout')
    return <Icon {...props} data-cy="logout-icon"/>
}