import {Box} from "@mui/system";
import AlertSettingsLine from "./AlertSettingsLine";
import './AlertsSettings.css';

const AlertsSettingsList = ({alertGroups, extendedAlertConfigs, alertsToDelete, onChange, selectedConfigsTemp={configs: {}}, searchString, severity, selectedLocations, sx={}, lineSx={}, isNewAlert = false}) => {

    const filterByLocations = (alert) => {
        for (let idx in alert.locations)
            if (selectedLocations[alert.locations[idx].id] !== false) return true;
        return false;
    }

    return (
        <Box className='AlertsSettingsList' style={{ padding: alertsToDelete ? "16px 24px" : "0px"}}>
            {Object.keys(alertGroups).map(key => {
                const alert = alertGroups[key];
                const isEnableExtendedAlert = extendedAlertConfigs.find((exAlert) => exAlert.group_id === alert.groupId)?.enabled
                if ((!searchString || alert.type.toLocaleLowerCase().indexOf(searchString) >= 0) &&
                    (alert.severity === severity || severity === 'All') &&
                    filterByLocations(alert) !== false) {
                    return (
                        <AlertSettingsLine alert={alert} isEnable={isEnableExtendedAlert} onChange={onChange} key={alert.groupId} isNewAlert={isNewAlert}
                    selected={!!selectedConfigsTemp.configs[alert.groupId]?.selected || (alertsToDelete && alertsToDelete[alert.groupId])} sx={lineSx}/>
                    );
                }
                return undefined;
            })}
        </Box>
    )
};

export default AlertsSettingsList;
