//params {productId = concatinations of code and projection, startDate - ms, signature - Signature from requester3}
export async function rasterMetaData({ productId, signature, startDate }) {
    if (!productId || !signature) return;

    const path = `meta/tiles/product-instances/${productId}.json`;
    const url = signature.makeUrl({ path });
    return fetch(url).then(response => response.json()).then(data => handleFirstRequest(data, startDate)).then(data => {
        if (data.forecastTime) {
            return data
        }
        let setTimes = new Set(data.times);
        let currentDeltaTimePage = currentProductDeltaTimePage(data.times);
        let slices = getSlicesParams(startDate, currentDeltaTimePage);
        let arrPrm = slices.map((s) => {
            return getDataPage(path, s, signature)
        });
        return Promise.all(arrPrm).then((pages) => {
            pages.forEach((times) => {
                times.forEach((t)=>{setTimes.add(t)})
            })
            let arrSetTimes = Array.from(setTimes);
            return {
                forecastTime: undefined,
                times: arrSetTimes
            }
        });
             
    }).catch(console.log);
}

async function getDataPage(path, olderThan, signature) {
    const url = signature.makeUrl({ path, params: { older_than: olderThan } });
    return fetch(url).
        then(response => response.json()).
        then(data => data.map(t => t.time)).
        catch(console.log);

}

function currentProductDeltaTimePage(times) {
    if (times.length <= 1) {
        return undefined
    }
    const t1 = new Date(times[times.length - 1]).getTime();
    const t2 = new Date(times[0]).getTime();
    return Math.abs(t1 - t2);
}

//return older_than times params
//startDate: (new Date(alert.start_date).toISOString().slice(0, 19) + 'Z'),
function getSlicesParams(startDate, deltaTimePage) {
    const arrSlices = [];
    const firstPrevSlice = new Date().getTime() - deltaTimePage;
    if (startDate > firstPrevSlice) {
        return arrSlices;
    }
    for (let prevTime = firstPrevSlice; startDate < prevTime; prevTime -= deltaTimePage) {
        const formatedTS = new Date(prevTime).toISOString().slice(0, 19) + 'Z';
        arrSlices.push(formatedTS);
    }
    return arrSlices;
}

function handleFirstRequest(data, startDate) {
    if (!data || !data[0]) return;
    if (!data[0].valid_times)
        return {
            forecastTime: undefined,
            times: data.map(t => t.time)
        }
    // find and return api forecast containing startDate  - WL-512
    const startDateIso = new Date(startDate).toISOString().slice(0, 19) + 'Z';
    for (let fidx in data) {
        const forecast = data[fidx];
        if (!forecast.valid_times.length) continue;
        if (forecast.valid_times[forecast.valid_times.length - 1] < startDateIso) {
            return {
                forecastTime: forecast.time,
                times: forecast.valid_times
            }
        }
    }
    return {
        forecastTime: data[0].time,
        times: data[0].valid_times
    }
}