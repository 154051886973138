import {createSvgIcon} from "@mui/material/utils";

export default function LockIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 9.42395V7C17.5 4.243 15.257 2 12.5 2C9.743 2 7.5 4.243 7.5 7V9.42395C5.737 10.198 4.5 11.955 4.5 14V17C4.5 19.757 6.743 22 9.5 22H15.5C18.257 22 20.5 19.757 20.5 17V14C20.5 11.955 19.263 10.198 17.5 9.42395ZM9.5 7C9.5 5.346 10.846 4 12.5 4C14.154 4 15.5 5.346 15.5 7V9H9.5V7ZM18.5 17C18.5 18.654 17.154 20 15.5 20H9.5C7.846 20 6.5 18.654 6.5 17V14C6.5 12.346 7.846 11 9.5 11H15.5C17.154 11 18.5 12.346 18.5 14V17ZM13.5 15V16C13.5 16.553 13.052 17 12.5 17C11.948 17 11.5 16.553 11.5 16V15C11.5 14.447 11.948 14 12.5 14C13.052 14 13.5 14.447 13.5 15Z"
                fill="currentColor"/>
        </svg>
        , 'Lock')
    return <Icon {...props} data-cy="lock-icon"/>
}