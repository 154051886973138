import {styled} from '@mui/material/styles'
import {PickersDay as MuiPickersDay} from '@mui/x-date-pickers/PickersDay'

import theme from 'app/Theme'

const StyledPickersDay = styled(MuiPickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'today',
})(({outsideCurrentMonth, today}) =>
    (
        {
            ...(outsideCurrentMonth && {
                color: theme.palette.action.disabled,
            }),
            ...(today && {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
                borderWidth: '1px',
                borderStyle: 'solid',
            }),
            margin: '0 4px 0 4px',
        }))

export function PickersDay({...other}) {
    return <StyledPickersDay {...other}/>
}
