import {Link} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import logoTabMenu from 'shared/assets/png/logo.png';
import {Spacer} from "shared/ui/Spacer";
import 'widgets/header/Header.css';
import {MainMenu} from 'widgets/mainMenu';
import ShowNotificationsPopupButton from "widgets/notification/ShowNotificationsPopupButton";


export default function Header() {
    const activeTab = new URL(document.URL).pathname;

    const tabNumbers = {
        "/": 1,
        "/timeline": 0,
        "/alerts": 1,
        "/map": 2,
        "/settings": 4,
        "/alerts/newalert": 5,
        "/alerts/edit-alert": 5,
        "/reports": 3,
    }
    if (tabNumbers[activeTab] === undefined) {
        return <></>
    }
    
    return (
        <div className={'row'} style={{justifyContent: 'left'}}>
            {/*{(activeTab === '/timeline' || activeTab === '/map') && !collapseSidebar &&*/}
            {/*    <div className={'row'} style={{width: '288px', height: 60, padding: '16px 24px 12px 24px', alignItems: 'center', borderRight: 'solid 1px #dbe0ea'}}>*/}
            {/*        <img style={{width: '150px'}} src={logoTabMenu} alt={'logo'}/>*/}
            {/*        <IconButton sx={{background: '#ffffff', marginLeft: 'auto'}} onClick={()=>onCollapse(true)}>*/}
            {/*            <svg style={{maxWidth: '17px', maxHeight: '17px', fill: 'none'}}><use href={SideBarIcon + '#svg2'} /></svg>*/}
            {/*        </IconButton>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{(activeTab === '/timeline' || activeTab === '/map') && collapseSidebar &&*/}
            {/*    <div className={'row'} style={{width: '96px', height: 60, padding: '16px 24px 12px 24px', alignItems: 'center', justifyContent: 'center', borderRight: 'solid 1px #dbe0ea'}}>*/}
            {/*        <img style={{width: '150px'}} src={logoTabMenu} alt={'logo'}/>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className={'row gap0 header'}>
                <Link className={'baronLogo'} href={'/timeline'}>
                    <img src={logoTabMenu} alt={'logo'}/>
                </Link>
                <div style={{width: '135px'}}/>
                <Tabs value={tabNumbers[activeTab]} >
                    <Tab variant={'primary'} label="Timeline" href="/timeline" style={{minWidth: 'unset'}}/>
                    <Tab label="Alerts" href="/alerts" style={{minWidth: 'unset'}}/>
                    <Tab label="Map" href="/map" style={{minWidth: 'unset'}}/>
                    {/*<Tab label="Reports" href="/reports"/>*/}
                </Tabs>
                <Spacer/>
                <ShowNotificationsPopupButton/>
            <MainMenu />
            </div>
        </div>
    );
}
