import {createSvgIcon} from "@mui/material/utils";
import React from "react";

export default function CheckBoxIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
            <rect stroke="currentColor" fill="white" rx="3.5" height="16" width="16" y="4" x="4"/>
            <path fill="currentColor"
                  d="m15.8698,9.13018c0.1736,0.17356 0.1736,0.45497 0,0.62853l-4.88886,4.88889c-0.17357,0.1736 -0.45498,0.1736 -0.62854,0l-2.22223,-2.22222c-0.17356,-0.17356 -0.17356,-0.45497 0,-0.62854c0.17357,-0.17356 0.45498,-0.17356 0.62854,0l1.90796,1.90796l4.57463,-4.57462c0.1736,-0.17357 0.455,-0.17357 0.6285,0z"
                  clipRule="evenodd" fillRule="evenodd"/>
        </svg>
        ,
        'CheckBoxChecked',
    )
    return <Icon {...props} data-cy="check-box-icon"/>
}