import React, { useEffect, useState } from 'react';
import 'pages/map/ui/legends/legends.css';

const EXCEPTIONAL_LIST = ['alert-all-poly']; // banned

export function Legends({products, activeProductsIndexes, nonStandartProjection}) {

	const [indexLegend, setIndexLegend] = useState(0);
	const [allLegends, setAllLegends] = useState({});
	const [activeLegends, setActiveLegends] = useState([]);

	useEffect(()=>{
		const newActiveLegends = [];	
		activeProductsIndexes.forEach(id => {
			if (allLegends[id] === undefined) {
				_loadImage(id, (urlLegend)=>{
					setActiveLegends([...activeLegends,urlLegend]);
				});
			}else{
				if (allLegends[id]){
					newActiveLegends.push(allLegends[id]);
				}
			}
		});
		setActiveLegends(newActiveLegends);
	},[activeProductsIndexes])

	useEffect(() => {
		setIndexLegend((currentIndex) => {
			const lastActiveLegendIndex = activeLegends.length - 1
			if (lastActiveLegendIndex >= 0) {
				return lastActiveLegendIndex	
			}

			return currentIndex
		})
	}, [activeLegends])

	const 	_url = "//staticbaronwebapps.velocityweather.com/digitial_wx/widgets/legends/";

	function _loadImage(id, onLoad) {
		const prod = products.find((e)=>{return(id===e.id)})
		if (!prod) {
			console.error(`Product with id ${id} not found.`);
			return;
		}
		let imgElement = document.createElement('img');
		let urlLegend =`${_url}${prod.api_product_code}_${nonStandartProjection[prod.api_product_code] || 'Standard-Mercator'}.svg`;

		let newLegends = Object.assign({},allLegends);
		newLegends[id] = false;
		setAllLegends(newLegends);
		if (EXCEPTIONAL_LIST.includes(prod.api_product_code)) return;
		imgElement.addEventListener('load', e => {
			let newLegends = Object.assign({}, allLegends);
			newLegends[id] = urlLegend;
			setAllLegends(newLegends);
			onLoad(urlLegend);
		});
		imgElement.src = urlLegend;
	}


	function handleChangeIndex(s) {
		let newIndex = (indexLegend + s + activeLegends.length) % activeLegends.length;
		setIndexLegend(newIndex);
	}

	if (!activeLegends.length) {
		return <div></div>;
	}
	const src = activeLegends[indexLegend];

	return (
		<div className="legendswrapper">
			{(activeLegends.length > 1) && <div className="legendsprevbtn"
				onClick={() => handleChangeIndex(-1)} aria-label="Previous Legend" ></div>}
			<img className="legendimage" src={src} alt="" />
			{(activeLegends.length > 1) && <div className="legendsnextbtn"
				onClick={() => handleChangeIndex(1)} aria-label="Next Legend" ></div>}
		</div>
	);


	
}
/*
export class Legends extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			indexLegend: 0,
			activeLegends: [],
			allLegends: {}
		}
		this._mapOverwritedLegensKeys();
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
	}

	_url = "//staticbaronwebapps.velocityweather.com/digitial_wx/widgets/legends/";
	_textProductLegends = ["traffic", "bsv", "nws"];

	_productLineToKey(prod) {
		try {
			let splitted = prod.split(':')[0].split('/');
			let newP = splitted[0];
			if (newP.indexOf('.') > -1) {
				newP = newP.split('.')[0];
			}
			const productCfg = splitted[1] ? newP + '/' + splitted[1] : newP;
			return productCfg;
		} catch (e) {
			return;
		}
	}

	_updateActiveLegends() {
		const legends = [];
		const prods = this._getKeysProducts();
		prods.forEach(e => {
			if (this.state.allLegends[e]) {
				legends.push(this.state.allLegends[e]);
			}
		});
		this.setState({ activeLegends: legends })
	}

	_mapOverwritedLegensKeys() {
		let overwritedLegends = {};
		try {
			window._opts.products.forEach(e => {
				let elem = e.split(':');
				const pair = {
					name: elem[0],
					prodKey: this._productLineToKey(elem[1])
				}
				if (pair.prodKey) {
					overwritedLegends[pair.prodKey] = window._opts.legends[pair.name];
				}
			});
		} catch (err) {
			overwritedLegends = {};
		}
		this.setState({ activeLegends: overwritedLegends });
	}

	_loadImage(prod) {
		let imgElement = document.createElement('img');
		const urlLegend = this._url + prod.replace('/', '_') + '.svg';
		let newLegends = Object.assign({}, this.state.allLegends);
		newLegends[prod] = false;
		this.setState({ allLegends: newLegends }, this._updateActiveLegends);
		imgElement.addEventListener('load', e => {
			let newLegends = Object.assign({}, this.state.allLegends);
			newLegends[prod] = urlLegend;
			this.setState({ allLegends: newLegends }, this._updateActiveLegends);
		});
		imgElement.src = urlLegend;
	}

	_getKeysProducts() {
		let p = this.props.products.map(this._productLineToKey);
		p = p.filter(e => (e !== undefined));
		p.reverse();
		return p;
	}

	_initLegendsSrcs() {
		const newMappedList = this._getKeysProducts();
		newMappedList.forEach(e => {
			if (this.state.allLegends[e] === undefined) {
				this._loadImage(e);
			}
		});
	}

	componentDidMount() {
		this._initLegendsSrcs();
	}

	//TODO: read gerDerivedState and reimplement architecture for this part
	UNSAFE_componentWillReceiveProps() {
		this._initLegendsSrcs();
		this._updateActiveLegends();
	}

	handleChangeIndex(s) {
		let newIndex = (this.state.indexLegend + s + this.state.activeLegends.length) % this.state.activeLegends.length;
		this.setState({ indexLegend: newIndex });
	}


	render() {
		if (!this.state.activeLegends.length) {
			return <div></div>;
		}
		const src = this.state.activeLegends[this.state.indexLegend];
		return (
			<div className="legendswrapper">
				{(this.state.activeLegends.length > 1) && <div className="legendsprevbtn"
					onClick={() => this.handleChangeIndex(-1)} aria-label="Previous Legend" ></div>}
				<img className="legendimage" src={src} alt="" />
				{(this.state.activeLegends.length > 1) && <div className="legendsnextbtn"
					onClick={() => this.handleChangeIndex(1)} aria-label="Next Legend" ></div>}
			</div>
		);
	}
}
*/