import {createSvgIcon} from "@mui/material/utils";

export default function RestartIcon(props) {
    const Icon = createSvgIcon(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3567 13.0443C11.147 14.0926 9.59937 14.6686 7.9987 14.6663C4.3167 14.6663 1.33203 11.6817 1.33203 7.99967C1.33203 4.31767 4.3167 1.33301 7.9987 1.33301C11.6807 1.33301 14.6654 4.31767 14.6654 7.99967C14.6654 9.42367 14.2187 10.7437 13.4587 11.8263L11.332 7.99967H13.332C13.3319 6.77048 12.9072 5.57905 12.1298 4.62693C11.3524 3.67482 10.2699 3.02047 9.06559 2.77458C7.86124 2.52869 6.60892 2.70635 5.52048 3.27752C4.43205 3.84868 3.57431 4.77828 3.09238 5.90906C2.61045 7.03984 2.5339 8.30238 2.87569 9.4831C3.21748 10.6638 3.95663 11.6902 4.96809 12.3887C5.97955 13.0872 7.20123 13.4149 8.42646 13.3163C9.6517 13.2177 10.8053 12.6989 11.692 11.8477L12.3567 13.0443Z" fill="currentColor"/>
        </svg>

        , 'Start')
    return <Icon {...props} data-cy="start-icon"/>
}