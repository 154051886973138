let counterCdn = 0;

const arrCdnApi = [
    "//cdn.velocityweather.com/v1/",
    "//cdn1.velocityweather.com/v1/",
    "//cdn2.velocityweather.com/v1/",
    "//cdn3.velocityweather.com/v1/",
    "//cdn4.velocityweather.com/v1/",
    "//cdn5.velocityweather.com/v1/"
];

export function Transformer(requester) {
    return (url, type) => {
        if ((url.indexOf('cdnmaps.velocityweather') > -1) || (url.indexOf('vmaps.velocityweather.com') > -1)) {
            const headers = {
                'X-VW-key': requester.getKey(),
                'X-VW-sig': requester.sign.sig,
                'X-VW-ts': requester.sign.ts
            }
            return { url, type, headers };
        }

        if (url.indexOf('{baron_tile}') > -1) {
            counterCdn++;
            counterCdn %= arrCdnApi.length;
            const currentUrl = url.replace('{baron_tile}', arrCdnApi[counterCdn] + requester.getKey() + "/tms/1.0.0/");
            url = currentUrl + requester.getSignature();
        }
        return { url, type };
    }
}