import {MenuItem} from "@mui/material";
import {useState} from "react";
import * as React from "react";
import UserIcon from "shared/assets/icons/User";
import UserInfoModal from "./UserInfoModal";

export default function AccountMenuItem({currentUser, setCurrentUser}) {

    const [isOpenUserPopUp, setIsOpenUserPopUp] = useState(false);

    const handleOpenUserDetailsModal = event => {
        event.stopPropagation()
        setIsOpenUserPopUp(true)
    }

    const handleCloseUserDetailsModal = event => {
        event.stopPropagation()
        setIsOpenUserPopUp(false)
    }

    return (
        <MenuItem onClick={handleOpenUserDetailsModal}>
            <UserIcon size={'small'}/>
            Account
            <UserInfoModal isOpen={isOpenUserPopUp} onClose={handleCloseUserDetailsModal} currentUser={currentUser} setCurrentUser={setCurrentUser}/>
        </MenuItem>
    )
}