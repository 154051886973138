import React from 'react';
import {Button, Link} from "@mui/material";
import logoTabMenu from "../../shared/assets/png/logo.png";
import "./infoPages.css";

const PrivacyPolicy = () => {
    return (
        <div className={"infoPages_wrapper"}>
            <div className={"infoPages_header"}>
                <Link href={'/signup'}>
                    <img src={logoTabMenu} alt={'logo'}/>
                </Link>
                <Button
                    variant={'text'} color={'primary'}
                    className={"infoPages_button"}
                    href={'/signup'}
                >
                    Sign up
                </Button>
            </div>
            <div className={"infoPages_content"}>
                <h2>Privacy Policy</h2>
                <br/>
                <h4>Introduction</h4>
                <p>
                    Baron Weather, Inc., a Delaware corporation ("Company" or "We") respect your privacy and are
                    committed to protecting it through our compliance with this policy.
                </p>
                <p>
                    This policy describes the types of information we may collect from you or that you may provide when
                    you visit the website <a href={"/alerts"}
                                             target={"_blank"}>WEATHERLOGIC.COM</a> (our "Website") and our practices
                    for collecting, using,
                    maintaining, protecting and disclosing that information.
                </p>
                <p>
                    This policy applies to information we collect:
                </p>
                <p>
                    On this Website.
                </p>
                <p>
                    In e-mail, text and other electronic messages between you and this Website.
                </p>
                <p>
                    It does not apply to information collected by:
                </p>
                <p>
                    us offline or through any other means, including on any other website operated by Company or any
                    third party (including our affiliates and subsidiaries); or
                </p>
                <p>
                    any third party (including our affiliates and subsidiaries), including through any application or
                    content (including advertising) that may link to or be accessible from the Website.
                </p>
                <p>
                    Please read this policy carefully to understand our policies and practices regarding your
                    information and how we will treat it. If you do not agree with our policies and practices, your
                    choice is not to use our Website. By accessing or using this Website, you agree to this privacy
                    policy. This policy may change from time to time. Your continued use of this Website after we make
                    changes is deemed to be acceptance of those changes, so please check the policy periodically for
                    updates.
                </p>
                <br/>
                <h4>Children Under the Age of 13</h4>
                <p>
                    Our Website is not intended for children under 18 years of age. No one under age 18 may provide any
                    information to or on the Website. We do not knowingly collect personal information from children
                    under 18. If you are under 18, do not use or provide any information on this Website or on or
                    through any of its features/register on the Website, make any purchases through the Website, use any
                    of the interactive or public comment features of this Website or provide any information about
                    yourself to us, including your name, address, telephone number, e-mail address or any screen name or
                    user name you may use. If we learn we have collected or received personal information from a child
                    under 18 without verification of parental consent, we will delete that information. If you believe
                    we might have any information from or about a child under 18, please contact us at Baron Weather,
                    Inc., 4930 Research Drive, Huntsville, AL 35805 OR email at <a
                    href="mailto:support@WEATHERLOGIC.COM"> support@WeatherLogic.com</a>.
                </p>
                <br/>
                <h4>Information We Collect About You and How We Collect It</h4>
                <p>
                    We collect several types of information from and about users of our Website, including information:
                </p>
                <p>
                    by which you may be personally identified, such as name, postal address, e-mail address, telephone
                    number or ANY OTHER INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED AS PERSONAL OR PERSONALLY
                    IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW ("personal information");
                </p>
                <p>
                    that is about you but individually does not identify you; and/or
                </p>
                <p>
                    about your internet connection, the equipment you use to access our Website and usage details.
                </p>
                <p>
                    We collect this information:
                </p>
                <p>
                    Directly from you when you provide it to us.
                </p>
                <p>
                    Automatically as you navigate through the site. Information collected automatically may include
                    usage details, IP addresses and information collected through cookies, web beacons, and other
                    tracking technologies.
                </p>
                <p>
                    From third parties, for example, our business partners.
                </p>
                <br/>
                <h4>Information You Provide to Us</h4>
                <p>
                    The information we collect on or through our Website may include:
                </p>
                <p>
                    Information that you provide by filling in forms on our Website. This includes information provided
                    at the time of registering to use our Website, posting material, or requesting further services. We
                    may also ask you for information when you report a problem with our Website.
                </p>
                <p>
                    Records and copies of your correspondence (including e-mail addresses), if you contact us.
                </p>
                <p>
                    Your responses to surveys that we might ask you to complete for research purposes.
                </p>
                <p>
                    Details of transactions you carry out through our Website and of the fulfillment of your orders. You
                    may be required to provide financial information before placing an order through our Website.
                </p>
                <p>
                    Your search queries on the Website.
                </p>
                <p>
                    You also may provide information to be published or displayed (hereinafter, "posted") on public
                    areas of the Website, or transmitted to other users of the Website or third parties (collectively,
                    "User Contributions"). Your User Contributions are posted on and transmitted to others at your own
                    risk.
                </p>
                <br/>
                <h4>Information We Collect Through Automatic Data Collection Technologies</h4>
                <p>
                    As you navigate through and interact with our Website, we may use automatic data collection
                    technologies to collect certain information about your equipment, browsing actions and patterns,
                    including:
                </p>
                <p>
                    Details of your visits to our Website, including traffic data, location data, and other
                    communication data and the resources that you access and use on the Website.
                </p>
                <p>
                    Information about your computer and internet connection, including your IP address, operating system
                    and browser type.
                </p>
                <p>
                    The information we collect automatically may include personal information or we may maintain it or
                    associate it with personal information we collect in other ways or receive from third parties. It
                    helps us to improve our Website and to deliver a better and more personalized service, including by
                    enabling us to:
                </p>
                <p>
                    Estimate our audience size and usage patterns.
                </p>
                <p>
                    Store information about your preferences, allowing us to customize our Website according to your
                    individual interests.
                </p>
                <p>
                    Speed up your searches.
                </p>
                <p>
                    Recognize you when you return to our Website.
                </p>
                <p>
                    The technologies we use for this automatic data collection may include:
                </p>
                <p>
                    <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your
                    computer.
                    You may refuse to accept browser cookies by activating the appropriate setting on your browser.
                    However, if you select this setting you may be unable to access certain parts of our Website. Unless
                    you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies
                    when you direct your browser to our Website.
                </p>
                <p>
                    <b>Flash Cookies.</b> Certain features of our Website may use local stored objects (or Flash
                    cookies) to
                    collect and store information about your preferences and navigation to, from and on our Website.
                    Flash cookies are not managed by the same browser settings as are used for browser cookies.
                </p>
                <p>
                    <b>Web Beacons.</b> Pages of our the Website and our e-mails may contain small electronic files
                    known as
                    web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit the
                    Company, for example, to count users who have visited those pages or opened an e-mail and for other
                    related website statistics (for example, recording the popularity of certain website content and
                    verifying system and server integrity).
                </p>
                <br/>
                <h4>Third-party Use of Cookies and Other Tracking Technologies</h4>
                <p>
                    Some content or applications, including advertisements, on the Website are served by third-parties,
                    including advertisers, ad networks and servers, content providers and application providers. These
                    third parties may use cookies alone or in conjunction with web beacons or other tracking
                    technologies to collect information about you when you use our website. The information they collect
                    may be associated with your personal information or they may collect information, including personal
                    information, about your online activities over time and across different websites and other online
                    services. They may use this information to provide you with interest-based (behavioral) advertising
                    or other targeted content.
                </p>
                <p>
                    We do not control these third parties' tracking technologies or how they may be used. If you have
                    any questions about an advertisement or other targeted content, you should contact the responsible
                    provider directly.
                </p>
                <br/>
                <h4>How We Use Your Information</h4>
                <p>
                    We use information that we collect about you or that you provide to us, including any personal
                    information:
                </p>
                <p>
                    To present our Website and its contents to you.
                </p>
                <p>
                    To provide you with information, products or services that you request from us.
                </p>
                <p>
                    To fulfill any other purpose for which you provide it.
                </p>
                <p>
                    To provide you with notices about your [account/subscription], including expiration and renewal
                    notices.
                </p>
                <p>
                    To carry out our obligations and enforce our rights arising from any contracts entered into between
                    you and us, including for billing and collection.
                </p>
                <p>
                    To notify you about changes to our Website or any products or services we offer or provide though
                    it.
                </p>
                <p>
                    In any other way we may describe when you provide the information.
                </p>
                <p>
                    For any other purpose with your consent.
                </p>
                <p>
                    We may also use your information to contact you about our own and third-parties' goods and services
                    that may be of interest to you. If you do not want us to use your information in this way, please
                    check the relevant box located on the form on which we collect your data (the registration form).
                </p>
                <p>
                    We may use the information we have collected from you to enable us to display advertisements to our
                    advertisers' target audiences. Even though we do not disclose your personal information for these
                    purposes without your consent, if you click on or otherwise interact with an advertisement, the
                    advertiser may assume that you meet its target criteria.
                </p>
                <br/>
                <h4>Disclosure of Your Information</h4>
                <p>
                    We may disclose aggregated information about our users, and information that does not identify any
                    individual, without restriction.
                </p>
                <p>
                    We may disclose personal information that we collect or you provide as described in this privacy
                    policy:
                </p>
                <p>
                    To our subsidiaries and affiliates.
                </p>
                <p>
                    To contractors, service providers and other third parties we use to support our business and who are
                    bound by contractual obligations to keep personal information confidential and use it only for the
                    purposes for which we disclose it to them.
                </p>
                <p>
                    To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                    dissolution or other sale or transfer of some or all of the Company's assets, whether as a going
                    concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information
                    held by the Company about our Website users is among the assets transferred.
                </p>
                <p>
                    To third parties to market their products or services to you if you have not opted out of these
                    disclosures.
                </p>
                <p>
                    To fulfill the purpose for which you provide it.
                </p>
                <p>
                    For any other purpose disclosed by us when you provide the information.
                </p>
                <p>
                    With your consent.
                </p>
                <p>
                    We may also disclose your personal information:
                </p>
                <p>
                    To comply with any court order, law or legal process, including to respond to any government or
                    regulatory request.
                </p>
                <p>
                    To enforce or apply our <a href={"/terms-and-conditions"} target="_blank">terms
                    of use</a> and other agreements, including for billing and collection
                    purposes.
                </p>
                <p>
                    If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                    the Company, our customers or others. This includes exchanging information with other companies and
                    organizations for the purposes of fraud protection and credit risk reduction.
                </p>
                <br/>
                <h4>Choices About How We Use and Disclose Your Information</h4>
                <p>
                    We strive to provide you with choices regarding the personal information you provide to us. We have
                    created mechanisms to provide you with the following control over your information:
                </p>
                <p>
                    <b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all or some browser
                    cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie
                    settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies,
                    please note that some parts of this site may then be inaccessible or not function properly.
                </p>
                <p>
                    <b>Disclosure of Your Information for Third-Party Advertising.</b> If you do not want us to share
                    your
                    personal information with unaffiliated or non-agent third parties for promotional purposes, you can
                    opt-out by checking the relevant box located on the form on which we collect your data (the
                    registration form.
                </p>
                <p>
                    <b>Promotional Offers from the Company.</b> If you do not wish to have your contact information used
                    by the
                    Company to promote our own or third parties' products or services, you can opt-out by checking the
                    relevant box located on the form on which we collect your data (the registration form). If we have
                    sent you a promotional e-mail, you may send us a return e-mail asking to be omitted from future
                    e-mail distributions. This opt out does not apply to information provided to the Company as a result
                    of a product purchase, warranty registration, product service experience or other transactions.
                </p>
                <p>
                    <b>Targeted Advertising.</b> If you do not want us to use information that we collect or that you
                    provide
                    to us to deliver advertisements according to our advertisers' target-audience preferences, you can
                    opt-out by checking the relevant box located on the form on which we collect your data (the
                    registration form). For this opt-out to function, you must have your browser set to accept browser
                    cookies.
                </p>
                <p>
                    We do not control third parties' collection or use of your information to serve interest-based
                    advertising. However these third parties may provide you with ways to choose not to have your
                    information collected or used in this way. You can opt out of receiving targeted ads from members of
                    the Network Advertising Initiative ("NAI") on the NAI's website.
                </p>
                <br/>
                <h4>Accessing and Correcting Your Information</h4>
                <p>
                    You can review and change your personal information by logging into the Website and visiting your
                    account profile page.
                </p>
                <br/>
                <h4>Your California Privacy Rights</h4>
                <p>
                    California Civil Code Section § 1798.83 permits users of our Website that are California residents
                    to request certain information regarding our disclosure of personal information to third parties for
                    their direct marketing purposes. To make such a request, please send an e-mail to
                    <a
                        href="mailto:support@WEATHERLOGIC.COM"> support@weatherlogic.com</a> or write us at: Baron
                    Weather, Inc., 4930 Research Drive, Huntsville, AL
                    35805
                </p>
                <br/>
                <h4>Changes to Our Privacy Policy</h4>
                <p>
                    It is our policy to post any changes we make to our privacy policy on this page with a notice that
                    the privacy policy has been updated on the Website home page. If we make material changes to how we
                    treat our users' personal information, we will notify you through a notice on the Website home page.
                    The date the privacy policy was last revised is identified at the top of the page. You are
                    responsible for ensuring we have an up-to-date active and deliverable e-mail address for you, and
                    for periodically visiting our Website and this privacy policy to check for any changes.
                </p>
                <br/>
                <h4>Contact Information</h4>
                <p>
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at:
                </p>
                <p>
                    Baron Weather, Inc.<br/>
                    Attn: Weather Logic Administrator<br/>
                    4930 Research Drive, Huntsville, AL 35805
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;