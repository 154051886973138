import {Box, Button} from '@mui/material';
import React, {useEffect} from 'react';
import PlusIcon from "shared/assets/icons/Plus";
import TrashIcon from "shared/assets/icons/Trash";
import {Spacer} from "shared/ui/Spacer";
import {WeatherDefinitionLineCreator} from './weatherDefinitionLineCreator/WeatherDefinitionLineCreator';


function createNewWeatherLineObject() {
    return {
        product_name: '',
        relation: '',
        values: [],
        units: '',
        isValid: false,
        union: 'AND'
    }
}

export function WeatherDefinitionGroupCreator({groups, defaultValue, onChange, onRemove }) {

    const createDefaultLineObject = () =>{
        let defaultLine = [createNewWeatherLineObject()];
        onChange(defaultLine);
    }

    const lines = defaultValue || [];

    useEffect(() => {
        if(defaultValue.length < 1){
            createDefaultLineObject()
        }
    }, []);

    function addNew() {
        let newLines = [...lines, createNewWeatherLineObject()];
        onChange(newLines);
    }

    function elemChange(key, newVal) {
        lines[key] = newVal;
        onChange([...lines]);
    }

    function removeLine(key) {
        onChange(lines.filter((l,k)=>(k!==key)));
    }

    let lineObjects = lines.map((line, i)=>{
        return <WeatherDefinitionLineCreator
            key={i}
            defaultValue={line}
            onChange={(val) => elemChange(i, val)}
            onRemove={() => removeLine(i)}
            isFirst={!i}
        />
    });

    return (
        <Box className={'column gap16 fullWidth'} sx={{padding: '24px', borderRadius: '12px'}}>
            {lineObjects}
            <Box className={'row'}>
                <Button
                    className={'paragraph medium'}
                    startIcon={<PlusIcon size={'small'}/>}
                    variant={'text'}
                    color={'primary'}
                    size={'small'}
                    onClick={addNew}
                    disabled={!defaultValue[0]?.isValid || defaultValue[0]?.product_name === ""}
                >
                    New Parameter
                </Button>
                <Spacer/>
                <Button
                    data-cy={'remove-group-button'}
                    className={'paragraph medium'}
                    startIcon={<TrashIcon size={'small'}/>}
                    onClick={onRemove}
                    variant={'text'}
                    color={'error'}
                    size={'small'}
                    disabled={groups.length <= 1}
                >
                    Remove group
                </Button>
            </Box>
        </Box>
    )
}