import {
    Box,
    Button,
    FormControlLabel,
    Typography,
    Snackbar,
    Alert as MuiAlert,
    Autocomplete, Divider
} from '@mui/material';

import {api} from 'shared/libs/Auth';
import {CheckBox} from 'shared/ui/CheckBox';
import TextField from "@mui/material/TextField";
import React, { useState, useMemo, useEffect } from 'react';
import {getTimeZones} from "../../../../shared/libs/TimeZone";
import DefaultNotifications from "./DefaultNotifications"
import './PreferencesSetting.css';

import { DEFAULT_MESSAGES } from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/StepperScreens';

export default function PreferencesSetting() {

    const [measureSystem, setMeasureSystem] = useState('Metric');
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY');
    const [defaultTimezone, setDefaultTimezone] = useState({ value: '', label: '' });
    const [timeFormat, setTimeFormat] = useState('12 hours');

    const [receiveSmsAlerts, setReceiveSmsAlerts] = useState(false);
    const [receiveEmailAlerts, setReceiveEmailAlerts] = useState(false);
    const [enableSoundAlerts, setEnableSoundAlerts] = useState(false);

    const [defaultNotificationMessages, setDefaultNotificationMessages] = useState({
        fc_appears: DEFAULT_MESSAGES.forecastAppears,
        fc_updates: DEFAULT_MESSAGES.forecastUpdates,
        we_starts: DEFAULT_MESSAGES.weatherEventStarted,
        custom_time: DEFAULT_MESSAGES.customTime,
    });
    
    const updateDefaultNotificationMessages = (messageType, newText) => {
        // debugger
        setDefaultNotificationMessages(prevMessages => ({
            ...prevMessages,
            [messageType]: newText,
        }));
    };

    // Load timezones once
    const [timeZones, setTimeZones] = useState([]);

    useEffect(() => {
        setTimeZones(getTimeZones());
    }, []);

    // Using useMemo for optimization calls
    const timeZoneOptions = useMemo(() => timeZones, [timeZones]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'success', 'error', 'warning', 'info'


    useEffect(() => {
        api.get('/user')
            .then(response => {
                const [data] = response.data;

                // setMeasureSystem(data.measureSystem);
                // setDateFormat(data.dateFormat);
                // setTimeFormat(data.timeFormat);
                setReceiveSmsAlerts(data.allow_sms_notifications);
                setReceiveEmailAlerts(data.allow_email_notifications);
                setEnableSoundAlerts(data.notification_sound);

                if (data.default_notification_messages) {
                    const messages = JSON.parse(data.default_notification_messages);
                    setDefaultNotificationMessages(messages);
                }


                const userTimeZone = timeZoneOptions.find(option => option.value === data.timezone);
                if (userTimeZone) {
                    setDefaultTimezone(userTimeZone);
                }
            })
            .catch(error => {
                console.error('Error receiving user data:', error);
            });
    }, [timeZones]);


    const handleTimeFormat = (event, newTimeFormat) => {
        setTimeFormat(newTimeFormat)
    }


    const handleSave = () => {
        // console.log('Measure System:', measureSystem);
        // console.log('Date Format:', dateFormat);
        // console.log('Time Format:', timeFormat);
        // console.log('SMS Alerts:', receiveSmsAlerts);
        // console.log('Email Alerts:', receiveEmailAlerts);
        // console.log('Time Zone:', defaultTimezone.label || 'No time zone selected');

        const settingsData = {
            allow_sms_notifications: receiveSmsAlerts,
            allow_email_notifications: receiveEmailAlerts,
            notification_sound: enableSoundAlerts,
            default_notification_messages: JSON.stringify(defaultNotificationMessages),
            timezone: defaultTimezone.value
        };
        api.patch('/user', settingsData)
            .then(response => {
                setSnackbarMessage('Settings updated successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch(error => {
                setSnackbarMessage('error updating settings');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('error saving settings:', error);
            });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    return (
        <Box className="PreferencesSetting settings">
            <Box className={'settings-toolbar'} style={{borderBottom: "1px solid var(--palette-grey-100)"}}>
                <h3 style={{lineHeight: "45px"}}>
                    Preferences
                </h3>
            </Box>
            <Box className={'column gap16 fullWidth'} style={{padding: '16px 24px'}}>

            <Box className={'preferencesCard'}>
                <Typography className={'subtitle'}>
                    Default formats
                </Typography>
                {/* <FormControl sx={{width:'400px'}}>
                    <InputLabel id="demo-simple-select-label">Measure system</InputLabel>
                    <MuiSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={measureSystem}
                        label="Measure system"
                        onChange={()=> setMeasureSystem(measureSystem)}
                    >
                        <MenuItem value='Metric'>Metric</MenuItem>
                        <MenuItem value='Imperial'>Imperial</MenuItem>

                    </MuiSelect>
                </FormControl> */}


                {/* <FormControl sx={{width:'400px'}}>
                    <InputLabel id="demo-simple-select-label">Date format</InputLabel>
                    <MuiSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dateFormat}
                        label="Date format"
                        onChange={event => setDateFormat(event.target.value)}
                    >
                        <MenuItem value='DD/MM/YYYY'>DD/MM/YYYY</MenuItem>
                        <MenuItem value='MM/DD/YYYY'>MM/DD/YYYY</MenuItem>
                        <MenuItem value='YYYY/MM/DD'>YYYY/MM/DD</MenuItem>
                    </MuiSelect>
                </FormControl> */}

                {/* <InputLabel id="demo-simple-select-label">Time format</InputLabel>
                <ToggleButtonGroup
                    value={timeFormat}
                    exclusive
                    onChange={handleTimeFormat}

                >
                    <ToggleButton sx={{width:'200px', height:'40px'}} value='12 hours'>12 hours</ToggleButton>
                    <ToggleButton sx={{width:'200px', height:'40px'}} value='24 hours'>24 hours</ToggleButton>
                </ToggleButtonGroup> */}

                <Typography variant={'label'} sx={{width: '400px'}}>
                    Default time zone
                    <Autocomplete
                        value={defaultTimezone.label}
                        onChange={(event, value) => setDefaultTimezone({...value})}
                        options={timeZoneOptions}
                        renderInput={params => <TextField {...params}/>}
                    />
                </Typography>
            </Box>
            <Box className={'preferencesCard'}>
                <Typography className={'subtitle'}>
                    Notifications
                </Typography>
                <Box sx={{display: 'grid'}}>
                    <FormControlLabel label='Receive SMS alerts notifications'
                        control={<CheckBox size={'medium'} checked={receiveSmsAlerts} onChange={(e) => setReceiveSmsAlerts(e.target.checked)} />}
                    />
                    <FormControlLabel label='Receive email alerts notifications'
                        control={<CheckBox size={'medium'} checked={receiveEmailAlerts} onChange={(e) => setReceiveEmailAlerts(e.target.checked)} />}
                    />
                    <FormControlLabel label='Enable sound for desktop alert notifications'
                        control={<CheckBox size={'medium'} checked={enableSoundAlerts} onChange={(e) => setEnableSoundAlerts(e.target.checked)} />}
                    />
                </Box>
            </Box>
            <Box className={'preferencesCard'}>
                <Typography className={'subtitle'}>Default notification messages</Typography>
                <Box className={'column fullWidth'}>
                    <DefaultNotifications
                        messageType="fc_appears"
                        title="Forecast appears"
                        message = {defaultNotificationMessages.fc_appears}
                        onChange = {updateDefaultNotificationMessages}
                    />
                    <Divider/>
                    <DefaultNotifications
                        messageType="fc_updates"
                        title="Forecast updates"
                        message = {defaultNotificationMessages.fc_updates}
                        onChange = {updateDefaultNotificationMessages}
                    />
                    <Divider/>
                    <DefaultNotifications
                        messageType="we_starts"
                        title="Weather event starts"
                        message = {defaultNotificationMessages.we_starts}
                        onChange = {updateDefaultNotificationMessages}
                    />
                    <Divider/>
                    <DefaultNotifications
                        messageType="custom_time"
                        title="Custom time"
                        message = {defaultNotificationMessages.custom_time}
                        onChange = {updateDefaultNotificationMessages}
                    />
                </Box>
            </Box>
            </Box>

            <Button 
                variant={'contained'}
                color={'primary'}
                sx={{margin: '16px auto 16px 24px'}}
                onClick={handleSave}>
                Save preferences
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    )
}
