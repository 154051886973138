import React from 'react';
import {Box, FormControlLabel, FormGroup, Switch} from '@mui/material';
import InfoPanel from "../../../../../shared/ui/InfoPanel";


function ReportingStepScreen({isIncludeReport, includeReportOnChange}) {

    return (
        <Box sx={{
            width: 1,
            gap: '16px',
            boxSizing: 'border-box',
            borderRadius: '16xp',
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            overflowY: "auto",
            paddingTop: "2px"
        }}>
            <Box sx={{
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '16px',
                gap: '12px',
                border: '1px solid #f0f2f5',
                borderRadius: '12px'
            }}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    padding: "5px 0",
                    border: '1px solid #f0f2f5',
                    borderRadius: '12px'
                }}>
                    <FormGroup>
                        <FormControlLabel label="Include a detailed report with notification"
                                          control={<Switch onChange={includeReportOnChange}
                                                           checked={isIncludeReport}/>}/>
                    </FormGroup>
                </Box>
                <InfoPanel title={"Report set up"}
                           text={"These report settings are global for the selected locations and weather definitions. Each notification for this alert will include a report link."}/>
            </Box>
        </Box>
    );
}

export default ReportingStepScreen;