import {createSvgIcon} from "@mui/material/utils";

export default function ListIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.85124 10.6164C4.08562 10.6164 3.46759 11.2344 3.46759 12C3.46759 12.7657 4.08562 13.3837 4.85124 13.3837C5.61686 13.3837 6.23489 12.7657 6.23489 12C6.23489 11.2344 5.61686 10.6164 4.85124 10.6164ZM4.85124 5.08179C4.08562 5.08179 3.46759 5.69982 3.46759 6.46544C3.46759 7.23106 4.08562 7.84909 4.85124 7.84909C5.61686 7.84909 6.23489 7.23106 6.23489 6.46544C6.23489 5.69982 5.61686 5.08179 4.85124 5.08179ZM4.85124 16.151C4.08562 16.151 3.46759 16.7783 3.46759 17.5347C3.46759 18.291 4.09485 18.9183 4.85124 18.9183C5.60764 18.9183 6.23489 18.291 6.23489 17.5347C6.23489 16.7783 5.61686 16.151 4.85124 16.151ZM7.61855 18.4571H20.5326V16.6122H7.61855V18.4571ZM7.61855 12.9225H20.5326V11.0776H7.61855V12.9225ZM7.61855 5.543V7.38787H20.5326V5.543H7.61855Z"
                fill="currentColor"/>
        </svg>, 'List')
    return <Icon {...props} data-cy="list-icon"/>
}