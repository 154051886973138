import {createSvgIcon} from "@mui/material/utils";

export default function DashboardIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.50011 2C3.84326 2 2.50012 3.34314 2.50012 4.99999V10.2501C2.50012 10.8024 2.94784 11.2501 3.50012 11.2501H10.5001C11.0524 11.2501 11.5001 10.8024 11.5001 10.2501V3C11.5001 2.44771 11.0524 2 10.5001 2H5.50011ZM4.50011 4.99999C4.50011 4.44771 4.94783 3.99999 5.50011 3.99999H9.50009V9.25009H4.50011V4.99999Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.50012 13.0001C2.94784 13.0001 2.50012 13.4478 2.50012 14.0001V18.9999C2.50012 20.6568 3.84326 21.9999 5.50011 21.9999H10.5001C11.0524 21.9999 11.5001 21.5522 11.5001 20.9999V14.0001C11.5001 13.4478 11.0524 13.0001 10.5001 13.0001H3.50012ZM4.50011 18.9999V15.0001H9.50009V19.9999H5.50011C4.94783 19.9999 4.50011 19.5522 4.50011 18.9999Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.5001 14.0001C13.5001 13.4478 13.9478 13.0001 14.5001 13.0001H21.5C22.0523 13.0001 22.5 13.4478 22.5 14.0001V18.9999C22.5 20.6568 21.1569 21.9999 19.5001 21.9999H14.5001C13.9478 21.9999 13.5001 21.5522 13.5001 20.9999V14.0001ZM15.5001 15.0001V19.9999H19.5001C20.0523 19.9999 20.5 19.5522 20.5 18.9999V15.0001H15.5001Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.5001 2C13.9478 2 13.5001 2.44771 13.5001 3V10.2501C13.5001 10.8024 13.9478 11.2501 14.5001 11.2501H21.5C22.0523 11.2501 22.5 10.8024 22.5 10.2501V4.99999C22.5 3.34314 21.1569 2 19.5001 2H14.5001ZM15.5001 9.25009V3.99999H19.5001C20.0523 3.99999 20.5 4.44771 20.5 4.99999V9.25009H15.5001Z"
                  fill="currentColor"/>
        </svg>, 'Dashboard')
    return <Icon {...props} data-cy="dashboard-icon"/>
}