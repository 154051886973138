import {createSvgIcon} from "@mui/material/utils";


export default function CheckCircleCheckedIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.71 10.71L11.71 15.71C11.51 15.9 11.26 16 11 16C10.74 16 10.49 15.9 10.29 15.71L7.29001 12.71C6.90001 12.32 6.90001 11.68 7.29001 11.29C7.68001 10.9 8.31999 10.9 8.70999 11.29L11 13.5901L15.29 9.29004C15.68 8.90004 16.32 8.90004 16.71 9.29004C17.1 9.68004 17.1 10.32 16.71 10.71Z"
                fill="currentColor"/>
        </svg>
        , 'CheckCircleChecked')
    return <Icon {...props} data-cy="check-circle-icon"/>
}