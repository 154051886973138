import {createSvgIcon} from "@mui/material/utils";

export default function MapMarkerIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0005 5.99951C9.79449 5.99951 8.00049 7.79351 8.00049 9.99951C8.00049 12.2055 9.79449 13.9995 12.0005 13.9995C14.2065 13.9995 16.0005 12.2055 16.0005 9.99951C16.0005 7.79351 14.2065 5.99951 12.0005 5.99951ZM12.0005 11.9995C10.8975 11.9995 10.0005 11.1025 10.0005 9.99951C10.0005 8.89651 10.8975 7.99951 12.0005 7.99951C13.1035 7.99951 14.0005 8.89651 14.0005 9.99951C14.0005 11.1025 13.1035 11.9995 12.0005 11.9995ZM11.9965 1.99951C7.43749 1.99951 4.00049 5.43851 4.00049 9.99951C4.00049 16.3255 8.81248 20.2595 10.8815 21.6565C11.2195 21.8855 11.6085 22.0005 11.9965 22.0005C12.3835 22.0005 12.7705 21.8864 13.1085 21.6584C15.1805 20.2594 19.9995 16.3235 19.9995 9.99951C20.0005 5.43851 16.5595 1.99951 11.9965 1.99951ZM12.0015 19.9995C10.1975 18.7805 6.00049 15.3665 6.00049 9.99951C6.00049 6.52251 8.52149 3.99951 11.9965 3.99951C15.4195 3.99951 18.0005 6.57851 18.0005 9.99951C18.0005 15.3635 13.7975 18.7805 12.0015 19.9995Z"
                fill="currentColor"/>
        </svg>, 'MapMarker')
    return <Icon {...props} data-cy="map-marker-icon"/>
}