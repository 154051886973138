import {Button, Link, Menu, MenuItem} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import theme from 'app/Theme';
import {api, logoutUser} from 'shared/libs/Auth';
import LogoutIcon from "shared/assets/icons/Logout";
import MoreIcon from "shared/assets/icons/More";
import SettingsIcon from "shared/assets/icons/Settings";
import {Box} from "@mui/system";
import AccountMenuItem from "widgets/mainMenu/ui/AccountMenuItem";


const AccountButton = props => {
    return (
        <Button
            {...props}
            size={'small'}
            endIcon={<MoreIcon sx={{pointerEvents: 'none'}}/>}
            disableRipple
            sx={{
                ':hover': {
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.grey[500],
                    },
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderColor: 'transparent',
                    color: theme.palette.grey[700],
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.grey[500],
                },
                backgroundColor: 'transparent',
                width: 'max-content',
                color: theme.palette.grey[700],
            }}
        />
    )
}

export default function MainMenu() {

    // const userData = useContext(UserContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        api.get('/user').then(response => {
            const [data] = response.data;
            setCurrentUser({
                userName: (() => {
                    if (data.username.match(/\s\w*/)[0] === ' ') return data.username.replace(/\s/g, '');
                    else return data.username;
                })(),
                email: data.email,
                phone: data.phone,
                status: 'Active subscription',
                allow_sms_notifications: data.allow_sms_notifications,
                allow_email_notifications: data.allow_email_notifications,
            });
        });
    }, [])

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        logoutUser()
        handleClose()
    }
    return (
        <Box>
            <AccountButton
                data-cy={'account-menu-button'}
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls={open ? 'account-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
            >
                {currentUser?.userName}
            </AccountButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                sx={{'& .MuiPaper-root': {width: 186}}}
            >
                <AccountMenuItem currentUser={currentUser} setCurrentUser={setCurrentUser}/>
                <MenuItem
                    component={Link}
                    href={'/settings#weather-definitions'}
                    underline={'none'}
                >
                    <SettingsIcon size={'small'}/>
                    Settings
                </MenuItem>
                <MenuItem onClick={handleLogout} color={'error'}>
                    <LogoutIcon size={'small'}/>
                    Log out
                </MenuItem>
            </Menu>
        </Box>
    );
}