import {
    Box,
    InputAdornment,
    Chip,
    Divider
} from '@mui/material';
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from 'react';
import CheckIcon from "shared/assets/icons/Check";
import theme from "app/Theme";
import SearchIcon from "../../shared/assets/icons/Search";
import {CheckBox} from "shared/ui/CheckBox";
import WeatherDefinitionLineCheckbox from './WeatherDefinitionLineCheckbox';
import {fetchWeatherDefinitions, fetchRealLocations, fetchAlertConfigurations, fetchWeatherIcons, fetchConfigurationGroups} from "../../pages/alerts/api/FetchAlerts";


export default function WeatherDefinitionSelect({onChange, selectedDefinitions, doAction}) {
    const [definitions, setDefinitions] = useState([]);
    const [icons, setIcons] = useState([]);
    const [locations, setLocations] = useState([]);
    const [alertConfigs, setAlertConfigs] = useState([]);
    const [configGroups, setConfigGroups] = useState([]);
    const [severityFilter, setSeverityFilter] = useState({Critical: true, Severe: true, Moderate: true});
    const [searchFilter, setSearchFilter] = useState('');


    useEffect(() => {
        fetchWeatherIcons().then((data) => {setIcons(data)});
        fetchWeatherDefinitions().then((data) => {
            const definitions = data.sort((a, b) => a.name.localeCompare(b.name));
            setDefinitions(definitions)
        });
        fetchRealLocations().then((data) => {setLocations(data.location)});
        fetchAlertConfigurations().then((data) => {setAlertConfigs(data)});
        fetchConfigurationGroups().then((data) => {setConfigGroups(data)});
    }, []);

    useEffect(() => {
        if (!definitions || !icons) return;
        const icons_obj = {};
        icons.forEach(icon => {
            icons_obj[icon.id] = icon;
        });
        const definitions_obj = {};
        definitions.forEach(definition => {
            definition.icon_url = icons_obj[definition.icon]
            definitions_obj[definition.id] = definition;
            definition.configurations = {};
        });
        const locations_obj = {};
        locations.forEach(location => {
            locations_obj[location.id] = location;
        });
        const configs_obj = {};
        alertConfigs.forEach(config => {
            configs_obj[config.id] = config;
        });
        configGroups.forEach(relation => {
            const config = configs_obj[relation[1]];
            if (!config) return;
            const definition = definitions_obj[config.weather_definition_id];
            const location = locations_obj[config.location_id];
            if (!definition || !location) return;
            if (!definition.configurations[relation[0]]) definition.configurations[relation[0]] = [];
            definition.configurations[relation[0]].push(location);
        });
    }, [definitions, icons, locations, alertConfigs, configGroups]);

    const allSelected = () => {
        if (!definitions.length) return false;
        let selected = true;
        definitions.forEach((def) => {
            if (!selectedDefinitions[def.id]) selected = false;
        });
        return selected;
    }
    const allIndeterminate = () => {
        if (!definitions.length) return false;
        const state0 = !selectedDefinitions[definitions[0].id];
        let indet = false;
        definitions.forEach((def) => {
            if (!selectedDefinitions[def.id] !== state0) indet = true;
        });
        return indet;
    }
    const selectAll = () => {
        let state = !allSelected();
        let selected = {};
        definitions.forEach((def) => {
            selected[def.id] = state;
        });
        onChange(selected);
    }
    const selectOne = (def) => {
        const sel = {};
        sel[def.id] = !selectedDefinitions[def.id];
        onChange(sel);
    }
    const changeSeverityFilter = (severity) => {
        const newFilter = {...severityFilter};
        newFilter[severity] = !newFilter[severity];
        setSeverityFilter(newFilter);
    }
    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    return (
        <Box className={'column gap0 fullWidth'}>
            <TextField
                fullWidth
                search={'true'}
                type={'search'}
                onChange={({target}) => setSearchFilter(target.value)}
                placeholder={'Search for weather definition'}
                InputProps={{
                    startAdornment:
                        <InputAdornment position={'start'}>
                            <SearchIcon size={'small'}/>
                        </InputAdornment>
                }}
            />
            <Box className={'row gap8 fullWidth'} sx={{marginTop: '12px'}}>
                <Chip
                    size={'small'}
                    icon={severityFilter['Critical'] && <CheckIcon size={'small'}/>}
                    label={'Critical'}
                    variant={severityFilter['Critical']
                        ? theme.palette.weather.variant.Critical
                        : "grey"
                    }
                    onClick={() => {
                        changeSeverityFilter('Critical')
                    }}
                />
                <Chip
                    size={'small'}
                    icon={severityFilter['Severe'] && <CheckIcon size={'small'}/>}
                    label={'Severe'}
                    variant={severityFilter['Severe']
                        ? theme.palette.weather.variant.Severe
                        : "default"
                }
                    onClick={() => {
                        changeSeverityFilter('Severe')
                    }}
                />
                <Chip
                    size={'small'}
                    icon={severityFilter['Moderate'] && <CheckIcon size={'small'}/>}
                    label={'Moderate'}
                    variant={severityFilter['Moderate']
                        ? theme.palette.weather.variant.Moderate
                        : "default"
                }
                    onClick={() => {
                        changeSeverityFilter('Moderate')
                    }}
                />
            </Box>
            <Box className={'row gap4'} sx={{padding: '10px 12px', marginTop: '16px'}}>
                <CheckBox checked={allSelected()} indeterminate={allIndeterminate()} onClick={(ev) => selectAll(ev)}/>
                <Box className={"paragraph regular"}>
                    All weather definitions ({definitions.length})
                </Box>
            </Box>
            <Divider/>
            <Box className={'spacer'} sx={{overflow: 'auto'}}>
                <Box
                    className={'column gap8 fullWidth'}
                    sx={{
                        marginTop: '12px',
                    }}
                >
                    {definitions.map(wd => (
                        severityFilter[wd.severity] && filterName(wd.name) &&
                        <WeatherDefinitionLineCheckbox definition={wd} selectedDefinition={selectedDefinitions[wd.id]}
                                                       onChange={selectOne} doAction={doAction}/>
                    ))}
                </Box>
            </Box>
        </Box>
    )
} 
