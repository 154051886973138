import {createSvgIcon} from "@mui/material/utils";


export default function MinusSquareIcon(props) {
    const Icon = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <rect width="16" height="16" x="4" y="4" fill="currentColor" rx="3.5"/>
            <path stroke="#fff" strokeLinecap="round" d="M16.407 12H7.593" strokeWidth="1.34064"/>
        </svg>, 'MinusSquare')
    return <Icon {...props} data-cy="minus-square-icon"/>
}