import {createSvgIcon} from "@mui/material/utils";


export default function DragIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 9C20 7.89543 19.1046 7 18 7C16.8954 7 16 7.89543 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9Z"
                fill="currentColor"/>
            <path
                d="M20 15C20 13.8954 19.1046 13 18 13C16.8954 13 16 13.8954 16 15C16 16.1046 16.8954 17 18 17C19.1046 17 20 16.1046 20 15Z"
                fill="currentColor"/>
            <path
                d="M12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7Z"
                fill="currentColor"/>
            <path
                d="M14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17C13.1046 17 14 16.1046 14 15Z"
                fill="currentColor"/>
            <path
                d="M6 7C7.10457 7 8 7.89543 8 9C8 10.1046 7.10457 11 6 11C4.89543 11 4 10.1046 4 9C4 7.89543 4.89543 7 6 7Z"
                fill="currentColor"/>
            <path
                d="M8 15C8 13.8954 7.10457 13 6 13C4.89543 13 4 13.8954 4 15C4 16.1046 4.89543 17 6 17C7.10457 17 8 16.1046 8 15Z"
                fill="currentColor"/>
        </svg>
        , 'Drag')
    return <Icon {...props} data-cy="drag-icon"/>
}