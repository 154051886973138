import {Box, IconButton, Button, Popover, Chip, MenuItem, Link} from '@mui/material';
import MoreIcon from "shared/assets/icons/More";
import React, {useState, useEffect} from 'react';
import {DefinitionRenderLarge} from '../../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge';
import CopyIcon from '../../../shared/assets/icons/Copy';
import PenIcon from '../../../shared/assets/icons/Pen';
import MapPinIcon from '../../../shared/assets/icons/MapPin';
import BellIcon from '../../../shared/assets/icons/Bell';
import TrashIcon from '../../../shared/assets/icons/Trash';
import NewWeatherDefinitionPage from '../../../widgets/weatherDefinition/NewWeatherDefinitionPage';
import theme from '../../../app/Theme';
import {
    deleteWeatherDefinition,
    fetchWeatherDefinition,
    deleteConfigurationGroup
} from "../../../pages/alerts/api/FetchAlerts";
import ManageAlertConfigurationPanel
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {Spacer} from "shared/ui/Spacer";
import AgreeModal from "shared/ui/AgreeModal";
import List from "@mui/material/List";


export function WeatherDefinitionDetails({
                                             definition_id,
                                             onChange,
                                             doAction,
                                             editable = false,
                                             disableAddAlertButton = false,
                                             onDeleteFromAlert,
                                             setShowSidebar = () => {}, //for menu in alert stepper
                                             setHideSettingsMenu = () => {}, //for menu of settings
                                         }) {

    const [showMore, setShowMore] = useState(false);
    const [editDefinition, setEditDefinition] = useState(false);
    const [deleteDefinition, setDeleteDefinition] = useState(false);
    const [deleteConfig, setDeleteConfig] = useState(false);
    const [editAlertConfig, setEditAlertConfig] = useState(null);
    const [definition, setDefinition] = useState(null);

    useEffect(() => {
        fetchWeatherDefinition(definition_id).then((data) => {
            data.groups = data.rules;
            setDefinition(data)
        });
    }, []);

    useEffect(() => {
        fetchWeatherDefinition(definition_id).then((data) => {
            data.groups = data.rules;
            setDefinition(data)
        });
    }, [editDefinition, editAlertConfig]);

    if (!definition) return (<></>)

    const localDoAction = (action, value) => {
        if (action === 'edit_configuration') {
            setEditAlertConfig(value)
        } else if (action === 'delete') {
            deleteWeatherDefinition(definition.id).then(onChange);
            onDeleteFromAlert();
        }
    }

    const doCopyDefinition = () => {
        const copyDef = JSON.parse(JSON.stringify(definition));
        copyDef.id = -1;
        copyDef.name += ' Copy';
        setEditDefinition(copyDef);
        setShowSidebar(false);
        setHideSettingsMenu(true);
    }

    const doEditDefinition = () => {
        const editDef = JSON.parse(JSON.stringify(definition));
        setEditDefinition(editDef);
        setShowSidebar(false);
        setHideSettingsMenu(true);
    }

    const doDeleteDefinition = (agreed) => {
        if (agreed) {
            if (!doAction) {
                localDoAction('delete', definition);
            } else {
                onChange();
                doAction(definition, 'delete');
                return;
            }
        }
        setDeleteDefinition(false);
    }

    const addNewConfiguration = () => {
        doAction(definition.id, 'edit_configuration', null);
        // doAction('edit_configuration', {back: true, locations: {}, definition: definition.id}, null);
    }

    const copyConfiguration = (id) => {
        const locations = {};
        definition.configurations[id].forEach(loc => {
            locations[loc.id] = true
        });
        doAction('edit_configuration', {back: true, locations: locations, definition: definition.id})
        setShowMore(false);
    }

    const editConfiguration = (id) => {
        const locations = {};
        definition.configurations[id].forEach(loc => {
            locations[loc.id] = true
        });
        doAction({back: true, locations: locations, definition: definition.id, id: id}, 'edit_configuration')
        setShowMore(false);
    }

    const deleteConfiguration = (id) => {
        setDeleteConfig(id);
        setShowMore(false);
    }

    const doDeleteConfiguration = (agreed) => {
        if (agreed) {
            deleteConfigurationGroup(deleteConfig);
            delete definition.configurations[deleteConfig];
        }
        setDeleteConfig(false);
        setShowMore(false);
    }

    const configurations = [];
    for (let key in definition.configurations)
        configurations.push(
            <Box style={{
                padding: '16px',
                background: '#fff',
                lineHeight: '28px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <Box>
                    {definition.configurations[key].map(location => (<>
                                    <span style={{
                                        fontSize: "14px",
                                        color: theme.palette.grey[700],
                                        backgroundColor: theme.palette.grey[100],
                                        borderRadius: '8px',
                                        padding: '4px 10px',
                                        marginRight: '10px'
                                    }}>
                                            <span style={{whiteSpace: 'nowrap'}}><MapPinIcon sx={{
                                                verticalAlign: 'middle',
                                                paddingBottom: '2px',
                                                width: '16px',
                                                marginRight: '5px'
                                            }}/>{location.label}</span>
                                    </span>{' '}</>
                    ))}
                </Box>
                <Box sx={{'marginLeft': 'auto'}}>
                    <IconButton onClick={({currentTarget}) => {
                        setShowMore({target: currentTarget, key: key})
                    }} sx={{padding: '7px', background: '#fff', border: 'solid 1px #F0F2F5'}}>
                        <MoreIcon size={'small'}/>
                    </IconButton>
                </Box>
            </Box>
        )

    return (
        <Box className={'fullHeight fullWidth'}>
            {!editDefinition && !editAlertConfig &&
                <Box className="WeatherDefinitionPanel fullWidth" sx={{overflow: "unset"}}>
                    <Box className={'toolbar padding'}
                         style={{borderBottom: "1px solid var(--palette-grey-100)", borderRadius:"16px 0px 0px 0px"}}>
                        <IconButton size={'small'} variant={'outlined'} onClick={onChange}>
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {definition.name}
                        </h3>
                        <Chip
                            variant={theme.palette.severity.variant[definition.severity]}
                            size={'small'}
                            label={definition.severity}
                        />
                        <Spacer/>
                        {editable && !disableAddAlertButton &&
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={addNewConfiguration}
                                startIcon={<BellIcon/>}
                            >
                                Add alert
                            </Button>
                        }
                        {editable && definition.rules &&
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={doCopyDefinition}
                                startIcon={<CopyIcon/>}
                            >
                                Copy
                            </Button>
                        }
                        {editable && definition.rules && definition.user && <>
                            <Button
                                variant={'outlined'}
                                onClick={doEditDefinition}
                                startIcon={<PenIcon/>}
                            >
                                Edit
                            </Button>
                            <Button
                                variant={'outlined'}
                                color={'error'}
                                startIcon={<TrashIcon/>}
                                onClick={() => {
                                    setDeleteDefinition(true)
                                }}
                            >
                                Delete
                            </Button>
                        </>}
                        {!editable &&
                            <Button
                                variant={'outlined'}
                                href={`/settings?weather_definition_id=${definition_id}#weather-definitions`}
                            >
                                Details
                            </Button>
                        }
                    </Box>
                    <Box style={{display:"flex", flexDirection:"column", gap:"16px", margin:"24px"}}>
                        <Box sx={{padding: '16px', borderRadius: '10px', border: '1px solid #f0f2f5'}}>
                            <Box className="paragraph medium" sx={{color: theme.palette.grey[600]}}>PARAMETERS</Box>
                            {definition.rules && <DefinitionRenderLarge definition={definition}/>}
                            {!definition.rules && <Box>{definition.description || "This is a fixed weather definition"}</Box>}
                        </Box>
                        {editable &&
                            <Box sx={{
                                display:"flex", flexDirection:"column",
                                padding: '16px', gap:"8px",
                                borderRadius: '10px',
                                border: '1px solid #f0f2f5',
                                background: theme.palette.grey.background
                            }}>
                                <Box className="paragraph medium" sx={{color: theme.palette.grey[600]}}>ALERT
                                    CONFIGURATIONS</Box>
                                {configurations}
                                {showMore &&
                                    <Popover
                                        open={true}
                                        anchorEl={showMore.target}
                                        onClose={() => {
                                            setShowMore(false)
                                        }}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                        transformOrigin={{vertical: 'top', horizontal: 'right',}}
                                    >
                                        <List>
                                            <MenuItem onClick={() => copyConfiguration(showMore.key)}>
                                                <CopyIcon size={'small'}/>
                                                Copy
                                            </MenuItem>
                                            <MenuItem onClick={() => editConfiguration(showMore.key)}>
                                                <PenIcon size={'small'}/>
                                                Edit
                                            </MenuItem>
                                            <MenuItem color={'error'} onClick={() => deleteConfiguration(showMore.key)}>
                                                <TrashIcon size={'small'}/>
                                                Delete
                                            </MenuItem>
                                        </List>
                                    </Popover>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {editDefinition &&
                <NewWeatherDefinitionPage sx={{width: '100%'}} defaultValue={editDefinition} onClose={() => {
                    setEditDefinition(false);
                    setShowSidebar(true)
                    setHideSettingsMenu(false)
                }}/>
            }
            {deleteDefinition &&
                <AgreeModal
                    data={{
                        message: <>Are you sure you want delete <span style={{
                            fontWeight: "500",
                            color: "var(--palette-grey-900)"
                        }}>{definition.name}</span> weather defintion and <span style={{
                            fontWeight: "500",
                            color: "var(--palette-grey-900)"
                        }}>all alert configurations</span> linked to this definition?</>,
                        title: "Delete weather definition",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: doDeleteDefinition,
                    }}
                />
            }
            {deleteConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want delete "${definition.name}" alert configuration?`,
                        title: "Delete alert configuration",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: doDeleteConfiguration,
                    }}
                />
            }
            {editAlertConfig &&
                <ManageAlertConfigurationPanel args={editAlertConfig} onClose={() => setEditAlertConfig(false)}/>
            }
        </Box>
    )
}

// export function DeleteModal({deleteFunc, title, message, width}) {
//     const content = (
//                     <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: width}}>
//                         <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center'}}>
//                             <Box className='modal-header' sx={{display: 'flex'}}>{title}</Box>
//                             <IconButton onClick={() => {deleteFunc(false)}} sx={{marginLeft: 'auto', padding: '4px'}}>
//                                 <Close sx={{width: '16px', height: '16px'}}/>
//                             </IconButton>
//                         </Box>
//                         <Box className='modal-delete-text' sx={{width: '100%'}}>{message}</Box>
//                         <Box className='modal-buttons' sx={{display: 'flex'}}>
//                             <Button variant={'secondary'} sx={{display: 'flex', marginLeft: '0px'}} className='timeline-shadow timeline-cancel' onClick={()=>{deleteFunc(false)}}>Go back</Button>
//                             <Button sx={{display: 'flex'}} className='timeline-delete'
//                                 onClick={()=>{deleteFunc(true)}}><TrashIcon style={{width: "20px", marginRight: '10px', filter: "brightness(70%)"}}/>Yes, delete</Button>
//                         </Box>
//                     </Box>
//     );
//     return (
//         <Dialog className='timeline-modal' open={true} onClose={() => {deleteFunc(false)}}>
//             {content}
//         </Dialog>
//     );
// }
