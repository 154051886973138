import React, {useEffect, useState} from 'react';

const ContactStatus = ({status}) => {

    const [fontColor, setFontColor] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");

    useEffect(() => {
        switch (status.replace(" ", "_")) {
            case "No_subscription":
                setFontColor("var(--palette-grey-500)");
                setBackgroundColor("var(--palette-grey-100)");
                break;
            case "Admin":
                setFontColor("var(--palette-info-dark)");
                setBackgroundColor("var(--palette-info-light)");
                break;
            case "Invite_sent":
                setFontColor("var(--palette-warning-dark)");
                setBackgroundColor("var(--palette-warning-light)");
                break;
            case "Active_subscription":
                setFontColor("var(--palette-success-dark)");
                setBackgroundColor("var(--palette-success-light)");
                break;
            case "Subscription_expired":
                setFontColor("var(--palette-error-dark)");
                setBackgroundColor("var(--palette-error-light)");
                break;
            default:
                setFontColor("var(--palette-grey-500)");
                setBackgroundColor("var(--palette-grey-100)");
        }
    }, []);


    return (
        <div style={{
            height: "20px",
            borderRadius: "8px",
            padding: "0 12px",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
            backgroundColor: backgroundColor,
            color: fontColor
        }}>
            {status}
        </div>
    );
};

export default ContactStatus;