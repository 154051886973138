import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import ModalComponent from "./ModalComponent";

const ProgressModal = ({visible, title = "Please wait while the operation completes..."}) => {
    return (
        <ModalComponent visible={visible}>
            <div style={{width: "520px", height: "150px", display:"flex", flexDirection:"column", gap:"20px"}}>
                <div className='modal-header'>{title}</div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                    <CircularProgress />
                </div>
            </div>
        </ModalComponent>
    );
};

export default ProgressModal;