import {Button, Link} from "@mui/material";
import logoTabMenu from "../../shared/assets/png/logo.png";
import "./infoPages.css"

export default function EmailConfirmed() {
    return (
        <div className={"infoPages_wrapper"}>
            <div className={"infoPages_header"}>
                <Link href={'/login'}>
                    <img src={logoTabMenu} alt={'logo'}/>
                </Link>
                <Button
                    variant={'text'} color={'primary'}
                    className={"infoPages_button"}
                    href={'/signup'}
                >
                    Sign up
                </Button>
            </div>
            <div className={"infoPages_content"}>
                <h2>Email confirmation</h2>
                Email is confirmed. Thank you. Wait for approval.
            </div>
        </div>
    )
}