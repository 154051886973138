import React from 'react';
import {Box, Button} from "@mui/material";
import MapPinIcon from "../../../../shared/assets/icons/MapPin";
import {Spacer} from "../../../../shared/ui/Spacer";
import PenIcon from "../../../../shared/assets/icons/Pen";
import TrashIcon from "../../../../shared/assets/icons/Trash";

const LastCreatedLocation = ({location, onEdit, onDelete}) => {
    return (
        <Box style={{display:"flex", flexDirection:"row", padding:"4px 8px",
            height:"48px", gap:"4px", background: "white", borderRadius:"8px", border: "1px solid var(--palette-grey-100)"}}>
            <Box style={{display:"flex", flexDirection:"row", alignItems:"center", color: "var(--palette-grey-900)", gap:"8px"}}>
                <MapPinIcon/>
                <Box style={{fontWeight:"500", fontSize:"14px"}}>{location.label}</Box>
            </Box>
            <Spacer/>
            <Box style={{display:"flex", flexDirection:"row", gap:"8px", alignItems:"center"}}>
                <Box style={{marginRight:"8px", fontWeight:"400", fontSize:"14px", color: "var(--palette-grey-500)"}}>
                    {location.location_group}
                </Box>
                <Button
                    data-cy={'edit-button'}
                    variant={'outlined'}
                    color={'primary'}
                    size={'small'}
                    startIcon={<PenIcon size={'small'}/>}
                    onClick={onEdit}
                >
                    Edit
                </Button>
                <Button
                    color={'error'}
                    size={'small'}
                    startIcon={<TrashIcon size={'small'}/>}
                    onClick={onDelete}
                >
                    Remove
                </Button>
            </Box>
        </Box>
    );
};

export default LastCreatedLocation;