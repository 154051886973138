// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl' // ! is important here
import {Box, Typography, Zoom} from '@mui/material'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'pages/map/ui/css/map.css'
import 'pages/map/ui/css/conds.css'
import 'pages/map/ui/css/style.css'
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker'
import {Transformer} from 'pages/map/model/utils/urltransformer'
import {Legends} from 'pages/map/ui/legends/legends'
import MapMenu from 'pages/map/ui/MapMenu'
import {MapSidebar} from 'pages/map/ui/MapSidebar'
import {TimeLineMap} from 'pages/map/ui/timeline/timeline'
import React, {useEffect, useRef, useState} from 'react'
import requester from 'shared/libs/requester/baron-signature.js'
import {Signature} from 'shared/libs/requester3/baron-signature'
import AgreeModal from '../../../shared/ui/AgreeModal'
import SaveViewModal from '../../../shared/ui/SaveViewModal'
import {
    createDefaultMapView,
    createMapView,
    deleteMapView,
    editMapView,
    fetchDefaultMapView,
    fetchMapViews,
} from '../../alerts/api/FetchAlerts'
import {ProductsController, TEXT_PRODUCTS_CODES} from '../model/Tile/ProductsController'
import {
    DistanceTool,
    PinPointTool,
    PointQueryTool,
    SwitchModeTool
} from "features/mapTools"

const signature = new Signature()

maplibregl.workerClass = maplibreglWorker

const NON_STANDARD_PROJECTION = {
    'north-american-radar': 'Mask1-Mercator',
}
const NEXRAD_GROUP = [-1000, -1001, -1002, -1003, -1004, -1005]; //TODO: move out


export const MapLibre = ({
                             initialState = undefined, locations, products, setOpenAddLocations = () => {
    }
                         }) => {
    const currentDateInMs = new Date().getTime()

    const [stateDate, setStateDate] = useState({
        startDate: currentDateInMs - 3600000,
        endDate: currentDateInMs,
    })

    const productsController = useRef();
    const [locationsLayers, setLocationLayers] = useState({});
    const [activeProducts, setActiveProducts] = useState([]);
    const [activeLocations, setActiveLocations] = useState([]);
    const [locationHash, setLocationHash] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState(currentDateInMs);
    const mapLibreElem = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [clearPQ, setClearPQ] = useState(false);
    const [views, setViews] = useState([]);
    const [saveViewModal, setSaveViewModal] = useState(false);
    const [editViewModalID, setEditViewModalID] = useState(null);
    const [deleteViewModalID, setDeleteViewModalID] = useState(null);
    const [activeTool, setActiveTool] = useState(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    const checkBoxesInSaveModal = [
        {key: 'position', text: 'Map position'},
        {key: 'layers', text: 'Selected layers'},
        {key: 'locations', text: 'Selected locations'},
    ]
    const newView = {
        id: '',
        title: '',
        includes: {position: true, layers: true, locations: true},
        data: {
            position: locationHash,
            layers: activeProducts,
            locations: activeLocations,
        },
    }

    const loadMenuItemsObj = {}
    for (const idx in views) {
        loadMenuItemsObj[views[idx].id] = views[idx]
    }

    const editedViews = Object.fromEntries( // Old views with values from newView
        Object.keys(loadMenuItemsObj).map((id) => [
            id,
            {
                ...newView,
                id: loadMenuItemsObj[id].id,
                title: loadMenuItemsObj[id].title,
            },
        ]),
    )

    useEffect(() => {
        fetchMapViews().then((newViews) => {
            newViews.forEach((v) => {
                Object.assign(v, v.data_json);
                delete v.data_json
            })
            setViews(newViews)
        })
    }, [])

    useEffect(() => {
        productsController.current
        && productsController.current.setTimeRange(stateDate)
    }, [stateDate])

    const handleSaveNewView = (view) => {
        setSaveViewModal(false)
        setEditViewModalID(null)

        if (!view) return

        const apiView = {
            name: view.title,
            order: view.order,
            data_json: view,
        }
        delete view.name
        delete view.order

        if (!view.id) {
            delete view.id
            createMapView(apiView).then((view) => {
                if (view.error) return
                Object.assign(view, view.data_json)
                delete view.data_json
                setViews([...views, view])
            })
            return
        }
        apiView.id = view.id
        delete view.id
        editMapView(apiView).then((view) => {
            if (view.error) return
            Object.assign(view, view.data_json)
            delete view.data_json
            views.forEach((v, idx) => {
                if (v.id === view.id) views[idx] = view
            })
            setViews([...views])
        })
    }

    const handleDeleteView = (agree, id) => {
        setDeleteViewModalID(null)

        const view = loadMenuItemsObj[id]

        if (!view) return
        if (!loadMenuItemsObj[view.id]) return
        if (agree) {
            deleteMapView(view.id)
            const newViews = views.filter((v) => v.id !== view.id)
            setViews(newViews)
        }
    }

    const initProductsAndLocationsFromAppState = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const alertId = urlParams.get('alert_id');

        if (!alertId) {
            fetchDefaultMapView()
                .then((defaultMapViewRes) => {
                    const defaultMapView = defaultMapViewRes[0]
                    if (defaultMapView && defaultMapViewRes) {
                        const availableProducts = products
                            .filter(product => defaultMapView.data_json.activeProducts.includes(product.id) && product.available)
                            .map(product => product.id);
                        setActiveProducts(availableProducts || [])

                        const existingActiveLocations = defaultMapView.data_json.activeLocations.filter(id =>
                            locations.some(location => location.id === id)
                        );
                        setActiveLocations(existingActiveLocations || [])

                        setLocationHash(defaultMapView.data_json.locationHash || window.location.hash)
                        window.location.hash = defaultMapView.data_json.locationHash || window.location.hash
                    } else {
                        console.error('Default Map View is undefined')
                    }
                })
                .catch((error) => {
                    console.error('Default Map View error: ', error)
                })
        }
    }

    useEffect(() => {
        if (!initialState || !mapLoaded) return
        // reset
        setActiveLocations(initialState.initialLocations || [])
        initialState.initialDates && setStateDate(initialState.initialDates)
        initialState.initialDates && setSelectedTime(initialState.initialDates.startDate)
        setActiveProducts(initialState.initialProducts || [])
    }, [initialState, mapLoaded])

    useEffect(() => {
        requester.isready(componentDidMount)
    }, [])

    useEffect(() => {
        activeLocations.forEach((id) => {
            if (locationsLayers[id]) return
            const loc = locations.find((p) => (p.id === id))
            if (!loc) {
                return
            }
            locationsLayers[id] = new maplibregl.Marker({color: 'var(--palette-primaryRed-light)', scale: 0.5})
                .setLngLat(loc.coordinates)
                .addTo(window.map)
            locationsLayers[id].getElement().setAttribute('title', loc.label)
        })

        const _clearedLocations = Object.assign({}, locationsLayers)
        for (const id in locationsLayers) {
            if (!activeLocations.includes(+id)) {
                _clearedLocations[id] && _clearedLocations[id].remove()
                delete _clearedLocations[id]
            }
        }

        setLocationLayers(_clearedLocations)
    }, [activeLocations])

    useEffect(() => {
        const mapProducts = []
        const productObjects = [] // REALLY CRINGE!!! TODO: pass into entire product object, not only product code
        activeProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`;
                mapProducts.push(productCode);
                productObjects.push(prod)
            } else {
                console.error(`Product with id ${p} not found.`);
            }
        });
        
        if (productsController.current) {
            productsController.current.setProducts(mapProducts, productObjects);
            reEnableWmsIfNeed()
        }

    }, [activeProducts]);

    const reEnableWmsIfNeed = () => {
        const isNeedToReEnableWMs = productsController.current.mode;
        const isNeedRePlay = isPlaying;
        productsController.current.switchWMSMode(false);

        setIsPlaying(false);
        setIsLoading(false);

        if (isNeedToReEnableWMs) {
            setIsLoading(true);
            productsController.current.switchWMSMode(true, () => {
                setIsLoading(false);
                isNeedRePlay && setIsPlaying(true);
            });
        }
    }

    function componentDidMount() {
        const map = new maplibregl.Map({
            container: 'map',
            style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
            hash: true,
            transformRequest: Transformer(requester),
            minZoom: 2,
            dragRotate: false,
            touchZoomRotate: false,
        })
        window.map = map;

        map.on('disableProducts', handleDisableProducts)
        map.on('load', () => {
            mapLibreElem.current = map
            initProductsAndLocationsFromAppState()
            setMapLoaded(true)
        });
        map.on('moveend', () => {
            const newHash = window.location.hash
            setLocationHash(newHash)
        });
        map.addControl(new maplibregl.NavigationControl({
            showCompass: false,
        }), 'bottom-left');
        map.addControl(new maplibregl.ScaleControl(), 'bottom-right');
        map.addControl(new maplibregl.ScaleControl({
            unit: 'imperial'
        }), 'bottom-right');
        map.addControl(new maplibregl.AttributionControl({
            customAttribution: ['Lightning data provided by ENTLN Lightning',
                '<a href="https://baronweather.com/" target="_blank">Baron Weather</a>'],
        }));
        map.addControl(new maplibregl.FullscreenControl());
        map.on('movestart', () => {
            setIsPlaying(false)
            productsController.current && productsController.current.switchWMSMode(false)
            setIsPlaying(false)
        });

        productsController.current = new ProductsController({
            signature,
            map,
            startDate: stateDate.startDate,
            endDate: stateDate.endDate,
            time: currentDateInMs,
            onChangeTimeRange: setStateDate,
        });
    }

    const handleDisableProducts = ({productsToBeDisabled}) => {
        setActiveProducts(prevState => {
            return prevState.filter(
                (product) => !productsToBeDisabled.includes(product)
            );
        })
    }

    function clearPQPopups() {
        setClearPQ(!clearPQ)
    }

    useEffect(() => {
        if (!mapLibreElem.current) {
            // map is not initialized yet
            return
        }
        // control where we get to the map
        const urlParams = new URLSearchParams(window.location.search);
        const alertId = urlParams.get('alert_id');
        if (!alertId) {
            const defaultMapView = {
                name: 'defaultmapview',
                order: 1,
                data_json: {
                    activeProducts: activeProducts,
                    activeLocations: activeLocations,
                    locationHash: locationHash,
                },
            }
            createDefaultMapView(defaultMapView)
                .catch((error) => console.log(error))
        }
    }, [activeProducts, activeLocations, locationHash])
    const onViewAction = (action, id) => {
        switch (action) {
            case 'save_view':
                setSaveViewModal(true)
                break
            case 'edit_view':
                setEditViewModalID(id)
                break
            case 'delete_view':
                setDeleteViewModalID(id)
                break
            default:
                console.error('no action handler for action ', action)
                break
        }
    }

    const _handleProductSwitch = (prods) => {
        setActiveProducts(prods);
    }

    return (
        <Box className={'row gap0 fullHeight fullWidth'}>
            <MapSidebar
                setActiveProducts={setActiveProducts}
                onSwitchProduct={_handleProductSwitch}
                reEnableWmsIfNeed={reEnableWmsIfNeed}
                onSwitchLocation={setActiveLocations}
                activeProducts={activeProducts}
                activeLocations={activeLocations}
                products={products ? products.filter((p) => (!!p.api_product_code)) : []}
                locations={locations}
            />
            <Box
                className="MapLibre spacer"
                style={{
                    height: '100%', display: 'flex',
                    flexDirection: 'column', alignItems: 'stretch',
                    borderRadius: '16px 0 0 16px', overflow: 'hidden',
                    boxShadow: '-4px 0px 24px 0px #677A8E3D'
                }}
            >
                <MapMenu
                    onSwitchProduct={setActiveProducts}
                    onSwitchLocation={setActiveLocations}
                    onSwitchLocationHash={(newMapPosition) => {
                        setLocationHash(newMapPosition)
                        window.location.hash = newMapPosition
                    }}
                    activeProducts={activeProducts}
                    activeLocations={activeLocations}
                    onClearLocations={() => setActiveLocations([])}
                    onClearLayers={() => setActiveProducts([])}
                    onAllClear={() => {
                        setActiveProducts([])
                        setActiveLocations([])
                        clearPQPopups()
                    }}
                    onClearPqPopups={() => clearPQPopups()}
                    setOpenAddLocations={setOpenAddLocations}
                    views={views}
                    onViewAction={onViewAction}
                    locationHash={locationHash}
                />
                <div
                    className="baronmap"
                    id="baronmapwrapper"
                >
                    <div id="map"></div>

                    {activeProducts.length > 0 && (
                        <>
                            <div style={{
                                width: '100%', height: '120px',
                                position: 'absolute', bottom: '0px',
                                zIndex: '3', display: 'flex',
                                alignItems: 'center',
                                backgroundColor: 'rgba(240, 242, 245, 0.1)',
                                boxShadow: 'rgba(0, 0, 0, 0.12) 0px -2px 12px 0px',
                                backdropFilter: 'blur(2px)',
                            }}
                            >
                                <TimeLineMap
                                    // need to add inital value or keep time on map element
                                    isLoading={isLoading}
                                    startDate={stateDate.startDate}
                                    endDate={stateDate.endDate}
                                    onChangeTime={selectedTime => {
                                        productsController.current && productsController.current.setTime(selectedTime);
                                        window.map.fire('selectedTime');
                                    }}
                                    onPlayBtn={(isPause) => {
                                        if (!isPause) {
                                            setIsLoading(true)
                                            productsController.current.switchWMSMode(true, () => {
                                                setIsLoading(false)
                                            })
                                        }
                                    }
                                    }
                                    onChangePlaying={setIsPlaying}
                                    isPlaying={isPlaying}
                                    selectedTime={selectedTime}
                                ></TimeLineMap>
                            </div>
                        </>
                    )}
                    <div style={{
                        position: "absolute",
                        bottom: "226px",
                        width: "fit-content",
                        height: "fit-content",
                        left: "23px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}>
                        { productsController.current?.textProductController?.isEnabled('metar')
                            && <SwitchModeTool
                                product={productsController.current.textProductController.getProduct('metar')}
                            />
                        }
                        <DistanceTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool("DistanceTool")}
                        />
                        <PinPointTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('PinPointTool')}
                        />
                        <PointQueryTool
                            productsController={productsController}
                            activeProducts={activeProducts}
                            clear={clearPQ}
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool("PointQuery")}
                        />
                    </div>
                </div>
                <Legends
                    activeProductsIndexes={activeProducts}
                    products={products}
                    nonStandartProjection={NON_STANDARD_PROJECTION}
                />
            </Box>
            {saveViewModal
                && <SaveViewModal
                    headerText='Save current view'
                    saveText='Save view'
                    saveFunc={handleSaveNewView}
                    view={newView}
                    checkBoxes={checkBoxesInSaveModal}
                />
            }
            {editViewModalID
                && <SaveViewModal
                    headerText='Edit view'
                    saveText='Save changes'
                    saveFunc={handleSaveNewView}
                    view={editedViews[editViewModalID]}
                    checkBoxes={checkBoxesInSaveModal}
                />
            }
            {deleteViewModalID
                && <AgreeModal data={{
                    message: <Box
                        className='column'
                        sx={{
                            alignContent: 'stretch',
                            overflow: 'hidden',
                            '&.MuiBox-root': {width: '100%'},
                        }}
                    >
                        <Typography sx={{fontSize: '18px'}}>
                            {`Are you sure you want to delete "${loadMenuItemsObj[deleteViewModalID].title}" view?`}
                        </Typography>
                    </Box>,
                    title: 'Delete view',
                    agreeMsg: 'Delete',
                    mode: "deleting",
                    agreeFunc: (agree) => handleDeleteView(agree, deleteViewModalID),
                }}
                />
            }
        </Box>
    )
}
