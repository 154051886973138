import 'shared/ui/PasswordBar/PasswordBar.css';

export function PasswordBar({fillPercent}) {
    function getStrength(percentage) {
        if (percentage >= 0 && percentage <= 25) {
            return 'Weak';
        } else if (percentage >= 26 && percentage <= 50) {
            return 'Moderate';
        } else if (percentage >= 51 && percentage <= 75) {
            return 'Good';
        } else if (percentage >= 76 && percentage <= 100) {
            return 'Very good';
        } else {
            return 'Invalid percentage. Please enter a value between 0 and 100.';
        }
    }

    return (
        <div className={'barContainer'}>
            <div className={'barBackground'}>
                <div className={'bar'} style={{width: `${fillPercent}%`}}/>
            </div>
            <div className={'strengthText'}>
                {getStrength(fillPercent)}
            </div>
        </div>
    )
}