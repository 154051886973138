import {Box, MenuItem, FormControlLabel} from '@mui/material';
import {CheckBox} from "shared/ui/CheckBox";
import TrashIcon from 'shared/assets/icons/Trash';
import "shared/ui/ItemSelectTopBar/ItemsSelectTopBar.css";
import {Spacer} from "shared/ui/Spacer";
import ArrowRightIcon from "shared/assets/icons/ArrowRight";
import PenIcon from "../../assets/icons/Pen";

export default function ItemsSelectTopBar({ onAction, visible, allSelected, indeterminate, type = undefined}) {
    const handleToggle = () => {
        onAction(undefined, 'selectall', !allSelected);
    }

    const handleMove = () => {
        onAction(undefined, 'openMovePopup');
    }

    const handleChangeUrgency = () => {
        onAction(undefined, 'mass_change_urgency');
    }

    // console.log("visible", visible)

    return (
        <Box className={visible ? "row itemsDeleteVisible" : "row itemsDeleteInvisible"}>
            <FormControlLabel
                control={
                    <CheckBox
                        checked={allSelected}
                        onChange={(ev) => { if (ev) ev.stopPropagation(); handleToggle(); }}
                        indeterminate={indeterminate}
                    />                
                }
                label="Select all"
            />
            
            <Spacer />

            {type == 'usercontacts' &&
                <MenuItem key={'move to group'} onClick={handleMove}>
                    <ArrowRightIcon size={'small'}/>
                    Move to group...
                </MenuItem>
            }

            {type == 'actionitems' &&
                <MenuItem key={'move to group'} onClick={handleChangeUrgency}>
                    <PenIcon size={'small'}/>
                    Change urgency
                </MenuItem>
            }

            <MenuItem
                key={'delete'}
                className={'regular'}
                variant={'outlined'}
                color={'error'}
                onClick={(ev) => {
                    if (ev) ev.stopPropagation();
                    onAction(undefined, 'delete')
                }}
            >
            <TrashIcon size={'small'}/>
                Delete
            </MenuItem>
        </Box>
    );
}
