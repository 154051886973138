import {createSvgIcon} from "@mui/material/utils";


export default function LinkIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5549 10.4675L18.7149 12.3075C18.3236 12.6985 17.6913 12.6985 17.3001 12.3075C16.9088 11.9165 16.9088 11.2844 17.3001 10.8934L19.1401 9.05347C20.2937 7.89947 20.2937 6.02243 19.1401 4.86743C18.0215 3.74843 16.0714 3.74843 14.9538 4.86743L11.5928 8.22644C11.4748 8.34444 11.3847 8.45248 11.3257 8.54248C10.4271 9.76648 10.5542 11.3735 11.5938 12.4125C11.984 12.8035 11.984 13.4365 11.5938 13.8265C11.3987 14.0215 11.1426 14.1194 10.8864 14.1194C10.6303 14.1194 10.3741 14.0215 10.178 13.8265C8.43504 12.0835 8.22593 9.38349 9.67976 7.40649C9.78282 7.24549 9.95586 7.0345 10.177 6.8125L13.5389 3.45251C14.4755 2.51551 15.7222 1.99951 17.0469 1.99951C18.3717 1.99951 19.6184 2.51651 20.5559 3.45251C22.489 5.38751 22.489 8.53353 20.5549 10.4675ZM12.4183 10.1735C12.0271 10.5645 12.0271 11.1965 12.4183 11.5875C13.2358 12.4045 13.5029 13.5835 13.1067 14.6855C12.9756 15.0785 12.7375 15.4554 12.4183 15.7744L9.05739 19.1335C7.93976 20.2525 5.98963 20.2525 4.871 19.1335C3.71734 17.9795 3.71734 16.1025 4.871 14.9475L6.71103 13.1075C7.10225 12.7165 7.10225 12.0845 6.71103 11.6935C6.31981 11.3025 5.68745 11.3025 5.29622 11.6935L3.45619 13.5334C1.52209 15.4674 1.52209 18.6136 3.45619 20.5476C4.39372 21.4846 5.63945 22.0005 6.9652 22.0005C8.29096 22.0005 9.53665 21.4836 10.4732 20.5476L13.8341 17.1885C14.3724 16.6495 14.7777 16.0026 14.9978 15.3396C15.6411 13.5516 15.1849 11.5245 13.8331 10.1735C13.4429 9.78246 12.8085 9.78246 12.4183 10.1735Z"
                fill="currentColor"/>
        </svg>
        , 'Link')
    return <Icon {...props} data-cy="link-icon"/>
}