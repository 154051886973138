import React from "react";
import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import {ReactComponent  as Background} from "shared/assets/emptyStates/Background.svg";
import {ReactComponent  as LocationsPin} from "shared/assets/emptyStates/LocationsPin.svg";
import {ReactComponent  as TimeLineMap} from "shared/assets/emptyStates/TimeLineMap.svg";

export default function EmptyStateTimeline({title, text}) {

		return (
				<Box sx={{height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
						<Box sx={{display:"flex", flexDirection:"column", justifyContent:"end", width:"280px", height:"390px", borderRadius:"12px", padding:"20px 20px 60px 20px", position:"relative", overflow:"hidden", backgroundColor:"white"}}>
								<Box sx={{position:"absolute", right:"-180px", bottom:"-7px"}}>
										<Background/>
								</Box>
								<Box sx={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
										<Box sx={{position:"absolute", right:"30px", top:"20px"}}>
												<TimeLineMap/>
										</Box>
										<Box sx={{position:"absolute", left:"10px", top:"36px"}}>
												<LocationsPin/>
										</Box>
								</Box>
								<Box sx={{display:"flex", alignItems:"start", flexDirection:"column", textAlign: "start", gap:"12px"}}>
										<Typography sx={{fontSize:"24px", fontWeight:"500", width:"190px", lineHeight:"32px", height:"64px", letterSpacing:"0.25px"}}>{title}</Typography>
										<Typography sx={{fontSize:"14px", fontWeight:"400", width:"240px", lineHeight:"20px", height:"48px", color:"var(--palette-grey-500)", letterSpacing:"0.25px"}}>{text}</Typography>
								</Box>
						</Box>
				</Box>
		)
}