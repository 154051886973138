import React, { useState, useEffect } from 'react';
import {MenuItem, IconButton} from '@mui/material';
import TextField from "@mui/material/TextField";
import {PhoneField} from "shared/ui/PhoneField/PhoneField";
import CloseIcon from "shared/assets/icons/Close";
import CopyTooltip from "shared/ui/CopyTooltip";

import {Select} from "shared/ui/Select";
import {formatPhoneNumber} from "shared/libs/Utils";
import {Box} from "@mui/system";

export default function NewContactLine({ contact, groups, onRemove, onContactChange }) {
    // Field states
    const [name, setUserName] = useState(contact.name);
    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);
    const [userGroup, setUserGroup] = useState(contact.group === "" ? null : contact.group);

    const CheckIsValidEmail = (emailAddress) =>{
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(emailAddress)
    }

    const [isValidEmail, setIsValidEmail] = useState(CheckIsValidEmail(contact.email));
    const [isValidPhone, setIsValidPhone] = useState(contact.phone !== 'Invalid');

    useEffect(() => {
        const updatedContact = {
            ...contact,
            name,
            email,
            phone,
            group: userGroup === null ? "" : userGroup
        };

        onContactChange(updatedContact);
        setIsValidEmail(CheckIsValidEmail(updatedContact.email));
        setIsValidPhone(updatedContact.phone !== 'Invalid');
    }, [name, email, phone, userGroup, onContactChange, contact]);

    const handleChangeGroup = (event) => {
        setUserGroup(event.target.value === "No Group" ? null : event.target.value);
    };

    return (
        <Box sx={{display:"flex", flexDirection:"row", gap:"16px", alignItems:"center", width:"1168px"}}>
            <TextField
                placeholder='Name'
                value={name}
                onChange={(e) => setUserName(e.target.value)}
                sx={{width:"268px"}}
            />
            <TextField
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                    endAdornment: <CopyTooltip textToCopy={email}/>,
                }}
                sx={{width:"268px"}}
                error= {!(isValidEmail || email === '')}
            />
            <Box sx={{width:"268px"}}>
                <PhoneField
                    placeholder='Phone'
                    phone={phone}
                    onChange={(phoneInput) => {
                        setPhone(phoneInput)
                    }}
                    errorBorder = {false}
                    InputProps={{
                        endAdornment: <CopyTooltip textToCopy={formatPhoneNumber(phone)}/>,
                    }}
                />
            </Box>
            <Select
                placeholder={'User group'}
                value={userGroup}
                onChange={handleChangeGroup}
                sx={{width:"268px"}}
            >
                <MenuItem value="No Group">No Group</MenuItem>
                {groups.map((group, index) => (
                    <MenuItem key={index} value={group.groupName}>{group.groupName}</MenuItem>
                ))}
            </Select>
            <IconButton variant={'outlined'} size='medium' onClick={() => onRemove(contact.id)}>
                <CloseIcon size={'small'}/>
            </IconButton>
        </Box>
    )
}
