import {createSvgIcon} from "@mui/material/utils";

export default function PlusIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line stroke="currentColor" x1="11.9912" y1="4.51904" x2="11.9912" y2="19.4807"
                  strokeWidth="2"
                  strokeLinecap="round"/>
            <line stroke="currentColor" x1="19.4814" y1="11.9907" x2="4.51978" y2="11.9907"
                  strokeWidth="2"
                  strokeLinecap="round"/>
        </svg>, 'Plus')
    return <Icon {...props} data-cy="plus-icon"/>
}