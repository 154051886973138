import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Chip,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {CheckBox} from "../../../../shared/ui/CheckBox";
import './SingleSiteRadar.scss'

const isSelected = (selectedProducts, product) => {
    return !!selectedProducts?.includes(product.current.id)
}

const isStationSelected = () => {
    return !!getCurrentStation()
}

const getCurrentStation = () => {
    return window.map?.customSettingSSR ?? ''
}

export default function SingleSiteRadar({reEnableWmsIfNeed, setActiveProducts, group, onSelect, selectedProducts}) {
    const ssrLayers = useRef(group.slice(1).map(layer => layer.id))
    const product = useRef(group[0])

    const [selected, setSelected] = useState(isSelected(selectedProducts, product))
    const [station, setStation] = useState(getCurrentStation())
    const [stationSelected, setStationSelected] = useState(isStationSelected())

    useEffect(() => {
        const map = window.map
        map.on('stationClick', handleStationClick)

        return () => {
            map.off('stationClick', handleStationClick)
        }
    }, [])

    useEffect(() => {
        setSelected(isSelected(selectedProducts, product))
    }, [selectedProducts, stationSelected])

    useEffect(() => {
        if (!selected || !stationSelected) {
            clear()
        }

        if (selected && stationSelected && !getCurrentLayer()) {
            const contouredSuperResReflectivityLayer = group[1].id
            onSelect(null, contouredSuperResReflectivityLayer)
        }
    }, [selected, stationSelected])

    useEffect(() =>  {
        if (stationSelected) {
            reEnableWmsIfNeed()
        }
    }, [station])

    const getCurrentLayer = () => {
        return selectedProducts.find(product => ssrLayers.current.includes(product)) ?? NaN
    }

    const clear = () => {
        setActiveProducts(prevState => {
            return removeSsrLayers(prevState)
        })
    }

    const handleStationClick = ({station, selected}) => {
        setStation(station.name)
        setStationSelected(selected)
        window.map.customSettingSSR = selected ? station.name : null;
        window.map.fire('change_customSettingSSR');
    }

    const handleSelectProduct = (event) => {
        event.stopPropagation()
        onSelect(event, product.current.id)
    }

    const handleLayerChange = (event, value) => {
        setActiveProducts(prevState => {
            const activeProducts = removeSsrLayers(prevState)
            if (activeProducts.length + 1 <= 5) {
                activeProducts.push(Number(value))
            }

            return activeProducts
        })
    }

    const removeSsrLayers = (selectedProducts) => {
        return selectedProducts.filter(productId => {
            return !ssrLayers.current.includes(productId)
        })
    }

    const handleDeselectStation = () => {
        setStationSelected(false)
        window.map.customSettingSSR = ''
        clear()
        window.map.fire('stationStateChange', {stationName: station, selected: false})
    }

    return (
        <Accordion
            data-cy={'single-site-radar-switch'}
            variant={'dark'}
            expanded={selected}
            onChange={handleSelectProduct}
        >
            <AccordionSummary
                variant={'dark'}
                sx={{height: '32px'}}
            >
                <Box className={'row gap4'}>
                    <CheckBox checked={selected}/>
                    <Typography>
                        {group[0].name}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                variant={'dark'}
            >
                <Typography color={'var(--palette-grey-500)'}>
                    Select a site from the map to apply layers
                </Typography>
                <Box className={classNames('radio-group', {visible: stationSelected})}>
                    <Chip
                        variant={'white'}
                        label={station}
                        onDelete={handleDeselectStation}
                    />
                    <Box>
                        <RadioGroup
                            value={getCurrentLayer()}
                            onChange={handleLayerChange} sx={{paddingLeft: '24px'}}
                        >
                            {group.map((layer, index) => {
                                if (index === 0) {
                                    return
                                }

                                return (
                                    <FormControlLabel
                                        key={layer.id}
                                        control={<Radio size={'small'}/>}
                                        label={layer.name}
                                        value={layer.id}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}