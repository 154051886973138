function getSpline(x, a) {
    let n = x.length - 1, h = [], y = [], l = [], u = [], z = [], c = [], b = [], d = [], k = [], s = [];
    for (let i = 0; i < n; i++) {
        h[i] = x[i + 1] - x[i];
        k[i] = a[i + 1] - a[i];
        s[i] = k[i] / h[i];
    }
    for (let i = 1; i < n; i++) {
        y[i] = 3 / h[i] * (a[i + 1] - a[i]) - 3 / h[i - 1] * (a[i] - a[i - 1]);
    }
    l[0] = 1;
    u[0] = 0;
    z[0] = 0;
    for (let i = 1; i < n; i++) {
        l[i] = 2 * (x[i + 1] - x[i - 1]) - h[i - 1] * u[i - 1];
        u[i] = h[i] / l[i];
        z[i] = (y[i] - h[i - 1] * z[i - 1]) / l[i];
    }
    l[n] = 1;
    z[n] = 0;
    c[n] = 0;
    for (let i = (n - 1); i >= 0; i--) {
        c[i] = z[i] - u[i] * c[i + 1];
        b[i] = (a[i + 1] - a[i]) / h[i] - h[i] * (c[i + 1] + 2 * c[i]) / 3;
        d[i] = (c[i + 1] - c[i]) / (3 * h[i]);
    }
    b[n] = 0;
    d[n] = 0;

    return function (p) {
        for (var i = x.length - 1; i > 0; i--)
            if (x[i] <= p) break;
        let deltaX = p - x[i];
        return a[i] + b[i] * deltaX + c[i] * Math.pow(deltaX, 2) + d[i] * Math.pow(deltaX, 3);
    }
}

export default function getInterpolatedPointsForRender(points){
    const xs = [], ys=[], temp=[];
    points.forEach((p,i)=>{
        xs.push(p.coordinates[0]);
        ys.push(p.coordinates[1]);
        temp.push(i);
    });
    const splineX = getSpline(temp, xs), splineY = getSpline(temp, ys), way = [];
    for (let i=0; i<points.length-1; i++){
        way.push([splineX(i), splineY(i)]);
        for (let j=1; j<100; j++){
            way.push([splineX(i+0.01*j), splineY(i+0.01*j)]);
        }
    }
    way.push([splineX(points.length-1), splineY(points.length-1)]);
    return way;
}