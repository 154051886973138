import {
    Box,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    MenuItem
} from '@mui/material';
import MoreIcon from "shared/assets/icons/More";
import ArrowDownSmallIcon from "shared/assets/icons/ArrowDownSmall";
import React, {useState} from 'react';
import CopyIcon from '../../shared/assets/icons/Copy';
import SettingsIcon from '../../shared/assets/icons/Settings';
import { ChipSeverity } from 'shared/ui/SpanSeverity';
import {CheckBox} from "shared/ui/CheckBox";
import {Spacer} from "shared/ui/Spacer";
import { DefinitionRenderCompact } from '../../widgets/weatherDefinition/definitionRender/DefinitionRenderCompact';
import theme from '../../app/Theme';
import List from "@mui/material/List";

export default function WeatherDefinitionLineCheckbox({definition, selectedDefinition, onChange, doAction}){

    const [showMore, setShowMore] = useState(false);

    definition.groups = definition.rules;
    const copyDefinition = () => {
        const copyDef = JSON.parse(JSON.stringify(definition));
        copyDef.id = -1;
        copyDef.name += ' Copy';
        doAction('copy', copyDef);
    }

    const openDefinition = () => {
        doAction('open', definition)
    }

    return(
        <Accordion
            variant={'light'}
            disableGutters
            elevation={0}
            square
            key={definition.id}
            sx={{
                padding: 0,
                boxShadow: 'none',
            }}
        >
            <AccordionSummary
                variant={'light'}
                expandIcon={<ArrowDownSmallIcon/>}
                aria-controls="panel1a-content"
                sx={{height: '40px',}}
            >
                <Box className={'row gap4 fullWidth'}>
                    <CheckBox
                        checked={selectedDefinition === true}
                        onChange={() => onChange(definition)}
                        onClick={(ev) => ev.stopPropagation()}
                    />
                    <Typography sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',}}>
                        {definition.name}вфывфыв
                    </Typography>
                    <Spacer/>
                    <Box sx={{
                        borderRadius: '2px',
                        width: '10px',
                        height: '10px',
                        marginRight: '4px',
                        background: theme.palette.weather[definition.severity].main
                    }}/>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    border: 0,
                    padding: '8px 5px 8px 10px'
                }}
                className="paragraph regular"
                variant={'light'}
            >
                <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1, height: '32px', padding: 0}}>
                    <ChipSeverity severity={definition.severity} size="small"/>
                    <Box sx={{'marginLeft': 'auto'}}>
                        <IconButton variant={'outlined'} onClick={({currentTarget}) => {setShowMore(currentTarget)}}>
                            <MoreIcon size={'small'}/>
                        </IconButton>
                    </Box>
                    {showMore &&
                        <Popover
                            open={true}
                            anchorEl={showMore}
                            onClose={() => {
                                setShowMore(false)
                            }}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                            transformOrigin={{vertical: 'top', horizontal: 'right',}}
                        >
                            <List>
                                {definition.rules &&
                                    <MenuItem onClick={copyDefinition}>
                                        <CopyIcon size={'small'}/>
                                        Copy
                                    </MenuItem>
                                }
                                <MenuItem onClick={openDefinition}>
                                    <SettingsIcon size={'small'}/>
                                    View in settings
                                </MenuItem>
                            </List>
                        </Popover>
                    }
                </Box>
                {definition.rules && <DefinitionRenderCompact definition={definition}/>}
                {!definition.rules && <Box>{definition.description || "This is a fixed weather definition"}</Box>}
            </AccordionDetails>
        </Accordion>
    )
}