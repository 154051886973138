import {createSvgIcon} from "@mui/material/utils";


export default function EnvelopeIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 4H7C4.243 4 2 6.243 2 9V15C2 17.757 4.243 20 7 20H17C19.757 20 22 17.757 22 15V9C22 6.243 19.757 4 17 4ZM7 6H17C18.178 6 19.189 6.68798 19.68 7.67798L13.664 11.688C12.653 12.362 11.347 12.362 10.336 11.688L4.32001 7.67798C4.81101 6.68798 5.822 6 7 6ZM17 18H7C5.346 18 4 16.654 4 15V9.86804L9.22601 13.3521C10.069 13.9141 11.034 14.1951 12 14.1951C12.965 14.1951 13.931 13.9141 14.773 13.3521L20 9.86804V15C20 16.654 18.654 18 17 18Z"
                fill="currentColor"/>
        </svg>
        , 'Envelope')
    return <Icon {...props} data-cy="envelope-icon"/>
}