import React, { useEffect, useState } from 'react';
import {Box, Drawer, Button, IconButton, Divider} from '@mui/material';
import RenderedDefinitionsSelect from './RenderedDefinitionsSelect';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";




export default function WeatherDefinitionDrawer({ setIsWeatherDifinitionDrawer, open, anchor, onApply, onCancel, savedSelectedDefinitions, doAction }) {
    const [selectedDefinitions, setSelectedDefinitions] = useState({});

    useEffect(() => {
        setSelectedDefinitions(savedSelectedDefinitions);
    }, [savedSelectedDefinitions]);

    const onSelect = (changes) => {
        const newSelected = Object.assign({...selectedDefinitions}, changes);
        setSelectedDefinitions(newSelected);
    }
    const cancelSelection = () => {
        setSelectedDefinitions(savedSelectedDefinitions);
        onCancel()
    }

    return (
        <Drawer
            className="WeatherDefinitionDrawer"
            anchor={anchor}
            open={open}
            onClose={()=>setIsWeatherDifinitionDrawer(false)}
            sx={{'& .MuiDrawer-paper': {width: '560px', padding: '16px 32px 16px 28px'}}}
        >
            <Box className={'column fullWidth'}>
                <Box className={'toolbar'}>
                    <IconButton variant={'outlined'} onClick={cancelSelection}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <Box className={'subtitle medium'}>
                        Weather definitions
                    </Box>
                </Box>
                <Divider/>
                <RenderedDefinitionsSelect
                    onChange={onSelect}
                    selectedDefinitions={selectedDefinitions}
                    doAction={doAction}
                />
                <Box className={'drawerButtonBox'} sx={{height: '72px'}}>
                    <Button
                        className={'regular'}
                        variant={'outlined'}
                        onClick={cancelSelection}
                        sx={{width: '96px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={'regular'}
                        onClick={() => onApply(selectedDefinitions)}
                        sx={{width: '96px'}}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
}


