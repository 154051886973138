import {useEffect, useState} from 'react';
import {api} from 'shared/libs/Auth';
import logoTabMenu from "../../shared/assets/png/logo.png";
import {Button, Link} from "@mui/material";
import "./infoPages.css";

export default function ApproveUserList() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        api.get('/users/pending_approval')
            .then(response => {
                const usersWithStatusAndError = response.data.map(user => ({
                    ...user,
                    isApproved: false,
                    error: '',
                    isProcessing: false
                }));
                setUsers(usersWithStatusAndError);
                setError('');
            })
            .catch(error => {
                console.error('Error receiving users data:', error);
                setError('Failed to fetch users or you do not have permission to this page');
            });
    }, []);

    const handleApprove = (user) => {
        setUsers(users.map(u => u.id === user.id ? {...u, isProcessing: true} : u));
        api.post(user.approve_url)
            .then(response => {
                setUsers(users.map(u => u.id === user.id ? {
                    ...u,
                    isApproved: true,
                    error: '',
                    isProcessing: false
                } : u));
            })
            .catch(error => {
                console.error('Error approving user:', error);
                setUsers(users.map(u => u.id === user.id ? {
                    ...u,
                    error: 'Error approving user',
                    isProcessing: false
                } : u));
            });
    };

    return (
        <div className={"infoPages_wrapper"}>
            <div className={"infoPages_header"}>
                <Link href={'/login'}>
                    <img src={logoTabMenu} alt={'logo'}/>
                </Link>
                <Button
                    variant={'text'} color={'primary'}
                    className={"infoPages_button"}
                    href={'/signup'}
                >
                    Sign up
                </Button>
            </div>
            <div className={"infoPages_content"}>
                <h2>List of users awaiting approval</h2>
                {error ? (
                    <p className={'infoPages_error'}>{error}</p>
                ) : users.length > 0 ? (
                    <ul className={"infoPages_list"}>
                        {users.map(user => (
                            <li key={user.id} className={"infoPages_line"}>
                                Email: {user.email}
                                <div className={"infoPages_buttons_block"}>
                                    {user.error && <div className="infoPages_error">{user.error}</div>}
                                    {user.isApproved ? (
                                        <span className={"infoPages_success"}>Approved!</span>
                                    ) : (
                                        <>
                                            <Button href={`${user.admin_edit_url}`} target="_blank"
                                                    variant={'text'}
                                                    color={'primary'}
                                                    rel="noopener noreferrer">
                                                Edit
                                            </Button>
                                            <Button onClick={() => handleApprove(user)} disabled={user.isProcessing}
                                                    className={"infoPages_button"}>
                                                Approve
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : <p>There are currently no users awaiting approval.</p>}
            </div>
        </div>
    );
}
