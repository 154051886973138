import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import MapPinIcon from "shared/assets/icons/MapPin";
import {CheckBox} from "shared/ui/CheckBox";

const calcLocations = (locations, selectedLocations, searchString) => {
    let s = searchString && searchString.trim().toLocaleLowerCase();
    let selected = 0;
    let unselected = 0;
    for (let i in locations) {
        let loc = locations[i];
        if (!s || loc.label.toLocaleLowerCase().indexOf(s) >= 0) {
            if (selectedLocations[loc.id] === false) {
                unselected++;
            }
            else {
                selected++;
            }
        }
    }
    return [selected, unselected];
}

export function calcSelected(locationGroups, searchString, selectedLocations) {
    let selected = 0;
    let unselected = 0;
    // let groups;
    if (!locationGroups) {
        return [0, 0];
    }
    // let s = searchString.trim().toLocaleLowerCase();
    for (let group in locationGroups) {
        let locs = locationGroups[group];
        let [sel, unsel] = calcLocations(locs, selectedLocations, searchString);
        selected += sel;
        unselected += unsel;
    }
    return [selected, unselected];
}
export function LocationsTree(
    {
        locationGroups,
        selectedLocations,
        handleSelectedLocations,
        searchString,
        variant='light',
    }) {
    let s = searchString.trim().toLocaleLowerCase();

    function filterLocations(group) {
        let selected = [];
        for (let i in group) {
            let loc = group[i];
            if (loc.label.toLocaleLowerCase().indexOf(s) >= 0) {
                selected.push(loc);
            }
        }
        selected.sort((a, b) => a.label.localeCompare(b.label));
        return selected;
    }
    const toggleLocation = (loc) => {
        let state = selectedLocations[loc.id];
        selectedLocations[loc.id] = state === false;
        handleSelectedLocations({...selectedLocations});
    };
    const LocationItems = ({locations}) => {
        return locations.map(location => {
            let checked = selectedLocations[location.id] !== false;
            return (
                <div className={'row'} style={{gap: '4px', justifyContent: 'flex-start'}}>
                    <FormControlLabel
                        label={
                            <div className={'paragraph'}>
                                {location.label}
                            </div>}
                        control={
                            <CheckBox
                                checked={checked}
                                onClick={(event) => { event.stopPropagation(); toggleLocation(location); }}
                            />}
                    />
                    <Spacer/>
                    <MapPinIcon size={'small'}/>
                </div>
            )
        })
    }
    const groupSelected = (locations) => {
        let [selected, unselected] = calcLocations(locations, selectedLocations);
        return unselected === 0;
    }
    const groupIndeterminate = (locations) => {
        let [selected, unselected] = calcLocations(locations, selectedLocations);
        return unselected > 0 && selected > 0; 
    }
    const handleSelectGroup = (locations) => {
        let selected = groupSelected(locations);
        for (let i in locations) {
            let loc = locations[i];
            selectedLocations[loc.id] = !selected;
        }
        handleSelectedLocations({...selectedLocations});
    }

    return (
        Object.keys(locationGroups ?? {}).map(groupName => {
            const group = locationGroups[groupName];
            const locations = filterLocations(group);
            console.log("*** RocationsTree", groupName, typeof(groupName)); 
            if (locations.length === 0) {
                return undefined;
            }
            return (
                <Accordion
                    defaultExpanded
                    variant={variant}
                >
                    <AccordionSummary variant={variant}>
                        <div className={'row'} style={{gap: '4px'}}>
                            <CheckBox 
                                checked={groupSelected(locations)}
                                indeterminate={groupIndeterminate(locations)} 
                                onClick={(event) => { event.stopPropagation(); handleSelectGroup(locations); }}
                            />
                            <div className={'paragraph'} style={{color: theme.palette.grey[900]}}>
                                {`${groupName || 'Ungrouped locations'} (${locations.length})`}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails
                        className={'column fullWidth'}
                        variant={variant}
                        sx={{
                            paddingLeft: '36px',
                            paddingRight: '14px',
                        }}
                    >
                        <LocationItems
                            locations={locations}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })
    )
}
