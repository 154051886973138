import React, {useState} from 'react';
import {Autocomplete, Button, IconButton, TextField} from "@mui/material";
import ModalComponent from "../../../../shared/ui/ModalComponent";
import CloseIcon from "../../../../shared/assets/icons/Close";

const allUrgency = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'High', value: 'High'},]

const ChangeUrgencyModal = ({visible, onChange}) => {

    const [chosenUrgency, setChosenUrgency] = useState('Low');

    const handleChangeUrgency = () => {
        onChange("mass_save_urgency", null, chosenUrgency)
    }

    return (
        <ModalComponent visible={visible}>
            <div style={{
                width: '472px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    lineHeight: "32px"
                }}>
                    <h3>Change urgency</h3>
                    <IconButton variant={'outlined'} size={"small"} sx={{height: "32px", padding: "8px"}}
                                onClick={onChange}>
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                    </IconButton>
                </div>
                <div style={{fontSize: "16px", fontWeight: "400", lineHeight: "24px"}}>Select the new urgency level for
                    selected action
                    items
                </div>
                <div style={{display: "flex", flexDirection: "column", gap: "4px", width: "100%"}}>
                    <div style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "var(--palette-grey-600)"
                    }}>
                        Urgency
                    </div>
                    <Autocomplete options={allUrgency} style={{width: "100%"}} value={allUrgency.find(option => option.value === chosenUrgency)}
                                  onChange={(event, newValue) => {
                                      setChosenUrgency(newValue.value);
                                  }}
                                  renderInput={(params) => <TextField {...params}/>}/>
                </div>

                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '12px',
                    alignSelf: 'stretch',
                    height: "40px"
                }}>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        style={{width: "fit-content", fontWeight: "400"}}
                        onClick={onChange}
                    >
                        Go back
                    </Button>
                    <Button
                        style={{width: "fit-content", fontWeight: "400"}}
                        onClick={handleChangeUrgency}
                    >
                        Change urgency
                    </Button>
                </div>
            </div>
        </ModalComponent>
    );
};

export default ChangeUrgencyModal;