import {Box, Button, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from "react-transition-group";

import {isPasswordValid} from 'shared/libs/Utils';
import theme from 'app/Theme';
import {resetPassword} from "shared/libs/Auth";
import {ErrorMessages} from "shared/ui/ErrorMessages";
import {Spacer} from "shared/ui/Spacer";
import cloud from 'shared/assets/svg/cloud.svg';
import cloud_1 from 'shared/assets/svg/cloud_1.svg';
import logo from 'shared/assets/png/logo.png';
import moon from 'shared/assets/svg/moon.svg';
import 'pages/Auth.css';
import 'pages/login/Login.css';
import {PasswordBar} from 'shared/ui/PasswordBar/PasswordBar';
import {PasswordField} from 'shared/ui/PasswordField';
import {PasswordStrengthIndicator} from 'shared/ui/PasswordStrengthIndicator/PasswordStrengthIndicator';

const SECTIONS = {
    LoginSection: "LoginSection",
    SuccessfullyResetPasswordSection: "SuccessfullyResetPasswordSection"
}

export default function ResetPassword() {
    const [section, setSection] = useState(SECTIONS.LoginSection)
    const loginSectionRef = useRef(null)
    const successfullyResetSectionRef = useRef(null)

    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState({});
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState({});

    useEffect(() => {
        const isValid = isPasswordValid(password)
        setPasswordStrength(isValid)
    }, [password])

    function handleLogin(event) {
        event?.preventDefault()
        const url = window.location.href;
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);

        const uid = params.get('uid');
        const token = params.get('token');

        resetPassword(password, uid, token)
            .then(() => {
                setSection(SECTIONS.SuccessfullyResetPasswordSection)
            })
            .catch(error => {
                setError(true)
                setErrorData(error?.response?.data)
            })
    }

    function LoginSection(props) {
        return (
            <Box className={'section'} {...props}>
                <Box
                    className={'form'}
                    component="form"
                    onSubmit={handleLogin}
                    sx={{height: '444px'}}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <Spacer/>
                    <Typography variant={'label'}>
                        Create password
                        <PasswordField onChange={event => setPassword(event.target.value)}/>
                    </Typography>
                    <PasswordBar fillPercent={passwordStrength.percent}/>
                    <PasswordStrengthIndicator
                        minLength={passwordStrength.minLength}
                        hasNumber={passwordStrength.hasNumber}
                        hasSpecialChar={passwordStrength.hasSpecialChar}
                        hasUppercaseChar={passwordStrength.hasUppercaseChar}
                    />
                    <Spacer/>
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <Button
                            type={'submit'}
                            disabled={!passwordStrength.isValid}
                            onClick={handleLogin}
                            sx={{width: '160px'}}
                        >
                            Log in
                        </Button>
                    </div>
                </Box>
            </Box>
        )
    }

    function SuccessfullyResetPasswordSection(props) {
        return (
            <div className={'section'} {...props}>
                <Box
                    className={'form'}
                    component="form"
                    autoComplete="off"
                    sx={{height: '444px'}}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <Spacer/>
                    <Box className={'sectionHeader'}>
                        <h3>Congratulations!</h3>
                        <div className={'subtitle'}>
                            You have successfully reset a password. <br/>
                            Now you can log in to your account.
                        </div>
                    </Box>
                    <ErrorMessages error={error} errorData={errorData}/>
                    <Spacer/>
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <Button href={'login'} sx={{width: '160px'}}>
                            Log in
                        </Button>
                    </div>
                </Box>
            </div>
        )
    }


    return (
        <div className={'background'}>
            <div className={'dawnGradient'}>
                <div className={'leftGradient'}>
                    <img className={'cloud_0'} src={cloud} alt={'cloud'}/>
                    <div className={'cloudMoonArea'}>
                        <img className={'moon'} src={moon} alt={'moon'}/>
                        <img className={'cloud_1'} src={cloud_1} alt={'cloud_1'}/>
                    </div>
                    <img className={'cloud_2'} src={cloud} alt={'cloud'}/>
                    <img className={'cloud_3'} src={cloud} alt={'cloud'}/>
                    <div className={'container'}>
                        <div className={'leftContainer'}>
                            <div style={{flexGrow: 1, display: 'grid',}}>
                                <CSSTransition
                                    in={section === SECTIONS.LoginSection}
                                    nodeRef={loginSectionRef}
                                    timeout={1000}
                                    classNames={'section'}
                                    unmountOnExit
                                >
                                    {LoginSection({ref: loginSectionRef})}
                                </CSSTransition>
                                <CSSTransition
                                    in={section === SECTIONS.SuccessfullyResetPasswordSection}
                                    nodeRef={successfullyResetSectionRef}
                                    timeout={1000}
                                    classNames={'section'}
                                    unmountOnExit
                                >
                                    {SuccessfullyResetPasswordSection({ref: successfullyResetSectionRef})}
                                </CSSTransition>
                            </div>
                        </div>
                        <div/>
                        <div className={'alreadySignedUpContainer'}>
                            <div className={'sectionHeader alreadySignedUpSection'}>
                                <h3 className={'white'}>
                                    Already signed up?
                                </h3>
                                <div
                                    className={'subtitle'}
                                    style={{marginTop: '25px', color: theme.palette.grey[100],}}
                                >
                                    Welcome to <b>Baron weather</b>! To continue you will need to <br/>
                                    create a new account for the Baron Weather API site
                                </div>
                                <Spacer/>
                                <Button
                                    variant={'outlined'}
                                    href={'signup'}
                                    sx={{width: '160px'}}
                                >
                                    Sign up
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}