import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Accordion,
    FormControlLabel
} from '@mui/material';
import { api } from 'shared/libs/Auth';
import { CheckBox } from "shared/ui/CheckBox";
import RecipientLine from 'entities/contact/ui/RecipientLine';
import Contacts from 'widgets/contact/Contacts';

import {SearchInput} from "shared/ui/SearchInput";
import PlusIcon from "shared/assets/icons/Plus";
import AccordionSummaryUserGroup from "shared/ui/Accordion/AccordionSummaryUserGroup";
import AccordionDetailsUserGroup from "shared/ui/Accordion/AccordionDetailsUserGroup";
import theme from "app/Theme";


export default function RecipientList({contactsData = {}, onContactsDataChange, selfNotifyData = {}, onSelfNotify ,showSidebar, setShowSidebar }) {    
    const [userContacts, setUserContacts] = useState([]);
    const [groupUsers, setGroupUsers] = useState([]);
    const [userInfo, setUserInfo] = useState([])

    const [searchQuery, setSearchQuery] = useState('');
    const [showContacts, setShowContacts] = useState(false);

    const [allowSelfNotificationEmail, setAllowSelfNotificationEmail] = useState(selfNotifyData.allow_self_notification_email !== undefined ? selfNotifyData.allow_self_notification_email : true);
    const [allowSelfNotificationSms, setAllowSelfNotificationSms] = useState(selfNotifyData.allow_self_notification_sms !== undefined ? selfNotifyData.allow_self_notification_sms : true);

    useEffect(() => {
        onSelfNotify({ allow_self_notification_email: allowSelfNotificationEmail, allow_self_notification_sms: allowSelfNotificationSms });
    }, [allowSelfNotificationEmail, allowSelfNotificationSms]);

    const calculateGroupCheckboxState = (groupName, type, contacts, contactsData) => {
        const groupContacts = contacts.filter(contact => contact.group === groupName);
        const activeGroupContacts = groupContacts.filter(contact => type === 'email' ? contact.email : contact.phone);
        if (activeGroupContacts.length === 0) {
            return { checked: false, indeterminate: false, disabled: true };
        }

        const areAllChecked = activeGroupContacts.every(contact => contactsData[contact.id] && contactsData[contact.id][type]);
        const areSomeChecked = activeGroupContacts.some(contact => contactsData[contact.id] && contactsData[contact.id][type]);
    
        return {
            checked: areAllChecked,
            indeterminate: areSomeChecked && !areAllChecked,
            disabled: false
        };
    };

    const handleGroupCheckboxClick = (e, groupName, type) => {
        e.stopPropagation();
        // Toggling the group checkbox state
        const isGroupChecked = e.target.checked;

        let updatedContactsData = {
            ...contactsData,
        };
        userContacts.forEach(contact => {
            if (contact.group === groupName) {
                const id = contact.id;
                const updatedValue = isGroupChecked ? (type === 'email' ? !!contact.email : !!contact.phone) : false;
                updatedContactsData = { // new contact data calculation
                    ...updatedContactsData,
                    [id]: {
                        ...updatedContactsData[id], // current condition of the contact
                        [type]: updatedValue
                    }
                };
            }
        });
        onContactsDataChange(updatedContactsData);
    };

    const calculateGlobalCheckboxState = (type) => {
        const activeContacts = userContacts.filter(contact => type === 'email' ? contact.email : contact.phone);
        if (activeContacts.length === 0 && !userInfo) {
            return { checked: false, indeterminate: false, disabled: true };
        }

        const areAllChecked = activeContacts.every(contact => Boolean(contactsData[contact.id]?.[type])) && (type === 'email' ? allowSelfNotificationEmail : allowSelfNotificationSms);
        const areSomeChecked = activeContacts.some(contact => Boolean(contactsData[contact.id]?.[type])) || (type === 'email' ? allowSelfNotificationEmail : allowSelfNotificationSms);

        return {
            checked: areAllChecked,
            indeterminate: areSomeChecked && !areAllChecked,
            disabled: false
        };
    };

    const handleGlobalCheckboxClick = (type) => {
        const isAllChecked = calculateGlobalCheckboxState(type).checked
        const newContactsData = { ...contactsData };

        userContacts.forEach(contact => {
            const contactFieldExists = type === 'email' ? !!contact.email : !!contact.phone;
            if (contactFieldExists) {
                newContactsData[contact.id] = { ...newContactsData[contact.id], [type]: !isAllChecked };
            }
        });

        handleUserCheckedChange(!isAllChecked, type === 'email' ? 'email' : 'phone');
        onContactsDataChange(newContactsData);
    };

    const updateContactData = (updatedContact) => {
        loadUserContacts();
        setUserContacts(prevContacts => 
            prevContacts.map(contact => 
                contact.id === updatedContact.id ? updatedContact : contact
            )
        );
    };

    const handleNotificationCheckedChange = (contact, isChecked, type) => {
        const id = contact.id;
        const updatedValue = isChecked ? (type === 'email' ? !!contact.email : !!contact.phone) : false;
    
        // new contact data calculation
        const updatedContactsData = {
            ...contactsData, // current condition
            [id]: {
                ...contactsData[id], // current condition of the contact
                [type]: updatedValue
            }
        };
    
        onContactsDataChange(updatedContactsData);
    };

    const handleUserCheckedChange = (isChecked, type) => {
        if (type === 'email') {
            setAllowSelfNotificationEmail(isChecked);
            onSelfNotify({ allow_self_notification_email: isChecked, allow_self_notification_sms: allowSelfNotificationSms });
        } else {
            setAllowSelfNotificationSms(isChecked);
            onSelfNotify({ allow_self_notification_email: allowSelfNotificationEmail, allow_self_notification_sms: isChecked });
        }
    };

    // Handler for show Contacts
    const handleShowContacts = () => {
        setShowContacts(true);
        setShowSidebar(false);
    };    
    const handleHideContacts = () => {
        setShowContacts(false);
        setShowSidebar(true);
    };

    const [allGroups, setAllGroups] = useState([]);
    useEffect(() => {
        const groupSet = new Set();
        userContacts.forEach(contact => {
            if (contact.group && !groupSet.has(contact.group)) {
                groupSet.add(contact.group);
            }
        });
        setAllGroups([...groupSet]);
    }, [userContacts]);

    const addGroup = (newGroup) => {
        setAllGroups(prevGroups => [...prevGroups, newGroup]);
    };

    const sortAndGroupContacts = (contacts) => {
        // Sorting contacts
        contacts.sort((a, b) => a.name.localeCompare(b.name));

        const groups = {};
        let noGroupContacts = [];
    
        contacts.forEach(contact => {
            if (contact.group) {
                const groupName = contact.group;
                groups[groupName] = groups[groupName] || [];
                groups[groupName].push(contact);
            } else {
                noGroupContacts.push(contact);
            }
        });

        // Sorting groups
        const sortedGroups = Object.keys(groups).sort().map(groupName => ({
            groupName,
            contacts: groups[groupName]
        }));

        return { sortedGroups, noGroupContacts };
    };

    const loadUserContacts = async () => {
        try {
            const response = await api.get('/contacts');
            const contacts = response.data;
            if (!Array.isArray(contacts)) {
                throw new Error('Data is not an array');
            }

            const { sortedGroups, noGroupContacts } = sortAndGroupContacts(contacts);

            api.get('/user').then(response => setUserInfo({...response.data[0], status: 'Active subscription'}))

            setUserContacts(contacts);
            setGroupUsers(sortedGroups);
            //setNoGroupUsers(noGroupContacts);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        loadUserContacts();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const getFilteredNoGroupContacts = userContacts.filter(contact =>
        !contact.group &&
        (contact.name.toLowerCase().includes(searchQuery) ||
        (contact.email && contact.email.toLowerCase().includes(searchQuery)) ||
        (contact.phone && contact.phone.toString().toLowerCase().includes(searchQuery)))
    );

    const getFilteredUserInfo = (
        (userInfo.username && userInfo.username.toLowerCase().includes(searchQuery)) ||
        (userInfo.email && userInfo.email.toLowerCase().includes(searchQuery)) ||
        (userInfo.phone && userInfo.phone.toLowerCase().includes(searchQuery))
    );

    const getFilteredGroupContacts = () => {
        return groupUsers.map(({ groupName, contacts }) => {
            const filteredContacts = contacts.filter(contact =>
                contact.name.toLowerCase().includes(searchQuery) ||
                (contact.email && contact.email.toLowerCase().includes(searchQuery)) ||
                (contact.phone && contact.phone.toString().toLowerCase().includes(searchQuery))
            );
    
            return { groupName, contacts: filteredContacts };
        }).filter(group => group.contacts.length > 0);
    };

    const updateContactsData = (newData, oldData) => {
        setUserContacts(newData);
        const { sortedGroups, noGroupContacts } = sortAndGroupContacts(newData);
        setGroupUsers(sortedGroups);

        const oldIds = oldData.map(contact => contact.id);
        const newestContacts = newData.filter(contact => !oldIds.includes(contact.id));
        const newestContactsData = {...contactsData, ...newestContacts.reduce((acc, contact) => {
            acc[contact.id] = {
                email: !!contact.email,
                phone: !!contact.phone
            };
            return acc;
        }, {})};

        onContactsDataChange(newestContactsData)
        //setNoGroupUsers(noGroupContacts);
    };
 
    const emailGlobalCheckboxState = calculateGlobalCheckboxState('email');
    const phoneGlobalCheckboxState = calculateGlobalCheckboxState('phone');

    return (
        <>
            {showSidebar && (
                <>
                <Box className={'row fullWidth'} sx={{justifyContent:'space-between'}}>
                    <Box className={'subtitle medium'} sx={{ textTransform: 'uppercase'}}>
                        Recipients
                    </Box>
                
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={handleShowContacts}
                        startIcon={<PlusIcon/>}
                    >
                        Add contacts
                    </Button>                  
                </Box>
                </>
            )}
           
            {showContacts ? (
                <Contacts 
                    sx={{width: '100%'}}
                    contacts={userContacts}
                    groups={groupUsers}
                    onHideContacts={handleHideContacts} 
                    onUpdateContacts={updateContactsData}
                />
            ) : (<>         
                <SearchInput
                    className={'fullWidth'}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={'Search for contact'}
                />
                {/* <NotificationRecipients recipients={Object.values(userContacts).filter(contact => !!contactsData[contact.id]?.email)}>
                    <Typography className={'label'}>
                        Email:
                    </Typography>
                </NotificationRecipients>
                <NotificationRecipients recipients={Object.values(userContacts).filter(contact => !!contactsData[contact.id]?.phone)}>
                    <Typography className={'label'}>
                        Send SMS:
                    </Typography>
                </NotificationRecipients> */}


                <Box className={"globalRecipientCheckboxes fullWidth"}
                    sx={{
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',

                    }}
                >
                    <Box
                        className={'row gap4'}
                        sx={{width: '140px', flexGrow: 1}}
                    >
                        <Typography sx={{ color: theme.palette.other.tooltip }}>
                            Select all
                        </Typography>
                    </Box>
                    <Box
                        className={'row gap8'}
                        sx={{width: '30px', flexGrow: 1}}
                    >
                        <FormControlLabel 
                            control={
                                <CheckBox
                                    onClick={() => handleGlobalCheckboxClick('email')}
                                    checked={emailGlobalCheckboxState.checked}
                                    indeterminate={emailGlobalCheckboxState.indeterminate}
                                    disabled={emailGlobalCheckboxState.disabled}
                                />
                            }
                            label={
                                <Typography className={'medium'} sx={{ color: theme.palette.other.tooltip }}>
                                    All emails
                                </Typography>
                            }
                        />
                    </Box>
                    <Box
                        className={'row gap8'}
                        sx={{width: '254px'}}
                    >
                        <FormControlLabel 
                            control={
                                <CheckBox
                                    onClick={() => handleGlobalCheckboxClick('phone')}
                                    checked={phoneGlobalCheckboxState.checked}
                                    indeterminate={phoneGlobalCheckboxState.indeterminate}
                                    disabled={phoneGlobalCheckboxState.disabled}
                                />
                            }
                            label={
                                <Typography className={'medium'} sx={{ color: theme.palette.other.tooltip }}>
                                    All phones
                                </Typography>
                            }
                        />
                    </Box>
                </Box>



                <Box className={'recipientList'} style={{maxHeight:"none"}}>
                    {
                        getFilteredUserInfo &&
                        <Box className={'row gap8'}>
                            <RecipientLine
                                key={userInfo.id}
                                contact={userInfo}
                                onUpdateContact={updateContactData}
                                emailChecked={allowSelfNotificationEmail}
                                smsChecked={allowSelfNotificationSms}
                                onCheckedChange={(isChecked, type) => handleUserCheckedChange(isChecked, type)}
                                isUser={true}
                            />
                        </Box>
                    }
                    {getFilteredGroupContacts().map(({ groupName, contacts }) => {
                        const emailCheckboxState = calculateGroupCheckboxState(groupName, 'email', userContacts, contactsData);
                        const phoneCheckboxState = calculateGroupCheckboxState(groupName, 'phone', userContacts, contactsData);
                
                        return (
                        <Accordion key={groupName}>
                            <AccordionSummaryUserGroup
                                aria-controls={`panel-${groupName}-content`}
                                id={`panel-${groupName}-header`}
                            >
                                <Box
                                    className={'row gap4'}
                                    sx={{cursor: 'pointer', width: '200px', flexGrow: 1}}
                                >
                                    <Typography sx={{color: theme.palette.grey[500]}}>
                                        {groupName} ({contacts.length})
                                    </Typography>
                                </Box>
                                <Box className={'row gap8'} sx={{width: '107px', flexGrow: 1}}>
                                    <CheckBox
                                        onClick={(e) => handleGroupCheckboxClick(e, groupName, 'email')}
                                        checked={emailCheckboxState.checked}
                                        indeterminate={emailCheckboxState.indeterminate}
                                        disabled={emailCheckboxState.disabled}
                                    />
                                    <Typography sx={{color: theme.palette.grey[500]}}>
                                        Email
                                    </Typography>
                                </Box>
                                <Box className={'row gap8'} sx={{width: '197px'}}>
                                    <CheckBox
                                        onClick={(e) => handleGroupCheckboxClick(e, groupName, 'phone')}
                                        checked={phoneCheckboxState.checked}
                                        indeterminate={phoneCheckboxState.indeterminate}
                                        disabled={phoneCheckboxState.disabled}
                                    />
                                    <Typography sx={{color: theme.palette.grey[500]}}>
                                        Phone
                                    </Typography>
                                </Box>
                            </AccordionSummaryUserGroup>
                            <AccordionDetailsUserGroup className={'gap8'}>
                                <Box className={'column gap8 fullWidth'}>
                                {contacts.map(contact => (
                                    <RecipientLine
                                        key={contact.id}
                                        contact={contact}
                                        onUpdateContact={updateContactData}
                                        emailChecked={!!contactsData[contact.id]?.email}
                                        smsChecked={!!contactsData[contact.id]?.phone}
                                        onCheckedChange={(isChecked, type) => handleNotificationCheckedChange(contact, isChecked, type)}
                                        allGroups={allGroups}
                                        addGroup={addGroup}
                                        editable={true}
                                    />
                                ))}
                                </Box>
                            </AccordionDetailsUserGroup>
                        </Accordion>
                    );
                    })}
                    {/* Add ungrouped users */}
                    <Box className={'column gap8 fullWidth'}>
                    {getFilteredNoGroupContacts.map(contact => (
                        <RecipientLine
                            key={contact.id}
                            contact={contact}
                            onUpdateContact={updateContactData}
                            emailChecked={!!contactsData[contact.id]?.email}
                            smsChecked={!!contactsData[contact.id]?.phone}
                            onCheckedChange={(isChecked, type) => handleNotificationCheckedChange(contact, isChecked, type)}
                            allGroups={allGroups}
                            addGroup={addGroup}
                            editable={true}
                        />
                    ))}
                    </Box>
                </Box>
            </>)}
        </>
    );
}
