import {createSvgIcon} from "@mui/material/utils";

export default function TrashIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 5.06689H17.441C17.01 5.06689 16.629 4.79291 16.492 4.38391L16.176 3.43384C15.903 2.61684 15.14 2.06689 14.279 2.06689H9.71997C8.85797 2.06689 8.095 2.61694 7.823 3.43494L7.50702 4.38293C7.37102 4.79193 6.98898 5.06689 6.55798 5.06689H3C2.448 5.06689 2 5.51389 2 6.06689C2 6.61989 2.448 7.06689 3 7.06689H4.065L4.81598 18.3329C4.95498 20.4269 6.70801 22.0669 8.80701 22.0669H15.194C17.293 22.0669 19.046 20.4259 19.185 18.3329L19.936 7.06689H21C21.552 7.06689 22 6.61989 22 6.06689C22 5.51389 21.552 5.06689 21 5.06689ZM9.72101 4.06689L14.28 4.06592L14.596 5.01587C14.602 5.03387 14.611 5.04889 14.618 5.06689H9.38397C9.38997 5.04889 9.40001 5.03289 9.40601 5.01489L9.72101 4.06689ZM17.189 18.2C17.119 19.247 16.243 20.0669 15.193 20.0669H8.80603C7.75703 20.0669 6.88 19.247 6.81 18.2L6.06897 7.06689H6.55902C6.66602 7.06689 6.77098 7.05287 6.87598 7.04187C6.91898 7.04787 6.95598 7.06689 7.00098 7.06689H17.001C17.045 7.06689 17.083 7.04687 17.126 7.04187C17.231 7.05287 17.335 7.06689 17.443 7.06689H17.933L17.189 18.2ZM15 11.0669V16.0669C15 16.6199 14.552 17.0669 14 17.0669C13.448 17.0669 13 16.6199 13 16.0669V11.0669C13 10.5139 13.448 10.0669 14 10.0669C14.552 10.0669 15 10.5139 15 11.0669ZM11 11.0669V16.0669C11 16.6199 10.552 17.0669 10 17.0669C9.448 17.0669 9 16.6199 9 16.0669V11.0669C9 10.5139 9.448 10.0669 10 10.0669C10.552 10.0669 11 10.5139 11 11.0669Z"
                fill="currentColor"/>
        </svg>, 'Trash')
    return <Icon {...props} data-cy="trash-icon"/>
}