import PhoneInput from 'react-phone-input-2'
import React, {useEffect, useState} from 'react'
import 'react-phone-input-2/lib/material.css'
import 'shared/ui/PhoneField/PhoneField.css'
import {Spacer} from 'shared/ui/Spacer'

export function PhoneField({InputProps, slotProps, onChange = () => {}, errorBorder= true, phone, ['data-cy']: dataCy, ...other}) {
    const [isValidPhone, setIsValidPhone] = useState(!errorBorder || phone !== '')
    const [phoneState, setPhoneState] = useState(phone)

    const isValidPhoneNumber = (inputNumber, phoneData) => {
        if (errorBorder && inputNumber === '') {
            return false
        } else if (!errorBorder && inputNumber === '') {
            return true
        }

        const startsWithDialCode = inputNumber.startsWith(phoneData.dialCode)
        const dialCodeStartsWithInput = phoneData.dialCode.startsWith(inputNumber)
        const correctLength = inputNumber.length === phoneData.format.split('').filter((char) => char === '.').length

        return (startsWithDialCode || dialCodeStartsWithInput) && correctLength
    }

    const handlePhoneChange = (phoneInput, phoneData) => {
        setPhoneState(phoneInput)
        const isValid = isValidPhoneNumber(phoneInput, phoneData)
        setIsValidPhone(isValid)

        onChange(isValid ? phoneInput : 'Invalid')
    }

    return (
        <div data-cy={dataCy}
            style={{display: 'grid', ...slotProps?.root?.sx, padding: '0 1px'}}>
            <PhoneInput
                className={InputProps?.startAdornment ? 'startAdornment' : ''}
                autoComplete={'on'}
                specialLabel={''}
                country={'us'}
                onlyCountries={['us']}
                defaultValue={''}
                disableDropdown={true}
                fullWidth
                value={phoneState}
                masks={{th: '... ... ... ... .'}} // custom number masks for unusual countries numbers
                onChange={(phoneInput, phoneData) => handlePhoneChange(phoneInput, phoneData)}
                isValid={isValidPhone}
                disabled={InputProps?.readOnly}
                {...other}
            />
            <div
                className={'row fullWidth overlap'}
                style={{
                    alignItems: 'center',
                    padding: '0 12px',
                }}
            >
                <div style={{zIndex: 1, display: 'flex', alignItems: 'center'}}>
                    {InputProps?.startAdornment}
                </div>
                <Spacer/>
                <div style={{zIndex: 1, display: 'flex', alignItems: 'center'}}>
                    {InputProps?.endAdornment}
                </div>
            </div>
        </div>
    )
}
