import {createSvgIcon} from "@mui/material/utils";


export default function DesktopSaveIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 2H7C4.243 2 2 4.243 2 7V13C2 15.757 4.243 18 7 18H11V20H8C7.448 20 7 20.447 7 21C7 21.553 7.448 22 8 22H16C16.552 22 17 21.553 17 21C17 20.447 16.552 20 16 20H13V18H17C19.757 18 22 15.757 22 13V7C22 4.243 19.757 2 17 2ZM20 13C20 14.654 18.654 16 17 16H7C5.346 16 4 14.654 4 13V7C4 5.346 5.346 4 7 4H17C18.654 4 20 5.346 20 7V13Z"
                fill="currentColor"/>
            <path
                d="M8.29301 9.64307C7.90201 10.0341 7.90201 10.6661 8.29301 11.0571L11.293 14.0571C11.488 14.2521 11.744 14.3501 12 14.3501C12.256 14.3501 12.5119 14.2521 12.7069 14.0571L15.7069 11.0571C16.0979 10.6661 16.0979 10.0341 15.7069 9.64307C15.3159 9.25207 14.684 9.25207 14.293 9.64307L13 10.9362V6.3501C13 5.7971 12.552 5.3501 12 5.3501C11.448 5.3501 11 5.7971 11 6.3501V10.9362L9.70695 9.64307C9.31595 9.25207 8.68401 9.25207 8.29301 9.64307Z"
                fill="currentColor"/>
        </svg>
        , 'DesktopSave')
    return <Icon {...props} data-cy="desktop-save-icon"/>
}