import {createSvgIcon} from "@mui/material/utils";

export default function MenuIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 5C2 4.447 2.448 4 3 4H16C16.552 4 17 4.447 17 5C17 5.553 16.552 6 16 6H3C2.448 6 2 5.553 2 5ZM21 11H3C2.448 11 2 11.447 2 12C2 12.553 2.448 13 3 13H21C21.552 13 22 12.553 22 12C22 11.447 21.552 11 21 11ZM12 18H3C2.448 18 2 18.447 2 19C2 19.553 2.448 20 3 20H12C12.552 20 13 19.553 13 19C13 18.447 12.552 18 12 18Z"
                fill="currentColor"/>
        </svg>, 'Menu')
    return <Icon {...props} data-cy="menu-icon"/>
}