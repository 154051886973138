import {createSvgIcon} from "@mui/material/utils";

export default function EyeIcon(props) {
    const Icon = createSvgIcon(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4386 9.98755C20.097 7.75155 17.0858 4.00049 11.9972 4.00049C6.90959 4.00049 3.89842 7.75155 2.55579 9.98755C1.81099 11.2285 1.81099 12.7715 2.55579 14.0125C3.89842 16.2485 6.90959 19.9995 11.9972 19.9995C17.0858 19.9995 20.097 16.2485 21.4386 14.0125C22.1834 12.7715 22.1834 11.2295 21.4386 9.98755ZM19.7241 12.9835C18.5994 14.8575 16.1011 18.0005 11.9972 18.0005C7.89432 18.0005 5.395 14.8565 4.27031 12.9835C3.90641 12.3775 3.90641 11.6235 4.27031 11.0165C5.395 9.14348 7.89432 5.99951 11.9972 5.99951C16.1011 5.99951 18.5994 9.14248 19.7241 11.0165C20.088 11.6235 20.088 12.3775 19.7241 12.9835ZM11.9972 7.50049C9.51687 7.50049 7.49843 9.51949 7.49843 12.0005C7.49843 14.4815 9.51687 16.5005 11.9972 16.5005C14.4775 16.5005 16.496 14.4815 16.496 12.0005C16.496 9.51949 14.4775 7.50049 11.9972 7.50049ZM11.9972 14.5005C10.6196 14.5005 9.49788 13.3795 9.49788 12.0005C9.49788 10.6215 10.6196 9.50049 11.9972 9.50049C13.3748 9.50049 14.4965 10.6215 14.4965 12.0005C14.4965 13.3795 13.3748 14.5005 11.9972 14.5005Z"
                fill="currentColor"/>
        </svg>, 'Eye')
    return <Icon {...props} data-cy="eye-icon"/>
}

