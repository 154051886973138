import {Typography} from "@mui/material";


/**
 * Renders a list of triggered rules.
 *
 * @param {string} rules A string of rules in the format:
 *
 * ```
 * product_name::rule_text::start_time_1,end_time_1,value_1::start_time_2,end_time_2,value_2::...::f|c\n
 * product_name1::rule_text::start_time_1,end_time_1,value_1::start_time_2,end_time_2,value_2::...::f|c
 * ```
 * where:
 *
 * * `\n` line separator between rules
 * * `f` or `c` at the end of the line indicates whether the rule is for a forecast or current value.
 *
 * @param {...*} other Other props to be passed to the `Typography` component.
 *
 * @returns {ReactElement} A list of `Typography` components.
 */
export default function TriggeredRules({rules, ...other}) {
    return (
        rules.split('\n').map(rule => {
            const splittedRule = rule.split("::")
            const productName = splittedRule[0]
            const productMeta = splittedRule[2].split(",")
            const productValue = productMeta[productMeta.length - 1]

            return (
                <Typography {...other}>
                    {productName} = {productValue}
                </Typography>
            )
        })
    )
}