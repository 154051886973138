import {fetchProducts} from "../../../pages/alerts/api/FetchAlerts";

fetchProducts().then((data) => {
    const groups = {};
    const numeric_relations = ['=', '!=', '<', '>', '<=', '>=', '><'];
    const text_relations = ['=', '!='];  // wl-219
    const time_relations = ['d><'];
    for (let idx in data) {
        const product = data[idx];
        if (product.result_type === 'numeric') product.relations = numeric_relations;
        else if (product.result_type === 'time') product.relations = time_relations;
        else product.relations = text_relations;
        if (!product.product_id.indexOf('spc-') || !product.product_id.indexOf('air-quality-'))  // wl-294
            product.relations = [...product.relations, '<', '>', '<=', '>=', '><']
        apiProducts.push(product);
        productsDict[product.name] = product;
        if (!groups[product.product_group])
            groups[product.product_group] = {name: product.product_group, products: [product,]};
        else groups[product.product_group].products.push(product);
    }
    for (let group in groups) productGroups.push(groups[group]);
});

export const apiProducts = [];
export const productGroups = [];
export const productsDict = {};
export const DICTIONARY_RELATIONS = {
    '=' : 'Is',
    '!=' : 'Is Not',
    '<' : 'Less than',
    '>' : 'Greater than',
    '<=' : 'Less than or Equal to',
    '>=' : 'Greater than or Equal to',
    '><': 'Between',
    'd><': 'Range',
}
