import theme from "../../app/Theme";
import {MenuItem} from "@mui/material";
import {Select} from "../../shared/ui/Select";
import React from "react";


export const SeveritySelector = ({severity, onChange}) => {

    return (<Select
            placeholder={''}
            defaultValue={severity}
            onChange={onChange}
            InputProps={{
                startAdornment: <div className={'paragraph'} style={{color: theme.palette.grey[400]}}>
                    Severity:
                </div>
            }}
        >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Critical"}>Critical</MenuItem>
            <MenuItem value={"Severe"}>Severe</MenuItem>
            <MenuItem value={"Moderate"}>Moderate</MenuItem>
        </Select>)
}