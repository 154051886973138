import cloudyIcon from "../../shared/assets/weatherIcons/cloudy.svg";
import dangerRoadIcon from "../../shared/assets/weatherIcons/danger_road.svg";
import floodingIcon from "../../shared/assets/weatherIcons/flooding.svg";
import floodingRainIcon from "../../shared/assets/weatherIcons/flooding_rain.svg";
import foggyIcon from "../../shared/assets/weatherIcons/foggy.svg";
import generalCautionIcon from "../../shared/assets/weatherIcons/general_caution.svg";
import hailIcon from "../../shared/assets/weatherIcons/hail.svg";
import highTempIcon from "../../shared/assets/weatherIcons/high_temp.svg";
import hurricaneIcon from "../../shared/assets/weatherIcons/hurricane.svg";
import iceIcon from "../../shared/assets/weatherIcons/ice.svg";
import lightningIcon from "../../shared/assets/weatherIcons/lightning.svg";
import lowTempIcon from "../../shared/assets/weatherIcons/low_temp.svg";
import poorAirIcon from "../../shared/assets/weatherIcons/poor_air.svg";
import rainIcon from "../../shared/assets/weatherIcons/rain.svg";
import snowIcon from "../../shared/assets/weatherIcons/snow.svg";
import thunderstormIcon from "../../shared/assets/weatherIcons/thunderstorm.svg";
import tornadoIcon from "../../shared/assets/weatherIcons/tornado.svg";
import windRainIcon from "../../shared/assets/weatherIcons/wind_rain.svg";
import windSpeedIcon from "../../shared/assets/weatherIcons/wind_speed.svg";

export const weatherIcons = {
    'Cloudy': cloudyIcon,
    'Dangerous roads': dangerRoadIcon,
    'Damaging Wind': windRainIcon,
    'Flood Risk': floodingIcon,
    'Flash Flood Risk': floodingRainIcon,
    'NWS Hurricane Warning': hurricaneIcon,
    'Hailstorm warning': hailIcon,
    'Hail': hailIcon,
    'High Wind': windSpeedIcon,
    'High Winds': windSpeedIcon,
    'Rainfall Accumulation': rainIcon,
    'Heavy Rain': rainIcon,
    'Lightning': lightningIcon,
    'Poor Air Quality': poorAirIcon,
    'Tornado Warning': tornadoIcon,
    'Tornado Watch': tornadoIcon,
    'Low Visibility': foggyIcon,
    'Low Temps': lowTempIcon,
    'High Temps': highTempIcon,
    'Snow Accumulation': iceIcon,
    'NWS Lake Effect Snow Advisory': snowIcon,
    'Severe Thunderstorm': thunderstormIcon,
    'General Caution': generalCautionIcon,
};
