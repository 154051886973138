import React from 'react'
import {Typography} from '@mui/material'
import {Box} from '@mui/system'
import {ReactComponent as Background} from 'shared/assets/emptyStates/Background.svg'
import {ReactComponent as AlertsRectangle1} from 'shared/assets/emptyStates/AlertsRectangle1.svg'
import {ReactComponent as AlertsRectangleMain} from 'shared/assets/emptyStates/AlertsRectangleMain.svg'
import {ReactComponent as AlertsRectangle2} from 'shared/assets/emptyStates/AlertsRectangle2.svg'
import {ReactComponent as AlertsRectangle3} from 'shared/assets/emptyStates/AlertsRectangle3.svg'

export default function EmptyStatesAlerts({title, text}) {
    return (
        <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                width: '600px',
                height: '360px',
                borderRadius: '12px',
                padding: '40px',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: 'white',
            }}>
                <Box sx={{position: 'absolute', right: '10px', bottom: '-7px'}}>
                    <Background/>
                </Box>
                <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{position: 'absolute', right: '0', top: '11px'}}>
                        <AlertsRectangle1/>
                    </Box>
                    <Box sx={{position: 'absolute', right: '120px', top: '82px'}}>
                        <AlertsRectangleMain/>
                    </Box>
                    <Box sx={{position: 'absolute', right: '0px', top: '153px'}}>
                        <AlertsRectangle2/>
                    </Box>
                    <Box sx={{position: 'absolute', right: '0px', top: '215px'}}>
                        <AlertsRectangle3/>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column',
                    textAlign: 'start',
                    gap: '12px',
                }}>
                    <Typography sx={{
                        fontSize: '24px',
                        fontWeight: '500',
                        width: '260px',
                        lineHeight: '32px',
                        height: '64px',
                        letterSpacing: '0.25px',
                    }}>{title}</Typography>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        width: '245px',
                        lineHeight: '20px',
                        height: '40px',
                        color: 'var(--palette-grey-500)',
                        letterSpacing: '0.25px',
                    }}>{text}</Typography>
                </Box>
            </Box>
        </Box>
    )
}
