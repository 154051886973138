import {Box, Button, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from "react-transition-group";
import {isPasswordValid} from 'shared/libs/Utils';
import {setPassword as setPasswordAuth} from "shared/libs/Auth";
import {loginUser} from 'shared/libs/Auth'
import {ErrorMessages} from "shared/ui/ErrorMessages";
import {Spacer} from 'shared/ui/Spacer'
import cloud from 'shared/assets/svg/cloud.svg';
import logo from 'shared/assets/png/logo.png';
import 'pages/Auth.css';
import {PasswordBar} from 'shared/ui/PasswordBar/PasswordBar';
import {PasswordField} from 'shared/ui/PasswordField'
import {PasswordStrengthIndicator} from 'shared/ui/PasswordStrengthIndicator/PasswordStrengthIndicator';
import 'pages/register/RegisterPage.css';

const SECTIONS = {
    SignupSection: "SignupSection",
    SuccessfullySetPasswordSection: "SuccessfullySetPasswordSection"
}

export default function CreatePassword() {
    const [section, setSection] = useState(SECTIONS.SignupSection)
    const signupSectionRef = useRef(null)
    const successfullySetSectionRef = useRef(null)

    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState({});
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState({});

    useEffect(() => {
        const isValid = isPasswordValid(password)
        setPasswordStrength(isValid)
    }, [password])

    function handleSignup(event) {
        event?.preventDefault()
        const url = window.location.href;
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);

        const uid = params.get('uid');
        const token = params.get('token');

        setPasswordAuth(password, uid, token)
            .then(responseData => {
                // TODO uncomment this if user needs to be approved by admin
                loginUser(responseData.email, password).then(() => {
                    window.location.replace('/alerts');
                })
                .catch(error => {
                    console.log(error)
                })
              
                setSection('SuccessfullySetPasswordSection')
            })
            .catch(error => {
                setError(true)
                setErrorData(error?.response?.data)
            });
    }

    function SignupSection(props) {
        return (
            <Box className={'section'}
                 {...props}
            >
                <Box className={'form'}
                     component={'form'}
                     onSubmit={handleSignup}
                     sx={{height: '600px',}}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <Spacer/>
                    <Typography variant={'label'}>
                        Create password
                        <PasswordField onChange={event => setPassword(event.target.value)} label='Create Password'/>
                    </Typography>
                    <PasswordBar fillPercent={passwordStrength.percent}/>
                    <PasswordStrengthIndicator
                        minLength={passwordStrength.minLength}
                        hasNumber={passwordStrength.hasNumber}
                        hasSpecialChar={passwordStrength.hasSpecialChar}
                        hasUppercaseChar={passwordStrength.hasUppercaseChar}
                    />
                    <ErrorMessages error={error} errorData={errorData}/>
                    <Spacer/>
                    <Box className={'row'} style={{justifyContent: 'center'}}>
                        <Button
                            type={'submit'}
                            disabled={!passwordStrength.isValid}
                            onClick={handleSignup}
                            sx={{width: '160px'}}
                        >
                            Sign up
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    function SuccessfullySetPasswordSection(props) {
        return (
            <Box className={'section'}>
                <Box className={'form'}
                     component={'form'}
                     autoComplete={'off'}
                     sx={{height: '600px',}}
                     {...props}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <Spacer/>
                    <Box className={'sectionHeader'}>
                        <h3>Congratulations!</h3>
                        <div className={'subtitle'}>
                            You have successfully set a password. <br/>
                            Now you can log in to your account.
                        </div>
                    </Box>
                    <Spacer/>
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <Button
                            type={'submit'}
                            href={'/login'}
                            sx={{width: '160px'}}
                        >
                            Log in
                        </Button>
                    </div>
                </Box>
            </Box>
        )
    }

    return (
        <div className={'background'}>
            <div className={'nightGradient'}>
                <div className={'rightGradient'}>
                    <img className={'night_cloud_0'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_1'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_2'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_3'} src={cloud} alt={'cloud'}/>
                    <div className={'container'}>
                        <div className={'alreadyHaveAccountContainer'}>
                            <div className={'sectionHeader alreadyHaveAccountSection'}>
                                <h3 className={'white'}>Already have an account?</h3>
                                <div className={'subtitle white'}>
                                    Don’t forget: If you have a previous Velocity Weather <br/>
                                    account, you will need to create a new account for the <br/>
                                    Baron Weather API site.
                                </div>
                                <Spacer/>
                                <Button
                                    variant={'outlined'}
                                    href='/login'
                                    sx={{width: '160px'}}
                                >
                                    Log in
                                </Button>
                            </div>
                        </div>
                        <div style={{flexGrow: 1, display: 'grid', }}>
                            <CSSTransition
                                in={section === SECTIONS.SignupSection}
                                nodeRef={signupSectionRef}
                                timeout={1000}
                                classNames={'section'}
                                unmountOnExit
                            >
                                {SignupSection({ref: signupSectionRef})}
                            </CSSTransition>
                            <CSSTransition
                                in={section === SECTIONS.SuccessfullySetPasswordSection}
                                nodeRef={successfullySetSectionRef}
                                timeout={1000}
                                classNames={'section'}
                                unmountOnExit
                            >
                                {SuccessfullySetPasswordSection({ref: successfullySetSectionRef})}
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}