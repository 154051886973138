import {InputAdornment} from '@mui/material'
import SearchIcon from 'shared/assets/icons/Search'
import TextField from '@mui/material/TextField'
import React from 'react'

export function SearchInput(props) {
    return (
        <TextField
            type='search'
            {...props}
            InputProps={{
                ...props?.InputProps,
                startAdornment:
                    <InputAdornment position={'start'}>
                        <SearchIcon size={'small'}/>
                    </InputAdornment>,
            }}
        />
    )
}
