import {Chip, Box, Button, Typography, MenuItem} from '@mui/material';
import {isEmpty} from "shared/libs/Utils";
import React, { useState, useEffect, useRef } from 'react';
import {
    fetchRealLocations,
    fetchAllReports,
    createReport,
    deleteReport,
    editReport
} from "../alerts/api/FetchAlerts";
import theme from "../../app/Theme";
import {Select} from 'shared/ui/Select';
import ReportItem from './ReportItem';
import ReportDetails from './ReportDetails';
import ReportNotification from './ReportNotification';
import NewReport from './NewReport';
import PlusIcon from 'shared/assets/icons/Plus';
import {DateRangePicker} from 'shared/ui/datePickers/DateRangePicker';
import AgreeModal from 'shared/ui/AgreeModal';
import {calcGroups} from "pages/alerts/ui/AlertsPage";
import {calcSelected} from "widgets/location/ui/LocationsTree";
import dayjs from "dayjs";
import {SearchInput} from "shared/ui/SearchInput";
import ItemsSelectTopBar from "../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar";

export default function ReportList({triggered, onChange}) {
    const [reports, setReports] = useState([]);
    const [openReport, setOpenReport] = useState(undefined);
    const [newReport, setNewReport] = useState(undefined);
    const [searchString, setSearchString] = useState("");
    const [severity, setSeverity] = useState('All');
    const [dateRange, setDateRange] = useState([]);
    const [todelete, setToDelete] = useState({});
    const [reportsModal, setReportsModal] = useState(false);
    const [refresh, setRefresh] = useState();
    const [wait, setWait] = useState();
    const [isOpenLocationPanel, setIsOpenLocationPanel] = useState(false);
    const [locations, setLocations] = useState();
    const groups = useRef();
    const [selectedLocations, setSelectedLocations] = useState({});
    const [searchedLocations, setSearchedLocations] = useState('');
    const modalDataRef = useRef();
    const [editingReport, setEditingReport] = useState(undefined)
    const [singleReportDelete, setSingleReportDelete] = useState({})
    const openReportRef = useRef(false);

    let showReportList = () => {
        setReportsModal(false);
        setWait(undefined);
        setOpenReport(undefined);
        setNewReport(undefined);
        setEditingReport(undefined);
    }

    useEffect(() => {
        setWait(true);
        // let locs;
        let ps = [fetchAllReports(triggered)];
        if (triggered && !locations) {
            ps.push(fetchRealLocations());
        }
        Promise.allSettled(ps).then((rr) => {
            if (rr[0].status === 'fulfilled') {
                let data = rr[0].value;
                setReports(data.data);
                setToDelete({});
                if (openReportRef.current) {
                    setReportsModal(false);
                    setWait(undefined);
                    setNewReport(undefined);
                    setEditingReport(undefined);
                    openReportRef.current = false;
                }
                else showReportList();
            }
            if (rr.length > 1 && rr[1].status === 'fulfilled') {
                let locations = rr[1].value.location;
                setLocations(locations);
                groups.current = calcGroups(locations);
            }
        });
    }, [refresh]);

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value);
    };

    let searchStringChanged = ({target}) => {
        setSearchString(target.value.trim());
    };

    // let listKey = r => (r.name+'/'+r.alertconfiguration.location.label).toLocaleLowerCase();
    const filterByDate = rr => {
        if (dateRange.length === 1) {
            return rr.filter(r => {
                let alert = r.alert;
                if (alert) {
                    const date = dayjs(alert.issue_time);
                    return date.isBetween(dateRange[0], dateRange[0], 'day', '[]')
                }
                return false;
            })
        }

        return rr.filter(r => {
            let alert = r.alert;
            if (alert) {
                const date = dayjs(alert.issue_time)
                return date.isBetween(dateRange[0], dateRange[1], 'day', '[]')
            }
            return false;
        })
    }

    function groupByDate(arr) {
        arr.sort((a, b) => dayjs(b.alert?.issue_time) - dayjs(a.alert?.issue_time)); // Sorting alerts by start date before grouping
        let groups = {};

        arr.forEach(a => {
            let dateKey = dayjs(a.alert.issue_time).format('D MMMM YYYY');
            if (groups[dateKey]) {
                groups[dateKey].push(a);
            } else {
                groups[dateKey] = [a];
            }
        });
        return groups;
    }
    let filter = rr => {
        if (severity !== 'All') {
            rr = rr.filter(x => x.alertconfiguration.weather_definition.severity === severity)
        }
        let s = searchString.toLocaleLowerCase();
        if (s) {
            rr = rr.filter(x => x.name.toLocaleLowerCase().indexOf(s) >= 0);
        }
        if (locations) {
            rr = rr.filter(r => selectedLocations[r.alertconfiguration.location.id] !== false);
        }
        if (dateRange.length) {
            rr = filterByDate(rr);
        }
        // if (!triggered) {
        //     rr.sort((a, b) => listKey(a).localeCompare(listKey(b)));
        // }
        return rr;
    };
    let filteredReports = filter(reports);
    let todeletecnt = 0;
    if (!isEmpty(todelete)) {
        // to clear marked for delete reports when they filtered out, i.e. invisible for now
        let changed = false;
        let ids = filteredReports.map(x => x.id);
        for (let id in todelete) {
            if (todelete[id] && !ids.includes(parseInt(id))) {
                delete todelete[id];
                changed = true;
            }
            else if (todelete[id]) {
                todeletecnt += 1;
            }
        }
        if (changed) {
            if (todeletecnt === 0) {
                setToDelete({});
            }
            else {
                setToDelete({...todelete});
            }
            console.log("todelete changed", todelete, todeletecnt);
        }
    }

    let delReports = () => {
        setReportsModal(true);
        let ids = isEmpty(singleReportDelete) ? Object.keys(todelete).filter(id => todelete[id] === true).map(id => parseInt(id)):Object.keys(singleReportDelete).map(id => parseInt(id));
        let delreps = []
        filteredReports.forEach(r => {
            if (ids.includes(r.id)) {
                // let loc = r.alertconfiguration.location;
                // let locationGroup = loc.location_group;
                // let locationName = loc.label;
                // if (locationGroup) {
                //     locationName = locationGroup+"/"+locationName;
                // }
                // delreps.push(r.name+"\u00A0("+locationName+")");
                delreps.push(r.weatherdefinition.name);
            }
        });
        let message;
        if (delreps.length === 1) {
            message = <Typography>Are you sure you want delete this report and all settings linked to it?</Typography>
        }
        else {
            message = <Typography sx={{fontSize:"18px"}}>Are you sure you want delete these reports and all settings linked to them?</Typography>
        }
            
        let msg = <Box className='column' sx={{alignContent: 'stretch', overflow: "hidden", maxWidth: "100%"}}>
                      {message}
                      <Box sx={{maxHeight: "200px", overflowY: "auto"}}>{delreps.map(x => <span>{x}<br/></span>)}</Box>
                  </Box>
        modalDataRef.current = {
            message: msg,
            agreeFunc: doDeleteReports,
            title: 'Delete reports',
            mode: "deleting",
            agreeMsg: 'Yes, delete'
        };
        setSingleReportDelete({});
    }

    let askCancelNewReport = () => {
        setReportsModal(true);
        modalDataRef.current = {
            message: 'Are you sure you want discard your changes?',
            agreeFunc: agree => { if (agree) showReportList(); else setReportsModal(false); },
            title: 'Discard report',
            agreeMsg: 'Discard'
        };
    }

    const askCancelEditingReport = () => {
        setReportsModal(true);
        modalDataRef.current = {
            message: 'Are you sure you want discard your changes?',
            agreeFunc: agree => {
                if (agree) {
                    showReportList();
                }
                setReportsModal(false)
            },
            title: 'Discard report editing',
            agreeMsg: 'Discard'
        };
    }

    let clearAndSet  = (singleid) => {
        // to change todelete as a local variable to be visible by doDeleteReports
        // and to change it as a state
        for (let id in singleReportDelete) {
            singleReportDelete[id] = false;
        }
        singleReportDelete[singleid] = true;
        setSingleReportDelete({...singleReportDelete});
    }

    let onAction = (report, action, value) => {
        console.log("onAction", action, report, value);
        if (action === 'open_report') {
            setOpenReport(report);
        }
        else if (action === 'close_report') {
            setToDelete({});
            setOpenReport(undefined);
        }
        else if (action === 'edit') {
            setEditingReport(report);
        }
        else if (action === 'delete_report') {
            clearAndSet(report.id);
            delReports();
        }
        else if (action === 'select') {
            let todel = todelete;
            todel[report.id] = value;
            for (let id in todel) {
                if (todel[id]) {
                    setToDelete({...todel});
                    return;
                }
            }
            setToDelete({});
        }
        else if (action === 'selectall') {
            if (value) {
                filteredReports.forEach(r => todelete[r.id] = value);
                setToDelete({...todelete});
            }
            else {
                setToDelete({});
            }
        }
        else if (action === 'delete') {
            delReports();
        }
        else if (action === 'create_report') {
            console.log("create_report", value);
            createReport(value).then((data) => {
                console.log("create_report created", data);
                setRefresh(Date.now());
            }).catch((error) => {
                console.log("create_report", error);
                showReportList();
            })
            //onChange && onChange(false);
        }
        else if (action === 'edit_report') {
            console.log("edit_report", value);
            editReport(value).then(data => {
                console.log("edit_report edited",data);
                setRefresh(Date.now());
            }).catch((error) => {
                console.log("edit_report", error);
                showReportList();
            })
        }
        else if (action === 'edit_report_from_details') {
            console.log("edit_report_from_details", value);
            editReport(value).then(data => {
                console.log("edit_report_from_details edited",data);
                console.log("data.report", data.report)
                setRefresh(Date.now());
                setOpenReport(data.report);
                openReportRef.current = true;

            }).catch((error) => {
                console.log("edit_report_from_details", error);
                showReportList();
            })
        }
        else if (action === 'cancel_create_report') {
            if (report === undefined) {
                showReportList();
            }
            else {
                askCancelNewReport();
            }
        }
        else if (action === 'cancel_edit_report') {
            if (report === undefined) {
                showReportList();
            }
            else {
                askCancelEditingReport();
            }
        }
        else if (action === 'cancel_edit_report_from_details') {
            if (report === undefined) {
                showReportList();
            }
            else {
                setWait(undefined);
                setEditingReport(undefined);
                setNewReport(undefined);
                setOpenReport(report);
            }
        }
    };
    let doDeleteReports = (agree) => {
        console.log("doDeleteReports", agree, todelete);
        if (agree) {
            let proms = [];
            const deleteFromWhere = isEmpty(singleReportDelete)?todelete:singleReportDelete
            for (let id in deleteFromWhere) {
                proms.push(deleteReport(id));
            }
            setToDelete({});
            Promise.allSettled(proms).then((results) => {
                console.log("doDeleteReports.results", results);
                setRefresh(Date.now());
            });
        }
        else {
            setReportsModal(false);
        }
    };

    let locationCount = calcSelected(groups.current, searchedLocations, selectedLocations)[0];
    //let onChangeSelectedLocations = () => true;
    let listIsHidden = () => openReport || newReport || editingReport;
    if (triggered) {
        var groupedFilteredReports = groupByDate(filteredReports);
    }

    return (
        <>
        <Box className="Reports settings" sx={{ height: '100%', display: listIsHidden()? 'none':'flex',}}>
            <Box className={"settings-toolbar"}>
                <h3>
                    Reports
                </h3>
                {!triggered &&
                    <SearchInput
                        placeholder='Search'
                        value={searchString}
                        onChange={searchStringChanged}
                    />
                }

                {/*{triggered && <><Button*/}
                {/*    variant={'outlined'}*/}
                {/*    color={'secondary'}*/}
                {/*    startIcon={<MapMarkerIcon/>}*/}
                {/*    endIcon={<div><Chip label={locationCount} size={'small'}/></div>}*/}
                {/*    onClick={() => setIsOpenLocationPanel(true)}*/}
                {/*>*/}
                {/*    Locations*/}
                {/*</Button>*/}
                {/*<LocationsFilter*/}
                {/*    anchor={'right'}*/}
                {/*    open={isOpenLocationPanel}*/}
                {/*    onClose={() => setIsOpenLocationPanel(false)}*/}
                {/*    locationCount={locationCount}*/}
                {/*    locationGroups={groups.current}*/}
                {/*    selectedLocations={selectedLocations}*/}
                {/*    onChangeSelectedLocations={setSelectedLocations}*/}
                {/*    searchedLocations={searchedLocations}*/}
                {/*    setSearchedLocations={setSearchedLocations}*/}
                {/*/></>}*/}

                <Select
                    placeholder={''}
                    defaultValue={severity || 'All'}
                    onChange={handleSeverityChange}
                    InputProps={{
                        startAdornment:
                            <div className={'paragraph'} style={{color: theme.palette.grey[400]}}>
                                Severity:
                            </div>
                    }}
                >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Critical"}>Critical</MenuItem>
                    <MenuItem value={"Severe"}>Severe</MenuItem>
                    <MenuItem value={"Moderate"}>Moderate</MenuItem>
                </Select>
                {triggered && <DateRangePicker
                    onChange={setDateRange}
                    label={"Time period:"}
                    fullWidth={false}
                />}

                {!triggered &&
                    <Button
                        startIcon={<PlusIcon/>}
                        onClick={(ev) => {
                            ev.stopPropagation();
                            setNewReport(true)
                        }}
                        sx={{marginLeft: 'auto'}}
                    >
                        New report
                    </Button>
                }
            </Box>

            {!triggered && <ItemsSelectTopBar onAction={onAction} visible={!isEmpty(todelete)}/>}

            {/*{isEmpty(reports)? <EmptyStatesScreen title={"Configured reports will appear here"} text={"You will see reports here once they are created"}/>:*/}
            <Box sx={{marginBottom: '20px', overflow: 'auto', flex: '1 1 auto'}}>
                {!triggered && filteredReports.map(r => <ReportItem key={r.id} triggered={triggered} selected={todelete && todelete[r.id]} report={r} onAction={onAction} />)}
                {triggered && Object.keys(groupedFilteredReports)
                        .sort((a, b) => dayjs(b, 'D MMMM YYYY') - dayjs(a, 'D MMMM YYYY'))
                        .map(dateKey => (
                            <React.Fragment key={dateKey}>
                                <div className={'paragraph'} style={{marginLeft: "20px", color: '#7F8F9F'}}>{dateKey}</div>
                                {groupedFilteredReports[dateKey]
                                    .map(r => (
                                    <ReportItem key={r.id} triggered={triggered} selected={todelete && todelete[r.id]} report={r} onAction={onAction} />
                                ))}
                            </React.Fragment>
                        ))
                }
            </Box>
        </Box>
        {openReport && !triggered &&
            <ReportDetails report={openReport} onAction={onAction} editing={editingReport}/>
        }
        {openReport && triggered &&
            <ReportNotification report={openReport} onAction={onAction}/>
        }
        {newReport &&
            <NewReport onAction={onAction}/>
        }
        {reportsModal &&
            <AgreeModal
                wait={wait}
                data={modalDataRef.current}
            />
        }
        {editingReport &&
            <NewReport sx={{width:'100%'}} onAction={onAction} defaultValue={editingReport}/>
        }
        </>
    )
}

