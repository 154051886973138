import {createSvgIcon} from "@mui/material/utils";

export default function CopyIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.414 6L18 2.58606C17.622 2.20806 17.12 2 16.586 2H11C8.794 2 7 3.794 7 6H6C3.794 6 2 7.794 2 10V18C2 20.206 3.794 22 6 22H13C15.206 22 17 20.206 17 18H18C20.206 18 22 16.206 22 14V7.41394C22 6.88794 21.787 6.372 21.414 6ZM17 4.41394L19.586 7H18.265C17.567 7 17 6.43299 17 5.73499V4.41394ZM13 20H6C4.897 20 4 19.103 4 18V10C4 8.897 4.897 8 6 8H7V14C7 16.206 8.794 18 11 18H15C15 19.103 14.103 20 13 20ZM18 16H11C9.897 16 9 15.103 9 14V6C9 4.897 9.897 4 11 4H15V5.73499C15 7.53499 16.465 9 18.265 9H20V14C20 15.103 19.103 16 18 16Z"
                fill="currentColor"/>
        </svg>, 'Copy')
    return <Icon {...props} data-cy="copy-icon"/>
}