import {CheckBox} from "../../../../shared/ui/CheckBox";
import React, {useState} from "react";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {Spacer} from "../../../../shared/ui/Spacer";
import MoreIcon from "../../../../shared/assets/icons/More";
import CopyIcon from "../../../../shared/assets/icons/Copy";
import PenIcon from "../../../../shared/assets/icons/Pen";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import Urgency from "./Urgency";

const ActionItemsSettingsLine = ({actionItem, onChange, selected}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) => (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        if (action) onChange(action, actionItem)
    };

    const handleDeleteActionItem = () => {
        onChange('delete_actionItem', actionItem);
    }

    const handleEditActionItem = () => {
        setAnchorEl(null);
        onChange('edit_actionItem', actionItem);
    }

    const handleDuplicateActionItem = () =>{
        setAnchorEl(null);
        onChange('duplicate_actionItem', actionItem);
    }

    const handleSelect = (event) =>{
        event.preventDefault();
        event.stopPropagation();
        onChange('select', actionItem, !selected);
    }

    return (<div style={{
            display: "flex",
            cursor: "pointer",
            height: "56px",
            alignItems: "center",
            padding: "8px 16px",
            gap: "8px",
            borderRadius: "8px",
            border: "1px solid var(--palette-grey-100)",
        }}
                 onClick={handleClose('open')}>
            <CheckBox
                checked={selected || false}
                onClick={handleSelect}
            />
            <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                {actionItem.name}
            </Typography>
            <Spacer/>

            <Urgency Urgency={actionItem.urgency}/>

            <div>
                <IconButton
                    data-cy={'more-button'}
                    id="basic-button"
                    aria-controls={isOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? 'true' : undefined}
                    onClick={handleClick}
                    variant={'outlined'}
                    style={{width: "32px", height: "32px"}}
                >
                    <MoreIcon size={'small'}/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}
                    onClose={handleClose('')}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={handleDuplicateActionItem}
                        key="duplicate"
                    >
                        <CopyIcon size={'small'}/>
                        Copy
                    </MenuItem>
                    <MenuItem
                        onClick={handleEditActionItem}
                        key="edit"
                    >
                        <PenIcon size={'small'}/>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={handleDeleteActionItem}
                        key="delete"
                        color={'error'}
                    >
                        <TrashIcon size={'small'}/>
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>);

};

export default ActionItemsSettingsLine;
