import {Box, Button, Typography, IconButton} from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import ModalComponent from 'shared/ui/ModalComponent';
import CloseIcon from "../../shared/assets/icons/Close";
import EntityGroupSelector from "./EntityGroupSelector"


export function EntityGroupSelectorPopup({moveToGroupState, onChange, groups}) {

    const [moveToGroup, setMoveToGroup] = useState({...moveToGroupState});
    const [newGroups, setNewGroups] = useState([...groups]);

    const allGroupsOptions = newGroups.filter(group => group.group !== '').map((group) => ({
        group: group.group
    }));

    const groupNames = newGroups.map(item => item.group.toLowerCase());

    const [inputStartValue, setInputStartValue] = useState(allGroupsOptions.find(group => group.group === moveToGroupState.original) || null);

    const saveGroupName = () => {
        onChange(moveToGroup);
    };

    const inputStartValueRef = useRef(inputStartValue);
    useEffect(() => {
        inputStartValueRef.current = inputStartValue;
    }, [inputStartValue]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleAddGroupClick(inputStartValueRef.current.group);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);    

    const handleAddGroupClick = (inputValue) => {
        if (inputValue) {
            const inputValueLowerCase = inputValue.toLowerCase();
            const isGroupExists = newGroups.some(group => group.group.toLowerCase() === inputValueLowerCase);
    
            if (!isGroupExists) {
                const newOption = { group: inputValue };
                const updatedGroups = [...newGroups, newOption];
    
                setNewGroups(updatedGroups);
                setInputStartValue(newOption);
                setMoveToGroup({ ...moveToGroup, new: inputValue });
            }
        }
    };

    const handleGroupChange = (newValue) => {
        setMoveToGroup({...moveToGroup, new: newValue.group})
        setInputStartValue(newValue)
    };

    return (
            <ModalComponent visible={true}>
                <Box sx={{ width: '472px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '24px'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
                        <h3>Move to group</h3>
                        <IconButton variant={'outlined'} size={"small"} sx={{height:"32px"}} onClick={() => onChange(false)}>
                            <CloseIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                    </Box>

                    <Typography sx={{ fontSize: '16px', fontWeight:"400" }}>
                        Select the target group for the selected items
                    </Typography>

                    <EntityGroupSelector
                        value={inputStartValue}
                        onChange={handleGroupChange}
                        onInputChange={(newInputGroupValue) => {
                            setMoveToGroup({...moveToGroup, new: ""})
                            if (inputStartValue?.group !== newInputGroupValue) {
                                setInputStartValue({ group: newInputGroupValue });
                            }
                        }}
                        onAddOption={handleAddGroupClick}
                        options={allGroupsOptions}
                    />


                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch', paddingTop:"12px", borderTop:"1px solid #F0F2F5"}}>
                        <Button
                            className={'spacer regular'}
                            onClick={saveGroupName}
                            disabled={!inputStartValue || inputStartValue.group === "" || moveToGroup.new === ""}
                        >
                            Move to group
                        </Button>
                        <Button
                            className={'spacer regular'}
                            onClick={() => onChange(false)}
                            variant={'outlined'}
                            color={'secondary'}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ModalComponent>
            )
}
