import {IconButton, Link, Menu, MenuItem} from "@mui/material";
import theme from "app/Theme";
import {fetchWeatherDefinition} from "pages/alerts/api/FetchAlerts";
import React from "react";
import BellIcon from "shared/assets/icons/Bell";
import CalendarIcon from "shared/assets/icons/Calendar";
import HistoryIcon from "shared/assets/icons/History";
import MoreIcon from "shared/assets/icons/More";
import WeatherIcon from "shared/assets/icons/Weather";

export default function OpenAlertMenuButton({alert, setOpenDefinition, isNotification = false}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(null);
    };

    const openWeatherDefinition = (event) => {
        event.stopPropagation()
        fetchWeatherDefinition(alert.api_alert.wdef_id).then((data) => {
            setOpenDefinition(data);
        })
    }

    const editAlert = (event) => {
        event.stopPropagation()
        fetchWeatherDefinition(alert.api_alert.wdef_id).then((data) => {
            let group_id = null;
            for (let gid in data.configurations) {
                const group = data.configurations[gid];
                for (let idx in group) {
                    const loc = group[idx];
                    if (loc['id'] === alert.api_alert.loc_id) {
                        group_id = gid;
                        break;
                    }
                }
                if (group_id !== null) break;
            }
            if (group_id !== null) window.open('/alerts/edit-alert?edit=' + group_id, '_self');
        })
    }

    const isMenuOpen = !!anchorEl

    return (<>
        <IconButton
            id={'basic-button'}
            variant={'outlined'}
            size={isNotification ? "tiny" : "small"}
            onClick={handleClick}
        >
            <MoreIcon size={'small'} sx={{color: theme.palette.grey[800]}}/>
        </IconButton>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClick={event => event.stopPropagation()}
            onClose={handleCloseMenu}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            sx={{"& .MuiPaper-root": {width: 224}}}
        >
            <MenuItem>
                <CalendarIcon size={"small"}/>
                <Link underline={'none'}
                      href={isNotification ?
                          `/map?alert_id=${alert.alert_linkid}#5/${alert.api_alert?.coordinates?.[1]}/${alert.api_alert?.coordinates?.[0]}` :
                          `/map?alert_id=${alert.alert_id}#5/${alert.api_alert.location?.coordinates?.[1]}/${alert.api_alert.location?.coordinates?.[0]}`}>
                    View on map
                </Link>
            </MenuItem>
            <MenuItem>
                <HistoryIcon size={"small"}/>
                <Link underline={'none'} href={`/timeline?alert_id=${alert.alert_linkid || alert.alert_id}`}>
                    View on timeline
                </Link>
            </MenuItem>
            <MenuItem onClick={openWeatherDefinition}>
                <WeatherIcon size={"small"}/>
                View weather definition
            </MenuItem>
            <MenuItem onClick={editAlert}>
                <BellIcon size={"small"}/>
                Edit alert configuration
            </MenuItem>
        </Menu>
    </>)
}