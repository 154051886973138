import {createSvgIcon} from "@mui/material/utils";

export default function RadioIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" version="1.1" id="svg2" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="12.000002" cy="12.000002" r="11.5" fill="currentColor" stroke="currentColor" id="circle1"
                rx="11.249999"
                ry="11.249997"/>
            <ellipse
                cx="12.000004" cy="12.000002" fill="currentColor" id="circle2" rx="5.999999" ry="5.9999986"
            />
        </svg>, 'Radio')
    return <Icon {...props} data-cy="radio-icon"/>
}
