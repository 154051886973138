import {Link} from "@mui/material";
import LinkSquareIcon from "../../shared/assets/icons/LinkSquare";
import React from "react";


export const ShowReportButton = ({reportLink, style}) =>{
    const handleClick = (event) => {
        event.stopPropagation(); 
    };
    return (
        <span className={'field row gap4'} style={style} onClick={handleClick}>
            <Link href={reportLink} target="_blank">
                    Report
            </Link>
            <LinkSquareIcon color={'info'} size={'small'}/>
        </span>
    )
}
