import CircleIcon from "../../../../shared/assets/icons/Circle";
import React from "react";

const Urgency = ({Urgency}) => {

    return (
        <div style={{
            color: `var(--palette-urgency-${Urgency})`,
            display: "flex", gap: "8px", alignItems: "center",
            padding: "0 8px"
        }}>
            <CircleIcon size={"tiny"} style={{color: `var(--palette-urgency-${Urgency})`}}/>
            {Urgency}
        </div>
    );
};

export default Urgency;
