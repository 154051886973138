import {createSvgIcon} from "@mui/material/utils";


export default function StarIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.8869 9.59497C21.6089 8.73397 20.876 8.11897 19.974 7.99097L15.924 7.41101L14.1149 3.80005C13.7129 2.99805 12.903 2.5 12 2.5C11.097 2.5 10.287 2.99805 9.88496 3.80005L8.07594 7.41101L4.02595 7.99097C3.12395 8.11997 2.39096 8.73397 2.11296 9.59497C1.83796 10.449 2.07095 11.366 2.71895 11.99L5.64094 14.798L4.95195 18.7571C4.79895 19.6351 5.15295 20.509 5.87495 21.038C6.29195 21.344 6.78296 21.5 7.27696 21.5C7.64996 21.5 8.02594 21.411 8.37294 21.23L11.9989 19.3521L15.625 21.23C16.434 21.65 17.3909 21.574 18.1239 21.038C18.8459 20.509 19.1999 19.6351 19.0469 18.7571L18.358 14.798L21.28 11.99C21.929 11.366 22.1619 10.448 21.8869 9.59497ZM19.8949 10.548L16.5889 13.724C16.3489 13.953 16.24 14.2881 16.297 14.6171L17.0769 19.1C17.1059 19.265 17.0199 19.3681 16.9419 19.4241C16.8849 19.4651 16.7329 19.55 16.5459 19.454L12.4599 17.338C12.1719 17.188 11.828 17.188 11.54 17.338L7.45396 19.454C7.26696 19.55 7.11497 19.4651 7.05797 19.4241C6.97997 19.3671 6.89396 19.265 6.92296 19.1L7.70296 14.6171C7.75996 14.2891 7.64996 13.954 7.41096 13.724L4.10496 10.548C3.97996 10.428 3.98795 10.297 4.01695 10.209C4.04195 10.132 4.11294 9.99807 4.30894 9.97107L8.87697 9.31702C9.20197 9.27002 9.48295 9.06802 9.62895 8.77502L11.672 4.69604C11.757 4.52504 11.9139 4.5 11.9989 4.5C12.0839 4.5 12.2409 4.52504 12.3259 4.69604L14.3689 8.77502C14.5149 9.06802 14.797 9.27002 15.121 9.31702L19.69 9.97107C19.886 9.99807 19.9569 10.132 19.9819 10.209C20.0119 10.297 20.0199 10.428 19.8949 10.548Z"
                fill="currentColor"/>
        </svg>
        , 'Star')
    return <Icon {...props} data-cy="star-icon"/>
}