import ActionItemsSettingsLine from "./ActionItemsSettingsLine";

const ActionItemsSettingsList = ({actionItems, searchString, urgency, chosenCategory, onChange, selectedActionItems}) => {

    return (<div style={{
        display: "flex", flexDirection: "column", flex: "1 1 auto", overflow: "auto", gap: "16px", padding: "16px 24px"
    }}>
        {actionItems.map(actionItem => {
            {
                if ((!searchString || actionItem.name.toLocaleLowerCase().indexOf(searchString) >= 0) &&
                    (actionItem.urgency === urgency || urgency === 'All') &&
                    (actionItem.category.name === chosenCategory || chosenCategory === 'All')) {
                    return (<ActionItemsSettingsLine actionItem={actionItem} onChange={onChange} key={actionItem.id} selected={selectedActionItems.includes(actionItem)}/>);
                }
            }
            return null;
        })}
    </div>)
};

export default ActionItemsSettingsList;