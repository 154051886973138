import {createSvgIcon} from "@mui/material/utils";


export default function UserIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 13C15.533 13 18 10.532 18 7.5C18 4.468 15.533 2 12.5 2C9.467 2 7 4.468 7 7.5C7 10.532 9.467 13 12.5 13ZM12.5 4C14.43 4 16 5.57 16 7.5C16 9.43 14.43 11 12.5 11C10.57 11 9 9.43 9 7.5C9 5.57 10.57 4 12.5 4ZM20.5 17.967V18.978C20.5 19.974 20.136 20.929 19.475 21.667C19.277 21.888 19.004 22 18.729 22C18.492 22 18.253 21.916 18.062 21.745C17.65 21.377 17.616 20.744 17.984 20.333C18.316 19.962 18.499 19.48 18.499 18.978V17.967C18.499 16.568 17.572 15.358 16.246 15.025C16.054 14.977 15.849 15.001 15.685 15.095C13.704 16.197 11.286 16.191 9.32397 15.101C9.15097 15.002 8.947 14.977 8.755 15.025C7.426 15.358 6.5 16.568 6.5 17.967V18.978C6.5 19.481 6.68301 19.962 7.01501 20.333C7.38301 20.744 7.34801 21.377 6.93701 21.745C6.52501 22.114 5.89302 22.078 5.52502 21.667C4.86402 20.929 4.5 19.974 4.5 18.978V17.967C4.5 15.65 6.04903 13.642 8.26703 13.085C8.95403 12.91 9.69803 13.011 10.306 13.358C11.655 14.106 13.336 14.1121 14.704 13.3521C15.303 13.0091 16.047 12.909 16.735 13.084C18.951 13.642 20.5 15.649 20.5 17.967Z"
                fill="currentColor"/>
        </svg>
        , 'User')
    return <Icon {...props} data-cy="user-icon"/>
}