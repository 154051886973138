import {createSvgIcon} from "@mui/material/utils";


export default function LayerIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9913 16.923L12.9913 16.923L12.9863 16.9269C12.4088 17.3841 11.5873 17.3847 11.0087 16.9348C11.0087 16.9348 11.0087 16.9348 11.0086 16.9347L3.03177 10.7279C3.03149 10.7277 3.03121 10.7275 3.03093 10.7272C2.81286 10.5553 2.81293 10.2155 3.03115 10.0437C3.03135 10.0435 3.03156 10.0434 3.03177 10.0432L11.0068 3.82601C11.6008 3.37181 12.4148 3.3762 12.9913 3.82449C12.9913 3.82452 12.9913 3.82455 12.9914 3.82458L20.9682 10.0314C20.9685 10.0317 20.9689 10.0319 20.9692 10.0321C21.1871 10.204 21.187 10.5435 20.9691 10.7154C20.9688 10.7156 20.9685 10.7159 20.9682 10.7161L12.9913 16.923Z"
                stroke="currentColor" strokeWidth="1.5"/>
            <path
                d="M21.1448 15.2476C21.1448 15.2476 12.9627 22.0894 12.1335 22.0894C11.3044 22.0894 2.85522 15.2476 2.85522 15.2476"
                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        , 'Layer')
    return <Icon {...props} data-cy="layer-icon"/>
}