import React from 'react';
import { Autocomplete, TextField, Button, MenuItem } from '@mui/material';
import CloseIcon from "../../shared/assets/icons/Close";
import PlusSquareIcon from "../../shared/assets/icons/PlusSquare";

const EntityGroupSelector = ({ value, onChange, onInputChange, options, onAddOption }) => {

    const placeholder = 'No group'
    const getOptionLabel=(option) => option?.group || ''
    const renderInput = (params) => (
        <TextField
            {...params}
            placeholder={placeholder}
        />
    );
    const renderGroupOption = (props, option) => {
        // Add new group
        if (option.inputValue) {
            return (
                <Button
                    variant={'outlined'}
                    size={'small'}
                    startIcon={<PlusSquareIcon size={'small'}/>}
                    onClick={(event) => {
                        event.stopPropagation();
                        onAddOption(option.inputValue);
                        // handleAddGroupClick(option.inputValue);
                    }}
                    style={{width: 'max-content'}}
                >
                    {option.inputValue}
                </Button>
            );
        }

        // general option
        return <MenuItem {...props} style={{minHeight:"28px"}}>{option.group}</MenuItem>;
    };

    const filterOptions = (options, { inputValue }) => {
        const filtered = options.filter((option) => option.group.toLowerCase().includes(inputValue.toLowerCase()));
        if (inputValue && !options.includes(inputValue.toLowerCase())) {
            filtered.unshift({
                group: `${inputValue}`,
                inputValue,
            });
        }
        return filtered;
    };

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
            options={options}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            renderOption={renderGroupOption}
            renderInput={renderInput}
            sx={{ width: '100%' }}
        />
    );
};

export default EntityGroupSelector;