import {createSvgIcon} from "@mui/material/utils";

export default function MapPinIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 12.9041V17C11 17.553 11.448 18 12 18C12.552 18 13 17.553 13 17V12.9041C15.556 12.4321 17.5 10.19 17.5 7.5C17.5 4.468 15.033 2 12 2C8.967 2 6.5 4.468 6.5 7.5C6.5 10.19 8.444 12.4321 11 12.9041ZM12 4C13.93 4 15.5 5.57 15.5 7.5C15.5 9.43 13.93 11 12 11C10.07 11 8.5 9.43 8.5 7.5C8.5 5.57 10.07 4 12 4ZM20 18C20 20.626 15.976 22 12 22C8.024 22 4 20.626 4 18C4 16.298 5.795 14.9181 8.802 14.3101C9.344 14.1981 9.87101 14.551 9.98001 15.092C10.09 15.633 9.74 16.161 9.198 16.271C7.016 16.712 6 17.564 6 18C6 18.705 8.278 20 12 20C15.722 20 18 18.705 18 18C18 17.563 16.983 16.712 14.802 16.271C14.26 16.162 13.91 15.633 14.02 15.092C14.129 14.55 14.66 14.1981 15.198 14.3101C18.205 14.9181 20 16.298 20 18Z"
                fill="currentColor"/>
        </svg>, 'MapPin')
    return <Icon {...props} data-cy="map-pin-icon"/>
}