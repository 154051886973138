import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {SearchInput} from 'shared/ui/SearchInput'
import {GroupedFilter} from '../ui/GroupListFilter'

const filtersType = {
    layers: 'Layers',
    locations: 'Locations',
}

export function MapSidebar(
    {
        reEnableWmsIfNeed,
        setActiveProducts,
        onSwitchProduct,
        onSwitchLocation,
        products = [],
        locations = [],
        activeProducts = [],
        activeLocations = [],
    }) {
    const [textFilter, setTextFilter] = useState('')
    const [filter, setFilter] = useState(filtersType.layers)
    const [displayedFilterList, setDisplayedFilterList] = useState(<></>)
    const filterListRef = useRef(null)

    useEffect(() => {
        const search = textFilter.toLowerCase()
        if (filter === filtersType.layers) {
            setDisplayedFilterList(
                <GroupedFilter
                    reEnableWmsIfNeed={reEnableWmsIfNeed}
                    setActiveProducts={setActiveProducts}
                    objects={search ? products.filter((p) => (p.name.toLowerCase().indexOf(search) > -1)) : products}
                    onChange={onSwitchProduct}
                    activeIds={activeProducts}
                    key="productsFilter"
                    disableGroupCheckbox={true}
                />)
        }
        if (filter === filtersType.locations) {
            setDisplayedFilterList(
                <GroupedFilter
                    reEnableWmsIfNeed={reEnableWmsIfNeed}
                    objects={search ? locations.filter((p) => (p.label.toLowerCase().indexOf(search) > -1)) : locations}
                    onChange={onSwitchLocation}
                    typeFilter="locations"
                    activeIds={activeLocations}
                    key="locationsFilter"
                    disableGroupCheckbox={false}
                />)
        }
    }, [textFilter, filter, products, activeProducts, activeLocations])

    useEffect(() => {
        const storedScrollPosition = sessionStorage.getItem(`scrollPosition-${filter}`)
        if (storedScrollPosition && filterListRef.current) {
            // need async solution, but it works
            window.setTimeout(()=>{
                filterListRef.current.scrollTop = parseInt(storedScrollPosition, 10)
            }, 10)
        }
    }, [filter])

    useEffect(() => {
        const handleScroll = () => {
            if (filterListRef.current) {
                sessionStorage.setItem(`scrollPosition-${filter}`, filterListRef.current.scrollTop)
            }
        }

        if (filterListRef.current) {
            filterListRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (filterListRef.current) {
                filterListRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [filter])

    const handleFilterChange = (event, newFilter) => {
        if (!newFilter) {
            return
        }
        setFilter(newFilter)
    }
    return (
        <Box
            className={'column fullHeight'}
            sx={{minWidth: '288px'}}
        >
            <Box
                className={'column'}
                sx={{gap: '10px', padding: '10px 16px', width: '400px'}}
            >
                <SearchInput
                    placeholder={`Search for ${filter === filtersType.layers ? 'layers' : 'locations'}`}
                    onChange={(event) => setTextFilter(event.target.value)}
                />
                <ToggleButtonGroup
                    className={'fullWidth'}
                    value={filter}
                    exclusive
                    size={'small'}
                    onChange={handleFilterChange}
                >
                    <ToggleButton
                        variant={'secondary'}
                        value={filtersType.layers}
                        size={'small'}
                    >
                        Layers
                    </ToggleButton>
                    <ToggleButton
                        variant={'secondary'}
                        value={filtersType.locations}
                        size={'small'}
                    >
                        Locations
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box
                    ref={filterListRef}
                    className={'column gap8 fullWidth hint--top hint-rounded'}
                    sx={{height: `${window.innerHeight - 200}px`, textOverflow: 'ellipsis'}}
                >
                    {displayedFilterList}
                </Box>
            </Box>
        </Box>
    )
}
