import React from 'react';
import {Box, IconButton} from "@mui/material";
import MapPinIcon from "../../../../shared/assets/icons/MapPin";
import {Spacer} from "../../../../shared/ui/Spacer";
import PenIcon from "../../../../shared/assets/icons/Pen";
import CloseIcon from "../../../../shared/assets/icons/Close";

const SelectedLocation = ({location, onEdit, onDelete}) => {
    return (
        <Box style={{
            display: "flex",
            flexDirection: "row",
            padding: "6px 12px",
            gap: "4px",
            background: "white",
            borderRadius: "8px",
            border: "1px solid var(--palette-grey-100)",
            alignItems: "center",
            boxShadow: "0px 1px 3px 0px #677A8E3D"
        }}>
            <Box style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                color: "var(--palette-grey-900)"
            }}>
                <Box style={{display:"flex", alignItems:"start", gap:"8px"}}>
                    <Box style={{marginTop:"1px"}}>
                        <MapPinIcon size={"small"}/>
                    </Box>
                    <Box style={{fontWeight: "500", fontSize: "14px", wordBreak: "break-all", }}>
                        {location.label}
                    </Box>
                </Box>
                <Box style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "var(--palette-grey-500)",
                    wordBreak: "break-all",
                    marginLeft:"24px"
                }}>
                    {location.location_group}
                </Box>
            </Box>
            <Spacer/>
            <Box style={{display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                <IconButton
                    style={{boxShadow: "0px 1px 3px 0px #677A8E3D"}}
                    data-cy={'edit-button'}
                    variant={'outlined'}
                    color={'secondary'}
                    size={'small'}
                    onClick={onEdit}
                >
                    <PenIcon size={'small'}/>
                </IconButton>
                <IconButton
                    style={{boxShadow: "0px 1px 3px 0px #677A8E3D"}}
                    variant={'contained'}
                    color={'error'}
                    size={'small'}
                    onClick={onDelete}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default SelectedLocation;