import React from 'react'
import Modal from '@mui/material/Modal'
import {Box} from '@mui/material'

function ModalComponent({children, visible, onCloseModal, style={}}) {
    const stopPropagationForTab = (event) => {
        if (event.key === 'Tab') {
            event.stopPropagation()
        }
    }

    if (!visible) {
        return (
            <></>
        )
    }

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '24px',
        gap: '16px',
        background: '#FFFFFF',
        border: '1px solid #F0F2F5',
        boxShadow: '0px 4px 12px rgba(103, 122, 142, 0.24)',
        borderRadius: '12px',
    }

    return (
        <Modal
            open={visible}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyDown={stopPropagationForTab}
        >
            <Box sx={{...styleModal, ...style}}>
                {children}
            </Box>
        </Modal>
    )
}
export default ModalComponent
