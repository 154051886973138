import {FormHelperText, IconButton} from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import React, {useEffect, useState} from 'react'

import EyeIcon from 'shared/assets/icons/Eye'
import EyeSlashIcon from 'shared/assets/icons/EyeSlashIcon'

export function PasswordField({onChange, helperText, error, ...other}) {
    const [showPassword, setShowPassword] = useState(false)
    const [textHelper, setTextHelper] = useState('')

    useEffect(() => {
        setTextHelper("<div>{'First <br /> Second'}</div>")
    }, [textHelper])

    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth
            required
        >
            <OutlinedInput
                // id="outlined-adornment-password"
                autoComplete={'on'}
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                placeholder={'Type in'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            disableFocusRipple={true}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <EyeIcon/> : <EyeSlashIcon/>}
                        </IconButton>
                    </InputAdornment>
                }
                {...other}
            />
            <FormHelperText
                error={error}
                id="helper-text"
            >
                {helperText}
            </FormHelperText>
        </FormControl>
    )
}
