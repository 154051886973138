import {MapLibreLocationStatic} from "features/map/ui/mapLibreLocation/MapLibreLocation";
import React, {useEffect, useState, useRef} from 'react';
import {
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Snackbar,
    Alert, Typography, IconButton
} from '@mui/material';
import AreaIcon from "shared/assets/icons/Area";
import LineIcon from "shared/assets/icons/Line";
import MapPinIcon from "shared/assets/icons/MapPin";
import {LocationSearch} from 'features/location/locationSearcher/locationSearch';
import requester from 'shared/libs/requester/baron-signature.js';
import 'widgets/location/ui/manageLocationScreen/ManageLocationScreen.css';
import {CheckBox} from "shared/ui/CheckBox";
import {postLocation, putLocation, fetchRealLocations, deleteLocation} from 'pages/alerts/api/FetchAlerts';
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import {AddLocation} from "features/location/AddLocation";
import ArrowRightSmallIcon from "shared/assets/icons/ArrowRightSmall";
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import {SearchInput} from "shared/ui/SearchInput";
import {api} from '../../../../shared/libs/Auth'
import ImportCSVModal from "../../../../shared/ui/ImportCSVModal";
import SelectedLocation from "../../../../pages/settings/ui/savedLocations/SelectedLocation";
import ArrowDownSmallIcon from "../../../../shared/assets/icons/ArrowDownSmall";
import LastCreatedLocation from "../../../../pages/settings/ui/savedLocations/LastCreatedLocation";
import SelectLocationModal from "../../../../pages/settings/ui/savedLocations/SelectLocationModal";

const blankLocation = {title: '', radius: 0, group: '', groups: []}

// defaultLocation - geojson and type for editing should be detected automatically
export function ManageLocationScreen({
                                         defaultLocation = {},
                                         onChange,
                                         mapPositionRef,
                                         closeOnSave = false,
                                         editLocation = false,
                                         showLocations = false,
                                         selectLocations = false,
                                         selectedLocationsResult = new Map(),
                                         onCreatingLocation = () => {},
                                         stepOfAlert = false,
                                         lastCreatedLocs,
                                         onLocationCreated = () => {},
                                     }) {
    const [locationSettings, setLocationSettings] = useState(defaultLocation.options || {...blankLocation});
    const [coords, setCoords] = useState(defaultLocation.coordinates || Array.from(selectedLocationsResult.values()).pop() || (mapPositionRef?.current.position ? Object.values(mapPositionRef.current.position) : [-97, 38]));
    const [tmpCoords, setTmpCoords] = useState(defaultLocation.coordinates || Array.from(selectedLocationsResult.values()).pop() || (mapPositionRef?.current.position ? Object.values(mapPositionRef.current.position) : [-97, 38]));
    const [chosenCoords, setChosenCoords] = useState(!!editLocation);
    const [selectedSidebarMenu, setSelectedSidebarMenu] = useState("MainMenu"); // will use: 0-main menu, 1-add location menu, 2-existing location menu;
    const [currentEditType, setCurrentEditType] = useState((!editLocation) ? "noEditing" : "point"); // will use: 0-no editing, 1-point, 2-line, 3-polygone;
    const [freeze, setFreeze] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [lastCreatedLocations, setLastCreatedLocations] = useState(lastCreatedLocs || null);
    const [isEditingMode, setIsEditingMode] = useState(false);

    const [locations, setLocations] = useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(selectedLocationsResult);
    const [accordeon, setAccordeon] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const mapPositionRefLocal = useRef({});
    const [isImporting, setIsImporting] = useState(false);
    const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    });

    const openSnackbar = (message, severity) => {
        setSnackbar({open: true, message, severity});
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({open: false, message: "", severity: "success"});
    };

    useEffect(() => {
        fetchRealLocations().then((data) => {
            setLocations(data && data.location);
            calcGroups(data.location);
        });
    }, [freeze]);

    useEffect(() => {
        if (selectedLocations.size === 0 && selectedLocationsResult.size !== 0) {
            setSelectedLocations(selectedLocationsResult);
        }
    }, [selectedLocationsResult]);

    useEffect(() => {
        onCreatingLocation(currentEditType === "noEditing") //listener of start and end create of location
    }, [currentEditType, selectedSidebarMenu]);

    const calcGroups = (locations) => {
        if (!locations) {
            return;
        }
        const groups = {};
        locations.forEach(location => {
            if (!groups[location.location_group]) groups[location.location_group] = [];
            groups[location.location_group].push(location);
        });
        const groupsList = [];
        let ungrouped = [];
        let accordeon = {};
        for (let key in groups) {
            if (!key) {
                ungrouped = groups[key];
                continue
            }
            groupsList.push({
                group: key,
                locations: groups[key],
            })
            accordeon[key] = false;
        }
        groupsList.sort((a, b) => a.group.localeCompare(b.group));
        const newSettings = {...locationSettings};
        //newSettings.groups = [...groupsList];
        if (ungrouped.length) {
            let key = '';
            groupsList.push({group: key, locations: ungrouped})
            accordeon[key] = false;
        }
        setGroups(groupsList);
        setLocationSettings(newSettings);
        setAccordeon(accordeon);
    };
    let accordeonChanged = group => (event, isExpanded) => {
        let a = {...accordeon};
        a[group] = isExpanded;
        setAccordeon(a);
    };
    let searchStringChanged = ({target}) => {
        let s = target.value.trim();
        deselectAll();
        if (searchFilter.length === 0 && s.length !== 0) {
            let a = {...accordeon};
            for (let g in a) {
                a[g] = true;
            }
            setAccordeon(a);
        } else if (s.length === 0) {
            let a = {...accordeon};
            for (let g in a) {
                a[g] = false;
            }
            setAccordeon(a);
        }
        setSearchFilter(s);
    };

    useEffect(() => {
        if (selectLocations)
            onChange(selectedLocations);
    }, [selectedLocations]);

    function clearEntity() {
        setCoords(undefined);
    }

    function confirmSelection() {
        setCoords(tmpCoords);
        setCurrentEditType("noEditing");
        console.log("confirmSelection", coords, tmpCoords);
    }

    function cancelSelection() {
        setTmpCoords(coords);
        setCurrentEditType("noEditing");
    }

    const onApiResponse = (data, newLocationData) => {
        setFreeze(false);
        if (defaultLocation.coordinates || closeOnSave) {
            onChange(true);
            return
        }
        setLocationSettings({...blankLocation})
        setCurrentEditType("noEditing");
        setSelectedSidebarMenu("MainMenu");
        const selected = new Map(selectedLocations);
        if(data.id && data.coordinates){
            selected.set(data.id, data.coordinates);
            setSelectedLocations(selected);
        }

        if (lastCreatedLocations === null) { //Add first new location in lastCreatedLocation array
            setLastCreatedLocations([data]);
            onLocationCreated([data]);
        } else {
            if (isEditingMode) { //Edit location from lastCreatedLocation array
                const newLastCreatedLocations = lastCreatedLocations.map(location => {
                    return location.id === data[0] ? {
                        ...location,
                        label: newLocationData.label,
                        location_group: newLocationData.location_group,
                        coordinates: newLocationData.coordinates
                    } : location
                });
                setLastCreatedLocations(newLastCreatedLocations);
                onLocationCreated(newLastCreatedLocations)
            } else { //Add new location in lastCreatedLocation array
                const newLastCreatedLocations = [...lastCreatedLocations, data];
                setLastCreatedLocations(newLastCreatedLocations);
                onLocationCreated(newLastCreatedLocations);
            }
        }
//        const nextStepBtn = document.getElementById('newalertstep2');
//        if(nextStepBtn) nextStepBtn.click();
    }

    function prepareLocationObj(coords, locationSettings) {
        if (!coords || !locationSettings) {
            return;
        }
        const newLocation = {
            label: locationSettings.title || '',
            location_group: locationSettings.group || '',
            coordinates: coords
        }
        if (isEditingMode) {
            putLocation(locationSettings.id, newLocation).then((data) => onApiResponse(data, newLocation));
        } else {
            if (!defaultLocation.options) { // create new location
                postLocation(newLocation).then(onApiResponse);
            } else {
                putLocation(locationSettings.id, newLocation).then(onApiResponse);
            }
        }

        setFreeze(true);
        setChosenCoords(false);
        setIsEditingMode(false);
    }

    const handleEditLocation = (id) => {
        setIsEditingMode(true);

        const idToEdit = id;
        const editingLocation = locations.find(loc => loc.id === idToEdit);
        setTmpCoords(editingLocation.coordinates);

        let newLocationSettings = Object.assign({}, locationSettings);
        newLocationSettings.group = editingLocation.location_group;
        newLocationSettings.title = editingLocation.label;
        newLocationSettings.id = idToEdit;
        setLocationSettings(newLocationSettings)
    }

    const deleteLastLocationById = (id) => {
        const idToDelete = id;

        const updatedLastCreatedLocations = lastCreatedLocations.filter(loc => loc.id !== idToDelete);
        setLastCreatedLocations(updatedLastCreatedLocations);
        onLocationCreated(updatedLastCreatedLocations);

        const selected = new Map(selectedLocations);
        selected.delete(idToDelete);
        setSelectedLocations(selected);
        deleteLocation(idToDelete).then(r => {
            console.log("location deleted");
            setFreeze(false)
        })
    }

    let extractFullSelectedLocationsInfo = (selected) => {
        let ids = [];
        for (const id of selected.keys()) {
            ids.push(parseInt(id));
        }
        return locations.filter(x => ids.includes(x.id));
    };
    const groupLocations = group => {
        let locations = group.locations.sort((a, b) => a.label.localeCompare(b.label));
        if (searchFilter.length > 0) {
            return locations.filter(loc => filterName(loc.label));
        }
        return locations;
    }
    const allSelected = () => {
        if (!groups.length) return false;
        let selected = true;
        groups.forEach((group) => {
            if (!groupSelected(groupLocations(group))) selected = false;
        });
        return selected;
    }
    const allIndeterminate = () => {
        if (!groups.length) return false;
        let total = 0;
        let selected = 0;
        groups.forEach((group) => {
            let locations = groupLocations(group);
            if (locations.length > 0) {
                let n = groupNofSelected(locations);
                total += locations.length;
                selected += n;
            }
        });
        // console.log("allIndetermine", total, selected);
        return selected !== 0 && selected < total;
    }
    const selectAll = () => {
        let state = !allSelected();
        let selected = new Map(selectedLocations);
        groups.forEach((group) => {
            groupLocations(group).forEach((loc) => {
                if (!selected.has(loc.id) && state) selected.set(loc.id, loc.coordinates);
                if (!state) selected.delete(loc.id);
            });
        });
        setSelectedLocations(selected);
    }
    const deselectAll = () => {
        let selected = new Map(selectedLocations);
        groups.forEach((group) => {
            group.locations.forEach((loc) => {
                selected.delete(loc.id);
            });
        });
        setSelectedLocations(selected);
    }
    const groupNofSelected = (locations) => {
        let cnt = 0;
        locations.forEach((loc) => {
            if (selectedLocations.has(loc.id)) {
                cnt++;
            }
        });
        return cnt;
    }
    const groupSelected = (locations) => {
        let selected = true;
        locations.forEach((loc) => {
            if (!selectedLocations.has(loc.id)) selected = false;
        });
        return selected;
    }
    const groupIndeterminate = (locations) => {
        const state0 = !selectedLocations.has(locations[0].id);
        let indet = false;
        locations.forEach((loc) => {
            if (!selectedLocations.has(loc.id) !== state0) indet = true;
        });
        return indet;
    }
    const selectGroup = (ev, locations) => {
        if (ev) ev.stopPropagation();
        let state = !groupSelected(locations);
        let selected = new Map(selectedLocations);
        locations.forEach((loc) => {
            if (!selected.has(loc.id) && state) selected.set(loc.id, loc.coordinates);
            if (!state) selected.delete(loc.id);
        });
        setSelectedLocations(selected);
    }
    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    const clearLocationSetting = () => {
        let newLocationSettings = Object.assign({}, locationSettings);
        newLocationSettings.group = "";
        newLocationSettings.groups = [];
        newLocationSettings.radius = 0;
        newLocationSettings.title = "";
        setLocationSettings(newLocationSettings);
    }

    const handleCancel = () => {
        setChosenCoords(false);
        setCurrentEditType("noEditing");
        setSelectedSidebarMenu("MainMenu");
        setIsEditingMode(false);
        clearLocationSetting();
    }

    const handleDelete = (locId) => {
        setFreeze(true);
        handleDeleteFromSelectedLocations(locId);
        deleteLastLocationById(locId);
        clearLocationSetting();
    }

    const handleDeleteFromSelectedLocations = (locId) => {
        setSelectedLocations(prevState => {
            const selected = new Map(prevState);
            if (selected.has(locId)) {
                selected.delete(locId);
            }
            return selected
        })
    }

    const importLocationsActionHandler = (file, groupName, onSuccess, onError) => {
        setIsImporting(true);
        if (!file) {
            console.log("Import cancelled or failed.");
            openSnackbar("Import cancelled or failed", "error");
            setIsImporting(false);
            return;
        }
        console.log("Processing file:", file.name, "for group:", groupName);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('group', groupName);

        api.post('/locations', formData, {
            headers: {
                'Content-Type': undefined
            }
        })
            .then(response => {
                console.log("File uploaded successfully:", response.data);
                openSnackbar("Locations imported successfully", "success");
                fetchRealLocations().then(data => {
                    setLocations(data && data.location);
                    calcGroups(data.location);
                    setIsImporting(false);
                });
                onSuccess(response.data);
            })
            .catch(error => {
                onError(error.response ? error.response.data : {error: error.message});
                setIsImporting(false);
            });
        // .then(response => {
        //     console.log("File uploaded successfully:", response.data);
        //     openSnackbar("Locations imported successfully", "success");
        //     setErrorMessage(null);
        //     fetchRealLocations().then(data => {
        //         setLocations(data && data.location);
        //         calcGroups(data.location);
        //     });
        // })
        // .catch(error => {
        //     console.error("Failed to upload file:", error);
        //     openSnackbar("Failed to upload file: " + error.message, "error");
        //     setErrorMessage("The file format is incorrect. Please try to upload CSV file.");
        // });
    };

    const handleOpenSelectLocation = (location) =>{
        if (!location) return;
        setOpenSelectLocationModal(location);
    }

    const handleSelectLocation = (location) => {
        if (!location) return;

        setSelectedLocations(prevState => {
            const selected = new Map(prevState);
            console.log({prevState})
            if (!selected.has(location.id)) {
                selected.set(location.id, location.coordinates);
            } else {
                selected.delete(location.id);
            }

            return selected
        })
        setOpenSelectLocationModal(false)
    }

    return (
        <Box className={'column gap8 fullWidth LocationMapSelector'} sx={{
            flex: '1 1 auto',
            height: "100%",
            width: "96.5%",
            backgroundColor: "white",
            padding: stepOfAlert ? "2px" : "24px",
            borderRadius: "16px"
        }}>
            <Box sx={{
                display: "flex",
                flexGrow: '1',
                flexDirection: "row",
                gap: "12px",
                alignItems: 'start',
                overflow: 'hidden'
            }}>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px"}}>
                    <LocationSearch
                        requester={requester}
                        onSelect={(data) => {
                            if (data.crds) setTmpCoords(data.crds)
                            setChosenCoords(true)
                            setSelectedSidebarMenu("addLocationMenu")
                            setCurrentEditType("point")
                            setLocationSettings({
                                ...locationSettings,
                                title: data.name,
                            })
                        }}
                        mapPositionRef={mapPositionRef || mapPositionRefLocal}
                    />
                    <MapLibreLocationStatic
                        onSelectLocation={handleOpenSelectLocation}
                        onChange={(lngLat) => {
                            setChosenCoords(true);
                            setSelectedSidebarMenu("addLocationMenu");
                            setCurrentEditType("point");
                            setTmpCoords([Math.round(lngLat[0] * 10000) / 10000, Math.round(lngLat[1] * 10000) / 10000]);
                        }}
                        coordinates={tmpCoords}
                        editMode={1}
                        locations={locations}
                        selectedLocations={extractFullSelectedLocationsInfo(selectedLocations)}
                        chosenCoords={chosenCoords}
                        mapPositionRef={mapPositionRef || mapPositionRefLocal}
                        lastCreatedLocations={lastCreatedLocations}
                    />
                </Box>

                <Box
                    className='locations column'
                    sx={{
                        minWidth: '384px',
                        width: "384px",
                        height: '100%',
                        overflow: 'hidden',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        height: "100%",
                        paddingTop: "56px"
                    }}>
                        {(selectedSidebarMenu === "MainMenu" && currentEditType === "noEditing" && !defaultLocation.coordinates) &&
                            <Box style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                                {selectedLocations.size > 0 && stepOfAlert &&
                                    <Accordion variant={"light"}
                                               defaultExpanded
                                               style={{
                                                   width: "100%",
                                                   display: "flex",
                                                   flexDirection: "column",
                                                   padding: "16px 12px",
                                                   border: "solid 1px var(--palette-grey-100)",
                                                   borderRadius: "8px",
                                                   backgroundColor: "var(--palette-grey-background)",
                                               }}>
                                        <AccordionSummary
                                            expandIcon={
                                                <IconButton
                                                    size='small'
                                                    variant='outlined'
                                                >
                                                    <ArrowDownSmallIcon size={'small'}/>
                                                </IconButton>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                width: '100%',
                                                height: "32px",
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: '8px',
                                                padding: "0",
                                            }}>
                                            <Typography
                                                style={{
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                    color: "var(--palette-grey-600)"
                                                }}>
                                                Selected locations
                                                ({selectedLocations.size})
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                width: "100%",
                                                display: 'flex',
                                                flexDirection: 'column',
                                                background: 'var(--palette-grey-background)',
                                                padding: "0",
                                                gap: "8px",
                                                marginTop: "8px",
                                                maxHeight: "280px",
                                                overflowY: "auto"
                                            }}>
                                            {selectedLocations && extractFullSelectedLocationsInfo(selectedLocations).map((loc) => (
                                                <SelectedLocation
                                                    key={loc.id}
                                                    location={loc}
                                                    onEdit={() => {
                                                        setCurrentEditType("point");
                                                        setSelectedSidebarMenu("addLocationMenu");
                                                        setChosenCoords(true);
                                                        handleEditLocation(loc.id);
                                                    }}
                                                    onDelete={() => handleDeleteFromSelectedLocations(loc.id)}
                                                />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                <Box style={{
                                    display: "flex", flexWrap: "wrap", gap: "8px",
                                    width: "100%", height: "148px",
                                    padding: "16px 12px",
                                    border: "solid 1px var(--palette-grey-100)", borderRadius: "8px",
                                    backgroundColor: "var(--palette-grey-background)"
                                }}>
                                    <Typography
                                        style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-600)"}}>
                                        New location
                                    </Typography>
                                    <Box style={{display: "flex", flexWrap: "wrap", gap: "8px"}}>
                                        <Button
                                            data-cy={'add-point-button'}
                                            disabled={defaultLocation.coordinates}
                                            startIcon={<MapPinIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => {
                                                setCurrentEditType("point");
                                                setIsEditingMode(false);
                                                setChosenCoords(true);
                                                setSelectedSidebarMenu("addLocationMenu");
                                            }}
                                            style={{
                                                width: "174px",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                boxShadow: "0px 1px 3px 0px #677A8E3D"
                                            }}
                                        >
                                            Add point
                                        </Button>
                                        <Button
                                            startIcon={<LineIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => setCurrentEditType("line")}
                                            style={{
                                                width: "174px",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                boxShadow: "0px 1px 3px 0px #677A8E3D"
                                            }}
                                            disabled
                                        >
                                            Add line
                                        </Button>
                                        <Button
                                            startIcon={<AreaIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => setCurrentEditType("polygone")}
                                            style={{
                                                width: "174px",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                boxShadow: "0px 1px 3px 0px #677A8E3D"
                                            }}
                                            disabled
                                        >
                                            Add area
                                        </Button>
                                        <ImportCSVModal
                                            actionHandler={importLocationsActionHandler}
                                            initialGroups={groups}
                                            openSnackbar={openSnackbar}
                                            typeOfImportingFiles={"locations"}
                                            buttonTitle={"Import locations"}
                                            modalTitle={"Import locations"}
                                            errorMessage={errorMessage}
                                            isImporting={isImporting}
                                            style={{boxShadow: "0px 1px 3px 0px #677A8E3D"}}
                                        />
                                    </Box>
                                </Box>
                                {showLocations &&
                                    <Box style={{
                                        display: "flex", flexDirection: "column", gap: "8px",
                                        width: "100%", height: "100%",
                                        padding: "16px 12px",
                                        border: "solid 1px var(--palette-grey-100)", borderRadius: "8px",
                                        backgroundColor: "var(--palette-grey-background)"
                                    }}>
                                        <Typography style={{
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            color: "var(--palette-grey-600)"
                                        }}>
                                            Your locations
                                        </Typography>
                                        <Button
                                            data-cy={'existing-locations-button'}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            endIcon={
                                                <Box style={{
                                                    width: "32px",
                                                    height: "32px",
                                                    border: "1px solid var(--palette-grey-100)", borderRadius: "8px",
                                                    display: "flex", alignItems: "center", justifyContent: "center"
                                                }}>
                                                    <ArrowRightSmallIcon
                                                        size={"small"}
                                                    />
                                                </Box>}
                                            style={{
                                                width: "100%",
                                                height: "56px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "16px",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                boxShadow: "0px 1px 3px 0px #677A8E3D",
                                            }}
                                            onClick={() => {
                                                setSelectedSidebarMenu("existingLocationMenu");
                                                setChosenCoords(false)
                                            }}
                                        >
                                            Existing locations
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        }


                        {((selectedSidebarMenu === "addLocationMenu" && currentEditType === "point") || defaultLocation.coordinates && !showLocations) &&
                            <Box sx={{display: "flex", gap: "16px", flexDirection: "column", alignItems: "start"}}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "12px",
                                    padding: "2px"
                                }}>
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        sx={{height: "32px", width: "32px"}}
                                        disabled={defaultLocation.coordinates}
                                        onClick={handleCancel}
                                    >
                                        <ArrowLeftSmallIcon size={'small'}/>
                                    </Button>
                                    <h4>
                                        {isEditingMode ? 'Add point / Editing' : 'Add point'}
                                    </h4>
                                </Box>
                                <Divider/>
                                <AddLocation
                                    onSave={(loc) => {
                                        console.log("AddLocation", loc, coords, tmpCoords);
                                        prepareLocationObj(tmpCoords, loc);
                                    }}
                                    onClose={handleCancel}
                                    defaultValue={locationSettings}
                                    groups={groups}
                                    freeze={freeze}
                                    chosenCoords={chosenCoords}
                                    locations={locations}
                                    isEditingMode={isEditingMode}
                                    editLocation={editLocation}
                                />
                            </Box>
                        }
                        {showLocations && selectedSidebarMenu === "existingLocationMenu" &&
                            <Box sx={{
                                height: "fit-content",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px"
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "12px",
                                    padding: '2px'
                                }}>
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        sx={{height: "32px", width: "32px"}}
                                        onClick={() => {
                                            setSelectedSidebarMenu("MainMenu");
                                            setChosenCoords(false)
                                        }}
                                    >
                                        <ArrowLeftSmallIcon size={'small'}/>
                                    </Button>
                                    <h4>
                                        Existing locations
                                    </h4>
                                </Box>
                                <Divider/>
                                <SearchInput
                                    placeholder='Search for location'
                                    onChange={searchStringChanged}
                                />
                                <Box
                                    className={'row gap4'}
                                    style={{
                                        padding: '8px 10px 8px 12px',
                                        borderBottom: "1px solid var(--palette-grey-100)",
                                        cursor: "pointer",
                                        marginBottom: "-8px"
                                    }}
                                    onClick={(ev) => selectAll(ev)}
                                >
                                    <CheckBox
                                        checked={allSelected()}
                                        indeterminate={allIndeterminate()}
                                        onClick={(ev) => selectAll(ev)}
                                    />
                                    <Box className={'paragraph'} style={{color: theme.palette.grey[900]}}>
                                        All locations ({locations.length})
                                    </Box>
                                </Box>

                                <Box>
                                    <Box className='locationsInner' sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        alignContent: 'flex-start',
                                        height: "fit-content"
                                    }}>
                                        {groups.map(group => {
                                            let locations = groupLocations(group);
                                            if (locations.length === 0) {
                                                return undefined;
                                            }
                                            return (
                                                <Box className={'column fullWidth'} sx={{marginBottom: '16px'}}>
                                                    <Accordion
                                                        className={'fullWidth'}
                                                        onChange={accordeonChanged(group.group)}
                                                        variant={'light'}
                                                    >
                                                        <AccordionSummary
                                                            id={`locgroup-${group.group}-header`}
                                                            aria-controls={`locgroup-${group.group}-content`}
                                                            variant={'light'}
                                                        >
                                                            <Box className={'row gap4'}>
                                                                <CheckBox
                                                                    checked={groupSelected(locations)}
                                                                    indeterminate={groupIndeterminate(locations)}
                                                                    onClick={(ev) => selectGroup(ev, locations)}
                                                                />
                                                                <Box className={'paragraph'}
                                                                     style={{color: theme.palette.grey[900]}}>
                                                                    {group.group || 'Ungrouped locations'} ({group.locations.length})
                                                                </Box>
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails
                                                            className={'column gap8 locationGroup'}
                                                            variant={'light'}
                                                        >
                                                            {locations.map(loc => (
                                                                <Box
                                                                    className={'row gap4'}
                                                                    onClick={() => {
                                                                        handleSelectLocation(loc)
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    <CheckBox checked={selectedLocations.has(loc.id)}/>
                                                                    <Box className={'paragraph'}
                                                                         style={{color: theme.palette.grey[900]}}>
                                                                        {loc.label}
                                                                    </Box>
                                                                    <Spacer/>
                                                                    <MapPinIcon size={'small'}/>
                                                                </Box>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            {lastCreatedLocations?.length > 0 && ((stepOfAlert && selectedSidebarMenu === "addLocationMenu") || !stepOfAlert) &&
                <Box style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                    <Typography
                        className={'fullWidth'}
                        variant={'label'}
                        sx={{color: theme.palette.grey[500]}}
                    >
                        Last created locations
                    </Typography>
                    <Box
                        style={{
                            maxHeight: "95px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px"
                        }}
                        data-cy={'last-created-locations'}
                    >
                        {lastCreatedLocations.map((loc) => {
                            return (
                                <LastCreatedLocation
                                    location={loc}
                                    onEdit={() => {
                                        setCurrentEditType("point");
                                        setSelectedSidebarMenu("addLocationMenu");
                                        setChosenCoords(true);
                                        handleEditLocation(loc.id);
                                    }}
                                    onDelete={() => handleDelete(loc.id)}
                                />
                            )
                        })}
                    </Box>
                </Box>
            }
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {openSelectLocationModal &&
                <SelectLocationModal
                    location={openSelectLocationModal}
                    onSelect={(location) => handleSelectLocation(location)}
                    onCancel={() => setOpenSelectLocationModal(false)}
                    withoutButtons={!stepOfAlert}
                />}
        </Box>
    );
}
