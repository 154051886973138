import {createSvgIcon} from "@mui/material/utils";


export default function UserInfoIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 2.58606L19.414 7C19.787 7.372 20 7.88794 20 8.41394V17C20 19.757 17.757 22 15 22H9C6.243 22 4 19.757 4 17V7C4 4.243 6.243 2 9 2H13.586C14.12 2 14.622 2.20806 15 2.58606Z"
                fill="white"/>
            <path
                d="M19.414 7L15 2.58606C14.622 2.20806 14.12 2 13.586 2H9C6.243 2 4 4.243 4 7V17C4 19.757 6.243 22 9 22H15C17.757 22 20 19.757 20 17V8.41394C20 7.88794 19.787 7.372 19.414 7ZM15 5.41394L16.586 7H16.5C15.673 7 15 6.327 15 5.5V5.41394ZM15 20H9C7.346 20 6 18.654 6 17V7C6 5.346 7.346 4 9 4H13V5.5C13 7.43 14.57 9 16.5 9H18V17C18 18.654 16.654 20 15 20Z"
                fill="currentColor"/>
            <path
                d="M8.10193 10.1876C8.10193 9.99502 8.25794 9.83936 8.45017 9.83936H12.9774C13.1696 9.83936 13.3256 9.99502 13.3256 10.1876C13.3256 10.3802 13.1696 10.5358 12.9774 10.5358H8.45017C8.25794 10.5358 8.10193 10.3802 8.10193 10.1876ZM14.7186 12.2771H8.45017C8.25794 12.2771 8.10193 12.4327 8.10193 12.6253C8.10193 12.8179 8.25794 12.9736 8.45017 12.9736H14.7186C14.9108 12.9736 15.0668 12.8179 15.0668 12.6253C15.0668 12.4327 14.9108 12.2771 14.7186 12.2771ZM11.5844 14.7148H8.45017C8.25794 14.7148 8.10193 14.8705 8.10193 15.063C8.10193 15.2556 8.25794 15.4113 8.45017 15.4113H11.5844C11.7766 15.4113 11.9326 15.2556 11.9326 15.063C11.9326 14.8705 11.7766 14.7148 11.5844 14.7148Z"
                fill="currentColor"/>
        </svg>
        , 'UserInfo')
    return <Icon {...props} data-cy="user-info-icon"/>
}