import TextField from '@mui/material/TextField'
import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    MenuItem,
    Typography,
    ToggleButtonGroup, ToggleButton, DialogContent, Autocomplete,
} from '@mui/material'
import theme from '../../app/Theme'
import MapPinIcon from '../../shared/assets/icons/MapPin'
import PlusSquareIcon from '../../shared/assets/icons/PlusSquare'
import RadiusIcon from '../../shared/assets/icons/Radius'
import {NumberInput} from 'shared/ui/NumberInput'
import InfoCircleIcon from '../../shared/assets/icons/InfoCircle'

const POINT_MODE = {
    movePoint: 'movePoint',
    changeRadius: 'changeRadius',
}

export function AddLocation({
                                onSave,
                                onClose,
                                onMove,
                                defaultValue = {},
                                groups = [],
                                freeze = false,
                                chosenCoords,
                                locations,
                                isEditingMode,
                                editLocation
                            }) {
    const [newGroups, setNewGroups] = useState([...groups])
    const allGroupsOptions = newGroups.filter((group) => group.group !== '').map((group) => ({
        group: group.group,
        locations: group.locations,
    }))
    const groupNames = newGroups.map((item) => item.group.toLowerCase())

    const [title, setTitle] = useState(defaultValue.title || '')
    const [radius, setRadius] = useState(defaultValue.radius || '0')
    const [group, setGroup] = useState(allGroupsOptions.find((group) => group.group === defaultValue.group) || null)
    const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false)
    const [pointMode, setPointMode] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    useEffect(() => {
        setTitle(defaultValue.title)
        setNewGroups([...groups])
        const updatedGroups = [...groups]
        const allGroupsOptions = updatedGroups.filter((group) => group.group !== '').map((group) => ({
            group: group.group,
            locations: group.locations,
        }))
        setGroup(allGroupsOptions.find((group) => group.group === defaultValue.group) || null)
        setShowErrorMessage(false)
    }, [defaultValue])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && isAutocompleteFocused) {
                event.stopPropagation()
                handleAddGroupClick(group.group)
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [group, isAutocompleteFocused])

    const handleAddGroupClick = (inputValue) => {
        if (inputValue && !groupNames.includes(inputValue)) {
            const newOption = {group: inputValue, locations: []}
            const localGroups = [...newGroups]
            localGroups.push(newOption)
            setNewGroups(localGroups)
            setGroup(newOption)
        }
    }

    const handleGroupChange = (event, newValue) => {
        setGroup(newValue)
    }

    const filterOptions = (options, {inputValue}) => {
        const filtered = options.filter((option) => option.group.toLowerCase().includes(inputValue.toLowerCase()))
        if (inputValue && !groupNames.includes(inputValue.toLowerCase())) {
            filtered.unshift({
                group: `${inputValue}`,
                inputValue,
            })
        }
        return filtered
    }

    const renderGroupOption = (props, option) => {
        // Add new group
        if (option.inputValue) {
            return (
                <Button
                    variant={'outlined'}
                    size={'small'}
                    startIcon={<PlusSquareIcon size={'small'}/>}
                    onClick={(event) => {
                        event.stopPropagation()
                        handleAddGroupClick(option.inputValue)
                    }}
                    style={{width: 'max-content'}}
                >
                    {option.inputValue}
                </Button>
            )
        }
        // general option
        return (
            <MenuItem
                {...props}
                style={{minHeight: '28px'}}
            >
                {option.group}
            </MenuItem>
        )
    }

    const handleCancel = () => {
        onClose()
        setTitle('')
        setRadius('0')
        setGroup('')
    }

    const handleSaveLocation = () => {
        const isLocationExists = (title, locations) => {
            return locations.find((loc) => loc.label.trim().toLowerCase() === title.trim().toLowerCase());
        };

        const isGroupValid = (group, loc) => {
            return loc.location_group.trim() === group?.group.trim() || (loc.location_group.trim() === "" && group === null);
        };

        if (!isEditingMode && !editLocation && isLocationExists(title, locations)) {
            setShowErrorMessage(true);
            return;
        }

        // if ((isEditingMode || editLocation) && locations.some(loc => isGroupValid(group, loc) && isLocationExists(title, locations))) {
        //     setShowErrorMessage(true);
        //     return;
        // }

        if (title) {
            onSave({title, radius, group: group?.group, newGroups, id: defaultValue.id})
        }
    }

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            <DialogContent
                className={'column gap16'}
                sx={{width: '100%', padding: '0'}}
            >
                <Box style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography
                        className={'fullWidth'}
                        variant={'label'}
                        sx={{color: theme.palette.grey[500]}}
                    >
                        Name
                        <TextField
                            data-cy={'title-input'}
                            value={title}
                            onChange={({target}) => {
                                setTitle(target.value)
                                setShowErrorMessage(false)
                            }}
                            sx={{width: '100%'}}
                            InputProps={{
                                startAdornment: <MapPinIcon size={'small'}/>,
                            }}
                        />
                    </Typography>
                    {showErrorMessage
                        && <Typography style={{fontWeight: '500', color: 'var(--palette-warning-dark)', fontSize: '14px'}}>
                            <span style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                <InfoCircleIcon style={{color: 'var(--palette-warning-dark)'}}/>
                                                    Location with the same name already exists.
                            </span>
                        </Typography>}
                </Box>
                <Typography
                    variant={'label'}
                    sx={{color: theme.palette.grey[500]}}
                >
                    Point
                    <ToggleButtonGroup
                        className={'fullWidth'}
                        value={pointMode}
                        exclusive
                    >
                        {typeof onMove === 'function'
                            && <ToggleButton
                                className={'spacer'}
                                variant={'secondary light'}
                                value={POINT_MODE.movePoint}
                                size={'small'}
                                onClick={() => {
                                    onMove({title, radius, group: group?.group, newGroups, id: defaultValue.id})
                                }}
                               >
                                <MapPinIcon size={'small'}/>
                                <Typography>
                                    Move point
                                </Typography>
                            </ToggleButton>
                        }
                        <ToggleButton
                            className={'spacer'}
                            value={POINT_MODE.changeRadius}
                            variant={'secondary light'}
                            onClick={() => {
                                setPointMode(POINT_MODE.changeRadius)
                            }}
                            disabled
                        >
                            <RadiusIcon size={'small'}/>
                            <Typography sx={{color: '#AFB8C0'}}>
                                Change radius
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Typography>
                {pointMode === POINT_MODE.changeRadius
                    && <Typography
                        variant={'label'}
                        sx={{
                            padding: '8px 8px 16px 8px',
                            border: '1px solid',
                            borderRadius: '8px',
                            borderColor: theme.palette.grey.background,
                            backgroundColor: theme.palette.grey.background,
                        }}
                       >
                        Radius
                        <NumberInput
                            placeholder={''}
                            value={radius}
                            onChange={(value) => setRadius(value)}
                            format={(value) => `${value} km`}
                            min={0}
                        />
                    </Typography>
                }
                <Typography variant={'label'}>
                    Location group
                    <Autocomplete
                        value={group}
                        onChange={handleGroupChange}
                        onInputChange={(event, newInputGroupValue) => {
                            setGroup({group: newInputGroupValue, locations: []})
                        }}
                        onFocus={() => setIsAutocompleteFocused(true)}
                        onBlur={() => setIsAutocompleteFocused(false)}
                        options={allGroupsOptions}
                        getOptionLabel={(option) => option ? option.group : ''}
                        filterOptions={filterOptions}
                        renderOption={renderGroupOption}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='No group'
                            />
                        )}
                        sx={{width: '100%'}}
                        renderTags={() => null}
                        noOptionsText="Start typing to create a new group"
                    />
                </Typography>
                <Box
                    className={'row fullWidth'}
                    style={{padding: '2px'}}
                >
                    <Button
                        data-cy={'save-button'}
                        className={'spacer regular'}
                        disabled={freeze || !chosenCoords || title.trim() === ''}
                        onClick={handleSaveLocation}
                    >
                        Save
                    </Button>
                    <Button
                        className={'spacer regular'}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Box>
    )
}
