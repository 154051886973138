import {createSvgIcon} from "@mui/material/utils";

export default function CloseIcon(props) {
    const Icon = createSvgIcon(
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 17.5L12 12M12 12L17.5 6.5M12 12L6.5 6.5M12 12L17.5 17.5" stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>, 'Close')
    return <Icon {...props} data-cy="close-icon"/>
}